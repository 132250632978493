import { CompleteExpense } from '../schemas/fundingRequests/expenses/expenseSchema';
import { CompleteFundingRequest } from '../schemas/fundingRequests/fundingRequestSchema';

export type AddFundingRequest = {
  type: 'ADD_REQUEST';
  request: CompleteFundingRequest;
  isAlternate?: boolean;
  expense?: CompleteExpense;
};

export const addFundingRequest = (
  request: AddFundingRequest['request'],
  isAlternate?: AddFundingRequest['isAlternate'],
  expense?: AddFundingRequest['expense']
): AddFundingRequest => ({
  type: 'ADD_REQUEST',
  request,
  isAlternate,
  expense,
});
