import { match } from 'ts-pattern';
import {
  ExpenseMachineSender,
  ExpenseMachineState,
} from '../../../machines/components/ExpenseMachine';
import { DatePicker } from '../../DatePicker/DatePicker';
import { FormField } from '../../FormField';
import { TextArea } from '../../TextArea';
import { TextInput } from '../../TextInput';
import { Tooltip } from '../../Tooltip';

import styles from './ExpenseFormFields.module.scss';

interface ExpenseFormFieldsProps {
  state: ExpenseMachineState;
  send: ExpenseMachineSender;
}

export function ExpenseFormFields({ state, send }: ExpenseFormFieldsProps) {
  const { expense } = state.context;

  return (
    <>
      <div className={styles.row}>
        <FormField
          label="Vendor Name"
          feedbackType="warning"
          formFieldStatus={
            state.matches('editing.vendor.invalid') ? 'error' : 'default'
          }
          infoLabel={match(state)
            .when(
              () => state.matches('editing.vendor.invalid.empty'),
              () => 'This field is required.'
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="vendor"
            value={expense.vendor}
            onChange={(vendor) => send({ type: 'SET_VENDOR', vendor })}
            onFocus={() => send('FOCUS_VENDOR')}
            onBlur={() => send('BLUR_VENDOR')}
          />
        </FormField>
        <FormField
          label="Amount Spent"
          feedbackType="warning"
          formFieldStatus={
            state.matches('editing.cost.invalid') ? 'error' : 'default'
          }
          infoLabel={match(state)
            .when(
              () => state.matches('editing.cost.invalid.empty'),
              () => 'This field is required.'
            )
            .when(
              () => state.matches('editing.cost.invalid.value'),
              () => 'This field must be numeric.'
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="cost"
            value={expense.cost}
            onChange={(cost) => send({ type: 'SET_COST', cost })}
            onFocus={() => send('FOCUS_COST')}
            onBlur={() => send('BLUR_COST')}
          />
        </FormField>
      </div>
      <div className={styles.row}>
        <FormField
          label="Invoice Number"
          feedbackType="warning"
          formFieldStatus={
            state.matches('editing.invoice-number.invalid')
              ? 'error'
              : 'default'
          }
          infoLabel={match(state)
            .when(
              () => state.matches('editing.invoice-number.invalid.empty'),
              () => 'This field is required.'
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="invoice"
            value={expense.invoiceNumber}
            onChange={(invoiceNumber) =>
              send({ type: 'SET_INVOICE_NUMBER', invoiceNumber })
            }
            onBlur={() => send('BLUR_INVOICE_NUMBER')}
            onFocus={() => send('FOCUS_INVOICE_NUMBER')}
          />
        </FormField>
        <FormField
          label="Invoice Date"
          feedbackType="warning"
          formFieldStatus={
            state.matches('editing.date.invalid') ? 'error' : 'default'
          }
          infoLabel={match(state)
            .when(
              () => state.matches('editing.date.invalid.empty'),
              () => 'This field is required.'
            )
            .when(
              () => state.matches('editing.date.invalid.value'),
              () => 'Date must be on or after the application submission date.'
            )
            .otherwise(() => '')}
        >
          <DatePicker
            aria-label="Date of the expense"
            value={state.context.expense.date}
            onChange={(date) => send({ type: 'SET_DATE', date })}
            onBlur={() => send('BLUR_DATE')}
            onFocus={() => send('FOCUS_DATE')}
          />
        </FormField>
      </div>
      <FormField label="Description" note="Optional">
        <TextArea
          id="description"
          label="Description of expense"
          size="small"
          value={expense.description ?? ''}
          onChange={(description) =>
            send({ type: 'SET_DESCRIPTION', description })
          }
        />
      </FormField>
      <Tooltip
        label="Have multiple expenses?"
        icon="warningCircle"
        status="default"
      >
        <p>
          If you have multiple expenses to submit, you’ll have the option to add
          more after submitting the first one.
        </p>
      </Tooltip>
    </>
  );
}
