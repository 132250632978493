import cn from 'classnames';
import {
  FundingRequestsBlockMachineState,
  FundingRequestsBlockMachineSender,
} from '../../machines/components/FundingRequestsBlockMachine';
import { Block } from '../Block';
import { Button } from '../Button';
import { FundingRequestFormController } from './FundingRequestFormController';
import { Feedback } from '../Feedback';
import { formatMoney } from '../../utils/formatMoney';
import { getFundingRequestLabels } from '../../utils/getFundingRequestLabels';
import {
  ApplicationStage,
  ApplicationStatus,
  ApplicationType,
} from '../../graphql/operations';
import { ErrorFeedback } from '../ErrorFeedback';
import { FormFieldLabel } from '../FormFieldLabel';
import { match, P } from 'ts-pattern';
import { FundingRequestCardController } from './FundingRequestsCard/FundingRequestCardController';
import { SubmittedFundingRequestCardController } from './FundingRequestsCard/SubmittedFundingRequestCardController';

import styles from './FundingRequestsBlockView.module.scss';

interface FundingRequestsBlockViewProps {
  state: FundingRequestsBlockMachineState;
  send: FundingRequestsBlockMachineSender;
}

function ExtendedFundingRequestView({
  extendedRequest: {
    isOpen,
    request,
    ref,
    cardRef,
    submittedCardRef,
    isUnsettledCardView,
  },
  showExpenseEdit,
  showExpenseDelete,
}: {
  extendedRequest: FundingRequestsBlockMachineState['context']['extendedRequests'][0];
  showExpenseEdit: boolean;
  showExpenseDelete: boolean;
}) {
  return match([isOpen, request.isWritable, isUnsettledCardView])
    .with([true, true, true], () => (
      <SubmittedFundingRequestCardController
        key={request.id}
        actor={submittedCardRef}
        showExpenseEdit={true}
        showExpenseDelete={request.expenses.length > 1}
      />
    ))
    .with([true, true, P._], () => (
      <FundingRequestFormController key={request.id} actor={ref} />
    ))
    .with([true, P._, P._], () => (
      <SubmittedFundingRequestCardController
        key={request.id}
        actor={submittedCardRef}
        showExpenseEdit={showExpenseEdit}
        showExpenseDelete={showExpenseDelete}
      />
    ))
    .with([false, P._, P._], () => (
      <FundingRequestCardController
        key={request.id}
        actor={cardRef}
        showExpenseEdit={showExpenseEdit}
        showExpenseDelete={showExpenseDelete}
      />
    ))
    .exhaustive();
}

export function FundingRequestsBlockView({
  state,
  send,
}: FundingRequestsBlockViewProps) {
  const {
    applicationType,
    stage,
    year,
    remainingYearlyTotalForFundingRequests,
    maximumYearlyTotalForFundingRequests,
    submittedAt,
    openRequest,
  } = state.context;
  const { title, buttonText, iconName } =
    getFundingRequestLabels(applicationType);
  const isSubmitted = submittedAt !== null;
  const isApproved =
    state.context.applicationStatus === ApplicationStatus.Approved;

  const canEditOrDeleteExpenses = ![
    ApplicationStage.ClaimsSubmitted,
    ApplicationStage.ClaimsResubmitted,
    ApplicationStage.Completed,
  ].includes(stage);

  const alternateButton = (
    <Button
      id="addFundingRequestButton"
      variant="addnew"
      label={`Add an Alternate ${buttonText}`}
      onClick={() => send({ type: 'OPEN_REQUEST' })}
      disabled={state.context.budgetForFundingRequests < 0.01}
    />
  );

  return (
    <Block id={title} headerText={title} iconName={iconName}>
      {!submittedAt && applicationType === ApplicationType.Core && (
        <p>
          In addition to core tour funding, you may request up to $30,000 per
          year to subsidize the creation of Digital Content (includes the
          following only: videographer, media for online marketing and/or the
          cost of a music video – not the cost of placing any ads or
          photoshoots).
        </p>
      )}
      <div className={styles.requests}>
        {isSubmitted && isApproved && (
          <FormFieldLabel label={`Original ${title}`} />
        )}
        {!isSubmitted && (
          <Feedback size="compact" type="neutral">
            <p>
              {applicationType === ApplicationType.Core ? (
                <span>Remaining for {year}: </span>
              ) : (
                <span>Remaining for application: </span>
              )}
              <span
                className={cn({
                  [styles.red]: remainingYearlyTotalForFundingRequests <= 0,
                })}
              >
                <strong>
                  {formatMoney(remainingYearlyTotalForFundingRequests)}
                </strong>{' '}
                of{' '}
                <strong>
                  {formatMoney(maximumYearlyTotalForFundingRequests)}
                </strong>
              </span>
            </p>
          </Feedback>
        )}
        {state.context.extendedRequests
          .filter(({ request: { alternate } }) => alternate == false)
          .map((extendedRequest) => (
            <ExtendedFundingRequestView
              key={extendedRequest.request.id}
              extendedRequest={extendedRequest}
              showExpenseEdit={canEditOrDeleteExpenses}
              showExpenseDelete={canEditOrDeleteExpenses}
            />
          ))}
        {isSubmitted && isApproved && (
          <FormFieldLabel
            label={`Alternate ${title}`}
            tooltipContent="Alternate funding request(s) is subject to approval by the Starmaker staff, who will review it for eligibility. You will be notified about the status of your alternate funding request."
          />
        )}
        {isSubmitted &&
          isApproved &&
          state.context.extendedRequests
            .filter(({ request: { alternate } }) => alternate)
            .map((extendedRequest) => (
              <ExtendedFundingRequestView
                key={extendedRequest.request.id}
                extendedRequest={extendedRequest}
                showExpenseEdit={canEditOrDeleteExpenses}
                showExpenseDelete={canEditOrDeleteExpenses}
              />
            ))}
        {openRequest && <FundingRequestFormController actor={openRequest} />}
      </div>
      {match([state.context.openRequest, stage])
        .with([null, ApplicationStage.Unsubmitted], () => (
          <Button
            id="addFundingRequestButton"
            variant="addnew"
            label={`Add a ${buttonText}`}
            onClick={() => send({ type: 'OPEN_REQUEST' })}
            disabled={state.context.budgetForFundingRequests < 0.01}
          />
        ))
        .with(
          [null, ApplicationStage.ContractAndDirectDepositPending],
          [null, ApplicationStage.ContractAndDirectDepositUploaded],
          [null, ApplicationStage.ClaimsReopened],
          () => alternateButton
        )
        .otherwise(() => null)}
      {state.matches('ready.error') && <ErrorFeedback pulse={true} />}
    </Block>
  );
}
