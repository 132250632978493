import { z } from 'zod';
import { localDateSchema, serverDateSchema } from '../../dateSchema';

export const partialExpenseSchema = z.object({
  fundingRequestId: z.string().uuid().optional(),
  vendor: z.string(),
  cost: z.string(),
  invoiceNumber: z.string(),
  date: localDateSchema,
  description: z.string().optional(),
});

export type PartialExpense = z.infer<typeof partialExpenseSchema>;

export const completeExpenseSchema = partialExpenseSchema
  .extend({
    date: partialExpenseSchema.shape.date.unwrap(),
  })
  .required({ fundingRequestId: true });

export type CompleteExpense = z.infer<typeof completeExpenseSchema>;

export const unsettledExpenseSchema = completeExpenseSchema.partial({
  fundingRequestId: true,
});

export type UnsettledExpense = z.infer<typeof unsettledExpenseSchema>;

export const expenseSchema = completeExpenseSchema.extend({
  id: z.string().uuid(),
  date: serverDateSchema,
});

export type Expense = z.infer<typeof expenseSchema>;
