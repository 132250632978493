import {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
  assign,
  createMachine,
} from 'xstate';
import { StateFrom } from '../../utils/StateFrom';
import { StatesConfig } from '../../utils/StateConfig';
import { match } from 'ts-pattern';
import { parsePositiveFloat } from '../../utils/parsePositiveFloat';
import {
  CompleteExpense,
  PartialExpense,
} from '../../schemas/fundingRequests/expenses/expenseSchema';
import { FundingRequest } from '../../schemas/fundingRequests/fundingRequestSchema';
import { LocalDateTime } from '../../schemas/dateTimeSchema';
import { sendParent } from 'xstate/lib/actions';
import { addExpense } from '../../events/Expense/AddExpense';
import { CalendarDate, toCalendarDate } from '@internationalized/date';
import { EditContext } from '../../events/Expense/RefreshExpenses';
import { formatNumber } from '../../utils/formatNumber';
import { CloseExpense, closeExpense } from '../../events/Expense/CloseExpense';
import {
  deleteExpense,
  DeleteExpense,
} from '../../events/Expense/DeleteExpense';
import { OpenExpense, openExpense } from '../../events/Expense/OpenExpense';
import { updateExpense } from '../../events/Expense/UpdateExpense';

export type Events =
  | { type: 'SET_VENDOR'; vendor: string }
  | { type: 'BLUR_VENDOR' }
  | { type: 'FOCUS_VENDOR' }
  | { type: 'SET_COST'; cost: string }
  | { type: 'BLUR_COST' }
  | { type: 'FOCUS_COST' }
  | { type: 'SET_INVOICE_NUMBER'; invoiceNumber: string }
  | { type: 'BLUR_INVOICE_NUMBER' }
  | { type: 'FOCUS_INVOICE_NUMBER' }
  | { type: 'SET_DATE'; date: CalendarDate }
  | { type: 'BLUR_DATE' }
  | { type: 'FOCUS_DATE' }
  | { type: 'SET_DESCRIPTION'; description: string }
  | { type: 'ADD_EXPENSE'; expense?: CompleteExpense }
  | OpenExpense
  | DeleteExpense
  | CloseExpense;

const inputStates: StatesConfig<Context, Events> = {
  pristine: { on: { ADD_EXPENSE: 'invalid' } },
  valid: {
    type: 'final',
  },
  invalid: {},
};

export type Context = {
  editContext: EditContext | undefined;
  request?: FundingRequest;
  expense: PartialExpense;
  isUnsettledRequestExpense?: boolean;
  applicationSubmittedAt?: LocalDateTime;
};

type Services = {
  validateCompleteExpense: {
    data: CompleteExpense;
  };
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ExpenseMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    id: 'form',
    initial: 'editing',
    states: {
      editing: {
        type: 'parallel',
        states: {
          vendor: {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                },
              },
            },
            on: {
              SET_VENDOR: {
                actions: 'setVendor',
              },
              BLUR_VENDOR: [
                { cond: 'isEmpty', target: '.invalid.empty' },
                { target: '.valid' },
              ],
            },
          },
          cost: {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                  value: {},
                  high: {},
                },
              },
              checking: {
                always: [
                  { cond: 'isCostEmpty', target: 'invalid.empty' },
                  { cond: 'isCostNonNumeric', target: 'invalid.value' },
                  'formating',
                ],
              },
              formating: {
                entry: 'formatCostValue',
                always: 'valid',
              },
            },
            on: {
              SET_COST: {
                actions: 'setCost',
              },
              BLUR_COST: '.checking',
            },
          },
          'invoice-number': {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                },
              },
            },
            on: {
              SET_INVOICE_NUMBER: {
                actions: 'setInvoiceNumber',
              },
              BLUR_INVOICE_NUMBER: [
                { cond: 'isEmpty', target: '.invalid.empty' },
                { target: '.valid' },
              ],
            },
          },
          date: {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                  value: {},
                },
              },
            },
            on: {
              SET_DATE: {
                actions: 'setDate',
              },
              BLUR_DATE: [
                { cond: 'isEmpty', target: '.invalid.empty' },
                { cond: 'isDateValid', target: '.valid' },
                { target: '.invalid.value' },
              ],
            },
          },
          warning: {
            initial: 'off',
            states: {
              on: {
                type: 'final',
                on: { ADD_EXPENSE: 'pulse' },
              },
              off: {
                type: 'final',
                on: { ADD_EXPENSE: 'on' },
              },
              pulse: {
                type: 'final',
                after: { 1000: 'on' },
              },
            },
          },
        },
        onDone: 'complete',
      },
      complete: {
        on: {
          FOCUS_VENDOR: {
            target: [
              'editing.vendor.pristine',
              'editing.cost.valid',
              'editing.invoice-number.valid',
              'editing.date.valid',
            ],
          },
          FOCUS_COST: {
            target: [
              'editing.vendor.valid',
              'editing.cost.pristine',
              'editing.invoice-number.valid',
              'editing.date.valid',
            ],
          },
          FOCUS_INVOICE_NUMBER: {
            target: [
              'editing.vendor.valid',
              'editing.cost.valid',
              'editing.invoice-number.pristine',
              'editing.date.valid',
            ],
          },
          FOCUS_DATE: {
            target: [
              'editing.vendor.valid',
              'editing.cost.valid',
              'editing.invoice-number.valid',
              'editing.date.pristine',
            ],
          },
          ADD_EXPENSE: [
            {
              cond: 'isUnsettledRequestExpense',
              actions: 'setUnsettledRequestExpense',
            },
            'saving',
          ],
        },
      },
      saving: {
        invoke: {
          id: 'validateCompleteExpense',
          src: 'validateCompleteExpense',
          onDone: { actions: 'addExpense' },
        },
      },
    },
    on: {
      SET_DESCRIPTION: { actions: 'setDescription' },
      OPEN_EXPENSE: {
        actions: 'openExpense',
      },
      CLOSE_EXPENSE: {
        actions: 'closeExpense',
      },
      DELETE_EXPENSE: {
        actions: 'deleteExpense',
      },
    },
  },
  {
    guards: {
      isEmpty: ({ expense }, { type }) => {
        const value = match(type)
          .with('BLUR_VENDOR', () => expense.vendor)
          .with('BLUR_INVOICE_NUMBER', () => expense.invoiceNumber)
          .with('BLUR_DATE', () => expense.date)
          .exhaustive();

        return value === '' || value === null;
      },
      isCostEmpty: ({ expense }) => expense.cost === '',
      isCostNonNumeric: ({ expense }) =>
        isNaN(parsePositiveFloat(expense.cost)),
      isDateValid: ({ expense, applicationSubmittedAt }) =>
        Boolean(
          expense.date &&
            applicationSubmittedAt &&
            toCalendarDate(applicationSubmittedAt).compare(expense.date) <= 0
        ),
      isUnsettledRequestExpense: ({ isUnsettledRequestExpense }) =>
        Boolean(isUnsettledRequestExpense),
    },
    actions: {
      setVendor: assign(({ expense }, { vendor }) => ({
        expense: {
          ...expense,
          vendor,
        },
      })),
      setCost: assign(({ expense }, { cost }) => ({
        expense: {
          ...expense,
          cost,
        },
      })),
      setInvoiceNumber: assign(({ expense }, { invoiceNumber }) => ({
        expense: {
          ...expense,
          invoiceNumber,
        },
      })),
      setDate: assign(({ expense }, { date }) => ({
        expense: {
          ...expense,
          date,
        },
      })),
      setDescription: assign(({ expense }, { description }) => ({
        expense: {
          ...expense,
          description,
        },
      })),
      formatCostValue: assign(({ expense: { cost, ...expense } }) => ({
        expense: {
          ...expense,
          cost: formatNumber(parsePositiveFloat(cost)),
        },
      })),
      addExpense: sendParent(({ editContext }, { data: expense }) =>
        editContext === undefined
          ? addExpense(expense)
          : updateExpense(editContext.id, expense)
      ),
      setUnsettledRequestExpense: sendParent(({ expense }) => ({
        type: 'SET_EXPENSE',
        expense,
      })),
      deleteExpense: sendParent((_, { id }) => deleteExpense(id)),
      openExpense: sendParent(({ editContext }) =>
        openExpense(editContext?.id)
      ),
      closeExpense: sendParent(({ editContext }) =>
        closeExpense(editContext?.id)
      ),
    },
  }
);

type Machine = typeof machine;

export type ExpenseMachine = Machine;
export type ExpenseMachineState = StateFrom<Machine>;
export type ExpenseMachineSender = Sender<EventFrom<Machine>>;
export type ExpenseMachineEvent = EventFrom<Machine>;
export type ExpenseMachineOptions = MachineOptionsFrom<Machine, true>;
export type ExpenseMachineActor = ActorRefFrom<ExpenseMachine>;
