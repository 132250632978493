import { z } from 'zod';
import { FundingRequestStatus } from '../../graphql/operations';
import { localDateSchema, serverDateSchema } from '../dateSchema';
import {
  expenseSchema,
  unsettledExpenseSchema,
} from './expenses/expenseSchema';

export const partialFundingRequestSchema = z.object({
  applicationId: z.string().uuid(),
  cost: z.string(),
  date: localDateSchema,
  fundingType: z.string(),
  strategy: z.string(),
  reason: z.string(),
});

export type PartialFundingRequest = z.infer<typeof partialFundingRequestSchema>;

export const completeFundingRequestSchema = partialFundingRequestSchema.extend({
  date: partialFundingRequestSchema.shape.date.unwrap(),
});

export type CompleteFundingRequest = z.infer<
  typeof completeFundingRequestSchema
>;

export const fundingRequestSchema = completeFundingRequestSchema.extend({
  id: z.string().uuid(),
  status: z.nativeEnum(FundingRequestStatus),
  amountApproved: z.string(),
  isTooNew: z.boolean(),
  isTooOld: z.boolean(),
  isEligible: z.boolean(),
  isOwner: z.boolean(),
  isWritable: z.boolean(),
  isCancellable: z.boolean(),
  date: serverDateSchema,
  cancelled: z.boolean(),
  alternate: z.boolean(),
  expenses: expenseSchema.array().default([]),
});

export type FundingRequest = z.infer<typeof fundingRequestSchema>;

export type AdminFundingRequest = {
  status: FundingRequestStatus;
  approvedCostOverride: string;
};

export const requestAndExpenseSchema = z.object({
  expense: unsettledExpenseSchema,
  request: completeFundingRequestSchema,
});
