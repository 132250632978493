import { HeroSection } from '../components/HeroSection';
import cn from 'classnames';

import styles from './Orion.module.scss';
import { Footer } from '../components/Footer';
import { Link } from '../components/Link';
import { Article } from '../components/Article';

export function Orion() {
  return (
    <div className={styles.container}>
      <HeroSection
        title="Orion Program"
        primaryButtonText="Start Orion Application"
        primaryButtonLinkTo="/eligibility/orion"
        breadcrumbs={[{ text: 'Home', to: '/' }]}
      >
        The Orion program is a funding stream dedicated to supporting music
        creation by members of the Canadian music industry who are Black,
        Indigenous, and People of Colour.
      </HeroSection>
      <div className={cn('max-w-xl', styles.content)}>
        <Article>
          <p>
            The Canadian Starmaker Fund is an artist-centred vehicle intended to
            make a substantial and discernible difference to the careers of
            Canadian artists who benefit from it.
          </p>
          <p>
            However, racism and other systemic barriers may prevent applicants
            from qualifying for Starmaker’s core programs. The Orion program was
            established to distribute funds in a manner that lowers the barrier
            of entry for eligible Black, Indigenous, and People of Colour who
            have not yet met Starmaker sales criteria.
          </p>
          <p>
            Orion is a “stepping stone” program. It will fund a broad range of
            activities to support racialized artists as they continue to develop
            and increase the audience and industry reach needed to apply to
            Starmaker’s core programs.
          </p>
          <p>
            Orion is also a “living program” that adapts to and reflects the
            Board’s collective learning, community feedback, and industry
            change.
          </p>
          <h2 className={styles.heading}>
            The program is comprised of the following elements:
          </h2>
          <ol>
            <li>
              Qualifying applicants are identified as Black, Indigenous, and
              People of Colour - if you are a band rather than an individual,
              the majority of the band must be identified as Black, Indigenous,
              and People of Colour.
            </li>
            <li>
              <p>
                To qualify, you must show full-length or bundle sales of at
                least 1,500 units. A full-length release contains at least 6
                unique studio recordings – not including acoustic re-recordings,
                remixes, versions, etc. – and at least 25 minutes of music. A
                bundle contains at least 3 unique, previously unreleased studio
                recordings – not including acoustic re-recordings, remixes,
                versions, etc. – and at least 9 minutes of music that were made
                available no earlier than one year prior to the release of the
                bundle.
              </p>
              <p>
                They can be either physical, digital or equivalent streams (750
                single track streams from a full-length release or bundle will
                constitute one sales unit toward the achievement of the minimum
                sales threshold, as will 5 downloaded tracks from a release
                constitute one sales unit.)
              </p>
              <p>
                We will accept proof via Luminate/Soundscan, manufacturing
                invoices, and distributor net sales reports. International
                sales, streams, and downloads will be counted toward
                eligibility. Multiple releases from the previous 5 years leading
                up to the deadline for the round can be combined to show
                eligibility. Alternatively, you will be considered qualified for
                this program if you have been nominated for a Juno, Grammy, or
                Polaris prize shortlist for a release from the last 5 years. You
                can just enter the nominated release, list the sales as 1,500
                units and attach proof of the nomination.
              </p>
            </li>
            <li>
              Applicants with no previous approvals for this program or who had
              their eligibility lapse will need to show current eligibility to
              our Director, Finance and Programs, Michelle Farres (
              <Link href="mailto:michellefarres@starmaker.ca">
                michellefarres@starmaker.ca
              </Link>
              ) at least a week in advance of the deadline for the round in
              which you apply.
            </li>
            <li>
              The application process will be as follows: you will enter your
              artist information into the Starmaker website. After completion,
              you will then have the option to be redirected to this program,
              where you will complete the online application form. You can
              include attachments – including proof of eligibility, breakdowns
              of expenditures or other key information – and brief details on
              domestic or international activity and momentum, to illustrate why
              your request should be funded.
            </li>
            <li>
              Money can be requested for any music-related expenses – recording,
              promotion, publicity, advertising, digital marketing, touring,
              videos, etc. The maximum overall request must be capped at
              $20,000, and the program will only run once per calendar year.
            </li>
            <li>
              Artists who qualify for the core Starmaker programs or have
              previously been funded in the core Starmaker programs will no
              longer be able to apply for this annual $20,000 grant due to
              budgetary issues.
            </li>
            <li>
              Orion applicants can receive a maximum of 3 approvals before they
              can no longer apply to the program.
            </li>
            <li>All other Starmaker rules will apply.</li>
          </ol>
        </Article>
      </div>
      <Footer />
    </div>
  );
}
