import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigFloat: string;
  BigInt: string;
  Cursor: string;
  Date: string;
  Datetime: string;
  Email: string;
  JSON: string;
  UUID: string;
};

export type Account = Node & {
  __typename?: 'Account';
  /** Reads and enables pagination through a set of `AccountArtistBinding`. */
  accountArtistBindingsByAccountIdList: Array<AccountArtistBinding>;
  /** Reads a single `AccountPassword` that is related to this `Account`. */
  accountPasswordByAccountId?: Maybe<AccountPassword>;
  address: Scalars['String'];
  /** Reads and enables pagination through a set of `Application`. */
  applicationsByPrimaryContactIdList: Array<Application>;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByAccountIdList?: Maybe<Array<Maybe<Artist>>>;
  /** Reads and enables pagination through a set of `Attachment`. */
  attachmentsByAccountIdList: Array<Attachment>;
  /** Reads and enables pagination through a set of `BoardMemberApplicationBlock`. */
  boardMemberApplicationBlocksByAccountIdList: Array<BoardMemberApplicationBlock>;
  createdAt: Scalars['Date'];
  email: Scalars['Email'];
  id: Scalars['UUID'];
  modifiedAt: Scalars['Date'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  phoneNumber: Scalars['String'];
};

export type AccountAccountArtistBindingsByAccountIdListArgs = {
  condition?: InputMaybe<AccountArtistBindingCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountArtistBindingsOrderBy>>;
};

export type AccountApplicationsByPrimaryContactIdListArgs = {
  condition?: InputMaybe<ApplicationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ApplicationsOrderBy>>;
};

export type AccountArtistsByAccountIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

export type AccountAttachmentsByAccountIdListArgs = {
  condition?: InputMaybe<AttachmentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AttachmentsOrderBy>>;
};

export type AccountBoardMemberApplicationBlocksByAccountIdListArgs = {
  condition?: InputMaybe<BoardMemberApplicationBlockCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BoardMemberApplicationBlocksOrderBy>>;
};

export type AccountArtistBinding = Node & {
  __typename?: 'AccountArtistBinding';
  /** Reads a single `Account` that is related to this `AccountArtistBinding`. */
  accountByAccountId?: Maybe<Account>;
  accountId: Scalars['UUID'];
  /** Reads a single `Artist` that is related to this `AccountArtistBinding`. */
  artistByArtistId?: Maybe<Artist>;
  artistId: Scalars['UUID'];
  email?: Maybe<Scalars['Email']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  isYou?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/**
 * A condition to be used against `AccountArtistBinding` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type AccountArtistBindingCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
};

/** An input for mutations affecting `AccountArtistBinding` */
export type AccountArtistBindingInput = {
  accountId: Scalars['UUID'];
  artistId: Scalars['UUID'];
};

/** Represents an update to a `AccountArtistBinding`. Fields that are set will be updated. */
export type AccountArtistBindingPatch = {
  accountId?: InputMaybe<Scalars['UUID']>;
  artistId?: InputMaybe<Scalars['UUID']>;
};

/** Methods to use when ordering `AccountArtistBinding`. */
export enum AccountArtistBindingsOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** A condition to be used against `Account` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AccountCondition = {
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['Email']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Account` */
export type AccountInput = {
  address: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Date']>;
  email: Scalars['Email'];
  id?: InputMaybe<Scalars['UUID']>;
  modifiedAt?: InputMaybe<Scalars['Date']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type AccountInvitationStatusResponse = {
  __typename?: 'AccountInvitationStatusResponse';
  code?: Maybe<AccountInvitationStatusResponseCode>;
  email?: Maybe<Scalars['String']>;
  isOrion?: Maybe<Scalars['Boolean']>;
};

export enum AccountInvitationStatusResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

export type AccountLog = {
  __typename?: 'AccountLog';
  accountId?: Maybe<Scalars['UUID']>;
  logAccountId?: Maybe<Scalars['UUID']>;
  logMessage?: Maybe<Scalars['String']>;
  logRole?: Maybe<Scalars['String']>;
  logStatementDate?: Maybe<Scalars['Datetime']>;
  logTransactionDate?: Maybe<Scalars['Datetime']>;
  relation?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `AccountLog` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AccountLogCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `logAccountId` field. */
  logAccountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `logMessage` field. */
  logMessage?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `logRole` field. */
  logRole?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `logStatementDate` field. */
  logStatementDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `logTransactionDate` field. */
  logTransactionDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `relation` field. */
  relation?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `AccountLog`. */
export enum AccountLogsOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  LogAccountIdAsc = 'LOG_ACCOUNT_ID_ASC',
  LogAccountIdDesc = 'LOG_ACCOUNT_ID_DESC',
  LogMessageAsc = 'LOG_MESSAGE_ASC',
  LogMessageDesc = 'LOG_MESSAGE_DESC',
  LogRoleAsc = 'LOG_ROLE_ASC',
  LogRoleDesc = 'LOG_ROLE_DESC',
  LogStatementDateAsc = 'LOG_STATEMENT_DATE_ASC',
  LogStatementDateDesc = 'LOG_STATEMENT_DATE_DESC',
  LogTransactionDateAsc = 'LOG_TRANSACTION_DATE_ASC',
  LogTransactionDateDesc = 'LOG_TRANSACTION_DATE_DESC',
  Natural = 'NATURAL',
  RelationAsc = 'RELATION_ASC',
  RelationDesc = 'RELATION_DESC',
}

export type AccountPassword = Node & {
  __typename?: 'AccountPassword';
  /** Reads a single `Account` that is related to this `AccountPassword`. */
  accountByAccountId?: Maybe<Account>;
  accountId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `AccountPassword` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AccountPasswordCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `password` field. */
  password?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `AccountPassword` */
export type AccountPasswordInput = {
  accountId: Scalars['UUID'];
  password?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `AccountPassword`. Fields that are set will be updated. */
export type AccountPasswordPatch = {
  accountId?: InputMaybe<Scalars['UUID']>;
  password?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `AccountPassword`. */
export enum AccountPasswordsOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  Natural = 'NATURAL',
  PasswordAsc = 'PASSWORD_ASC',
  PasswordDesc = 'PASSWORD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** Represents an update to a `Account`. Fields that are set will be updated. */
export type AccountPatch = {
  address?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['UUID']>;
  modifiedAt?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Account`. */
export enum AccountsOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** An input for mutations affecting `AddAlternateRequestExpensePayload` */
export type AddAlternateRequestExpensePayloadInput = {
  cost?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

/** All input for the `addAlternateRequest` mutation. */
export type AddAlternateRequestInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  cost: Scalars['String'];
  date: Scalars['String'];
  expense?: InputMaybe<AddAlternateRequestExpensePayloadInput>;
  fundingType: Scalars['String'];
  reason: Scalars['String'];
  strategy: Scalars['String'];
};

/** The output of our `addAlternateRequest` mutation. */
export type AddAlternateRequestPayload = {
  __typename?: 'AddAlternateRequestPayload';
  /** Reads a single `Application` that is related to this `FundingRequest`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fundingRequest?: Maybe<FundingRequest>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `addArtist` mutation. */
export type AddArtistInput = {
  artistName: Scalars['String'];
  artistProvince: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyLegalProvince: Scalars['String'];
  companyLegalStatus: Scalars['String'];
  companyName: Scalars['String'];
  managementAddress: Scalars['String'];
  managementContactPerson: Scalars['String'];
  managementCountryId: Scalars['String'];
  managementEmail: Scalars['String'];
  managementLegalProvince: Scalars['String'];
  managementLegalStatus: Scalars['String'];
  managementName: Scalars['String'];
  managementPhoneNumber: Scalars['String'];
};

/** The output of our `addArtist` mutation. */
export type AddArtistPayload = {
  __typename?: 'AddArtistPayload';
  addArtistResponse?: Maybe<AddArtistResponse>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type AddArtistResponse = {
  __typename?: 'AddArtistResponse';
  artistId?: Maybe<Scalars['String']>;
  code?: Maybe<AddArtistResponseCode>;
};

export enum AddArtistResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

/** All input for the `addBoardMeetingBlock` mutation. */
export type AddBoardMeetingBlockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  roundId: Scalars['UUID'];
  title: Scalars['String'];
};

/** The output of our `addBoardMeetingBlock` mutation. */
export type AddBoardMeetingBlockPayload = {
  __typename?: 'AddBoardMeetingBlockPayload';
  boardMeetingBlock?: Maybe<BoardMeetingBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `FundingRound` that is related to this `BoardMeetingBlock`. */
  fundingRoundByFundingRoundId?: Maybe<FundingRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `addRelease` mutation. */
export type AddReleaseInput = {
  address: Scalars['String'];
  artistId: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  company: Scalars['String'];
  contact: Scalars['String'];
  date: Scalars['String'];
  distributorId: Scalars['String'];
  email: Scalars['String'];
  genreId: Scalars['String'];
  inCanada: Scalars['Boolean'];
  isMajorityEnglish: Scalars['Boolean'];
  labelId: Scalars['String'];
  notes: Scalars['String'];
  numberOfMinutes: Scalars['Int'];
  numberOfTracks: Scalars['Int'];
  otherDistributor?: InputMaybe<Scalars['String']>;
  otherLabel?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  releasesScanned: Scalars['Int'];
  title: Scalars['String'];
  tracksDownloaded: Scalars['Int'];
  tracksStreamed: Scalars['Int'];
  website: Scalars['String'];
};

/** The output of our `addRelease` mutation. */
export type AddReleasePayload = {
  __typename?: 'AddReleasePayload';
  addReleaseResponse?: Maybe<AddReleaseResponse>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type AddReleaseResponse = {
  __typename?: 'AddReleaseResponse';
  code?: Maybe<AddReleaseResponseCode>;
};

export enum AddReleaseResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

export type Application = Node & {
  __typename?: 'Application';
  /** Reads a single `Account` that is related to this `Application`. */
  accountByPrimaryContactId?: Maybe<Account>;
  alternateTourDatesAllowed?: Maybe<Scalars['Boolean']>;
  /** Reads a single `ApplicationBoardNote` that is related to this `Application`. */
  applicationBoardNoteByApplicationId?: Maybe<ApplicationBoardNote>;
  approvedFundingRequestsWithoutExpense?: Maybe<Scalars['Int']>;
  approvedTotal?: Maybe<Scalars['BigFloat']>;
  approvedTotalForFundingRequests?: Maybe<Scalars['BigFloat']>;
  approvedTotalForTourDates?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `Artist` that is related to this `Application`. */
  artistByArtistId?: Maybe<Artist>;
  artistId: Scalars['UUID'];
  artistModifiedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `Release`. */
  associatedReleaseList?: Maybe<Array<Maybe<Release>>>;
  /** Reads and enables pagination through a set of `AttachmentApplicationBinding`. */
  attachmentApplicationBindingsByApplicationIdList: Array<AttachmentApplicationBinding>;
  /** Reads a single `Attachment` that is related to this `Application`. */
  attachmentBySignedContractId?: Maybe<Attachment>;
  /** Reads a single `Attachment` that is related to this `Application`. */
  attachmentByTourSummaryId?: Maybe<Attachment>;
  /** Reads and enables pagination through a set of `Attachment`. */
  attachmentsByApplicationIdList?: Maybe<Array<Maybe<Attachment>>>;
  /** Reads and enables pagination through a set of `BoardMemberApplicationBlock`. */
  boardMemberApplicationBlocksByApplicationIdList: Array<BoardMemberApplicationBlock>;
  budgetForFundingRequests?: Maybe<Scalars['BigFloat']>;
  cancelledTotalForAlternateFundingRequests?: Maybe<Scalars['BigFloat']>;
  cancelledTotalForFundingRequests?: Maybe<Scalars['BigFloat']>;
  claimedTotal?: Maybe<Scalars['BigFloat']>;
  claimedTotalForFundingRequests?: Maybe<Scalars['BigFloat']>;
  claimedTotalForTourDates?: Maybe<Scalars['BigFloat']>;
  claimsReopenedAt?: Maybe<Scalars['Datetime']>;
  claimsResubmissionDeadline?: Maybe<Scalars['Datetime']>;
  claimsResubmittedAt?: Maybe<Scalars['Datetime']>;
  claimsSubmittedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByApplicationIdList: Array<Contact>;
  /** Reads a single `Contract` that is related to this `Application`. */
  contractByApplicationId?: Maybe<Contract>;
  createdAt: Scalars['Datetime'];
  /** Reads a single `DirectDepositAccount` that is related to this `Application`. */
  directDepositAccountByFundingRequestDirectDepositId?: Maybe<DirectDepositAccount>;
  /** Reads a single `DirectDepositAccount` that is related to this `Application`. */
  directDepositAccountByTouringDirectDepositId?: Maybe<DirectDepositAccount>;
  eligibilityDeadline?: Maybe<Scalars['Date']>;
  eligibilityDeadlineForFundingRequests?: Maybe<Scalars['Date']>;
  eligibilityDeadlineForTourDates?: Maybe<Scalars['Date']>;
  exceedsYearlyNumberOfTourDates?: Maybe<Scalars['Boolean']>;
  exceedsYearlyNumberOfTourDatesByRegionList?: Maybe<
    Array<Maybe<Scalars['String']>>
  >;
  exceedsYearlyTotalForFundingRequests?: Maybe<Scalars['Boolean']>;
  fundingRequestDirectDepositId?: Maybe<Scalars['UUID']>;
  fundingRequestPaid?: Maybe<Scalars['BigFloat']>;
  /** Reads and enables pagination through a set of `FundingRequest`. */
  fundingRequestsByApplicationIdList: Array<FundingRequest>;
  fundingRound?: Maybe<FundingRound>;
  /** Reads a single `FundingRound` that is related to this `Application`. */
  fundingRoundByFundingRoundId?: Maybe<FundingRound>;
  fundingRoundId?: Maybe<Scalars['UUID']>;
  getFundingRequestTotalsList?: Maybe<
    Array<Maybe<ApplicationsGetFundingRequestTotalsRecord>>
  >;
  id: Scalars['UUID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isClaimsReopenable?: Maybe<Scalars['Boolean']>;
  isClaimsResubmittable?: Maybe<Scalars['Boolean']>;
  isClaimsResubmitted?: Maybe<Scalars['Boolean']>;
  isClaimsSubmittable?: Maybe<Scalars['Boolean']>;
  isClaimsSubmitted?: Maybe<Scalars['Boolean']>;
  isCompletable?: Maybe<Scalars['Boolean']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  isSubmittable?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `ApplicationLog`. */
  logsList?: Maybe<Array<Maybe<ApplicationLog>>>;
  markedCompleteAt?: Maybe<Scalars['Datetime']>;
  maximumYearlyTotalForFundingRequests?: Maybe<Scalars['BigFloat']>;
  meetsClaimsExpenseRequirement?: Maybe<Scalars['Boolean']>;
  meetsClaimsFundingRequestRequirement?: Maybe<Scalars['Boolean']>;
  meetsClaimsTourDateRequirement?: Maybe<Scalars['Boolean']>;
  meetsClaimsTourSummaryRequirement?: Maybe<Scalars['Boolean']>;
  meetsDirectDepositRequirement?: Maybe<Scalars['Boolean']>;
  meetsFundingRequestRequirement?: Maybe<Scalars['Boolean']>;
  meetsOrionRoundRequirement?: Maybe<Scalars['Boolean']>;
  meetsRoundRequirements?: Maybe<Scalars['Boolean']>;
  meetsStatementRequirement?: Maybe<Scalars['Boolean']>;
  meetsTourDateRequirement?: Maybe<Scalars['Boolean']>;
  minNumFundingRequests?: Maybe<Scalars['Int']>;
  minNumTourDates?: Maybe<Scalars['Int']>;
  minStatementLength?: Maybe<Scalars['Int']>;
  modifiedAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  numberOfPendingRequests?: Maybe<Scalars['Int']>;
  numberOfUnsettledRequests?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByApplicationIdList: Array<Payment>;
  primaryContactId: Scalars['UUID'];
  remainingTotalForAlternateFundingRequests?: Maybe<Scalars['BigFloat']>;
  remainingYearlyTotalForFundingRequests?: Maybe<Scalars['BigFloat']>;
  reopenClaimsNotes?: Maybe<Scalars['String']>;
  requestedTotal?: Maybe<Scalars['BigFloat']>;
  requestedTotalForAlternateFundingRequests?: Maybe<Scalars['BigFloat']>;
  requestedTotalForFundingRequests?: Maybe<Scalars['BigFloat']>;
  requestedTotalForTourDates?: Maybe<Scalars['BigFloat']>;
  requestedYearlyTotalForFundingRequests?: Maybe<Scalars['BigFloat']>;
  reviewStatus: ApplicationReviewStatus;
  signedContractId?: Maybe<Scalars['UUID']>;
  stage?: Maybe<ApplicationStage>;
  statement: Scalars['String'];
  status: ApplicationStatus;
  submittedAt?: Maybe<Scalars['Datetime']>;
  tentativeTotal?: Maybe<Scalars['BigFloat']>;
  tentativeTotalForFundingRequests?: Maybe<Scalars['BigFloat']>;
  tentativeTotalForTourDates?: Maybe<Scalars['BigFloat']>;
  tentativeTotalNumberOfApprovedTourDates?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  totalNumberOfAlternateTourDates?: Maybe<Scalars['Int']>;
  totalNumberOfApprovedFundingRequests?: Maybe<Scalars['Int']>;
  totalNumberOfApprovedTourDates?: Maybe<Scalars['Int']>;
  totalNumberOfCancelledAlternateTourDates?: Maybe<Scalars['Int']>;
  totalNumberOfCancelledTourDates?: Maybe<Scalars['Int']>;
  totalNumberOfClaimedFundingRequests?: Maybe<Scalars['Int']>;
  totalNumberOfClaimedTourDates?: Maybe<Scalars['Int']>;
  totalNumberOfEligibleFundingRequests?: Maybe<Scalars['Int']>;
  totalNumberOfEligibleTourDates?: Maybe<Scalars['Int']>;
  totalNumberOfFundingRequests?: Maybe<Scalars['Int']>;
  totalNumberOfIneligibleFundingRequests?: Maybe<Scalars['Int']>;
  totalNumberOfIneligibleTourDates?: Maybe<Scalars['Int']>;
  totalNumberOfTourDates?: Maybe<Scalars['Int']>;
  totalPaid?: Maybe<Scalars['BigFloat']>;
  totalRequested?: Maybe<Scalars['BigFloat']>;
  tourDateCost?: Maybe<Scalars['BigFloat']>;
  /** Reads and enables pagination through a set of `TourDateRegion`. */
  tourDateRegionsList?: Maybe<Array<Maybe<TourDateRegion>>>;
  /** Reads and enables pagination through a set of `TourDate`. */
  tourDatesByApplicationId: TourDatesConnection;
  /** Reads and enables pagination through a set of `TourDate`. */
  tourDatesByApplicationIdList: Array<TourDate>;
  tourSummaryId?: Maybe<Scalars['UUID']>;
  touringDirectDepositId?: Maybe<Scalars['UUID']>;
  touringPaid?: Maybe<Scalars['BigFloat']>;
  type: ApplicationType;
  unsettledFundingRequestsWithoutExpense?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type ApplicationAlternateTourDatesAllowedArgs = {
  excludeId?: InputMaybe<Scalars['UUID']>;
};

export type ApplicationAssociatedReleaseListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ApplicationAttachmentApplicationBindingsByApplicationIdListArgs = {
  condition?: InputMaybe<AttachmentApplicationBindingCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AttachmentApplicationBindingsOrderBy>>;
};

export type ApplicationAttachmentsByApplicationIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ApplicationBoardMemberApplicationBlocksByApplicationIdListArgs = {
  condition?: InputMaybe<BoardMemberApplicationBlockCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BoardMemberApplicationBlocksOrderBy>>;
};

export type ApplicationContactsByApplicationIdListArgs = {
  condition?: InputMaybe<ContactCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};

export type ApplicationEligibilityDeadlineArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationEligibilityDeadlineForFundingRequestsArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationEligibilityDeadlineForTourDatesArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationExceedsYearlyNumberOfTourDatesArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationExceedsYearlyNumberOfTourDatesByRegionListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  nowDate?: InputMaybe<Scalars['Datetime']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ApplicationExceedsYearlyTotalForFundingRequestsArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationFundingRequestsByApplicationIdListArgs = {
  condition?: InputMaybe<FundingRequestCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FundingRequestsOrderBy>>;
};

export type ApplicationFundingRoundArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationGetFundingRequestTotalsListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ApplicationIsSubmittableArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationLogsListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ApplicationMeetsFundingRequestRequirementArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationMeetsOrionRoundRequirementArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationMeetsRoundRequirementsArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationMeetsTourDateRequirementArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationPaymentsByApplicationIdListArgs = {
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

export type ApplicationRemainingTotalForAlternateFundingRequestsArgs = {
  excludeId?: InputMaybe<Scalars['UUID']>;
};

export type ApplicationRemainingYearlyTotalForFundingRequestsArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationRequestedTotalForAlternateFundingRequestsArgs = {
  excludeId?: InputMaybe<Scalars['UUID']>;
};

export type ApplicationRequestedYearlyTotalForFundingRequestsArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationTotalNumberOfAlternateTourDatesArgs = {
  excludeId?: InputMaybe<Scalars['UUID']>;
};

export type ApplicationTotalNumberOfEligibleFundingRequestsArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationTotalNumberOfEligibleTourDatesArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationTotalNumberOfIneligibleFundingRequestsArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationTotalNumberOfIneligibleTourDatesArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationTourDateRegionsListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  nowDate?: InputMaybe<Scalars['Datetime']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ApplicationTourDatesByApplicationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TourDateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TourDatesOrderBy>>;
};

export type ApplicationTourDatesByApplicationIdListArgs = {
  condition?: InputMaybe<TourDateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TourDatesOrderBy>>;
};

export type ApplicationYearArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ApplicationBoardNote = Node & {
  __typename?: 'ApplicationBoardNote';
  /** Reads a single `Application` that is related to this `ApplicationBoardNote`. */
  applicationByApplicationId?: Maybe<Application>;
  applicationId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  notes: Scalars['String'];
};

/**
 * A condition to be used against `ApplicationBoardNote` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ApplicationBoardNoteCondition = {
  /** Checks for equality with the object’s `applicationId` field. */
  applicationId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `ApplicationBoardNote` */
export type ApplicationBoardNoteInput = {
  applicationId: Scalars['UUID'];
  notes?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `ApplicationBoardNote`. Fields that are set will be updated. */
export type ApplicationBoardNotePatch = {
  applicationId?: InputMaybe<Scalars['UUID']>;
  notes?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `ApplicationBoardNote`. */
export enum ApplicationBoardNotesOrderBy {
  ApplicationIdAsc = 'APPLICATION_ID_ASC',
  ApplicationIdDesc = 'APPLICATION_ID_DESC',
  Natural = 'NATURAL',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/**
 * A condition to be used against `Application` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ApplicationCondition = {
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `claimsReopenedAt` field. */
  claimsReopenedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `claimsResubmittedAt` field. */
  claimsResubmittedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `claimsSubmittedAt` field. */
  claimsSubmittedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `fundingRequestDirectDepositId` field. */
  fundingRequestDirectDepositId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `fundingRoundId` field. */
  fundingRoundId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `markedCompleteAt` field. */
  markedCompleteAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `primaryContactId` field. */
  primaryContactId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `reopenClaimsNotes` field. */
  reopenClaimsNotes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `reviewStatus` field. */
  reviewStatus?: InputMaybe<ApplicationReviewStatus>;
  /** Checks for equality with the object’s `signedContractId` field. */
  signedContractId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `statement` field. */
  statement?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<ApplicationStatus>;
  /** Checks for equality with the object’s `submittedAt` field. */
  submittedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `tourSummaryId` field. */
  tourSummaryId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `touringDirectDepositId` field. */
  touringDirectDepositId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<ApplicationType>;
};

/** An input for mutations affecting `Application` */
export type ApplicationInput = {
  artistId: Scalars['UUID'];
  claimsReopenedAt?: InputMaybe<Scalars['Datetime']>;
  claimsResubmittedAt?: InputMaybe<Scalars['Datetime']>;
  claimsSubmittedAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  fundingRequestDirectDepositId?: InputMaybe<Scalars['UUID']>;
  fundingRoundId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  markedCompleteAt?: InputMaybe<Scalars['Datetime']>;
  modifiedAt?: InputMaybe<Scalars['Datetime']>;
  primaryContactId: Scalars['UUID'];
  reopenClaimsNotes?: InputMaybe<Scalars['String']>;
  reviewStatus?: InputMaybe<ApplicationReviewStatus>;
  signedContractId?: InputMaybe<Scalars['UUID']>;
  statement?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ApplicationStatus>;
  submittedAt?: InputMaybe<Scalars['Datetime']>;
  tourSummaryId?: InputMaybe<Scalars['UUID']>;
  touringDirectDepositId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<ApplicationType>;
};

export type ApplicationLog = {
  __typename?: 'ApplicationLog';
  applicationId?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
  logAccountId?: Maybe<Scalars['UUID']>;
  logMessage?: Maybe<Scalars['String']>;
  logRole?: Maybe<Scalars['String']>;
  logStatementDate?: Maybe<Scalars['Datetime']>;
  logTransactionDate?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  relation?: Maybe<Scalars['String']>;
};

/** Represents an update to a `Application`. Fields that are set will be updated. */
export type ApplicationPatch = {
  artistId?: InputMaybe<Scalars['UUID']>;
  claimsReopenedAt?: InputMaybe<Scalars['Datetime']>;
  claimsResubmittedAt?: InputMaybe<Scalars['Datetime']>;
  claimsSubmittedAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  fundingRequestDirectDepositId?: InputMaybe<Scalars['UUID']>;
  fundingRoundId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  markedCompleteAt?: InputMaybe<Scalars['Datetime']>;
  modifiedAt?: InputMaybe<Scalars['Datetime']>;
  primaryContactId?: InputMaybe<Scalars['UUID']>;
  reopenClaimsNotes?: InputMaybe<Scalars['String']>;
  reviewStatus?: InputMaybe<ApplicationReviewStatus>;
  signedContractId?: InputMaybe<Scalars['UUID']>;
  statement?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ApplicationStatus>;
  submittedAt?: InputMaybe<Scalars['Datetime']>;
  tourSummaryId?: InputMaybe<Scalars['UUID']>;
  touringDirectDepositId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<ApplicationType>;
};

export enum ApplicationReviewStatus {
  BoardCall = 'BOARD_CALL',
  None = 'NONE',
  NotQualified = 'NOT_QUALIFIED',
  NotRecommended = 'NOT_RECOMMENDED',
  Recommended = 'RECOMMENDED',
}

export enum ApplicationStage {
  ClaimsReopened = 'CLAIMS_REOPENED',
  ClaimsResubmitted = 'CLAIMS_RESUBMITTED',
  ClaimsSubmitted = 'CLAIMS_SUBMITTED',
  Completed = 'COMPLETED',
  ContractAndDirectDepositPending = 'CONTRACT_AND_DIRECT_DEPOSIT_PENDING',
  ContractAndDirectDepositUploaded = 'CONTRACT_AND_DIRECT_DEPOSIT_UPLOADED',
  Submitted = 'SUBMITTED',
  Unsubmitted = 'UNSUBMITTED',
}

export enum ApplicationStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  InProgress = 'IN_PROGRESS',
  Submitted = 'SUBMITTED',
}

export enum ApplicationType {
  Core = 'CORE',
  Orion = 'ORION',
}

/** The return type of our `getFundingRequestTotals` query. */
export type ApplicationsGetFundingRequestTotalsRecord = {
  __typename?: 'ApplicationsGetFundingRequestTotalsRecord';
  total?: Maybe<Scalars['BigFloat']>;
  year?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `Application`. */
export enum ApplicationsOrderBy {
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  ClaimsReopenedAtAsc = 'CLAIMS_REOPENED_AT_ASC',
  ClaimsReopenedAtDesc = 'CLAIMS_REOPENED_AT_DESC',
  ClaimsResubmittedAtAsc = 'CLAIMS_RESUBMITTED_AT_ASC',
  ClaimsResubmittedAtDesc = 'CLAIMS_RESUBMITTED_AT_DESC',
  ClaimsSubmittedAtAsc = 'CLAIMS_SUBMITTED_AT_ASC',
  ClaimsSubmittedAtDesc = 'CLAIMS_SUBMITTED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FundingRequestDirectDepositIdAsc = 'FUNDING_REQUEST_DIRECT_DEPOSIT_ID_ASC',
  FundingRequestDirectDepositIdDesc = 'FUNDING_REQUEST_DIRECT_DEPOSIT_ID_DESC',
  FundingRoundIdAsc = 'FUNDING_ROUND_ID_ASC',
  FundingRoundIdDesc = 'FUNDING_ROUND_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MarkedCompleteAtAsc = 'MARKED_COMPLETE_AT_ASC',
  MarkedCompleteAtDesc = 'MARKED_COMPLETE_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  Natural = 'NATURAL',
  PrimaryContactIdAsc = 'PRIMARY_CONTACT_ID_ASC',
  PrimaryContactIdDesc = 'PRIMARY_CONTACT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReopenClaimsNotesAsc = 'REOPEN_CLAIMS_NOTES_ASC',
  ReopenClaimsNotesDesc = 'REOPEN_CLAIMS_NOTES_DESC',
  ReviewStatusAsc = 'REVIEW_STATUS_ASC',
  ReviewStatusDesc = 'REVIEW_STATUS_DESC',
  SignedContractIdAsc = 'SIGNED_CONTRACT_ID_ASC',
  SignedContractIdDesc = 'SIGNED_CONTRACT_ID_DESC',
  StatementAsc = 'STATEMENT_ASC',
  StatementDesc = 'STATEMENT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubmittedAtAsc = 'SUBMITTED_AT_ASC',
  SubmittedAtDesc = 'SUBMITTED_AT_DESC',
  TouringDirectDepositIdAsc = 'TOURING_DIRECT_DEPOSIT_ID_ASC',
  TouringDirectDepositIdDesc = 'TOURING_DIRECT_DEPOSIT_ID_DESC',
  TourSummaryIdAsc = 'TOUR_SUMMARY_ID_ASC',
  TourSummaryIdDesc = 'TOUR_SUMMARY_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
}

/** All input for the `approveApplicationAndNotify` mutation. */
export type ApproveApplicationAndNotifyInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emails: Scalars['String'];
};

/** The output of our `approveApplicationAndNotify` mutation. */
export type ApproveApplicationAndNotifyPayload = {
  __typename?: 'ApproveApplicationAndNotifyPayload';
  approveApplicationAndNotifyResponse?: Maybe<ApproveApplicationAndNotifyResponse>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type ApproveApplicationAndNotifyResponse = {
  __typename?: 'ApproveApplicationAndNotifyResponse';
  code?: Maybe<ApproveApplicationAndNotifyResponseCode>;
};

export enum ApproveApplicationAndNotifyResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

export type Artist = Node & {
  __typename?: 'Artist';
  aboveLifetimeFundingLimit?: Maybe<Scalars['Boolean']>;
  aboveSalesRequirement?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `AccountArtistBinding`. */
  accountArtistBindingsByArtistIdList: Array<AccountArtistBinding>;
  /** Reads and enables pagination through a set of `Application`. */
  applicationsByArtistIdList: Array<Application>;
  /** Reads and enables pagination through a set of `ArtistReleaseBinding`. */
  artistReleaseBindingsByArtistIdList: Array<ArtistReleaseBinding>;
  canStartApplication?: Maybe<Scalars['Boolean']>;
  companyLegalProvince?: Maybe<Scalars['String']>;
  companyLegalStatus: Scalars['String'];
  companyName: Scalars['String'];
  createdAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `DirectDepositAccount`. */
  directDepositAccountsByArtistIdList: Array<DirectDepositAccount>;
  /** Reads a single `EligibilityNotice` that is related to this `Artist`. */
  eligibilityNoticeByArtistId?: Maybe<EligibilityNotice>;
  eligibilityStatus?: Maybe<ArtistEligibility>;
  getTotalFunded?: Maybe<Scalars['BigFloat']>;
  hasEligibleRelease?: Maybe<Scalars['Boolean']>;
  hasReachedOrionApprovalLimit?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  importId?: Maybe<Scalars['Int']>;
  isCore?: Maybe<Scalars['Boolean']>;
  isCoreEligible?: Maybe<Scalars['Boolean']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  isOrion?: Maybe<Scalars['Boolean']>;
  isOrionEligible?: Maybe<Scalars['Boolean']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  isSlowingDown: Scalars['Boolean'];
  /** Reads a single `LegalStatus` that is related to this `Artist`. */
  legalStatusByCompanyLegalStatus?: Maybe<LegalStatus>;
  /** Reads a single `Management` that is related to this `Artist`. */
  managementByManagementId?: Maybe<Management>;
  managementId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  oldLifetimeTotal: Scalars['BigFloat'];
  oldOrionApprovalCount: Scalars['Int'];
  orionApprovalCount?: Maybe<Scalars['Int']>;
  orionApprovalLimit?: Maybe<Scalars['Int']>;
  /** Reads a single `Province` that is related to this `Artist`. */
  provinceByCompanyLegalProvince?: Maybe<Province>;
  /** Reads a single `Province` that is related to this `Artist`. */
  provinceByProvinceId?: Maybe<Province>;
  provinceId: Scalars['String'];
  /** Reads and enables pagination through a set of `Release`. */
  releasesByArtistIdList?: Maybe<Array<Maybe<Release>>>;
  showLegacyClaimsMessage: Scalars['Boolean'];
  status: ArtistStatus;
};

export type ArtistAccountArtistBindingsByArtistIdListArgs = {
  condition?: InputMaybe<AccountArtistBindingCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountArtistBindingsOrderBy>>;
};

export type ArtistApplicationsByArtistIdListArgs = {
  condition?: InputMaybe<ApplicationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ApplicationsOrderBy>>;
};

export type ArtistArtistReleaseBindingsByArtistIdListArgs = {
  condition?: InputMaybe<ArtistReleaseBindingCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistReleaseBindingsOrderBy>>;
};

export type ArtistDirectDepositAccountsByArtistIdListArgs = {
  condition?: InputMaybe<DirectDepositAccountCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DirectDepositAccountsOrderBy>>;
};

export type ArtistEligibilityStatusArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ArtistHasEligibleReleaseArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ArtistIsCoreEligibleArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ArtistIsEligibleArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ArtistReleasesByArtistIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReleasesOrderBy>>;
};

/** A condition to be used against `Artist` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ArtistCondition = {
  /** Checks for equality with the object’s `companyLegalProvince` field. */
  companyLegalProvince?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyLegalStatus` field. */
  companyLegalStatus?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `importId` field. */
  importId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isSlowingDown` field. */
  isSlowingDown?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `managementId` field. */
  managementId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `oldLifetimeTotal` field. */
  oldLifetimeTotal?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `oldOrionApprovalCount` field. */
  oldOrionApprovalCount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `provinceId` field. */
  provinceId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `showLegacyClaimsMessage` field. */
  showLegacyClaimsMessage?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<ArtistStatus>;
};

export enum ArtistEligibility {
  Eligible = 'ELIGIBLE',
  Ineligible = 'INELIGIBLE',
  Slowing = 'SLOWING',
}

/** An input for mutations affecting `Artist` */
export type ArtistInput = {
  companyLegalProvince?: InputMaybe<Scalars['String']>;
  companyLegalStatus: Scalars['String'];
  companyName: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  isSlowingDown?: InputMaybe<Scalars['Boolean']>;
  managementId?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
  oldLifetimeTotal?: InputMaybe<Scalars['BigFloat']>;
  oldOrionApprovalCount?: InputMaybe<Scalars['Int']>;
  provinceId: Scalars['String'];
  showLegacyClaimsMessage?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ArtistStatus>;
};

export type ArtistInvitationStatusResponse = {
  __typename?: 'ArtistInvitationStatusResponse';
  code?: Maybe<ArtistInvitationStatusResponseCode>;
  email?: Maybe<Scalars['String']>;
};

export enum ArtistInvitationStatusResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Linkable = 'LINKABLE',
  Success = 'SUCCESS',
}

export type ArtistLog = {
  __typename?: 'ArtistLog';
  artistId?: Maybe<Scalars['UUID']>;
  logAccountId?: Maybe<Scalars['UUID']>;
  logMessage?: Maybe<Scalars['String']>;
  logRole?: Maybe<Scalars['String']>;
  logStatementDate?: Maybe<Scalars['Datetime']>;
  logTransactionDate?: Maybe<Scalars['Datetime']>;
  relation?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `ArtistLog` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ArtistLogCondition = {
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `logAccountId` field. */
  logAccountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `logMessage` field. */
  logMessage?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `logRole` field. */
  logRole?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `logStatementDate` field. */
  logStatementDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `logTransactionDate` field. */
  logTransactionDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `relation` field. */
  relation?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `ArtistLog`. */
export enum ArtistLogsOrderBy {
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  LogAccountIdAsc = 'LOG_ACCOUNT_ID_ASC',
  LogAccountIdDesc = 'LOG_ACCOUNT_ID_DESC',
  LogMessageAsc = 'LOG_MESSAGE_ASC',
  LogMessageDesc = 'LOG_MESSAGE_DESC',
  LogRoleAsc = 'LOG_ROLE_ASC',
  LogRoleDesc = 'LOG_ROLE_DESC',
  LogStatementDateAsc = 'LOG_STATEMENT_DATE_ASC',
  LogStatementDateDesc = 'LOG_STATEMENT_DATE_DESC',
  LogTransactionDateAsc = 'LOG_TRANSACTION_DATE_ASC',
  LogTransactionDateDesc = 'LOG_TRANSACTION_DATE_DESC',
  Natural = 'NATURAL',
  RelationAsc = 'RELATION_ASC',
  RelationDesc = 'RELATION_DESC',
}

/** Represents an update to a `Artist`. Fields that are set will be updated. */
export type ArtistPatch = {
  companyLegalProvince?: InputMaybe<Scalars['String']>;
  companyLegalStatus?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  isSlowingDown?: InputMaybe<Scalars['Boolean']>;
  managementId?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  oldLifetimeTotal?: InputMaybe<Scalars['BigFloat']>;
  oldOrionApprovalCount?: InputMaybe<Scalars['Int']>;
  provinceId?: InputMaybe<Scalars['String']>;
  showLegacyClaimsMessage?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ArtistStatus>;
};

export type ArtistReleaseBinding = Node & {
  __typename?: 'ArtistReleaseBinding';
  /** Reads a single `Artist` that is related to this `ArtistReleaseBinding`. */
  artistByArtistId?: Maybe<Artist>;
  artistId: Scalars['UUID'];
  isOwner?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Release` that is related to this `ArtistReleaseBinding`. */
  releaseByReleaseId?: Maybe<Release>;
  releaseId: Scalars['UUID'];
};

/**
 * A condition to be used against `ArtistReleaseBinding` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ArtistReleaseBindingCondition = {
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `releaseId` field. */
  releaseId?: InputMaybe<Scalars['UUID']>;
};

/** An input for mutations affecting `ArtistReleaseBinding` */
export type ArtistReleaseBindingInput = {
  artistId: Scalars['UUID'];
  releaseId: Scalars['UUID'];
};

/** Represents an update to a `ArtistReleaseBinding`. Fields that are set will be updated. */
export type ArtistReleaseBindingPatch = {
  artistId?: InputMaybe<Scalars['UUID']>;
  releaseId?: InputMaybe<Scalars['UUID']>;
};

/** Methods to use when ordering `ArtistReleaseBinding`. */
export enum ArtistReleaseBindingsOrderBy {
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReleaseIdAsc = 'RELEASE_ID_ASC',
  ReleaseIdDesc = 'RELEASE_ID_DESC',
}

export enum ArtistStatus {
  Core = 'CORE',
  Orion = 'ORION',
  PendingOrion = 'PENDING_ORION',
}

/** Methods to use when ordering `Artist`. */
export enum ArtistsOrderBy {
  CompanyLegalProvinceAsc = 'COMPANY_LEGAL_PROVINCE_ASC',
  CompanyLegalProvinceDesc = 'COMPANY_LEGAL_PROVINCE_DESC',
  CompanyLegalStatusAsc = 'COMPANY_LEGAL_STATUS_ASC',
  CompanyLegalStatusDesc = 'COMPANY_LEGAL_STATUS_DESC',
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportIdAsc = 'IMPORT_ID_ASC',
  ImportIdDesc = 'IMPORT_ID_DESC',
  IsSlowingDownAsc = 'IS_SLOWING_DOWN_ASC',
  IsSlowingDownDesc = 'IS_SLOWING_DOWN_DESC',
  ManagementIdAsc = 'MANAGEMENT_ID_ASC',
  ManagementIdDesc = 'MANAGEMENT_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OldLifetimeTotalAsc = 'OLD_LIFETIME_TOTAL_ASC',
  OldLifetimeTotalDesc = 'OLD_LIFETIME_TOTAL_DESC',
  OldOrionApprovalCountAsc = 'OLD_ORION_APPROVAL_COUNT_ASC',
  OldOrionApprovalCountDesc = 'OLD_ORION_APPROVAL_COUNT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProvinceIdAsc = 'PROVINCE_ID_ASC',
  ProvinceIdDesc = 'PROVINCE_ID_DESC',
  ShowLegacyClaimsMessageAsc = 'SHOW_LEGACY_CLAIMS_MESSAGE_ASC',
  ShowLegacyClaimsMessageDesc = 'SHOW_LEGACY_CLAIMS_MESSAGE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
}

export type Attachment = Node & {
  __typename?: 'Attachment';
  /** Reads a single `Account` that is related to this `Attachment`. */
  accountByAccountId?: Maybe<Account>;
  accountId?: Maybe<Scalars['UUID']>;
  /** Reads and enables pagination through a set of `Application`. */
  applicationsBySignedContractIdList: Array<Application>;
  /** Reads and enables pagination through a set of `Application`. */
  applicationsByTourSummaryIdList: Array<Application>;
  /** Reads and enables pagination through a set of `AttachmentApplicationBinding`. */
  attachmentApplicationBindingsByAttachmentIdList: Array<AttachmentApplicationBinding>;
  /** Reads and enables pagination through a set of `AttachmentBoardMeetingBlockBinding`. */
  attachmentBoardMeetingBlockBindingsByAttachmentIdList: Array<AttachmentBoardMeetingBlockBinding>;
  id: Scalars['UUID'];
  isOwner?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  size: Scalars['Int'];
};

export type AttachmentApplicationsBySignedContractIdListArgs = {
  condition?: InputMaybe<ApplicationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ApplicationsOrderBy>>;
};

export type AttachmentApplicationsByTourSummaryIdListArgs = {
  condition?: InputMaybe<ApplicationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ApplicationsOrderBy>>;
};

export type AttachmentAttachmentApplicationBindingsByAttachmentIdListArgs = {
  condition?: InputMaybe<AttachmentApplicationBindingCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AttachmentApplicationBindingsOrderBy>>;
};

export type AttachmentAttachmentBoardMeetingBlockBindingsByAttachmentIdListArgs =
  {
    condition?: InputMaybe<AttachmentBoardMeetingBlockBindingCondition>;
    first?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<AttachmentBoardMeetingBlockBindingsOrderBy>>;
  };

export type AttachmentApplicationBinding = Node & {
  __typename?: 'AttachmentApplicationBinding';
  /** Reads a single `Application` that is related to this `AttachmentApplicationBinding`. */
  applicationByApplicationId?: Maybe<Application>;
  applicationId: Scalars['UUID'];
  /** Reads a single `Attachment` that is related to this `AttachmentApplicationBinding`. */
  attachmentByAttachmentId?: Maybe<Attachment>;
  attachmentId: Scalars['UUID'];
  isOwner?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/**
 * A condition to be used against `AttachmentApplicationBinding` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type AttachmentApplicationBindingCondition = {
  /** Checks for equality with the object’s `applicationId` field. */
  applicationId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `attachmentId` field. */
  attachmentId?: InputMaybe<Scalars['UUID']>;
};

/** An input for mutations affecting `AttachmentApplicationBinding` */
export type AttachmentApplicationBindingInput = {
  applicationId: Scalars['UUID'];
  attachmentId: Scalars['UUID'];
};

/** Represents an update to a `AttachmentApplicationBinding`. Fields that are set will be updated. */
export type AttachmentApplicationBindingPatch = {
  applicationId?: InputMaybe<Scalars['UUID']>;
  attachmentId?: InputMaybe<Scalars['UUID']>;
};

/** Methods to use when ordering `AttachmentApplicationBinding`. */
export enum AttachmentApplicationBindingsOrderBy {
  ApplicationIdAsc = 'APPLICATION_ID_ASC',
  ApplicationIdDesc = 'APPLICATION_ID_DESC',
  AttachmentIdAsc = 'ATTACHMENT_ID_ASC',
  AttachmentIdDesc = 'ATTACHMENT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type AttachmentBoardMeetingBlockBinding = Node & {
  __typename?: 'AttachmentBoardMeetingBlockBinding';
  /** Reads a single `Attachment` that is related to this `AttachmentBoardMeetingBlockBinding`. */
  attachmentByAttachmentId?: Maybe<Attachment>;
  attachmentId: Scalars['UUID'];
  blockId: Scalars['UUID'];
  /** Reads a single `BoardMeetingBlock` that is related to this `AttachmentBoardMeetingBlockBinding`. */
  boardMeetingBlockByBlockId?: Maybe<BoardMeetingBlock>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/**
 * A condition to be used against `AttachmentBoardMeetingBlockBinding` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type AttachmentBoardMeetingBlockBindingCondition = {
  /** Checks for equality with the object’s `attachmentId` field. */
  attachmentId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `blockId` field. */
  blockId?: InputMaybe<Scalars['UUID']>;
};

/** An input for mutations affecting `AttachmentBoardMeetingBlockBinding` */
export type AttachmentBoardMeetingBlockBindingInput = {
  attachmentId: Scalars['UUID'];
  blockId: Scalars['UUID'];
};

/** Represents an update to a `AttachmentBoardMeetingBlockBinding`. Fields that are set will be updated. */
export type AttachmentBoardMeetingBlockBindingPatch = {
  attachmentId?: InputMaybe<Scalars['UUID']>;
  blockId?: InputMaybe<Scalars['UUID']>;
};

/** Methods to use when ordering `AttachmentBoardMeetingBlockBinding`. */
export enum AttachmentBoardMeetingBlockBindingsOrderBy {
  AttachmentIdAsc = 'ATTACHMENT_ID_ASC',
  AttachmentIdDesc = 'ATTACHMENT_ID_DESC',
  BlockIdAsc = 'BLOCK_ID_ASC',
  BlockIdDesc = 'BLOCK_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/**
 * A condition to be used against `Attachment` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AttachmentCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `size` field. */
  size?: InputMaybe<Scalars['Int']>;
};

/** An input for mutations affecting `Attachment` */
export type AttachmentInput = {
  accountId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
  size: Scalars['Int'];
};

/** Represents an update to a `Attachment`. Fields that are set will be updated. */
export type AttachmentPatch = {
  accountId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `Attachment`. */
export enum AttachmentsOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SizeAsc = 'SIZE_ASC',
  SizeDesc = 'SIZE_DESC',
}

export type BoardMeetingBlock = Node & {
  __typename?: 'BoardMeetingBlock';
  /** Reads and enables pagination through a set of `AttachmentBoardMeetingBlockBinding`. */
  attachmentBoardMeetingBlockBindingsByBlockIdList: Array<AttachmentBoardMeetingBlockBinding>;
  /** Reads and enables pagination through a set of `Attachment`. */
  attachmentsList?: Maybe<Array<Maybe<Attachment>>>;
  content: Scalars['String'];
  displayOrder: Scalars['Int'];
  /** Reads a single `FundingRound` that is related to this `BoardMeetingBlock`. */
  fundingRoundByFundingRoundId?: Maybe<FundingRound>;
  fundingRoundId: Scalars['UUID'];
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  title: Scalars['String'];
};

export type BoardMeetingBlockAttachmentBoardMeetingBlockBindingsByBlockIdListArgs =
  {
    condition?: InputMaybe<AttachmentBoardMeetingBlockBindingCondition>;
    first?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<AttachmentBoardMeetingBlockBindingsOrderBy>>;
  };

export type BoardMeetingBlockAttachmentsListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/**
 * A condition to be used against `BoardMeetingBlock` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BoardMeetingBlockCondition = {
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayOrder` field. */
  displayOrder?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `fundingRoundId` field. */
  fundingRoundId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `BoardMeetingBlock` */
export type BoardMeetingBlockInput = {
  content: Scalars['String'];
  displayOrder: Scalars['Int'];
  fundingRoundId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  title: Scalars['String'];
};

/** Represents an update to a `BoardMeetingBlock`. Fields that are set will be updated. */
export type BoardMeetingBlockPatch = {
  content?: InputMaybe<Scalars['String']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  fundingRoundId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `BoardMeetingBlock`. */
export enum BoardMeetingBlocksOrderBy {
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  DisplayOrderAsc = 'DISPLAY_ORDER_ASC',
  DisplayOrderDesc = 'DISPLAY_ORDER_DESC',
  FundingRoundIdAsc = 'FUNDING_ROUND_ID_ASC',
  FundingRoundIdDesc = 'FUNDING_ROUND_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
}

export type BoardMemberApplicationBlock = Node & {
  __typename?: 'BoardMemberApplicationBlock';
  /** Reads a single `Account` that is related to this `BoardMemberApplicationBlock`. */
  accountByAccountId?: Maybe<Account>;
  accountId: Scalars['UUID'];
  /** Reads a single `Application` that is related to this `BoardMemberApplicationBlock`. */
  applicationByApplicationId?: Maybe<Application>;
  applicationId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/**
 * A condition to be used against `BoardMemberApplicationBlock` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BoardMemberApplicationBlockCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `applicationId` field. */
  applicationId?: InputMaybe<Scalars['UUID']>;
};

/** An input for mutations affecting `BoardMemberApplicationBlock` */
export type BoardMemberApplicationBlockInput = {
  accountId: Scalars['UUID'];
  applicationId: Scalars['UUID'];
};

/** Represents an update to a `BoardMemberApplicationBlock`. Fields that are set will be updated. */
export type BoardMemberApplicationBlockPatch = {
  accountId?: InputMaybe<Scalars['UUID']>;
  applicationId?: InputMaybe<Scalars['UUID']>;
};

/** Methods to use when ordering `BoardMemberApplicationBlock`. */
export enum BoardMemberApplicationBlocksOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  ApplicationIdAsc = 'APPLICATION_ID_ASC',
  ApplicationIdDesc = 'APPLICATION_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type BoardMemberInvitationStatusResponse = {
  __typename?: 'BoardMemberInvitationStatusResponse';
  code?: Maybe<BoardMemberInvitationStatusResponseCode>;
  email?: Maybe<Scalars['String']>;
};

export enum BoardMemberInvitationStatusResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Linkable = 'LINKABLE',
  Success = 'SUCCESS',
}

/** All input for the `cancelFundingRequest` mutation. */
export type CancelFundingRequestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  fundingRequestId: Scalars['UUID'];
};

/** The output of our `cancelFundingRequest` mutation. */
export type CancelFundingRequestPayload = {
  __typename?: 'CancelFundingRequestPayload';
  cancelFundingRequestResponse?: Maybe<CancelFundingRequestResponse>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CancelFundingRequestResponse = {
  __typename?: 'CancelFundingRequestResponse';
  code?: Maybe<CancelFundingRequestResponseCode>;
  fundingRequest?: Maybe<FundingRequest>;
};

export enum CancelFundingRequestResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

/** All input for the `cancelTourDate` mutation. */
export type CancelTourDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  tourDateId: Scalars['UUID'];
};

/** The output of our `cancelTourDate` mutation. */
export type CancelTourDatePayload = {
  __typename?: 'CancelTourDatePayload';
  cancelTourDateResponse?: Maybe<CancelTourDateResponse>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CancelTourDateResponse = {
  __typename?: 'CancelTourDateResponse';
  code?: Maybe<CancelTourDateResponseCode>;
  tourDate?: Maybe<TourDate>;
};

export enum CancelTourDateResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

/** All input for the `changePassword` mutation. */
export type ChangePasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

/** The output of our `changePassword` mutation. */
export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  changePasswordResponse?: Maybe<ChangePasswordResponse>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type ChangePasswordResponse = {
  __typename?: 'ChangePasswordResponse';
  code?: Maybe<ChangePasswordResponseCode>;
};

export enum ChangePasswordResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
  WrongPassword = 'WRONG_PASSWORD',
}

/** All input for the `clearFluxCapacitor` mutation. */
export type ClearFluxCapacitorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `clearFluxCapacitor` mutation. */
export type ClearFluxCapacitorPayload = {
  __typename?: 'ClearFluxCapacitorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['Datetime']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Contact = Node & {
  __typename?: 'Contact';
  /** Reads a single `Application` that is related to this `Contact`. */
  applicationByApplicationId?: Maybe<Application>;
  applicationId: Scalars['UUID'];
  email: Scalars['Email'];
  isOwner?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** A condition to be used against `Contact` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ContactCondition = {
  /** Checks for equality with the object’s `applicationId` field. */
  applicationId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['Email']>;
};

/** An input for mutations affecting `Contact` */
export type ContactInput = {
  applicationId: Scalars['UUID'];
  email: Scalars['Email'];
};

/** Represents an update to a `Contact`. Fields that are set will be updated. */
export type ContactPatch = {
  applicationId?: InputMaybe<Scalars['UUID']>;
  email?: InputMaybe<Scalars['Email']>;
};

/** Methods to use when ordering `Contact`. */
export enum ContactsOrderBy {
  ApplicationIdAsc = 'APPLICATION_ID_ASC',
  ApplicationIdDesc = 'APPLICATION_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type Contract = Node & {
  __typename?: 'Contract';
  /** Reads a single `Application` that is related to this `Contract`. */
  applicationByApplicationId?: Maybe<Application>;
  applicationId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  size: Scalars['Int'];
  url: Scalars['String'];
};

/**
 * A condition to be used against `Contract` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ContractCondition = {
  /** Checks for equality with the object’s `applicationId` field. */
  applicationId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `size` field. */
  size?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Contract` */
export type ContractInput = {
  applicationId: Scalars['UUID'];
  size: Scalars['Int'];
  url: Scalars['String'];
};

/** Represents an update to a `Contract`. Fields that are set will be updated. */
export type ContractPatch = {
  applicationId?: InputMaybe<Scalars['UUID']>;
  size?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Contract`. */
export enum ContractsOrderBy {
  ApplicationIdAsc = 'APPLICATION_ID_ASC',
  ApplicationIdDesc = 'APPLICATION_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SizeAsc = 'SIZE_ASC',
  SizeDesc = 'SIZE_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
}

/** Methods to use when ordering `Country`. */
export enum CountriesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RegionIdAsc = 'REGION_ID_ASC',
  RegionIdDesc = 'REGION_ID_DESC',
}

export type Country = Node & {
  __typename?: 'Country';
  id: Scalars['UUID'];
  /** Reads and enables pagination through a set of `Label`. */
  labelsByCountryIdList: Array<Label>;
  /** Reads and enables pagination through a set of `Management`. */
  managementsByCountryIdList: Array<Management>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Region` that is related to this `Country`. */
  regionByRegionId?: Maybe<Region>;
  regionId: Scalars['String'];
  /** Reads and enables pagination through a set of `TourDate`. */
  tourDatesByTourDateCountryId: TourDatesConnection;
  /** Reads and enables pagination through a set of `TourDate`. */
  tourDatesByTourDateCountryIdList: Array<TourDate>;
};

export type CountryLabelsByCountryIdListArgs = {
  condition?: InputMaybe<LabelCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LabelsOrderBy>>;
};

export type CountryManagementsByCountryIdListArgs = {
  condition?: InputMaybe<ManagementCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ManagementsOrderBy>>;
};

export type CountryTourDatesByTourDateCountryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TourDateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TourDatesOrderBy>>;
};

export type CountryTourDatesByTourDateCountryIdListArgs = {
  condition?: InputMaybe<TourDateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TourDatesOrderBy>>;
};

/** A condition to be used against `Country` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CountryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `regionId` field. */
  regionId?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Country` */
export type CountryInput = {
  id?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
  regionId: Scalars['String'];
};

/** Represents an update to a `Country`. Fields that are set will be updated. */
export type CountryPatch = {
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['String']>;
};

/** All input for the create `AccountArtistBinding` mutation. */
export type CreateAccountArtistBindingInput = {
  /** The `AccountArtistBinding` to be created by this mutation. */
  accountArtistBinding: AccountArtistBindingInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AccountArtistBinding` mutation. */
export type CreateAccountArtistBindingPayload = {
  __typename?: 'CreateAccountArtistBindingPayload';
  /** The `AccountArtistBinding` that was created by this mutation. */
  accountArtistBinding?: Maybe<AccountArtistBinding>;
  /** Reads a single `Account` that is related to this `AccountArtistBinding`. */
  accountByAccountId?: Maybe<Account>;
  /** Reads a single `Artist` that is related to this `AccountArtistBinding`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Account` mutation. */
export type CreateAccountInput = {
  /** The `Account` to be created by this mutation. */
  account: AccountInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type CreateAccountInvitationResponse = {
  __typename?: 'CreateAccountInvitationResponse';
  code?: Maybe<CreateAccountInvitationResponseCode>;
  invitationId?: Maybe<Scalars['String']>;
};

export enum CreateAccountInvitationResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

/** All input for the create `AccountPassword` mutation. */
export type CreateAccountPasswordInput = {
  /** The `AccountPassword` to be created by this mutation. */
  accountPassword: AccountPasswordInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AccountPassword` mutation. */
export type CreateAccountPasswordPayload = {
  __typename?: 'CreateAccountPasswordPayload';
  /** Reads a single `Account` that is related to this `AccountPassword`. */
  accountByAccountId?: Maybe<Account>;
  /** The `AccountPassword` that was created by this mutation. */
  accountPassword?: Maybe<AccountPassword>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Account` mutation. */
export type CreateAccountPayload = {
  __typename?: 'CreateAccountPayload';
  /** The `Account` that was created by this mutation. */
  account?: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ApplicationBoardNote` mutation. */
export type CreateApplicationBoardNoteInput = {
  /** The `ApplicationBoardNote` to be created by this mutation. */
  applicationBoardNote: ApplicationBoardNoteInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ApplicationBoardNote` mutation. */
export type CreateApplicationBoardNotePayload = {
  __typename?: 'CreateApplicationBoardNotePayload';
  /** The `ApplicationBoardNote` that was created by this mutation. */
  applicationBoardNote?: Maybe<ApplicationBoardNote>;
  /** Reads a single `Application` that is related to this `ApplicationBoardNote`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Application` mutation. */
export type CreateApplicationInput = {
  /** The `Application` to be created by this mutation. */
  application: ApplicationInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Application` mutation. */
export type CreateApplicationPayload = {
  __typename?: 'CreateApplicationPayload';
  /** Reads a single `Account` that is related to this `Application`. */
  accountByPrimaryContactId?: Maybe<Account>;
  /** The `Application` that was created by this mutation. */
  application?: Maybe<Application>;
  /** Reads a single `Artist` that is related to this `Application`. */
  artistByArtistId?: Maybe<Artist>;
  /** Reads a single `Attachment` that is related to this `Application`. */
  attachmentBySignedContractId?: Maybe<Attachment>;
  /** Reads a single `Attachment` that is related to this `Application`. */
  attachmentByTourSummaryId?: Maybe<Attachment>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `DirectDepositAccount` that is related to this `Application`. */
  directDepositAccountByFundingRequestDirectDepositId?: Maybe<DirectDepositAccount>;
  /** Reads a single `DirectDepositAccount` that is related to this `Application`. */
  directDepositAccountByTouringDirectDepositId?: Maybe<DirectDepositAccount>;
  /** Reads a single `FundingRound` that is related to this `Application`. */
  fundingRoundByFundingRoundId?: Maybe<FundingRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CreateApplicationResponse = {
  __typename?: 'CreateApplicationResponse';
  code?: Maybe<CreateApplicationResponseCode>;
  id?: Maybe<Scalars['String']>;
};

export enum CreateApplicationResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

/** All input for the `createArtistAndLink` mutation. */
export type CreateArtistAndLinkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** The output of our `createArtistAndLink` mutation. */
export type CreateArtistAndLinkPayload = {
  __typename?: 'CreateArtistAndLinkPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  createArtistAndLinkResponse?: Maybe<CreateArtistAndLinkResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CreateArtistAndLinkResponse = {
  __typename?: 'CreateArtistAndLinkResponse';
  code?: Maybe<CreateArtistAndLinkResponseCode>;
};

export enum CreateArtistAndLinkResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

/** All input for the create `Artist` mutation. */
export type CreateArtistInput = {
  /** The `Artist` to be created by this mutation. */
  artist: ArtistInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `createArtistInvitation` mutation. */
export type CreateArtistInvitationInput = {
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `createArtistInvitation` mutation. */
export type CreateArtistInvitationPayload = {
  __typename?: 'CreateArtistInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  createArtistInvitationResponse?: Maybe<CreateArtistInvitationResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CreateArtistInvitationResponse = {
  __typename?: 'CreateArtistInvitationResponse';
  code?: Maybe<CreateArtistInvitationResponseCode>;
  email?: Maybe<Scalars['String']>;
  invitationId?: Maybe<Scalars['UUID']>;
};

export enum CreateArtistInvitationResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  PermissionDenied = 'PERMISSION_DENIED',
  Success = 'SUCCESS',
}

/** The output of our create `Artist` mutation. */
export type CreateArtistPayload = {
  __typename?: 'CreateArtistPayload';
  /** The `Artist` that was created by this mutation. */
  artist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LegalStatus` that is related to this `Artist`. */
  legalStatusByCompanyLegalStatus?: Maybe<LegalStatus>;
  /** Reads a single `Management` that is related to this `Artist`. */
  managementByManagementId?: Maybe<Management>;
  /** Reads a single `Province` that is related to this `Artist`. */
  provinceByCompanyLegalProvince?: Maybe<Province>;
  /** Reads a single `Province` that is related to this `Artist`. */
  provinceByProvinceId?: Maybe<Province>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ArtistReleaseBinding` mutation. */
export type CreateArtistReleaseBindingInput = {
  /** The `ArtistReleaseBinding` to be created by this mutation. */
  artistReleaseBinding: ArtistReleaseBindingInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ArtistReleaseBinding` mutation. */
export type CreateArtistReleaseBindingPayload = {
  __typename?: 'CreateArtistReleaseBindingPayload';
  /** Reads a single `Artist` that is related to this `ArtistReleaseBinding`. */
  artistByArtistId?: Maybe<Artist>;
  /** The `ArtistReleaseBinding` that was created by this mutation. */
  artistReleaseBinding?: Maybe<ArtistReleaseBinding>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Release` that is related to this `ArtistReleaseBinding`. */
  releaseByReleaseId?: Maybe<Release>;
};

/** All input for the create `AttachmentApplicationBinding` mutation. */
export type CreateAttachmentApplicationBindingInput = {
  /** The `AttachmentApplicationBinding` to be created by this mutation. */
  attachmentApplicationBinding: AttachmentApplicationBindingInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AttachmentApplicationBinding` mutation. */
export type CreateAttachmentApplicationBindingPayload = {
  __typename?: 'CreateAttachmentApplicationBindingPayload';
  /** Reads a single `Application` that is related to this `AttachmentApplicationBinding`. */
  applicationByApplicationId?: Maybe<Application>;
  /** The `AttachmentApplicationBinding` that was created by this mutation. */
  attachmentApplicationBinding?: Maybe<AttachmentApplicationBinding>;
  /** Reads a single `Attachment` that is related to this `AttachmentApplicationBinding`. */
  attachmentByAttachmentId?: Maybe<Attachment>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `AttachmentBoardMeetingBlockBinding` mutation. */
export type CreateAttachmentBoardMeetingBlockBindingInput = {
  /** The `AttachmentBoardMeetingBlockBinding` to be created by this mutation. */
  attachmentBoardMeetingBlockBinding: AttachmentBoardMeetingBlockBindingInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AttachmentBoardMeetingBlockBinding` mutation. */
export type CreateAttachmentBoardMeetingBlockBindingPayload = {
  __typename?: 'CreateAttachmentBoardMeetingBlockBindingPayload';
  /** The `AttachmentBoardMeetingBlockBinding` that was created by this mutation. */
  attachmentBoardMeetingBlockBinding?: Maybe<AttachmentBoardMeetingBlockBinding>;
  /** Reads a single `Attachment` that is related to this `AttachmentBoardMeetingBlockBinding`. */
  attachmentByAttachmentId?: Maybe<Attachment>;
  /** Reads a single `BoardMeetingBlock` that is related to this `AttachmentBoardMeetingBlockBinding`. */
  boardMeetingBlockByBlockId?: Maybe<BoardMeetingBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Attachment` mutation. */
export type CreateAttachmentInput = {
  /** The `Attachment` to be created by this mutation. */
  attachment: AttachmentInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Attachment` mutation. */
export type CreateAttachmentPayload = {
  __typename?: 'CreateAttachmentPayload';
  /** Reads a single `Account` that is related to this `Attachment`. */
  accountByAccountId?: Maybe<Account>;
  /** The `Attachment` that was created by this mutation. */
  attachment?: Maybe<Attachment>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `BoardMeetingBlock` mutation. */
export type CreateBoardMeetingBlockInput = {
  /** The `BoardMeetingBlock` to be created by this mutation. */
  boardMeetingBlock: BoardMeetingBlockInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BoardMeetingBlock` mutation. */
export type CreateBoardMeetingBlockPayload = {
  __typename?: 'CreateBoardMeetingBlockPayload';
  /** The `BoardMeetingBlock` that was created by this mutation. */
  boardMeetingBlock?: Maybe<BoardMeetingBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `FundingRound` that is related to this `BoardMeetingBlock`. */
  fundingRoundByFundingRoundId?: Maybe<FundingRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `BoardMemberApplicationBlock` mutation. */
export type CreateBoardMemberApplicationBlockInput = {
  /** The `BoardMemberApplicationBlock` to be created by this mutation. */
  boardMemberApplicationBlock: BoardMemberApplicationBlockInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BoardMemberApplicationBlock` mutation. */
export type CreateBoardMemberApplicationBlockPayload = {
  __typename?: 'CreateBoardMemberApplicationBlockPayload';
  /** Reads a single `Account` that is related to this `BoardMemberApplicationBlock`. */
  accountByAccountId?: Maybe<Account>;
  /** Reads a single `Application` that is related to this `BoardMemberApplicationBlock`. */
  applicationByApplicationId?: Maybe<Application>;
  /** The `BoardMemberApplicationBlock` that was created by this mutation. */
  boardMemberApplicationBlock?: Maybe<BoardMemberApplicationBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createBoardMemberInvitation` mutation. */
export type CreateBoardMemberInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `createBoardMemberInvitation` mutation. */
export type CreateBoardMemberInvitationPayload = {
  __typename?: 'CreateBoardMemberInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  createBoardMemberInvitationResponse?: Maybe<CreateBoardMemberInvitationResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CreateBoardMemberInvitationResponse = {
  __typename?: 'CreateBoardMemberInvitationResponse';
  accountId?: Maybe<Scalars['UUID']>;
  code?: Maybe<CreateBoardMemberInvitationResponseCode>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum CreateBoardMemberInvitationResponseCode {
  AccountLinked = 'ACCOUNT_LINKED',
  InvalidRequest = 'INVALID_REQUEST',
  InvitationCreated = 'INVITATION_CREATED',
}

/** All input for the create `Contact` mutation. */
export type CreateContactInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Contact` to be created by this mutation. */
  contact: ContactInput;
};

/** The output of our create `Contact` mutation. */
export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  /** Reads a single `Application` that is related to this `Contact`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Contact` that was created by this mutation. */
  contact?: Maybe<Contact>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Contract` mutation. */
export type CreateContractInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Contract` to be created by this mutation. */
  contract: ContractInput;
};

/** The output of our create `Contract` mutation. */
export type CreateContractPayload = {
  __typename?: 'CreateContractPayload';
  /** Reads a single `Application` that is related to this `Contract`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Contract` that was created by this mutation. */
  contract?: Maybe<Contract>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createCoreAccountInvitation` mutation. */
export type CreateCoreAccountInvitationInput = {
  address: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  company: Scalars['String'];
  contact: Scalars['String'];
  date: Scalars['String'];
  distributorId: Scalars['String'];
  email: Scalars['String'];
  genreId: Scalars['String'];
  inCanada: Scalars['Boolean'];
  invitationEmail: Scalars['String'];
  isMajorityEnglish: Scalars['Boolean'];
  labelId: Scalars['String'];
  notes: Scalars['String'];
  numberOfMinutes: Scalars['Int'];
  numberOfTracks: Scalars['Int'];
  otherDistributor?: InputMaybe<Scalars['String']>;
  otherLabel?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  releasesScanned: Scalars['Int'];
  title: Scalars['String'];
  tracksDownloaded: Scalars['Int'];
  tracksStreamed: Scalars['Int'];
  website: Scalars['String'];
};

/** The output of our `createCoreAccountInvitation` mutation. */
export type CreateCoreAccountInvitationPayload = {
  __typename?: 'CreateCoreAccountInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  createAccountInvitationResponse?: Maybe<CreateAccountInvitationResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Country` mutation. */
export type CreateCountryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Country` to be created by this mutation. */
  country: CountryInput;
};

/** The output of our create `Country` mutation. */
export type CreateCountryPayload = {
  __typename?: 'CreateCountryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Country` that was created by this mutation. */
  country?: Maybe<Country>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Region` that is related to this `Country`. */
  regionByRegionId?: Maybe<Region>;
};

/** All input for the create `DirectDepositAccount` mutation. */
export type CreateDirectDepositAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `DirectDepositAccount` to be created by this mutation. */
  directDepositAccount: DirectDepositAccountInput;
};

/** The output of our create `DirectDepositAccount` mutation. */
export type CreateDirectDepositAccountPayload = {
  __typename?: 'CreateDirectDepositAccountPayload';
  /** Reads a single `Artist` that is related to this `DirectDepositAccount`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DirectDepositAccount` that was created by this mutation. */
  directDepositAccount?: Maybe<DirectDepositAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Distributor` mutation. */
export type CreateDistributorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Distributor` to be created by this mutation. */
  distributor: DistributorInput;
};

/** The output of our create `Distributor` mutation. */
export type CreateDistributorPayload = {
  __typename?: 'CreateDistributorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Distributor` that was created by this mutation. */
  distributor?: Maybe<Distributor>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `EligibilityNotice` mutation. */
export type CreateEligibilityNoticeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EligibilityNotice` to be created by this mutation. */
  eligibilityNotice: EligibilityNoticeInput;
};

/** The output of our create `EligibilityNotice` mutation. */
export type CreateEligibilityNoticePayload = {
  __typename?: 'CreateEligibilityNoticePayload';
  /** Reads a single `Artist` that is related to this `EligibilityNotice`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EligibilityNotice` that was created by this mutation. */
  eligibilityNotice?: Maybe<EligibilityNotice>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Expense` mutation. */
export type CreateExpenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Expense` to be created by this mutation. */
  expense: ExpenseInput;
};

/** The output of our create `Expense` mutation. */
export type CreateExpensePayload = {
  __typename?: 'CreateExpensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Expense` that was created by this mutation. */
  expense?: Maybe<Expense>;
  /** An edge for our `Expense`. May be used by Relay 1. */
  expenseEdge?: Maybe<ExpensesEdge>;
  /** Reads a single `FundingRequest` that is related to this `Expense`. */
  fundingRequestByFundingRequestId?: Maybe<FundingRequest>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Expense` mutation. */
export type CreateExpensePayloadExpenseEdgeArgs = {
  orderBy?: InputMaybe<Array<ExpensesOrderBy>>;
};

/** All input for the create `FundingRequest` mutation. */
export type CreateFundingRequestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FundingRequest` to be created by this mutation. */
  fundingRequest: FundingRequestInput;
};

/** The output of our create `FundingRequest` mutation. */
export type CreateFundingRequestPayload = {
  __typename?: 'CreateFundingRequestPayload';
  /** Reads a single `Application` that is related to this `FundingRequest`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FundingRequest` that was created by this mutation. */
  fundingRequest?: Maybe<FundingRequest>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `FundingRound` mutation. */
export type CreateFundingRoundInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FundingRound` to be created by this mutation. */
  fundingRound: FundingRoundInput;
};

/** The output of our create `FundingRound` mutation. */
export type CreateFundingRoundPayload = {
  __typename?: 'CreateFundingRoundPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FundingRound` that was created by this mutation. */
  fundingRound?: Maybe<FundingRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Genre` mutation. */
export type CreateGenreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Genre` to be created by this mutation. */
  genre: GenreInput;
};

/** The output of our create `Genre` mutation. */
export type CreateGenrePayload = {
  __typename?: 'CreateGenrePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Genre` that was created by this mutation. */
  genre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Label` mutation. */
export type CreateLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Label` to be created by this mutation. */
  label: LabelInput;
};

/** The output of our create `Label` mutation. */
export type CreateLabelPayload = {
  __typename?: 'CreateLabelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Label`. */
  countryByCountryId?: Maybe<Country>;
  /** The `Label` that was created by this mutation. */
  label?: Maybe<Label>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `LegalStatus` mutation. */
export type CreateLegalStatusInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `LegalStatus` to be created by this mutation. */
  legalStatus: LegalStatusInput;
};

/** The output of our create `LegalStatus` mutation. */
export type CreateLegalStatusPayload = {
  __typename?: 'CreateLegalStatusPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LegalStatus` that was created by this mutation. */
  legalStatus?: Maybe<LegalStatus>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Management` mutation. */
export type CreateManagementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Management` to be created by this mutation. */
  management: ManagementInput;
};

/** The output of our create `Management` mutation. */
export type CreateManagementPayload = {
  __typename?: 'CreateManagementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Management`. */
  countryByCountryId?: Maybe<Country>;
  /** Reads a single `LegalStatus` that is related to this `Management`. */
  legalStatusByLegalStatus?: Maybe<LegalStatus>;
  /** The `Management` that was created by this mutation. */
  management?: Maybe<Management>;
  /** Reads a single `Province` that is related to this `Management`. */
  provinceByLegalProvince?: Maybe<Province>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createNewApplication` mutation. */
export type CreateNewApplicationInput = {
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `createNewApplication` mutation. */
export type CreateNewApplicationPayload = {
  __typename?: 'CreateNewApplicationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  createApplicationResponse?: Maybe<CreateApplicationResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createOrionAccountInvitation` mutation. */
export type CreateOrionAccountInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  invitationEmail: Scalars['String'];
};

/** The output of our `createOrionAccountInvitation` mutation. */
export type CreateOrionAccountInvitationPayload = {
  __typename?: 'CreateOrionAccountInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  createAccountInvitationResponse?: Maybe<CreateAccountInvitationResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Payment` mutation. */
export type CreatePaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Payment` to be created by this mutation. */
  payment: PaymentInput;
};

/** The output of our create `Payment` mutation. */
export type CreatePaymentPayload = {
  __typename?: 'CreatePaymentPayload';
  /** Reads a single `Application` that is related to this `Payment`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `DirectDepositAccount` that is related to this `Payment`. */
  directDepositAccountByDirectDepositAccountId?: Maybe<DirectDepositAccount>;
  /** The `Payment` that was created by this mutation. */
  payment?: Maybe<Payment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Province` mutation. */
export type CreateProvinceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Province` to be created by this mutation. */
  province: ProvinceInput;
};

/** The output of our create `Province` mutation. */
export type CreateProvincePayload = {
  __typename?: 'CreateProvincePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Province` that was created by this mutation. */
  province?: Maybe<Province>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Region` mutation. */
export type CreateRegionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Region` to be created by this mutation. */
  region: RegionInput;
};

/** The output of our create `Region` mutation. */
export type CreateRegionPayload = {
  __typename?: 'CreateRegionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Region` that was created by this mutation. */
  region?: Maybe<Region>;
};

/** All input for the create `Release` mutation. */
export type CreateReleaseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Release` to be created by this mutation. */
  release: ReleaseInput;
};

/** The output of our create `Release` mutation. */
export type CreateReleasePayload = {
  __typename?: 'CreateReleasePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Distributor` that is related to this `Release`. */
  distributorByDistributorId?: Maybe<Distributor>;
  /** Reads a single `Genre` that is related to this `Release`. */
  genreByGenreId?: Maybe<Genre>;
  /** Reads a single `Label` that is related to this `Release`. */
  labelByLabelId?: Maybe<Label>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Release` that was created by this mutation. */
  release?: Maybe<Release>;
};

/** All input for the create `Sale` mutation. */
export type CreateSaleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Sale` to be created by this mutation. */
  sale: SaleInput;
};

/** The output of our create `Sale` mutation. */
export type CreateSalePayload = {
  __typename?: 'CreateSalePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Release` that is related to this `Sale`. */
  releaseByReleaseId?: Maybe<Release>;
  /** The `Sale` that was created by this mutation. */
  sale?: Maybe<Sale>;
};

/** All input for the create `Single` mutation. */
export type CreateSingleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Single` to be created by this mutation. */
  single: SingleInput;
};

/** The output of our create `Single` mutation. */
export type CreateSinglePayload = {
  __typename?: 'CreateSinglePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Release` that is related to this `Single`. */
  releaseByReleaseId?: Maybe<Release>;
  /** The `Single` that was created by this mutation. */
  single?: Maybe<Single>;
};

/** All input for the create `TourDate` mutation. */
export type CreateTourDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TourDate` to be created by this mutation. */
  tourDate: TourDateInput;
};

/** The output of our create `TourDate` mutation. */
export type CreateTourDatePayload = {
  __typename?: 'CreateTourDatePayload';
  /** Reads a single `Application` that is related to this `TourDate`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `TourDate`. */
  countryByTourDateCountryId?: Maybe<Country>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TourDate` that was created by this mutation. */
  tourDate?: Maybe<TourDate>;
  /** An edge for our `TourDate`. May be used by Relay 1. */
  tourDateEdge?: Maybe<TourDatesEdge>;
  /** Reads a single `TourDateType` that is related to this `TourDate`. */
  tourDateTypeByTourDateTypeId?: Maybe<TourDateType>;
};

/** The output of our create `TourDate` mutation. */
export type CreateTourDatePayloadTourDateEdgeArgs = {
  orderBy?: InputMaybe<Array<TourDatesOrderBy>>;
};

/** All input for the create `TourDateType` mutation. */
export type CreateTourDateTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TourDateType` to be created by this mutation. */
  tourDateType: TourDateTypeInput;
};

/** The output of our create `TourDateType` mutation. */
export type CreateTourDateTypePayload = {
  __typename?: 'CreateTourDateTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TourDateType` that was created by this mutation. */
  tourDateType?: Maybe<TourDateType>;
};

/** All input for the create `VisibleAccountArtistBinding` mutation. */
export type CreateVisibleAccountArtistBindingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `VisibleAccountArtistBinding` to be created by this mutation. */
  visibleAccountArtistBinding: VisibleAccountArtistBindingInput;
};

/** The output of our create `VisibleAccountArtistBinding` mutation. */
export type CreateVisibleAccountArtistBindingPayload = {
  __typename?: 'CreateVisibleAccountArtistBindingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VisibleAccountArtistBinding` that was created by this mutation. */
  visibleAccountArtistBinding?: Maybe<VisibleAccountArtistBinding>;
};

/** All input for the `deleteAccountArtistBindingByAccountIdAndArtistId` mutation. */
export type DeleteAccountArtistBindingByAccountIdAndArtistIdInput = {
  accountId: Scalars['UUID'];
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `deleteAccountArtistBinding` mutation. */
export type DeleteAccountArtistBindingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AccountArtistBinding` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `AccountArtistBinding` mutation. */
export type DeleteAccountArtistBindingPayload = {
  __typename?: 'DeleteAccountArtistBindingPayload';
  /** The `AccountArtistBinding` that was deleted by this mutation. */
  accountArtistBinding?: Maybe<AccountArtistBinding>;
  /** Reads a single `Account` that is related to this `AccountArtistBinding`. */
  accountByAccountId?: Maybe<Account>;
  /** Reads a single `Artist` that is related to this `AccountArtistBinding`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAccountArtistBindingId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteAccountByEmail` mutation. */
export type DeleteAccountByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['Email'];
};

/** All input for the `deleteAccountById` mutation. */
export type DeleteAccountByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteAccount` mutation. */
export type DeleteAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Account` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAccountPasswordByAccountId` mutation. */
export type DeleteAccountPasswordByAccountIdInput = {
  accountId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `deleteAccountPassword` mutation. */
export type DeleteAccountPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AccountPassword` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `AccountPassword` mutation. */
export type DeleteAccountPasswordPayload = {
  __typename?: 'DeleteAccountPasswordPayload';
  /** Reads a single `Account` that is related to this `AccountPassword`. */
  accountByAccountId?: Maybe<Account>;
  /** The `AccountPassword` that was deleted by this mutation. */
  accountPassword?: Maybe<AccountPassword>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAccountPasswordId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Account` mutation. */
export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  /** The `Account` that was deleted by this mutation. */
  account?: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAccountId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteApplicationBoardNoteByApplicationId` mutation. */
export type DeleteApplicationBoardNoteByApplicationIdInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `deleteApplicationBoardNote` mutation. */
export type DeleteApplicationBoardNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ApplicationBoardNote` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `ApplicationBoardNote` mutation. */
export type DeleteApplicationBoardNotePayload = {
  __typename?: 'DeleteApplicationBoardNotePayload';
  /** The `ApplicationBoardNote` that was deleted by this mutation. */
  applicationBoardNote?: Maybe<ApplicationBoardNote>;
  /** Reads a single `Application` that is related to this `ApplicationBoardNote`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedApplicationBoardNoteId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteApplicationByArtistIdAndFundingRoundId` mutation. */
export type DeleteApplicationByArtistIdAndFundingRoundIdInput = {
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  fundingRoundId: Scalars['UUID'];
};

/** All input for the `deleteApplicationById` mutation. */
export type DeleteApplicationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteApplication` mutation. */
export type DeleteApplicationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Application` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Application` mutation. */
export type DeleteApplicationPayload = {
  __typename?: 'DeleteApplicationPayload';
  /** Reads a single `Account` that is related to this `Application`. */
  accountByPrimaryContactId?: Maybe<Account>;
  /** The `Application` that was deleted by this mutation. */
  application?: Maybe<Application>;
  /** Reads a single `Artist` that is related to this `Application`. */
  artistByArtistId?: Maybe<Artist>;
  /** Reads a single `Attachment` that is related to this `Application`. */
  attachmentBySignedContractId?: Maybe<Attachment>;
  /** Reads a single `Attachment` that is related to this `Application`. */
  attachmentByTourSummaryId?: Maybe<Attachment>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedApplicationId?: Maybe<Scalars['ID']>;
  /** Reads a single `DirectDepositAccount` that is related to this `Application`. */
  directDepositAccountByFundingRequestDirectDepositId?: Maybe<DirectDepositAccount>;
  /** Reads a single `DirectDepositAccount` that is related to this `Application`. */
  directDepositAccountByTouringDirectDepositId?: Maybe<DirectDepositAccount>;
  /** Reads a single `FundingRound` that is related to this `Application`. */
  fundingRoundByFundingRoundId?: Maybe<FundingRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteArtistById` mutation. */
export type DeleteArtistByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteArtistByImportId` mutation. */
export type DeleteArtistByImportIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
};

/** All input for the `deleteArtist` mutation. */
export type DeleteArtistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Artist` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteArtistInvitation` mutation. */
export type DeleteArtistInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  invitationId: Scalars['UUID'];
};

/** The output of our `deleteArtistInvitation` mutation. */
export type DeleteArtistInvitationPayload = {
  __typename?: 'DeleteArtistInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deleteArtistInvitationResponse?: Maybe<DeleteArtistInvitationResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type DeleteArtistInvitationResponse = {
  __typename?: 'DeleteArtistInvitationResponse';
  code?: Maybe<DeleteArtistInvitationResponseCode>;
  email?: Maybe<Scalars['String']>;
  invitationId?: Maybe<Scalars['UUID']>;
};

export enum DeleteArtistInvitationResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  PermissionDenied = 'PERMISSION_DENIED',
  Success = 'SUCCESS',
}

/** The output of our delete `Artist` mutation. */
export type DeleteArtistPayload = {
  __typename?: 'DeleteArtistPayload';
  /** The `Artist` that was deleted by this mutation. */
  artist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedArtistId?: Maybe<Scalars['ID']>;
  /** Reads a single `LegalStatus` that is related to this `Artist`. */
  legalStatusByCompanyLegalStatus?: Maybe<LegalStatus>;
  /** Reads a single `Management` that is related to this `Artist`. */
  managementByManagementId?: Maybe<Management>;
  /** Reads a single `Province` that is related to this `Artist`. */
  provinceByCompanyLegalProvince?: Maybe<Province>;
  /** Reads a single `Province` that is related to this `Artist`. */
  provinceByProvinceId?: Maybe<Province>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteArtistReleaseBindingByArtistIdAndReleaseId` mutation. */
export type DeleteArtistReleaseBindingByArtistIdAndReleaseIdInput = {
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  releaseId: Scalars['UUID'];
};

/** All input for the `deleteArtistReleaseBinding` mutation. */
export type DeleteArtistReleaseBindingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ArtistReleaseBinding` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `ArtistReleaseBinding` mutation. */
export type DeleteArtistReleaseBindingPayload = {
  __typename?: 'DeleteArtistReleaseBindingPayload';
  /** Reads a single `Artist` that is related to this `ArtistReleaseBinding`. */
  artistByArtistId?: Maybe<Artist>;
  /** The `ArtistReleaseBinding` that was deleted by this mutation. */
  artistReleaseBinding?: Maybe<ArtistReleaseBinding>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedArtistReleaseBindingId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Release` that is related to this `ArtistReleaseBinding`. */
  releaseByReleaseId?: Maybe<Release>;
};

/** All input for the `deleteAttachmentApplicationBindingByAttachmentIdAndApplicationId` mutation. */
export type DeleteAttachmentApplicationBindingByAttachmentIdAndApplicationIdInput =
  {
    applicationId: Scalars['UUID'];
    attachmentId: Scalars['UUID'];
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>;
  };

/** All input for the `deleteAttachmentApplicationBinding` mutation. */
export type DeleteAttachmentApplicationBindingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AttachmentApplicationBinding` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `AttachmentApplicationBinding` mutation. */
export type DeleteAttachmentApplicationBindingPayload = {
  __typename?: 'DeleteAttachmentApplicationBindingPayload';
  /** Reads a single `Application` that is related to this `AttachmentApplicationBinding`. */
  applicationByApplicationId?: Maybe<Application>;
  /** The `AttachmentApplicationBinding` that was deleted by this mutation. */
  attachmentApplicationBinding?: Maybe<AttachmentApplicationBinding>;
  /** Reads a single `Attachment` that is related to this `AttachmentApplicationBinding`. */
  attachmentByAttachmentId?: Maybe<Attachment>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAttachmentApplicationBindingId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteAttachmentBoardMeetingBlockBindingByAttachmentIdAndBlockId` mutation. */
export type DeleteAttachmentBoardMeetingBlockBindingByAttachmentIdAndBlockIdInput =
  {
    attachmentId: Scalars['UUID'];
    blockId: Scalars['UUID'];
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>;
  };

/** All input for the `deleteAttachmentBoardMeetingBlockBinding` mutation. */
export type DeleteAttachmentBoardMeetingBlockBindingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AttachmentBoardMeetingBlockBinding` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `AttachmentBoardMeetingBlockBinding` mutation. */
export type DeleteAttachmentBoardMeetingBlockBindingPayload = {
  __typename?: 'DeleteAttachmentBoardMeetingBlockBindingPayload';
  /** The `AttachmentBoardMeetingBlockBinding` that was deleted by this mutation. */
  attachmentBoardMeetingBlockBinding?: Maybe<AttachmentBoardMeetingBlockBinding>;
  /** Reads a single `Attachment` that is related to this `AttachmentBoardMeetingBlockBinding`. */
  attachmentByAttachmentId?: Maybe<Attachment>;
  /** Reads a single `BoardMeetingBlock` that is related to this `AttachmentBoardMeetingBlockBinding`. */
  boardMeetingBlockByBlockId?: Maybe<BoardMeetingBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAttachmentBoardMeetingBlockBindingId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteAttachmentById` mutation. */
export type DeleteAttachmentByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteAttachment` mutation. */
export type DeleteAttachmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Attachment` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Attachment` mutation. */
export type DeleteAttachmentPayload = {
  __typename?: 'DeleteAttachmentPayload';
  /** Reads a single `Account` that is related to this `Attachment`. */
  accountByAccountId?: Maybe<Account>;
  /** The `Attachment` that was deleted by this mutation. */
  attachment?: Maybe<Attachment>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAttachmentId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteBoardMeetingBlockById` mutation. */
export type DeleteBoardMeetingBlockByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteBoardMeetingBlock` mutation. */
export type DeleteBoardMeetingBlockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BoardMeetingBlock` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `BoardMeetingBlock` mutation. */
export type DeleteBoardMeetingBlockPayload = {
  __typename?: 'DeleteBoardMeetingBlockPayload';
  /** The `BoardMeetingBlock` that was deleted by this mutation. */
  boardMeetingBlock?: Maybe<BoardMeetingBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBoardMeetingBlockId?: Maybe<Scalars['ID']>;
  /** Reads a single `FundingRound` that is related to this `BoardMeetingBlock`. */
  fundingRoundByFundingRoundId?: Maybe<FundingRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteBoardMemberApplicationBlockByAccountIdAndApplicationId` mutation. */
export type DeleteBoardMemberApplicationBlockByAccountIdAndApplicationIdInput =
  {
    accountId: Scalars['UUID'];
    applicationId: Scalars['UUID'];
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>;
  };

/** All input for the `deleteBoardMemberApplicationBlock` mutation. */
export type DeleteBoardMemberApplicationBlockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BoardMemberApplicationBlock` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `BoardMemberApplicationBlock` mutation. */
export type DeleteBoardMemberApplicationBlockPayload = {
  __typename?: 'DeleteBoardMemberApplicationBlockPayload';
  /** Reads a single `Account` that is related to this `BoardMemberApplicationBlock`. */
  accountByAccountId?: Maybe<Account>;
  /** Reads a single `Application` that is related to this `BoardMemberApplicationBlock`. */
  applicationByApplicationId?: Maybe<Application>;
  /** The `BoardMemberApplicationBlock` that was deleted by this mutation. */
  boardMemberApplicationBlock?: Maybe<BoardMemberApplicationBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBoardMemberApplicationBlockId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteBoardMemberInvitation` mutation. */
export type DeleteBoardMemberInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `deleteBoardMemberInvitation` mutation. */
export type DeleteBoardMemberInvitationPayload = {
  __typename?: 'DeleteBoardMemberInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deleteBoardMemberInvitationResponse?: Maybe<DeleteBoardMemberInvitationResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type DeleteBoardMemberInvitationResponse = {
  __typename?: 'DeleteBoardMemberInvitationResponse';
  email?: Maybe<Scalars['String']>;
};

/** All input for the `deleteContactByApplicationIdAndEmail` mutation. */
export type DeleteContactByApplicationIdAndEmailInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['Email'];
};

/** All input for the `deleteContact` mutation. */
export type DeleteContactInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Contact` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Contact` mutation. */
export type DeleteContactPayload = {
  __typename?: 'DeleteContactPayload';
  /** Reads a single `Application` that is related to this `Contact`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Contact` that was deleted by this mutation. */
  contact?: Maybe<Contact>;
  deletedContactId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteContractByApplicationId` mutation. */
export type DeleteContractByApplicationIdInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `deleteContract` mutation. */
export type DeleteContractInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Contract` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Contract` mutation. */
export type DeleteContractPayload = {
  __typename?: 'DeleteContractPayload';
  /** Reads a single `Application` that is related to this `Contract`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Contract` that was deleted by this mutation. */
  contract?: Maybe<Contract>;
  deletedContractId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteCountryById` mutation. */
export type DeleteCountryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteCountry` mutation. */
export type DeleteCountryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Country` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Country` mutation. */
export type DeleteCountryPayload = {
  __typename?: 'DeleteCountryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Country` that was deleted by this mutation. */
  country?: Maybe<Country>;
  deletedCountryId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Region` that is related to this `Country`. */
  regionByRegionId?: Maybe<Region>;
};

/** All input for the `deleteDirectDepositAccountById` mutation. */
export type DeleteDirectDepositAccountByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteDirectDepositAccountByImportId` mutation. */
export type DeleteDirectDepositAccountByImportIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
};

/** All input for the `deleteDirectDepositAccount` mutation. */
export type DeleteDirectDepositAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DirectDepositAccount` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `DirectDepositAccount` mutation. */
export type DeleteDirectDepositAccountPayload = {
  __typename?: 'DeleteDirectDepositAccountPayload';
  /** Reads a single `Artist` that is related to this `DirectDepositAccount`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedDirectDepositAccountId?: Maybe<Scalars['ID']>;
  /** The `DirectDepositAccount` that was deleted by this mutation. */
  directDepositAccount?: Maybe<DirectDepositAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteDistributorById` mutation. */
export type DeleteDistributorByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteDistributorByName` mutation. */
export type DeleteDistributorByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** All input for the `deleteDistributor` mutation. */
export type DeleteDistributorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Distributor` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Distributor` mutation. */
export type DeleteDistributorPayload = {
  __typename?: 'DeleteDistributorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedDistributorId?: Maybe<Scalars['ID']>;
  /** The `Distributor` that was deleted by this mutation. */
  distributor?: Maybe<Distributor>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteEligibilityNoticeByArtistId` mutation. */
export type DeleteEligibilityNoticeByArtistIdInput = {
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `deleteEligibilityNotice` mutation. */
export type DeleteEligibilityNoticeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EligibilityNotice` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `EligibilityNotice` mutation. */
export type DeleteEligibilityNoticePayload = {
  __typename?: 'DeleteEligibilityNoticePayload';
  /** Reads a single `Artist` that is related to this `EligibilityNotice`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedEligibilityNoticeId?: Maybe<Scalars['ID']>;
  /** The `EligibilityNotice` that was deleted by this mutation. */
  eligibilityNotice?: Maybe<EligibilityNotice>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteExpenseById` mutation. */
export type DeleteExpenseByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteExpense` mutation. */
export type DeleteExpenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Expense` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Expense` mutation. */
export type DeleteExpensePayload = {
  __typename?: 'DeleteExpensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedExpenseId?: Maybe<Scalars['ID']>;
  /** The `Expense` that was deleted by this mutation. */
  expense?: Maybe<Expense>;
  /** An edge for our `Expense`. May be used by Relay 1. */
  expenseEdge?: Maybe<ExpensesEdge>;
  /** Reads a single `FundingRequest` that is related to this `Expense`. */
  fundingRequestByFundingRequestId?: Maybe<FundingRequest>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Expense` mutation. */
export type DeleteExpensePayloadExpenseEdgeArgs = {
  orderBy?: InputMaybe<Array<ExpensesOrderBy>>;
};

/** All input for the `deleteFundingRequestById` mutation. */
export type DeleteFundingRequestByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteFundingRequest` mutation. */
export type DeleteFundingRequestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FundingRequest` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `FundingRequest` mutation. */
export type DeleteFundingRequestPayload = {
  __typename?: 'DeleteFundingRequestPayload';
  /** Reads a single `Application` that is related to this `FundingRequest`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFundingRequestId?: Maybe<Scalars['ID']>;
  /** The `FundingRequest` that was deleted by this mutation. */
  fundingRequest?: Maybe<FundingRequest>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteFundingRoundById` mutation. */
export type DeleteFundingRoundByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteFundingRoundByTitle` mutation. */
export type DeleteFundingRoundByTitleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** All input for the `deleteFundingRound` mutation. */
export type DeleteFundingRoundInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FundingRound` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `FundingRound` mutation. */
export type DeleteFundingRoundPayload = {
  __typename?: 'DeleteFundingRoundPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFundingRoundId?: Maybe<Scalars['ID']>;
  /** The `FundingRound` that was deleted by this mutation. */
  fundingRound?: Maybe<FundingRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteGenreById` mutation. */
export type DeleteGenreByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteGenre` mutation. */
export type DeleteGenreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Genre` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Genre` mutation. */
export type DeleteGenrePayload = {
  __typename?: 'DeleteGenrePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedGenreId?: Maybe<Scalars['ID']>;
  /** The `Genre` that was deleted by this mutation. */
  genre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteLabelById` mutation. */
export type DeleteLabelByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteLabelByName` mutation. */
export type DeleteLabelByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** All input for the `deleteLabel` mutation. */
export type DeleteLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Label` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Label` mutation. */
export type DeleteLabelPayload = {
  __typename?: 'DeleteLabelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Label`. */
  countryByCountryId?: Maybe<Country>;
  deletedLabelId?: Maybe<Scalars['ID']>;
  /** The `Label` that was deleted by this mutation. */
  label?: Maybe<Label>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteLegalStatusById` mutation. */
export type DeleteLegalStatusByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** All input for the `deleteLegalStatus` mutation. */
export type DeleteLegalStatusInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LegalStatus` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `LegalStatus` mutation. */
export type DeleteLegalStatusPayload = {
  __typename?: 'DeleteLegalStatusPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLegalStatusId?: Maybe<Scalars['ID']>;
  /** The `LegalStatus` that was deleted by this mutation. */
  legalStatus?: Maybe<LegalStatus>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteManagementById` mutation. */
export type DeleteManagementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteManagementByImportId` mutation. */
export type DeleteManagementByImportIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
};

/** All input for the `deleteManagementDetails` mutation. */
export type DeleteManagementDetailsInput = {
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `deleteManagementDetails` mutation. */
export type DeleteManagementDetailsPayload = {
  __typename?: 'DeleteManagementDetailsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deleteManagementDetailsResponse?: Maybe<DeleteManagementDetailsResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type DeleteManagementDetailsResponse = {
  __typename?: 'DeleteManagementDetailsResponse';
  code?: Maybe<DeleteManagementDetailsResponseCode>;
};

export enum DeleteManagementDetailsResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

/** All input for the `deleteManagement` mutation. */
export type DeleteManagementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Management` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Management` mutation. */
export type DeleteManagementPayload = {
  __typename?: 'DeleteManagementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Management`. */
  countryByCountryId?: Maybe<Country>;
  deletedManagementId?: Maybe<Scalars['ID']>;
  /** Reads a single `LegalStatus` that is related to this `Management`. */
  legalStatusByLegalStatus?: Maybe<LegalStatus>;
  /** The `Management` that was deleted by this mutation. */
  management?: Maybe<Management>;
  /** Reads a single `Province` that is related to this `Management`. */
  provinceByLegalProvince?: Maybe<Province>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deletePaymentById` mutation. */
export type DeletePaymentByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deletePayment` mutation. */
export type DeletePaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Payment` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Payment` mutation. */
export type DeletePaymentPayload = {
  __typename?: 'DeletePaymentPayload';
  /** Reads a single `Application` that is related to this `Payment`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPaymentId?: Maybe<Scalars['ID']>;
  /** Reads a single `DirectDepositAccount` that is related to this `Payment`. */
  directDepositAccountByDirectDepositAccountId?: Maybe<DirectDepositAccount>;
  /** The `Payment` that was deleted by this mutation. */
  payment?: Maybe<Payment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteProvinceById` mutation. */
export type DeleteProvinceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** All input for the `deleteProvince` mutation. */
export type DeleteProvinceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Province` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Province` mutation. */
export type DeleteProvincePayload = {
  __typename?: 'DeleteProvincePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedProvinceId?: Maybe<Scalars['ID']>;
  /** The `Province` that was deleted by this mutation. */
  province?: Maybe<Province>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteRegionById` mutation. */
export type DeleteRegionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** All input for the `deleteRegion` mutation. */
export type DeleteRegionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Region` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Region` mutation. */
export type DeleteRegionPayload = {
  __typename?: 'DeleteRegionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedRegionId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Region` that was deleted by this mutation. */
  region?: Maybe<Region>;
};

/** All input for the `deleteReleaseById` mutation. */
export type DeleteReleaseByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteReleaseByImportId` mutation. */
export type DeleteReleaseByImportIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
};

/** All input for the `deleteRelease` mutation. */
export type DeleteReleaseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Release` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Release` mutation. */
export type DeleteReleasePayload = {
  __typename?: 'DeleteReleasePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedReleaseId?: Maybe<Scalars['ID']>;
  /** Reads a single `Distributor` that is related to this `Release`. */
  distributorByDistributorId?: Maybe<Distributor>;
  /** Reads a single `Genre` that is related to this `Release`. */
  genreByGenreId?: Maybe<Genre>;
  /** Reads a single `Label` that is related to this `Release`. */
  labelByLabelId?: Maybe<Label>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Release` that was deleted by this mutation. */
  release?: Maybe<Release>;
};

/** All input for the `deleteSaleById` mutation. */
export type DeleteSaleByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteSaleByImportId` mutation. */
export type DeleteSaleByImportIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
};

/** All input for the `deleteSale` mutation. */
export type DeleteSaleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Sale` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Sale` mutation. */
export type DeleteSalePayload = {
  __typename?: 'DeleteSalePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSaleId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Release` that is related to this `Sale`. */
  releaseByReleaseId?: Maybe<Release>;
  /** The `Sale` that was deleted by this mutation. */
  sale?: Maybe<Sale>;
};

/** All input for the `deleteSingleById` mutation. */
export type DeleteSingleByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteSingleByImportId` mutation. */
export type DeleteSingleByImportIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
};

/** All input for the `deleteSingle` mutation. */
export type DeleteSingleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Single` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `Single` mutation. */
export type DeleteSinglePayload = {
  __typename?: 'DeleteSinglePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSingleId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Release` that is related to this `Single`. */
  releaseByReleaseId?: Maybe<Release>;
  /** The `Single` that was deleted by this mutation. */
  single?: Maybe<Single>;
};

/** All input for the `deleteTourDateById` mutation. */
export type DeleteTourDateByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteTourDate` mutation. */
export type DeleteTourDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TourDate` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `TourDate` mutation. */
export type DeleteTourDatePayload = {
  __typename?: 'DeleteTourDatePayload';
  /** Reads a single `Application` that is related to this `TourDate`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `TourDate`. */
  countryByTourDateCountryId?: Maybe<Country>;
  deletedTourDateId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TourDate` that was deleted by this mutation. */
  tourDate?: Maybe<TourDate>;
  /** An edge for our `TourDate`. May be used by Relay 1. */
  tourDateEdge?: Maybe<TourDatesEdge>;
  /** Reads a single `TourDateType` that is related to this `TourDate`. */
  tourDateTypeByTourDateTypeId?: Maybe<TourDateType>;
};

/** The output of our delete `TourDate` mutation. */
export type DeleteTourDatePayloadTourDateEdgeArgs = {
  orderBy?: InputMaybe<Array<TourDatesOrderBy>>;
};

/** All input for the `deleteTourDateTypeById` mutation. */
export type DeleteTourDateTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** All input for the `deleteTourDateType` mutation. */
export type DeleteTourDateTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TourDateType` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `TourDateType` mutation. */
export type DeleteTourDateTypePayload = {
  __typename?: 'DeleteTourDateTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTourDateTypeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TourDateType` that was deleted by this mutation. */
  tourDateType?: Maybe<TourDateType>;
};

export type DirectDepositAccount = Node & {
  __typename?: 'DirectDepositAccount';
  account: Scalars['String'];
  /** Reads and enables pagination through a set of `Application`. */
  applicationsByFundingRequestDirectDepositIdList: Array<Application>;
  /** Reads and enables pagination through a set of `Application`. */
  applicationsByTouringDirectDepositIdList: Array<Application>;
  /** Reads a single `Artist` that is related to this `DirectDepositAccount`. */
  artistByArtistId?: Maybe<Artist>;
  artistId: Scalars['UUID'];
  id: Scalars['UUID'];
  importId?: Maybe<Scalars['Int']>;
  institution: Scalars['String'];
  maskedAccount: Scalars['String'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  paid?: Maybe<Scalars['BigFloat']>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByDirectDepositAccountIdList: Array<Payment>;
  transit: Scalars['String'];
};

export type DirectDepositAccountApplicationsByFundingRequestDirectDepositIdListArgs =
  {
    condition?: InputMaybe<ApplicationCondition>;
    first?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<ApplicationsOrderBy>>;
  };

export type DirectDepositAccountApplicationsByTouringDirectDepositIdListArgs = {
  condition?: InputMaybe<ApplicationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ApplicationsOrderBy>>;
};

export type DirectDepositAccountPaidArgs = {
  limitToApplicationId: Scalars['UUID'];
};

export type DirectDepositAccountPaymentsByDirectDepositAccountIdListArgs = {
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

/**
 * A condition to be used against `DirectDepositAccount` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DirectDepositAccountCondition = {
  /** Checks for equality with the object’s `account` field. */
  account?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `importId` field. */
  importId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `institution` field. */
  institution?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `maskedAccount` field. */
  maskedAccount?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transit` field. */
  transit?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `DirectDepositAccount` */
export type DirectDepositAccountInput = {
  account: Scalars['String'];
  artistId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  institution: Scalars['String'];
  maskedAccount: Scalars['String'];
  name: Scalars['String'];
  transit: Scalars['String'];
};

/** Represents an update to a `DirectDepositAccount`. Fields that are set will be updated. */
export type DirectDepositAccountPatch = {
  account?: InputMaybe<Scalars['String']>;
  artistId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  institution?: InputMaybe<Scalars['String']>;
  maskedAccount?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  transit?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `DirectDepositAccount`. */
export enum DirectDepositAccountsOrderBy {
  AccountAsc = 'ACCOUNT_ASC',
  AccountDesc = 'ACCOUNT_DESC',
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportIdAsc = 'IMPORT_ID_ASC',
  ImportIdDesc = 'IMPORT_ID_DESC',
  InstitutionAsc = 'INSTITUTION_ASC',
  InstitutionDesc = 'INSTITUTION_DESC',
  MaskedAccountAsc = 'MASKED_ACCOUNT_ASC',
  MaskedAccountDesc = 'MASKED_ACCOUNT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TransitAsc = 'TRANSIT_ASC',
  TransitDesc = 'TRANSIT_DESC',
}

export type Distributor = Node & {
  __typename?: 'Distributor';
  displayOrder?: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Release`. */
  releasesByDistributorIdList: Array<Release>;
};

export type DistributorReleasesByDistributorIdListArgs = {
  condition?: InputMaybe<ReleaseCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReleasesOrderBy>>;
};

/**
 * A condition to be used against `Distributor` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DistributorCondition = {
  /** Checks for equality with the object’s `displayOrder` field. */
  displayOrder?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Distributor` */
export type DistributorInput = {
  displayOrder?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
};

/** Represents an update to a `Distributor`. Fields that are set will be updated. */
export type DistributorPatch = {
  displayOrder?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Distributor`. */
export enum DistributorsOrderBy {
  DisplayOrderAsc = 'DISPLAY_ORDER_ASC',
  DisplayOrderDesc = 'DISPLAY_ORDER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type EligibilityNotice = Node & {
  __typename?: 'EligibilityNotice';
  /** Reads a single `Artist` that is related to this `EligibilityNotice`. */
  artistByArtistId?: Maybe<Artist>;
  artistId: Scalars['UUID'];
  message: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  type: EligibilityNoticeTypes;
};

/**
 * A condition to be used against `EligibilityNotice` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EligibilityNoticeCondition = {
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `message` field. */
  message?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<EligibilityNoticeTypes>;
};

/** An input for mutations affecting `EligibilityNotice` */
export type EligibilityNoticeInput = {
  artistId: Scalars['UUID'];
  message: Scalars['String'];
  type: EligibilityNoticeTypes;
};

/** Represents an update to a `EligibilityNotice`. Fields that are set will be updated. */
export type EligibilityNoticePatch = {
  artistId?: InputMaybe<Scalars['UUID']>;
  message?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EligibilityNoticeTypes>;
};

export enum EligibilityNoticeTypes {
  Ineligible = 'INELIGIBLE',
  Warning = 'WARNING',
}

/** Methods to use when ordering `EligibilityNotice`. */
export enum EligibilityNoticesOrderBy {
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  MessageAsc = 'MESSAGE_ASC',
  MessageDesc = 'MESSAGE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
}

export type Expense = Node & {
  __typename?: 'Expense';
  cost: Scalars['BigFloat'];
  createdAt: Scalars['Datetime'];
  date: Scalars['Date'];
  description: Scalars['String'];
  /** Reads a single `FundingRequest` that is related to this `Expense`. */
  fundingRequestByFundingRequestId?: Maybe<FundingRequest>;
  fundingRequestId: Scalars['UUID'];
  id: Scalars['UUID'];
  invoiceNumber: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  vendor: Scalars['String'];
};

/** A condition to be used against `Expense` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ExpenseCondition = {
  /** Checks for equality with the object’s `cost` field. */
  cost?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `fundingRequestId` field. */
  fundingRequestId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `invoiceNumber` field. */
  invoiceNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `vendor` field. */
  vendor?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Expense` */
export type ExpenseInput = {
  cost: Scalars['BigFloat'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  date: Scalars['Date'];
  description: Scalars['String'];
  fundingRequestId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  invoiceNumber: Scalars['String'];
  vendor: Scalars['String'];
};

/** Represents an update to a `Expense`. Fields that are set will be updated. */
export type ExpensePatch = {
  cost?: InputMaybe<Scalars['BigFloat']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  fundingRequestId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Expense` values. */
export type ExpensesConnection = {
  __typename?: 'ExpensesConnection';
  /** A list of edges which contains the `Expense` and cursor to aid in pagination. */
  edges: Array<ExpensesEdge>;
  /** A list of `Expense` objects. */
  nodes: Array<Maybe<Expense>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Expense` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Expense` edge in the connection. */
export type ExpensesEdge = {
  __typename?: 'ExpensesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Expense` at the end of the edge. */
  node?: Maybe<Expense>;
};

/** Methods to use when ordering `Expense`. */
export enum ExpensesOrderBy {
  CostAsc = 'COST_ASC',
  CostDesc = 'COST_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  FundingRequestIdAsc = 'FUNDING_REQUEST_ID_ASC',
  FundingRequestIdDesc = 'FUNDING_REQUEST_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvoiceNumberAsc = 'INVOICE_NUMBER_ASC',
  InvoiceNumberDesc = 'INVOICE_NUMBER_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  VendorAsc = 'VENDOR_ASC',
  VendorDesc = 'VENDOR_DESC',
}

/** All input for the `forgotPassword` mutation. */
export type ForgotPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `forgotPassword` mutation. */
export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  forgotPasswordResponse?: Maybe<ForgotPasswordResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  code?: Maybe<ForgotPasswordResponseCode>;
};

export enum ForgotPasswordResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

export type FundingRequest = Node & {
  __typename?: 'FundingRequest';
  alternate?: Maybe<Scalars['Boolean']>;
  amountApproved?: Maybe<Scalars['BigFloat']>;
  amountClaimed?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `Application` that is related to this `FundingRequest`. */
  applicationByApplicationId?: Maybe<Application>;
  applicationId: Scalars['UUID'];
  approvedCostOverride?: Maybe<Scalars['BigFloat']>;
  cancelled: Scalars['Boolean'];
  cost: Scalars['BigFloat'];
  createdAt: Scalars['Datetime'];
  date: Scalars['Date'];
  /** Reads and enables pagination through a set of `Expense`. */
  expensesByFundingRequestId: ExpensesConnection;
  /** Reads and enables pagination through a set of `Expense`. */
  expensesByFundingRequestIdList: Array<Expense>;
  fundingType: Scalars['String'];
  id: Scalars['UUID'];
  isCancellable?: Maybe<Scalars['Boolean']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  isTooNew?: Maybe<Scalars['Boolean']>;
  isTooOld?: Maybe<Scalars['Boolean']>;
  isWritable?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  reason: Scalars['String'];
  status: FundingRequestStatus;
  strategy: Scalars['String'];
};

export type FundingRequestExpensesByFundingRequestIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExpenseCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExpensesOrderBy>>;
};

export type FundingRequestExpensesByFundingRequestIdListArgs = {
  condition?: InputMaybe<ExpenseCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExpensesOrderBy>>;
};

export type FundingRequestIsEligibleArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type FundingRequestIsTooNewArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type FundingRequestIsTooOldArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `FundingRequest` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FundingRequestCondition = {
  /** Checks for equality with the object’s `applicationId` field. */
  applicationId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `approvedCostOverride` field. */
  approvedCostOverride?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `cancelled` field. */
  cancelled?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `cost` field. */
  cost?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `fundingType` field. */
  fundingType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `reason` field. */
  reason?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<FundingRequestStatus>;
  /** Checks for equality with the object’s `strategy` field. */
  strategy?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `FundingRequest` */
export type FundingRequestInput = {
  applicationId: Scalars['UUID'];
  approvedCostOverride?: InputMaybe<Scalars['BigFloat']>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  cost: Scalars['BigFloat'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  date: Scalars['Date'];
  fundingType: Scalars['String'];
  id?: InputMaybe<Scalars['UUID']>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FundingRequestStatus>;
  strategy: Scalars['String'];
};

/** Represents an update to a `FundingRequest`. Fields that are set will be updated. */
export type FundingRequestPatch = {
  applicationId?: InputMaybe<Scalars['UUID']>;
  approvedCostOverride?: InputMaybe<Scalars['BigFloat']>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  cost?: InputMaybe<Scalars['BigFloat']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  date?: InputMaybe<Scalars['Date']>;
  fundingType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FundingRequestStatus>;
  strategy?: InputMaybe<Scalars['String']>;
};

export enum FundingRequestStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Pending = 'PENDING',
  Unsettled = 'UNSETTLED',
}

/** Methods to use when ordering `FundingRequest`. */
export enum FundingRequestsOrderBy {
  ApplicationIdAsc = 'APPLICATION_ID_ASC',
  ApplicationIdDesc = 'APPLICATION_ID_DESC',
  ApprovedCostOverrideAsc = 'APPROVED_COST_OVERRIDE_ASC',
  ApprovedCostOverrideDesc = 'APPROVED_COST_OVERRIDE_DESC',
  CancelledAsc = 'CANCELLED_ASC',
  CancelledDesc = 'CANCELLED_DESC',
  CostAsc = 'COST_ASC',
  CostDesc = 'COST_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  FundingTypeAsc = 'FUNDING_TYPE_ASC',
  FundingTypeDesc = 'FUNDING_TYPE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReasonAsc = 'REASON_ASC',
  ReasonDesc = 'REASON_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  StrategyAsc = 'STRATEGY_ASC',
  StrategyDesc = 'STRATEGY_DESC',
}

export type FundingRound = Node & {
  __typename?: 'FundingRound';
  active?: Maybe<Scalars['Boolean']>;
  allowOrion: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `Application`. */
  applicationsByFundingRoundIdList: Array<Application>;
  boardEndDate?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `BoardMeetingBlock`. */
  boardMeetingBlocksByFundingRoundIdList: Array<BoardMeetingBlock>;
  boardMeetingDate: Scalars['Date'];
  boardStartDate?: Maybe<Scalars['Datetime']>;
  claimDeadline?: Maybe<Scalars['Datetime']>;
  closingSoon?: Maybe<Scalars['Boolean']>;
  contractDeadline?: Maybe<Scalars['Datetime']>;
  endDate: Scalars['Datetime'];
  futureCutoffDate?: Maybe<Scalars['Date']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  numberOfApplications?: Maybe<Scalars['Int']>;
  numberOfCoreApplications?: Maybe<Scalars['Int']>;
  numberOfOrionApplications?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Datetime']>;
  status?: Maybe<FundingRoundStatusType>;
  timezone?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type FundingRoundActiveArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type FundingRoundApplicationsByFundingRoundIdListArgs = {
  condition?: InputMaybe<ApplicationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ApplicationsOrderBy>>;
};

export type FundingRoundBoardMeetingBlocksByFundingRoundIdListArgs = {
  condition?: InputMaybe<BoardMeetingBlockCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BoardMeetingBlocksOrderBy>>;
};

export type FundingRoundClosingSoonArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type FundingRoundStatusArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `FundingRound` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FundingRoundCondition = {
  /** Checks for equality with the object’s `allowOrion` field. */
  allowOrion?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `boardEndDate` field. */
  boardEndDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `boardMeetingDate` field. */
  boardMeetingDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `boardStartDate` field. */
  boardStartDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `FundingRound` */
export type FundingRoundInput = {
  allowOrion: Scalars['Boolean'];
  boardEndDate?: InputMaybe<Scalars['Datetime']>;
  boardMeetingDate: Scalars['Date'];
  boardStartDate?: InputMaybe<Scalars['Datetime']>;
  endDate: Scalars['Datetime'];
  id?: InputMaybe<Scalars['UUID']>;
  startDate?: InputMaybe<Scalars['Datetime']>;
  title: Scalars['String'];
};

/** Represents an update to a `FundingRound`. Fields that are set will be updated. */
export type FundingRoundPatch = {
  allowOrion?: InputMaybe<Scalars['Boolean']>;
  boardEndDate?: InputMaybe<Scalars['Datetime']>;
  boardMeetingDate?: InputMaybe<Scalars['Date']>;
  boardStartDate?: InputMaybe<Scalars['Datetime']>;
  endDate?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  startDate?: InputMaybe<Scalars['Datetime']>;
  title?: InputMaybe<Scalars['String']>;
};

export enum FundingRoundStatusType {
  ClaimsInProgress = 'CLAIMS_IN_PROGRESS',
  Closed = 'CLOSED',
  ClosedForApplications = 'CLOSED_FOR_APPLICATIONS',
  OpenForApplications = 'OPEN_FOR_APPLICATIONS',
  OpenToBoard = 'OPEN_TO_BOARD',
  Upcoming = 'UPCOMING',
}

/** Methods to use when ordering `FundingRound`. */
export enum FundingRoundsOrderBy {
  AllowOrionAsc = 'ALLOW_ORION_ASC',
  AllowOrionDesc = 'ALLOW_ORION_DESC',
  BoardEndDateAsc = 'BOARD_END_DATE_ASC',
  BoardEndDateDesc = 'BOARD_END_DATE_DESC',
  BoardMeetingDateAsc = 'BOARD_MEETING_DATE_ASC',
  BoardMeetingDateDesc = 'BOARD_MEETING_DATE_DESC',
  BoardStartDateAsc = 'BOARD_START_DATE_ASC',
  BoardStartDateDesc = 'BOARD_START_DATE_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
}

export type Genre = Node & {
  __typename?: 'Genre';
  displayorder: Scalars['Int'];
  enabled: Scalars['Boolean'];
  id: Scalars['UUID'];
  maximumSales: Scalars['Int'];
  minimumSales: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Release`. */
  releasesByGenreIdList: Array<Release>;
  title: Scalars['String'];
};

export type GenreReleasesByGenreIdListArgs = {
  condition?: InputMaybe<ReleaseCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReleasesOrderBy>>;
};

/** A condition to be used against `Genre` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GenreCondition = {
  /** Checks for equality with the object’s `displayorder` field. */
  displayorder?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `maximumSales` field. */
  maximumSales?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minimumSales` field. */
  minimumSales?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Genre` */
export type GenreInput = {
  displayorder?: InputMaybe<Scalars['Int']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  maximumSales: Scalars['Int'];
  minimumSales: Scalars['Int'];
  title: Scalars['String'];
};

/** Represents an update to a `Genre`. Fields that are set will be updated. */
export type GenrePatch = {
  displayorder?: InputMaybe<Scalars['Int']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  maximumSales?: InputMaybe<Scalars['Int']>;
  minimumSales?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Genre`. */
export enum GenresOrderBy {
  DisplayorderAsc = 'DISPLAYORDER_ASC',
  DisplayorderDesc = 'DISPLAYORDER_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MaximumSalesAsc = 'MAXIMUM_SALES_ASC',
  MaximumSalesDesc = 'MAXIMUM_SALES_DESC',
  MinimumSalesAsc = 'MINIMUM_SALES_ASC',
  MinimumSalesDesc = 'MINIMUM_SALES_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
}

/** The return type of our `getAllBoardMembers` query. */
export type GetAllBoardMembersRecord = {
  __typename?: 'GetAllBoardMembersRecord';
  accountId?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** The return type of our `getPendingEmails` query. */
export type GetPendingEmailsRecord = {
  __typename?: 'GetPendingEmailsRecord';
  email?: Maybe<Scalars['String']>;
  invitationId?: Maybe<Scalars['UUID']>;
};

/** All input for the `insertDirectDepositAccount` mutation. */
export type InsertDirectDepositAccountInput = {
  account: Scalars['String'];
  artistId: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  institution: Scalars['String'];
  name: Scalars['String'];
  transit: Scalars['String'];
};

/** The output of our `insertDirectDepositAccount` mutation. */
export type InsertDirectDepositAccountPayload = {
  __typename?: 'InsertDirectDepositAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  insertDirectDepositAccountResponse?: Maybe<InsertDirectDepositAccountResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type InsertDirectDepositAccountResponse = {
  __typename?: 'InsertDirectDepositAccountResponse';
  code?: Maybe<InsertDirectDepositAccountResponseCode>;
};

export enum InsertDirectDepositAccountResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

/** All input for the `insertDirectDepositAndLinkToApplication` mutation. */
export type InsertDirectDepositAndLinkToApplicationInput = {
  account: Scalars['String'];
  applicationId: Scalars['UUID'];
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  institution: Scalars['String'];
  name: Scalars['String'];
  transit: Scalars['String'];
  type: InsertDirectDepositAndLinkToApplicationType;
};

/** The output of our `insertDirectDepositAndLinkToApplication` mutation. */
export type InsertDirectDepositAndLinkToApplicationPayload = {
  __typename?: 'InsertDirectDepositAndLinkToApplicationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  insertDirectDepositAndLinkToApplicationResponse?: Maybe<InsertDirectDepositAndLinkToApplicationResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type InsertDirectDepositAndLinkToApplicationResponse = {
  __typename?: 'InsertDirectDepositAndLinkToApplicationResponse';
  code?: Maybe<InsertDirectDepositAndLinkToApplicationResponseCode>;
  directDepositAccount?: Maybe<DirectDepositAccount>;
};

export enum InsertDirectDepositAndLinkToApplicationResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

export enum InsertDirectDepositAndLinkToApplicationType {
  FundingRequest = 'FUNDING_REQUEST',
  Touring = 'TOURING',
}

export type Label = Node & {
  __typename?: 'Label';
  address: Scalars['String'];
  contact: Scalars['String'];
  /** Reads a single `Country` that is related to this `Label`. */
  countryByCountryId?: Maybe<Country>;
  countryId: Scalars['UUID'];
  displayOrder: Scalars['Int'];
  email?: Maybe<Scalars['Email']>;
  enabled: Scalars['Boolean'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  phoneNumber: Scalars['String'];
  /** Reads and enables pagination through a set of `Release`. */
  releasesByLabelIdList: Array<Release>;
  type: LabelType;
  yearStarted?: Maybe<Scalars['Int']>;
};

export type LabelReleasesByLabelIdListArgs = {
  condition?: InputMaybe<ReleaseCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReleasesOrderBy>>;
};

/** A condition to be used against `Label` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LabelCondition = {
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `contact` field. */
  contact?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `countryId` field. */
  countryId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `displayOrder` field. */
  displayOrder?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['Email']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<LabelType>;
  /** Checks for equality with the object’s `yearStarted` field. */
  yearStarted?: InputMaybe<Scalars['Int']>;
};

/** An input for mutations affecting `Label` */
export type LabelInput = {
  address: Scalars['String'];
  contact: Scalars['String'];
  countryId: Scalars['UUID'];
  displayOrder?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['Email']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  type: LabelType;
  yearStarted?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `Label`. Fields that are set will be updated. */
export type LabelPatch = {
  address?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['UUID']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['Email']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<LabelType>;
  yearStarted?: InputMaybe<Scalars['Int']>;
};

export enum LabelType {
  Independent = 'INDEPENDENT',
  Major = 'MAJOR',
}

/** Methods to use when ordering `Label`. */
export enum LabelsOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  ContactAsc = 'CONTACT_ASC',
  ContactDesc = 'CONTACT_DESC',
  CountryIdAsc = 'COUNTRY_ID_ASC',
  CountryIdDesc = 'COUNTRY_ID_DESC',
  DisplayOrderAsc = 'DISPLAY_ORDER_ASC',
  DisplayOrderDesc = 'DISPLAY_ORDER_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  YearStartedAsc = 'YEAR_STARTED_ASC',
  YearStartedDesc = 'YEAR_STARTED_DESC',
}

export type LegalStatus = Node & {
  __typename?: 'LegalStatus';
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByCompanyLegalStatusList: Array<Artist>;
  description?: Maybe<Scalars['String']>;
  displayorder: Scalars['Int'];
  id: Scalars['String'];
  /** Reads and enables pagination through a set of `Management`. */
  managementsByLegalStatusList: Array<Management>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  provinceTitle?: Maybe<Scalars['String']>;
  requiresProvince: Scalars['Boolean'];
};

export type LegalStatusArtistsByCompanyLegalStatusListArgs = {
  condition?: InputMaybe<ArtistCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

export type LegalStatusManagementsByLegalStatusListArgs = {
  condition?: InputMaybe<ManagementCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ManagementsOrderBy>>;
};

/**
 * A condition to be used against `LegalStatus` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LegalStatusCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayorder` field. */
  displayorder?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `provinceTitle` field. */
  provinceTitle?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `requiresProvince` field. */
  requiresProvince?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `LegalStatus` */
export type LegalStatusInput = {
  description?: InputMaybe<Scalars['String']>;
  displayorder: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  provinceTitle?: InputMaybe<Scalars['String']>;
  requiresProvince: Scalars['Boolean'];
};

/** Represents an update to a `LegalStatus`. Fields that are set will be updated. */
export type LegalStatusPatch = {
  description?: InputMaybe<Scalars['String']>;
  displayorder?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  provinceTitle?: InputMaybe<Scalars['String']>;
  requiresProvince?: InputMaybe<Scalars['Boolean']>;
};

/** Methods to use when ordering `LegalStatus`. */
export enum LegalStatusesOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DisplayorderAsc = 'DISPLAYORDER_ASC',
  DisplayorderDesc = 'DISPLAYORDER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProvinceTitleAsc = 'PROVINCE_TITLE_ASC',
  ProvinceTitleDesc = 'PROVINCE_TITLE_DESC',
  RequiresProvinceAsc = 'REQUIRES_PROVINCE_ASC',
  RequiresProvinceDesc = 'REQUIRES_PROVINCE_DESC',
}

/** All input for the `linkDirectDepositAccountToApplication` mutation. */
export type LinkDirectDepositAccountToApplicationInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  directDepositId: Scalars['UUID'];
  type: LinkDirectDepositAccountToApplicationType;
};

/** The output of our `linkDirectDepositAccountToApplication` mutation. */
export type LinkDirectDepositAccountToApplicationPayload = {
  __typename?: 'LinkDirectDepositAccountToApplicationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  linkDirectDepositAccountToApplicationResponse?: Maybe<LinkDirectDepositAccountToApplicationResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type LinkDirectDepositAccountToApplicationResponse = {
  __typename?: 'LinkDirectDepositAccountToApplicationResponse';
  code?: Maybe<LinkDirectDepositAccountToApplicationResponseCode>;
  directDepositAccount?: Maybe<DirectDepositAccount>;
};

export enum LinkDirectDepositAccountToApplicationResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

export enum LinkDirectDepositAccountToApplicationType {
  FundingRequest = 'FUNDING_REQUEST',
  Touring = 'TOURING',
}

export type Management = Node & {
  __typename?: 'Management';
  address: Scalars['String'];
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByManagementIdList: Array<Artist>;
  contactPerson: Scalars['String'];
  /** Reads a single `Country` that is related to this `Management`. */
  countryByCountryId?: Maybe<Country>;
  countryId: Scalars['UUID'];
  email: Scalars['Email'];
  id: Scalars['UUID'];
  importId?: Maybe<Scalars['Int']>;
  legalProvince?: Maybe<Scalars['String']>;
  legalStatus: Scalars['String'];
  /** Reads a single `LegalStatus` that is related to this `Management`. */
  legalStatusByLegalStatus?: Maybe<LegalStatus>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  phoneNumber: Scalars['String'];
  /** Reads a single `Province` that is related to this `Management`. */
  provinceByLegalProvince?: Maybe<Province>;
};

export type ManagementArtistsByManagementIdListArgs = {
  condition?: InputMaybe<ArtistCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/**
 * A condition to be used against `Management` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ManagementCondition = {
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `contactPerson` field. */
  contactPerson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `countryId` field. */
  countryId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['Email']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `importId` field. */
  importId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `legalProvince` field. */
  legalProvince?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `legalStatus` field. */
  legalStatus?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Management` */
export type ManagementInput = {
  address: Scalars['String'];
  contactPerson: Scalars['String'];
  countryId: Scalars['UUID'];
  email: Scalars['Email'];
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  legalProvince?: InputMaybe<Scalars['String']>;
  legalStatus: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
};

/** Represents an update to a `Management`. Fields that are set will be updated. */
export type ManagementPatch = {
  address?: InputMaybe<Scalars['String']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['UUID']>;
  email?: InputMaybe<Scalars['Email']>;
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  legalProvince?: InputMaybe<Scalars['String']>;
  legalStatus?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Management`. */
export enum ManagementsOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  ContactPersonAsc = 'CONTACT_PERSON_ASC',
  ContactPersonDesc = 'CONTACT_PERSON_DESC',
  CountryIdAsc = 'COUNTRY_ID_ASC',
  CountryIdDesc = 'COUNTRY_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportIdAsc = 'IMPORT_ID_ASC',
  ImportIdDesc = 'IMPORT_ID_DESC',
  LegalProvinceAsc = 'LEGAL_PROVINCE_ASC',
  LegalProvinceDesc = 'LEGAL_PROVINCE_DESC',
  LegalStatusAsc = 'LEGAL_STATUS_ASC',
  LegalStatusDesc = 'LEGAL_STATUS_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** All input for the `markApplicationCompleted` mutation. */
export type MarkApplicationCompletedInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `markApplicationCompleted` mutation. */
export type MarkApplicationCompletedPayload = {
  __typename?: 'MarkApplicationCompletedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  markApplicationCompletedResponse?: Maybe<MarkApplicationCompletedResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type MarkApplicationCompletedResponse = {
  __typename?: 'MarkApplicationCompletedResponse';
  code?: Maybe<MarkApplicationCompletedResponseCode>;
};

export enum MarkApplicationCompletedResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  addAlternateRequest?: Maybe<AddAlternateRequestPayload>;
  addArtist?: Maybe<AddArtistPayload>;
  addBoardMeetingBlock?: Maybe<AddBoardMeetingBlockPayload>;
  addRelease?: Maybe<AddReleasePayload>;
  approveApplicationAndNotify?: Maybe<ApproveApplicationAndNotifyPayload>;
  cancelFundingRequest?: Maybe<CancelFundingRequestPayload>;
  cancelTourDate?: Maybe<CancelTourDatePayload>;
  changePassword?: Maybe<ChangePasswordPayload>;
  clearFluxCapacitor?: Maybe<ClearFluxCapacitorPayload>;
  /** Creates a single `Account`. */
  createAccount?: Maybe<CreateAccountPayload>;
  /** Creates a single `AccountArtistBinding`. */
  createAccountArtistBinding?: Maybe<CreateAccountArtistBindingPayload>;
  /** Creates a single `AccountPassword`. */
  createAccountPassword?: Maybe<CreateAccountPasswordPayload>;
  /** Creates a single `Application`. */
  createApplication?: Maybe<CreateApplicationPayload>;
  /** Creates a single `ApplicationBoardNote`. */
  createApplicationBoardNote?: Maybe<CreateApplicationBoardNotePayload>;
  /** Creates a single `Artist`. */
  createArtist?: Maybe<CreateArtistPayload>;
  createArtistAndLink?: Maybe<CreateArtistAndLinkPayload>;
  createArtistInvitation?: Maybe<CreateArtistInvitationPayload>;
  /** Creates a single `ArtistReleaseBinding`. */
  createArtistReleaseBinding?: Maybe<CreateArtistReleaseBindingPayload>;
  /** Creates a single `Attachment`. */
  createAttachment?: Maybe<CreateAttachmentPayload>;
  /** Creates a single `AttachmentApplicationBinding`. */
  createAttachmentApplicationBinding?: Maybe<CreateAttachmentApplicationBindingPayload>;
  /** Creates a single `AttachmentBoardMeetingBlockBinding`. */
  createAttachmentBoardMeetingBlockBinding?: Maybe<CreateAttachmentBoardMeetingBlockBindingPayload>;
  /** Creates a single `BoardMeetingBlock`. */
  createBoardMeetingBlock?: Maybe<CreateBoardMeetingBlockPayload>;
  /** Creates a single `BoardMemberApplicationBlock`. */
  createBoardMemberApplicationBlock?: Maybe<CreateBoardMemberApplicationBlockPayload>;
  createBoardMemberInvitation?: Maybe<CreateBoardMemberInvitationPayload>;
  /** Creates a single `Contact`. */
  createContact?: Maybe<CreateContactPayload>;
  /** Creates a single `Contract`. */
  createContract?: Maybe<CreateContractPayload>;
  createCoreAccountInvitation?: Maybe<CreateCoreAccountInvitationPayload>;
  /** Creates a single `Country`. */
  createCountry?: Maybe<CreateCountryPayload>;
  /** Creates a single `DirectDepositAccount`. */
  createDirectDepositAccount?: Maybe<CreateDirectDepositAccountPayload>;
  /** Creates a single `Distributor`. */
  createDistributor?: Maybe<CreateDistributorPayload>;
  /** Creates a single `EligibilityNotice`. */
  createEligibilityNotice?: Maybe<CreateEligibilityNoticePayload>;
  /** Creates a single `Expense`. */
  createExpense?: Maybe<CreateExpensePayload>;
  /** Creates a single `FundingRequest`. */
  createFundingRequest?: Maybe<CreateFundingRequestPayload>;
  /** Creates a single `FundingRound`. */
  createFundingRound?: Maybe<CreateFundingRoundPayload>;
  /** Creates a single `Genre`. */
  createGenre?: Maybe<CreateGenrePayload>;
  /** Creates a single `Label`. */
  createLabel?: Maybe<CreateLabelPayload>;
  /** Creates a single `LegalStatus`. */
  createLegalStatus?: Maybe<CreateLegalStatusPayload>;
  /** Creates a single `Management`. */
  createManagement?: Maybe<CreateManagementPayload>;
  createNewApplication?: Maybe<CreateNewApplicationPayload>;
  createOrionAccountInvitation?: Maybe<CreateOrionAccountInvitationPayload>;
  /** Creates a single `Payment`. */
  createPayment?: Maybe<CreatePaymentPayload>;
  /** Creates a single `Province`. */
  createProvince?: Maybe<CreateProvincePayload>;
  /** Creates a single `Region`. */
  createRegion?: Maybe<CreateRegionPayload>;
  /** Creates a single `Release`. */
  createRelease?: Maybe<CreateReleasePayload>;
  /** Creates a single `Sale`. */
  createSale?: Maybe<CreateSalePayload>;
  /** Creates a single `Single`. */
  createSingle?: Maybe<CreateSinglePayload>;
  /** Creates a single `TourDate`. */
  createTourDate?: Maybe<CreateTourDatePayload>;
  /** Creates a single `TourDateType`. */
  createTourDateType?: Maybe<CreateTourDateTypePayload>;
  /** Creates a single `VisibleAccountArtistBinding`. */
  createVisibleAccountArtistBinding?: Maybe<CreateVisibleAccountArtistBindingPayload>;
  /** Deletes a single `Account` using its globally unique id. */
  deleteAccount?: Maybe<DeleteAccountPayload>;
  /** Deletes a single `AccountArtistBinding` using its globally unique id. */
  deleteAccountArtistBinding?: Maybe<DeleteAccountArtistBindingPayload>;
  /** Deletes a single `AccountArtistBinding` using a unique key. */
  deleteAccountArtistBindingByAccountIdAndArtistId?: Maybe<DeleteAccountArtistBindingPayload>;
  /** Deletes a single `Account` using a unique key. */
  deleteAccountByEmail?: Maybe<DeleteAccountPayload>;
  /** Deletes a single `Account` using a unique key. */
  deleteAccountById?: Maybe<DeleteAccountPayload>;
  /** Deletes a single `AccountPassword` using its globally unique id. */
  deleteAccountPassword?: Maybe<DeleteAccountPasswordPayload>;
  /** Deletes a single `AccountPassword` using a unique key. */
  deleteAccountPasswordByAccountId?: Maybe<DeleteAccountPasswordPayload>;
  /** Deletes a single `Application` using its globally unique id. */
  deleteApplication?: Maybe<DeleteApplicationPayload>;
  /** Deletes a single `ApplicationBoardNote` using its globally unique id. */
  deleteApplicationBoardNote?: Maybe<DeleteApplicationBoardNotePayload>;
  /** Deletes a single `ApplicationBoardNote` using a unique key. */
  deleteApplicationBoardNoteByApplicationId?: Maybe<DeleteApplicationBoardNotePayload>;
  /** Deletes a single `Application` using a unique key. */
  deleteApplicationByArtistIdAndFundingRoundId?: Maybe<DeleteApplicationPayload>;
  /** Deletes a single `Application` using a unique key. */
  deleteApplicationById?: Maybe<DeleteApplicationPayload>;
  /** Deletes a single `Artist` using its globally unique id. */
  deleteArtist?: Maybe<DeleteArtistPayload>;
  /** Deletes a single `Artist` using a unique key. */
  deleteArtistById?: Maybe<DeleteArtistPayload>;
  /** Deletes a single `Artist` using a unique key. */
  deleteArtistByImportId?: Maybe<DeleteArtistPayload>;
  deleteArtistInvitation?: Maybe<DeleteArtistInvitationPayload>;
  /** Deletes a single `ArtistReleaseBinding` using its globally unique id. */
  deleteArtistReleaseBinding?: Maybe<DeleteArtistReleaseBindingPayload>;
  /** Deletes a single `ArtistReleaseBinding` using a unique key. */
  deleteArtistReleaseBindingByArtistIdAndReleaseId?: Maybe<DeleteArtistReleaseBindingPayload>;
  /** Deletes a single `Attachment` using its globally unique id. */
  deleteAttachment?: Maybe<DeleteAttachmentPayload>;
  /** Deletes a single `AttachmentApplicationBinding` using its globally unique id. */
  deleteAttachmentApplicationBinding?: Maybe<DeleteAttachmentApplicationBindingPayload>;
  /** Deletes a single `AttachmentApplicationBinding` using a unique key. */
  deleteAttachmentApplicationBindingByAttachmentIdAndApplicationId?: Maybe<DeleteAttachmentApplicationBindingPayload>;
  /** Deletes a single `AttachmentBoardMeetingBlockBinding` using its globally unique id. */
  deleteAttachmentBoardMeetingBlockBinding?: Maybe<DeleteAttachmentBoardMeetingBlockBindingPayload>;
  /** Deletes a single `AttachmentBoardMeetingBlockBinding` using a unique key. */
  deleteAttachmentBoardMeetingBlockBindingByAttachmentIdAndBlockId?: Maybe<DeleteAttachmentBoardMeetingBlockBindingPayload>;
  /** Deletes a single `Attachment` using a unique key. */
  deleteAttachmentById?: Maybe<DeleteAttachmentPayload>;
  /** Deletes a single `BoardMeetingBlock` using its globally unique id. */
  deleteBoardMeetingBlock?: Maybe<DeleteBoardMeetingBlockPayload>;
  /** Deletes a single `BoardMeetingBlock` using a unique key. */
  deleteBoardMeetingBlockById?: Maybe<DeleteBoardMeetingBlockPayload>;
  /** Deletes a single `BoardMemberApplicationBlock` using its globally unique id. */
  deleteBoardMemberApplicationBlock?: Maybe<DeleteBoardMemberApplicationBlockPayload>;
  /** Deletes a single `BoardMemberApplicationBlock` using a unique key. */
  deleteBoardMemberApplicationBlockByAccountIdAndApplicationId?: Maybe<DeleteBoardMemberApplicationBlockPayload>;
  deleteBoardMemberInvitation?: Maybe<DeleteBoardMemberInvitationPayload>;
  /** Deletes a single `Contact` using its globally unique id. */
  deleteContact?: Maybe<DeleteContactPayload>;
  /** Deletes a single `Contact` using a unique key. */
  deleteContactByApplicationIdAndEmail?: Maybe<DeleteContactPayload>;
  /** Deletes a single `Contract` using its globally unique id. */
  deleteContract?: Maybe<DeleteContractPayload>;
  /** Deletes a single `Contract` using a unique key. */
  deleteContractByApplicationId?: Maybe<DeleteContractPayload>;
  /** Deletes a single `Country` using its globally unique id. */
  deleteCountry?: Maybe<DeleteCountryPayload>;
  /** Deletes a single `Country` using a unique key. */
  deleteCountryById?: Maybe<DeleteCountryPayload>;
  /** Deletes a single `DirectDepositAccount` using its globally unique id. */
  deleteDirectDepositAccount?: Maybe<DeleteDirectDepositAccountPayload>;
  /** Deletes a single `DirectDepositAccount` using a unique key. */
  deleteDirectDepositAccountById?: Maybe<DeleteDirectDepositAccountPayload>;
  /** Deletes a single `DirectDepositAccount` using a unique key. */
  deleteDirectDepositAccountByImportId?: Maybe<DeleteDirectDepositAccountPayload>;
  /** Deletes a single `Distributor` using its globally unique id. */
  deleteDistributor?: Maybe<DeleteDistributorPayload>;
  /** Deletes a single `Distributor` using a unique key. */
  deleteDistributorById?: Maybe<DeleteDistributorPayload>;
  /** Deletes a single `Distributor` using a unique key. */
  deleteDistributorByName?: Maybe<DeleteDistributorPayload>;
  /** Deletes a single `EligibilityNotice` using its globally unique id. */
  deleteEligibilityNotice?: Maybe<DeleteEligibilityNoticePayload>;
  /** Deletes a single `EligibilityNotice` using a unique key. */
  deleteEligibilityNoticeByArtistId?: Maybe<DeleteEligibilityNoticePayload>;
  /** Deletes a single `Expense` using its globally unique id. */
  deleteExpense?: Maybe<DeleteExpensePayload>;
  /** Deletes a single `Expense` using a unique key. */
  deleteExpenseById?: Maybe<DeleteExpensePayload>;
  /** Deletes a single `FundingRequest` using its globally unique id. */
  deleteFundingRequest?: Maybe<DeleteFundingRequestPayload>;
  /** Deletes a single `FundingRequest` using a unique key. */
  deleteFundingRequestById?: Maybe<DeleteFundingRequestPayload>;
  /** Deletes a single `FundingRound` using its globally unique id. */
  deleteFundingRound?: Maybe<DeleteFundingRoundPayload>;
  /** Deletes a single `FundingRound` using a unique key. */
  deleteFundingRoundById?: Maybe<DeleteFundingRoundPayload>;
  /** Deletes a single `FundingRound` using a unique key. */
  deleteFundingRoundByTitle?: Maybe<DeleteFundingRoundPayload>;
  /** Deletes a single `Genre` using its globally unique id. */
  deleteGenre?: Maybe<DeleteGenrePayload>;
  /** Deletes a single `Genre` using a unique key. */
  deleteGenreById?: Maybe<DeleteGenrePayload>;
  /** Deletes a single `Label` using its globally unique id. */
  deleteLabel?: Maybe<DeleteLabelPayload>;
  /** Deletes a single `Label` using a unique key. */
  deleteLabelById?: Maybe<DeleteLabelPayload>;
  /** Deletes a single `Label` using a unique key. */
  deleteLabelByName?: Maybe<DeleteLabelPayload>;
  /** Deletes a single `LegalStatus` using its globally unique id. */
  deleteLegalStatus?: Maybe<DeleteLegalStatusPayload>;
  /** Deletes a single `LegalStatus` using a unique key. */
  deleteLegalStatusById?: Maybe<DeleteLegalStatusPayload>;
  /** Deletes a single `Management` using its globally unique id. */
  deleteManagement?: Maybe<DeleteManagementPayload>;
  /** Deletes a single `Management` using a unique key. */
  deleteManagementById?: Maybe<DeleteManagementPayload>;
  /** Deletes a single `Management` using a unique key. */
  deleteManagementByImportId?: Maybe<DeleteManagementPayload>;
  deleteManagementDetails?: Maybe<DeleteManagementDetailsPayload>;
  /** Deletes a single `Payment` using its globally unique id. */
  deletePayment?: Maybe<DeletePaymentPayload>;
  /** Deletes a single `Payment` using a unique key. */
  deletePaymentById?: Maybe<DeletePaymentPayload>;
  /** Deletes a single `Province` using its globally unique id. */
  deleteProvince?: Maybe<DeleteProvincePayload>;
  /** Deletes a single `Province` using a unique key. */
  deleteProvinceById?: Maybe<DeleteProvincePayload>;
  /** Deletes a single `Region` using its globally unique id. */
  deleteRegion?: Maybe<DeleteRegionPayload>;
  /** Deletes a single `Region` using a unique key. */
  deleteRegionById?: Maybe<DeleteRegionPayload>;
  /** Deletes a single `Release` using its globally unique id. */
  deleteRelease?: Maybe<DeleteReleasePayload>;
  /** Deletes a single `Release` using a unique key. */
  deleteReleaseById?: Maybe<DeleteReleasePayload>;
  /** Deletes a single `Release` using a unique key. */
  deleteReleaseByImportId?: Maybe<DeleteReleasePayload>;
  /** Deletes a single `Sale` using its globally unique id. */
  deleteSale?: Maybe<DeleteSalePayload>;
  /** Deletes a single `Sale` using a unique key. */
  deleteSaleById?: Maybe<DeleteSalePayload>;
  /** Deletes a single `Sale` using a unique key. */
  deleteSaleByImportId?: Maybe<DeleteSalePayload>;
  /** Deletes a single `Single` using its globally unique id. */
  deleteSingle?: Maybe<DeleteSinglePayload>;
  /** Deletes a single `Single` using a unique key. */
  deleteSingleById?: Maybe<DeleteSinglePayload>;
  /** Deletes a single `Single` using a unique key. */
  deleteSingleByImportId?: Maybe<DeleteSinglePayload>;
  /** Deletes a single `TourDate` using its globally unique id. */
  deleteTourDate?: Maybe<DeleteTourDatePayload>;
  /** Deletes a single `TourDate` using a unique key. */
  deleteTourDateById?: Maybe<DeleteTourDatePayload>;
  /** Deletes a single `TourDateType` using its globally unique id. */
  deleteTourDateType?: Maybe<DeleteTourDateTypePayload>;
  /** Deletes a single `TourDateType` using a unique key. */
  deleteTourDateTypeById?: Maybe<DeleteTourDateTypePayload>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  insertDirectDepositAccount?: Maybe<InsertDirectDepositAccountPayload>;
  insertDirectDepositAndLinkToApplication?: Maybe<InsertDirectDepositAndLinkToApplicationPayload>;
  linkDirectDepositAccountToApplication?: Maybe<LinkDirectDepositAccountToApplicationPayload>;
  markApplicationCompleted?: Maybe<MarkApplicationCompletedPayload>;
  removeBoardMember?: Maybe<RemoveBoardMemberPayload>;
  reopenApplicationClaims?: Maybe<ReopenApplicationClaimsPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  resubmitApplicationClaims?: Maybe<ResubmitApplicationClaimsPayload>;
  setFluxCapacitor?: Maybe<SetFluxCapacitorPayload>;
  submitApplication?: Maybe<SubmitApplicationPayload>;
  submitApplicationClaims?: Maybe<SubmitApplicationClaimsPayload>;
  subscribeToNewsletter?: Maybe<SubscribeToNewsletterPayload>;
  swapBoardMeetingBlockOrder?: Maybe<SwapBoardMeetingBlockOrderPayload>;
  unsubscribeFromNewsletter?: Maybe<UnsubscribeFromNewsletterPayload>;
  /** Updates a single `Account` using its globally unique id and a patch. */
  updateAccount?: Maybe<UpdateAccountPayload>;
  /** Updates a single `AccountArtistBinding` using its globally unique id and a patch. */
  updateAccountArtistBinding?: Maybe<UpdateAccountArtistBindingPayload>;
  /** Updates a single `AccountArtistBinding` using a unique key and a patch. */
  updateAccountArtistBindingByAccountIdAndArtistId?: Maybe<UpdateAccountArtistBindingPayload>;
  /** Updates a single `Account` using a unique key and a patch. */
  updateAccountByEmail?: Maybe<UpdateAccountPayload>;
  /** Updates a single `Account` using a unique key and a patch. */
  updateAccountById?: Maybe<UpdateAccountPayload>;
  updateAccountInvitation?: Maybe<UpdateAccountInvitationPayload>;
  /** Updates a single `AccountPassword` using its globally unique id and a patch. */
  updateAccountPassword?: Maybe<UpdateAccountPasswordPayload>;
  /** Updates a single `AccountPassword` using a unique key and a patch. */
  updateAccountPasswordByAccountId?: Maybe<UpdateAccountPasswordPayload>;
  /** Updates a single `Application` using its globally unique id and a patch. */
  updateApplication?: Maybe<UpdateApplicationPayload>;
  /** Updates a single `ApplicationBoardNote` using its globally unique id and a patch. */
  updateApplicationBoardNote?: Maybe<UpdateApplicationBoardNotePayload>;
  /** Updates a single `ApplicationBoardNote` using a unique key and a patch. */
  updateApplicationBoardNoteByApplicationId?: Maybe<UpdateApplicationBoardNotePayload>;
  /** Updates a single `Application` using a unique key and a patch. */
  updateApplicationByArtistIdAndFundingRoundId?: Maybe<UpdateApplicationPayload>;
  /** Updates a single `Application` using a unique key and a patch. */
  updateApplicationById?: Maybe<UpdateApplicationPayload>;
  /** Updates a single `Artist` using its globally unique id and a patch. */
  updateArtist?: Maybe<UpdateArtistPayload>;
  /** Updates a single `Artist` using a unique key and a patch. */
  updateArtistById?: Maybe<UpdateArtistPayload>;
  /** Updates a single `Artist` using a unique key and a patch. */
  updateArtistByImportId?: Maybe<UpdateArtistPayload>;
  updateArtistInvitation?: Maybe<UpdateArtistInvitationPayload>;
  /** Updates a single `ArtistReleaseBinding` using its globally unique id and a patch. */
  updateArtistReleaseBinding?: Maybe<UpdateArtistReleaseBindingPayload>;
  /** Updates a single `ArtistReleaseBinding` using a unique key and a patch. */
  updateArtistReleaseBindingByArtistIdAndReleaseId?: Maybe<UpdateArtistReleaseBindingPayload>;
  updateArtistStatus?: Maybe<UpdateArtistStatusPayload>;
  /** Updates a single `Attachment` using its globally unique id and a patch. */
  updateAttachment?: Maybe<UpdateAttachmentPayload>;
  /** Updates a single `AttachmentApplicationBinding` using its globally unique id and a patch. */
  updateAttachmentApplicationBinding?: Maybe<UpdateAttachmentApplicationBindingPayload>;
  /** Updates a single `AttachmentApplicationBinding` using a unique key and a patch. */
  updateAttachmentApplicationBindingByAttachmentIdAndApplicationId?: Maybe<UpdateAttachmentApplicationBindingPayload>;
  /** Updates a single `AttachmentBoardMeetingBlockBinding` using its globally unique id and a patch. */
  updateAttachmentBoardMeetingBlockBinding?: Maybe<UpdateAttachmentBoardMeetingBlockBindingPayload>;
  /** Updates a single `AttachmentBoardMeetingBlockBinding` using a unique key and a patch. */
  updateAttachmentBoardMeetingBlockBindingByAttachmentIdAndBlockId?: Maybe<UpdateAttachmentBoardMeetingBlockBindingPayload>;
  /** Updates a single `Attachment` using a unique key and a patch. */
  updateAttachmentById?: Maybe<UpdateAttachmentPayload>;
  /** Updates a single `BoardMeetingBlock` using its globally unique id and a patch. */
  updateBoardMeetingBlock?: Maybe<UpdateBoardMeetingBlockPayload>;
  /** Updates a single `BoardMeetingBlock` using a unique key and a patch. */
  updateBoardMeetingBlockById?: Maybe<UpdateBoardMeetingBlockPayload>;
  /** Updates a single `BoardMemberApplicationBlock` using its globally unique id and a patch. */
  updateBoardMemberApplicationBlock?: Maybe<UpdateBoardMemberApplicationBlockPayload>;
  /** Updates a single `BoardMemberApplicationBlock` using a unique key and a patch. */
  updateBoardMemberApplicationBlockByAccountIdAndApplicationId?: Maybe<UpdateBoardMemberApplicationBlockPayload>;
  /** Updates a single `Contact` using its globally unique id and a patch. */
  updateContact?: Maybe<UpdateContactPayload>;
  /** Updates a single `Contact` using a unique key and a patch. */
  updateContactByApplicationIdAndEmail?: Maybe<UpdateContactPayload>;
  /** Updates a single `Contract` using its globally unique id and a patch. */
  updateContract?: Maybe<UpdateContractPayload>;
  /** Updates a single `Contract` using a unique key and a patch. */
  updateContractByApplicationId?: Maybe<UpdateContractPayload>;
  /** Updates a single `Country` using its globally unique id and a patch. */
  updateCountry?: Maybe<UpdateCountryPayload>;
  /** Updates a single `Country` using a unique key and a patch. */
  updateCountryById?: Maybe<UpdateCountryPayload>;
  /** Updates a single `DirectDepositAccount` using its globally unique id and a patch. */
  updateDirectDepositAccount?: Maybe<UpdateDirectDepositAccountPayload>;
  /** Updates a single `DirectDepositAccount` using a unique key and a patch. */
  updateDirectDepositAccountById?: Maybe<UpdateDirectDepositAccountPayload>;
  /** Updates a single `DirectDepositAccount` using a unique key and a patch. */
  updateDirectDepositAccountByImportId?: Maybe<UpdateDirectDepositAccountPayload>;
  /** Updates a single `Distributor` using its globally unique id and a patch. */
  updateDistributor?: Maybe<UpdateDistributorPayload>;
  /** Updates a single `Distributor` using a unique key and a patch. */
  updateDistributorById?: Maybe<UpdateDistributorPayload>;
  /** Updates a single `Distributor` using a unique key and a patch. */
  updateDistributorByName?: Maybe<UpdateDistributorPayload>;
  /** Updates a single `EligibilityNotice` using its globally unique id and a patch. */
  updateEligibilityNotice?: Maybe<UpdateEligibilityNoticePayload>;
  /** Updates a single `EligibilityNotice` using a unique key and a patch. */
  updateEligibilityNoticeByArtistId?: Maybe<UpdateEligibilityNoticePayload>;
  /** Updates a single `Expense` using its globally unique id and a patch. */
  updateExpense?: Maybe<UpdateExpensePayload>;
  /** Updates a single `Expense` using a unique key and a patch. */
  updateExpenseById?: Maybe<UpdateExpensePayload>;
  /** Updates a single `FundingRequest` using its globally unique id and a patch. */
  updateFundingRequest?: Maybe<UpdateFundingRequestPayload>;
  /** Updates a single `FundingRequest` using a unique key and a patch. */
  updateFundingRequestById?: Maybe<UpdateFundingRequestPayload>;
  /** Updates a single `FundingRound` using its globally unique id and a patch. */
  updateFundingRound?: Maybe<UpdateFundingRoundPayload>;
  /** Updates a single `FundingRound` using a unique key and a patch. */
  updateFundingRoundById?: Maybe<UpdateFundingRoundPayload>;
  /** Updates a single `FundingRound` using a unique key and a patch. */
  updateFundingRoundByTitle?: Maybe<UpdateFundingRoundPayload>;
  /** Updates a single `Genre` using its globally unique id and a patch. */
  updateGenre?: Maybe<UpdateGenrePayload>;
  /** Updates a single `Genre` using a unique key and a patch. */
  updateGenreById?: Maybe<UpdateGenrePayload>;
  /** Updates a single `Label` using its globally unique id and a patch. */
  updateLabel?: Maybe<UpdateLabelPayload>;
  /** Updates a single `Label` using a unique key and a patch. */
  updateLabelById?: Maybe<UpdateLabelPayload>;
  /** Updates a single `Label` using a unique key and a patch. */
  updateLabelByName?: Maybe<UpdateLabelPayload>;
  /** Updates a single `LegalStatus` using its globally unique id and a patch. */
  updateLegalStatus?: Maybe<UpdateLegalStatusPayload>;
  /** Updates a single `LegalStatus` using a unique key and a patch. */
  updateLegalStatusById?: Maybe<UpdateLegalStatusPayload>;
  /** Updates a single `Management` using its globally unique id and a patch. */
  updateManagement?: Maybe<UpdateManagementPayload>;
  /** Updates a single `Management` using a unique key and a patch. */
  updateManagementById?: Maybe<UpdateManagementPayload>;
  /** Updates a single `Management` using a unique key and a patch. */
  updateManagementByImportId?: Maybe<UpdateManagementPayload>;
  /** Updates a single `Payment` using its globally unique id and a patch. */
  updatePayment?: Maybe<UpdatePaymentPayload>;
  /** Updates a single `Payment` using a unique key and a patch. */
  updatePaymentById?: Maybe<UpdatePaymentPayload>;
  /** Updates a single `Province` using its globally unique id and a patch. */
  updateProvince?: Maybe<UpdateProvincePayload>;
  /** Updates a single `Province` using a unique key and a patch. */
  updateProvinceById?: Maybe<UpdateProvincePayload>;
  /** Updates a single `Region` using its globally unique id and a patch. */
  updateRegion?: Maybe<UpdateRegionPayload>;
  /** Updates a single `Region` using a unique key and a patch. */
  updateRegionById?: Maybe<UpdateRegionPayload>;
  /** Updates a single `Release` using its globally unique id and a patch. */
  updateRelease?: Maybe<UpdateReleasePayload>;
  /** Updates a single `Release` using a unique key and a patch. */
  updateReleaseById?: Maybe<UpdateReleasePayload>;
  /** Updates a single `Release` using a unique key and a patch. */
  updateReleaseByImportId?: Maybe<UpdateReleasePayload>;
  updateReleaseSales?: Maybe<UpdateReleaseSalesPayload>;
  /** Updates a single `Sale` using its globally unique id and a patch. */
  updateSale?: Maybe<UpdateSalePayload>;
  /** Updates a single `Sale` using a unique key and a patch. */
  updateSaleById?: Maybe<UpdateSalePayload>;
  /** Updates a single `Sale` using a unique key and a patch. */
  updateSaleByImportId?: Maybe<UpdateSalePayload>;
  /** Updates a single `Single` using its globally unique id and a patch. */
  updateSingle?: Maybe<UpdateSinglePayload>;
  /** Updates a single `Single` using a unique key and a patch. */
  updateSingleById?: Maybe<UpdateSinglePayload>;
  /** Updates a single `Single` using a unique key and a patch. */
  updateSingleByImportId?: Maybe<UpdateSinglePayload>;
  /** Updates a single `TourDate` using its globally unique id and a patch. */
  updateTourDate?: Maybe<UpdateTourDatePayload>;
  /** Updates a single `TourDate` using a unique key and a patch. */
  updateTourDateById?: Maybe<UpdateTourDatePayload>;
  /** Updates a single `TourDateType` using its globally unique id and a patch. */
  updateTourDateType?: Maybe<UpdateTourDateTypePayload>;
  /** Updates a single `TourDateType` using a unique key and a patch. */
  updateTourDateTypeById?: Maybe<UpdateTourDateTypePayload>;
  upsertApplicationBoardNotes?: Maybe<UpsertApplicationBoardNotesPayload>;
  upsertEligibilityNotice?: Maybe<UpsertEligibilityNoticePayload>;
  upsertManagement?: Maybe<UpsertManagementPayload>;
  verifyNewsletterSubscription?: Maybe<VerifyNewsletterSubscriptionPayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddAlternateRequestArgs = {
  input: AddAlternateRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddArtistArgs = {
  input: AddArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddBoardMeetingBlockArgs = {
  input: AddBoardMeetingBlockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddReleaseArgs = {
  input: AddReleaseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationApproveApplicationAndNotifyArgs = {
  input: ApproveApplicationAndNotifyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelFundingRequestArgs = {
  input: CancelFundingRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelTourDateArgs = {
  input: CancelTourDateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearFluxCapacitorArgs = {
  input: ClearFluxCapacitorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAccountArtistBindingArgs = {
  input: CreateAccountArtistBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAccountPasswordArgs = {
  input: CreateAccountPasswordInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateApplicationBoardNoteArgs = {
  input: CreateApplicationBoardNoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateArtistArgs = {
  input: CreateArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateArtistAndLinkArgs = {
  input: CreateArtistAndLinkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateArtistInvitationArgs = {
  input: CreateArtistInvitationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateArtistReleaseBindingArgs = {
  input: CreateArtistReleaseBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAttachmentArgs = {
  input: CreateAttachmentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAttachmentApplicationBindingArgs = {
  input: CreateAttachmentApplicationBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAttachmentBoardMeetingBlockBindingArgs = {
  input: CreateAttachmentBoardMeetingBlockBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBoardMeetingBlockArgs = {
  input: CreateBoardMeetingBlockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBoardMemberApplicationBlockArgs = {
  input: CreateBoardMemberApplicationBlockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBoardMemberInvitationArgs = {
  input: CreateBoardMemberInvitationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateContactArgs = {
  input: CreateContactInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateContractArgs = {
  input: CreateContractInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCoreAccountInvitationArgs = {
  input: CreateCoreAccountInvitationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCountryArgs = {
  input: CreateCountryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDirectDepositAccountArgs = {
  input: CreateDirectDepositAccountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDistributorArgs = {
  input: CreateDistributorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEligibilityNoticeArgs = {
  input: CreateEligibilityNoticeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExpenseArgs = {
  input: CreateExpenseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFundingRequestArgs = {
  input: CreateFundingRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFundingRoundArgs = {
  input: CreateFundingRoundInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGenreArgs = {
  input: CreateGenreInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLabelArgs = {
  input: CreateLabelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLegalStatusArgs = {
  input: CreateLegalStatusInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateManagementArgs = {
  input: CreateManagementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNewApplicationArgs = {
  input: CreateNewApplicationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrionAccountInvitationArgs = {
  input: CreateOrionAccountInvitationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProvinceArgs = {
  input: CreateProvinceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRegionArgs = {
  input: CreateRegionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReleaseArgs = {
  input: CreateReleaseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSaleArgs = {
  input: CreateSaleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSingleArgs = {
  input: CreateSingleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTourDateArgs = {
  input: CreateTourDateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTourDateTypeArgs = {
  input: CreateTourDateTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVisibleAccountArtistBindingArgs = {
  input: CreateVisibleAccountArtistBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountArtistBindingArgs = {
  input: DeleteAccountArtistBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountArtistBindingByAccountIdAndArtistIdArgs = {
  input: DeleteAccountArtistBindingByAccountIdAndArtistIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountByEmailArgs = {
  input: DeleteAccountByEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountByIdArgs = {
  input: DeleteAccountByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountPasswordArgs = {
  input: DeleteAccountPasswordInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountPasswordByAccountIdArgs = {
  input: DeleteAccountPasswordByAccountIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationArgs = {
  input: DeleteApplicationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationBoardNoteArgs = {
  input: DeleteApplicationBoardNoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationBoardNoteByApplicationIdArgs = {
  input: DeleteApplicationBoardNoteByApplicationIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationByArtistIdAndFundingRoundIdArgs = {
  input: DeleteApplicationByArtistIdAndFundingRoundIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationByIdArgs = {
  input: DeleteApplicationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistArgs = {
  input: DeleteArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistByIdArgs = {
  input: DeleteArtistByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistByImportIdArgs = {
  input: DeleteArtistByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistInvitationArgs = {
  input: DeleteArtistInvitationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistReleaseBindingArgs = {
  input: DeleteArtistReleaseBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArtistReleaseBindingByArtistIdAndReleaseIdArgs = {
  input: DeleteArtistReleaseBindingByArtistIdAndReleaseIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAttachmentArgs = {
  input: DeleteAttachmentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAttachmentApplicationBindingArgs = {
  input: DeleteAttachmentApplicationBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAttachmentApplicationBindingByAttachmentIdAndApplicationIdArgs =
  {
    input: DeleteAttachmentApplicationBindingByAttachmentIdAndApplicationIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAttachmentBoardMeetingBlockBindingArgs = {
  input: DeleteAttachmentBoardMeetingBlockBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAttachmentBoardMeetingBlockBindingByAttachmentIdAndBlockIdArgs =
  {
    input: DeleteAttachmentBoardMeetingBlockBindingByAttachmentIdAndBlockIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAttachmentByIdArgs = {
  input: DeleteAttachmentByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBoardMeetingBlockArgs = {
  input: DeleteBoardMeetingBlockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBoardMeetingBlockByIdArgs = {
  input: DeleteBoardMeetingBlockByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBoardMemberApplicationBlockArgs = {
  input: DeleteBoardMemberApplicationBlockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBoardMemberApplicationBlockByAccountIdAndApplicationIdArgs =
  {
    input: DeleteBoardMemberApplicationBlockByAccountIdAndApplicationIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBoardMemberInvitationArgs = {
  input: DeleteBoardMemberInvitationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteContactArgs = {
  input: DeleteContactInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteContactByApplicationIdAndEmailArgs = {
  input: DeleteContactByApplicationIdAndEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteContractArgs = {
  input: DeleteContractInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteContractByApplicationIdArgs = {
  input: DeleteContractByApplicationIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCountryArgs = {
  input: DeleteCountryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCountryByIdArgs = {
  input: DeleteCountryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDirectDepositAccountArgs = {
  input: DeleteDirectDepositAccountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDirectDepositAccountByIdArgs = {
  input: DeleteDirectDepositAccountByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDirectDepositAccountByImportIdArgs = {
  input: DeleteDirectDepositAccountByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDistributorArgs = {
  input: DeleteDistributorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDistributorByIdArgs = {
  input: DeleteDistributorByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDistributorByNameArgs = {
  input: DeleteDistributorByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEligibilityNoticeArgs = {
  input: DeleteEligibilityNoticeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEligibilityNoticeByArtistIdArgs = {
  input: DeleteEligibilityNoticeByArtistIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExpenseArgs = {
  input: DeleteExpenseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExpenseByIdArgs = {
  input: DeleteExpenseByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFundingRequestArgs = {
  input: DeleteFundingRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFundingRequestByIdArgs = {
  input: DeleteFundingRequestByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFundingRoundArgs = {
  input: DeleteFundingRoundInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFundingRoundByIdArgs = {
  input: DeleteFundingRoundByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFundingRoundByTitleArgs = {
  input: DeleteFundingRoundByTitleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGenreArgs = {
  input: DeleteGenreInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGenreByIdArgs = {
  input: DeleteGenreByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLabelArgs = {
  input: DeleteLabelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLabelByIdArgs = {
  input: DeleteLabelByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLabelByNameArgs = {
  input: DeleteLabelByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLegalStatusArgs = {
  input: DeleteLegalStatusInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLegalStatusByIdArgs = {
  input: DeleteLegalStatusByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteManagementArgs = {
  input: DeleteManagementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteManagementByIdArgs = {
  input: DeleteManagementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteManagementByImportIdArgs = {
  input: DeleteManagementByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteManagementDetailsArgs = {
  input: DeleteManagementDetailsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePaymentArgs = {
  input: DeletePaymentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePaymentByIdArgs = {
  input: DeletePaymentByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProvinceArgs = {
  input: DeleteProvinceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProvinceByIdArgs = {
  input: DeleteProvinceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRegionArgs = {
  input: DeleteRegionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRegionByIdArgs = {
  input: DeleteRegionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReleaseArgs = {
  input: DeleteReleaseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReleaseByIdArgs = {
  input: DeleteReleaseByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReleaseByImportIdArgs = {
  input: DeleteReleaseByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSaleArgs = {
  input: DeleteSaleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSaleByIdArgs = {
  input: DeleteSaleByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSaleByImportIdArgs = {
  input: DeleteSaleByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSingleArgs = {
  input: DeleteSingleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSingleByIdArgs = {
  input: DeleteSingleByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSingleByImportIdArgs = {
  input: DeleteSingleByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTourDateArgs = {
  input: DeleteTourDateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTourDateByIdArgs = {
  input: DeleteTourDateByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTourDateTypeArgs = {
  input: DeleteTourDateTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTourDateTypeByIdArgs = {
  input: DeleteTourDateTypeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertDirectDepositAccountArgs = {
  input: InsertDirectDepositAccountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertDirectDepositAndLinkToApplicationArgs = {
  input: InsertDirectDepositAndLinkToApplicationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationLinkDirectDepositAccountToApplicationArgs = {
  input: LinkDirectDepositAccountToApplicationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMarkApplicationCompletedArgs = {
  input: MarkApplicationCompletedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveBoardMemberArgs = {
  input: RemoveBoardMemberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationReopenApplicationClaimsArgs = {
  input: ReopenApplicationClaimsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationResubmitApplicationClaimsArgs = {
  input: ResubmitApplicationClaimsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetFluxCapacitorArgs = {
  input: SetFluxCapacitorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubmitApplicationArgs = {
  input: SubmitApplicationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubmitApplicationClaimsArgs = {
  input: SubmitApplicationClaimsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubscribeToNewsletterArgs = {
  input: SubscribeToNewsletterInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSwapBoardMeetingBlockOrderArgs = {
  input: SwapBoardMeetingBlockOrderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUnsubscribeFromNewsletterArgs = {
  input: UnsubscribeFromNewsletterInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountArtistBindingArgs = {
  input: UpdateAccountArtistBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountArtistBindingByAccountIdAndArtistIdArgs = {
  input: UpdateAccountArtistBindingByAccountIdAndArtistIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountByEmailArgs = {
  input: UpdateAccountByEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountByIdArgs = {
  input: UpdateAccountByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountInvitationArgs = {
  input: UpdateAccountInvitationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountPasswordArgs = {
  input: UpdateAccountPasswordInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountPasswordByAccountIdArgs = {
  input: UpdateAccountPasswordByAccountIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationBoardNoteArgs = {
  input: UpdateApplicationBoardNoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationBoardNoteByApplicationIdArgs = {
  input: UpdateApplicationBoardNoteByApplicationIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationByArtistIdAndFundingRoundIdArgs = {
  input: UpdateApplicationByArtistIdAndFundingRoundIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationByIdArgs = {
  input: UpdateApplicationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistArgs = {
  input: UpdateArtistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistByIdArgs = {
  input: UpdateArtistByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistByImportIdArgs = {
  input: UpdateArtistByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistInvitationArgs = {
  input: UpdateArtistInvitationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistReleaseBindingArgs = {
  input: UpdateArtistReleaseBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistReleaseBindingByArtistIdAndReleaseIdArgs = {
  input: UpdateArtistReleaseBindingByArtistIdAndReleaseIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArtistStatusArgs = {
  input: UpdateArtistStatusInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAttachmentArgs = {
  input: UpdateAttachmentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAttachmentApplicationBindingArgs = {
  input: UpdateAttachmentApplicationBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAttachmentApplicationBindingByAttachmentIdAndApplicationIdArgs =
  {
    input: UpdateAttachmentApplicationBindingByAttachmentIdAndApplicationIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAttachmentBoardMeetingBlockBindingArgs = {
  input: UpdateAttachmentBoardMeetingBlockBindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAttachmentBoardMeetingBlockBindingByAttachmentIdAndBlockIdArgs =
  {
    input: UpdateAttachmentBoardMeetingBlockBindingByAttachmentIdAndBlockIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAttachmentByIdArgs = {
  input: UpdateAttachmentByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBoardMeetingBlockArgs = {
  input: UpdateBoardMeetingBlockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBoardMeetingBlockByIdArgs = {
  input: UpdateBoardMeetingBlockByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBoardMemberApplicationBlockArgs = {
  input: UpdateBoardMemberApplicationBlockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBoardMemberApplicationBlockByAccountIdAndApplicationIdArgs =
  {
    input: UpdateBoardMemberApplicationBlockByAccountIdAndApplicationIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContactByApplicationIdAndEmailArgs = {
  input: UpdateContactByApplicationIdAndEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContractArgs = {
  input: UpdateContractInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContractByApplicationIdArgs = {
  input: UpdateContractByApplicationIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCountryArgs = {
  input: UpdateCountryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCountryByIdArgs = {
  input: UpdateCountryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDirectDepositAccountArgs = {
  input: UpdateDirectDepositAccountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDirectDepositAccountByIdArgs = {
  input: UpdateDirectDepositAccountByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDirectDepositAccountByImportIdArgs = {
  input: UpdateDirectDepositAccountByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDistributorArgs = {
  input: UpdateDistributorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDistributorByIdArgs = {
  input: UpdateDistributorByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDistributorByNameArgs = {
  input: UpdateDistributorByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEligibilityNoticeArgs = {
  input: UpdateEligibilityNoticeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEligibilityNoticeByArtistIdArgs = {
  input: UpdateEligibilityNoticeByArtistIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExpenseArgs = {
  input: UpdateExpenseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExpenseByIdArgs = {
  input: UpdateExpenseByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFundingRequestArgs = {
  input: UpdateFundingRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFundingRequestByIdArgs = {
  input: UpdateFundingRequestByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFundingRoundArgs = {
  input: UpdateFundingRoundInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFundingRoundByIdArgs = {
  input: UpdateFundingRoundByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFundingRoundByTitleArgs = {
  input: UpdateFundingRoundByTitleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGenreArgs = {
  input: UpdateGenreInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGenreByIdArgs = {
  input: UpdateGenreByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLabelArgs = {
  input: UpdateLabelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLabelByIdArgs = {
  input: UpdateLabelByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLabelByNameArgs = {
  input: UpdateLabelByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLegalStatusArgs = {
  input: UpdateLegalStatusInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLegalStatusByIdArgs = {
  input: UpdateLegalStatusByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateManagementArgs = {
  input: UpdateManagementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateManagementByIdArgs = {
  input: UpdateManagementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateManagementByImportIdArgs = {
  input: UpdateManagementByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePaymentArgs = {
  input: UpdatePaymentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePaymentByIdArgs = {
  input: UpdatePaymentByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProvinceArgs = {
  input: UpdateProvinceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProvinceByIdArgs = {
  input: UpdateProvinceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRegionArgs = {
  input: UpdateRegionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRegionByIdArgs = {
  input: UpdateRegionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReleaseArgs = {
  input: UpdateReleaseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReleaseByIdArgs = {
  input: UpdateReleaseByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReleaseByImportIdArgs = {
  input: UpdateReleaseByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReleaseSalesArgs = {
  input: UpdateReleaseSalesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSaleArgs = {
  input: UpdateSaleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSaleByIdArgs = {
  input: UpdateSaleByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSaleByImportIdArgs = {
  input: UpdateSaleByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSingleArgs = {
  input: UpdateSingleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSingleByIdArgs = {
  input: UpdateSingleByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSingleByImportIdArgs = {
  input: UpdateSingleByImportIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTourDateArgs = {
  input: UpdateTourDateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTourDateByIdArgs = {
  input: UpdateTourDateByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTourDateTypeArgs = {
  input: UpdateTourDateTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTourDateTypeByIdArgs = {
  input: UpdateTourDateTypeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertApplicationBoardNotesArgs = {
  input: UpsertApplicationBoardNotesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertEligibilityNoticeArgs = {
  input: UpsertEligibilityNoticeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertManagementArgs = {
  input: UpsertManagementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationVerifyNewsletterSubscriptionArgs = {
  input: VerifyNewsletterSubscriptionInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type Payment = Node & {
  __typename?: 'Payment';
  amount: Scalars['BigFloat'];
  /** Reads a single `Application` that is related to this `Payment`. */
  applicationByApplicationId?: Maybe<Application>;
  applicationId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `DirectDepositAccount` that is related to this `Payment`. */
  directDepositAccountByDirectDepositAccountId?: Maybe<DirectDepositAccount>;
  directDepositAccountId: Scalars['UUID'];
  id: Scalars['UUID'];
  invoiceDate: Scalars['Date'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  transactionNumber: Scalars['String'];
};

/** A condition to be used against `Payment` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PaymentCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `applicationId` field. */
  applicationId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `directDepositAccountId` field. */
  directDepositAccountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `invoiceDate` field. */
  invoiceDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionNumber` field. */
  transactionNumber?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Payment` */
export type PaymentInput = {
  amount: Scalars['BigFloat'];
  applicationId: Scalars['UUID'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  directDepositAccountId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  invoiceDate: Scalars['Date'];
  notes?: InputMaybe<Scalars['String']>;
  transactionNumber: Scalars['String'];
};

/** Represents an update to a `Payment`. Fields that are set will be updated. */
export type PaymentPatch = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  applicationId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  directDepositAccountId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  invoiceDate?: InputMaybe<Scalars['Date']>;
  notes?: InputMaybe<Scalars['String']>;
  transactionNumber?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Payment`. */
export enum PaymentsOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  ApplicationIdAsc = 'APPLICATION_ID_ASC',
  ApplicationIdDesc = 'APPLICATION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DirectDepositAccountIdAsc = 'DIRECT_DEPOSIT_ACCOUNT_ID_ASC',
  DirectDepositAccountIdDesc = 'DIRECT_DEPOSIT_ACCOUNT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvoiceDateAsc = 'INVOICE_DATE_ASC',
  InvoiceDateDesc = 'INVOICE_DATE_DESC',
  Natural = 'NATURAL',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TransactionNumberAsc = 'TRANSACTION_NUMBER_ASC',
  TransactionNumberDesc = 'TRANSACTION_NUMBER_DESC',
}

export type PendingBoardMember = {
  __typename?: 'PendingBoardMember';
  email?: Maybe<Scalars['String']>;
};

export type Province = Node & {
  __typename?: 'Province';
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByCompanyLegalProvinceList: Array<Artist>;
  /** Reads and enables pagination through a set of `Artist`. */
  artistsByProvinceIdList: Array<Artist>;
  id: Scalars['String'];
  /** Reads and enables pagination through a set of `Management`. */
  managementsByLegalProvinceList: Array<Management>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  province: Scalars['String'];
};

export type ProvinceArtistsByCompanyLegalProvinceListArgs = {
  condition?: InputMaybe<ArtistCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

export type ProvinceArtistsByProvinceIdListArgs = {
  condition?: InputMaybe<ArtistCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

export type ProvinceManagementsByLegalProvinceListArgs = {
  condition?: InputMaybe<ManagementCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ManagementsOrderBy>>;
};

/**
 * A condition to be used against `Province` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ProvinceCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `province` field. */
  province?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Province` */
export type ProvinceInput = {
  id: Scalars['String'];
  province: Scalars['String'];
};

/** Represents an update to a `Province`. Fields that are set will be updated. */
export type ProvincePatch = {
  id?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Province`. */
export enum ProvincesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProvinceAsc = 'PROVINCE_ASC',
  ProvinceDesc = 'PROVINCE_DESC',
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /** Reads a single `Account` using its globally unique `ID`. */
  account?: Maybe<Account>;
  /** Reads a single `AccountArtistBinding` using its globally unique `ID`. */
  accountArtistBinding?: Maybe<AccountArtistBinding>;
  accountArtistBindingByAccountIdAndArtistId?: Maybe<AccountArtistBinding>;
  accountByEmail?: Maybe<Account>;
  accountById?: Maybe<Account>;
  accountInvitationStatus?: Maybe<AccountInvitationStatusResponse>;
  /** Reads a single `AccountPassword` using its globally unique `ID`. */
  accountPassword?: Maybe<AccountPassword>;
  accountPasswordByAccountId?: Maybe<AccountPassword>;
  /** Reads a set of `AccountArtistBinding`. */
  allAccountArtistBindingsList?: Maybe<Array<AccountArtistBinding>>;
  /** Reads a set of `AccountLog`. */
  allAccountLogsList?: Maybe<Array<AccountLog>>;
  /** Reads a set of `AccountPassword`. */
  allAccountPasswordsList?: Maybe<Array<AccountPassword>>;
  /** Reads a set of `Account`. */
  allAccountsList?: Maybe<Array<Account>>;
  /** Reads a set of `ApplicationBoardNote`. */
  allApplicationBoardNotesList?: Maybe<Array<ApplicationBoardNote>>;
  /** Reads a set of `Application`. */
  allApplicationsList?: Maybe<Array<Application>>;
  /** Reads a set of `ArtistLog`. */
  allArtistLogsList?: Maybe<Array<ArtistLog>>;
  /** Reads a set of `ArtistReleaseBinding`. */
  allArtistReleaseBindingsList?: Maybe<Array<ArtistReleaseBinding>>;
  /** Reads a set of `Artist`. */
  allArtistsList?: Maybe<Array<Artist>>;
  /** Reads a set of `AttachmentApplicationBinding`. */
  allAttachmentApplicationBindingsList?: Maybe<
    Array<AttachmentApplicationBinding>
  >;
  /** Reads a set of `AttachmentBoardMeetingBlockBinding`. */
  allAttachmentBoardMeetingBlockBindingsList?: Maybe<
    Array<AttachmentBoardMeetingBlockBinding>
  >;
  /** Reads a set of `Attachment`. */
  allAttachmentsList?: Maybe<Array<Attachment>>;
  /** Reads a set of `BoardMeetingBlock`. */
  allBoardMeetingBlocksList?: Maybe<Array<BoardMeetingBlock>>;
  /** Reads a set of `BoardMemberApplicationBlock`. */
  allBoardMemberApplicationBlocksList?: Maybe<
    Array<BoardMemberApplicationBlock>
  >;
  /** Reads a set of `Contact`. */
  allContactsList?: Maybe<Array<Contact>>;
  /** Reads a set of `Contract`. */
  allContractsList?: Maybe<Array<Contract>>;
  /** Reads a set of `Country`. */
  allCountriesList?: Maybe<Array<Country>>;
  /** Reads a set of `DirectDepositAccount`. */
  allDirectDepositAccountsList?: Maybe<Array<DirectDepositAccount>>;
  /** Reads a set of `Distributor`. */
  allDistributorsList?: Maybe<Array<Distributor>>;
  /** Reads a set of `EligibilityNotice`. */
  allEligibilityNoticesList?: Maybe<Array<EligibilityNotice>>;
  /** Reads and enables pagination through a set of `Expense`. */
  allExpenses?: Maybe<ExpensesConnection>;
  /** Reads a set of `Expense`. */
  allExpensesList?: Maybe<Array<Expense>>;
  /** Reads a set of `FundingRequest`. */
  allFundingRequestsList?: Maybe<Array<FundingRequest>>;
  /** Reads a set of `FundingRound`. */
  allFundingRoundsList?: Maybe<Array<FundingRound>>;
  /** Reads a set of `Genre`. */
  allGenresList?: Maybe<Array<Genre>>;
  /** Reads a set of `Label`. */
  allLabelsList?: Maybe<Array<Label>>;
  /** Reads a set of `LegalStatus`. */
  allLegalStatusesList?: Maybe<Array<LegalStatus>>;
  /** Reads a set of `Management`. */
  allManagementsList?: Maybe<Array<Management>>;
  /** Reads a set of `Payment`. */
  allPaymentsList?: Maybe<Array<Payment>>;
  /** Reads a set of `Province`. */
  allProvincesList?: Maybe<Array<Province>>;
  /** Reads a set of `Region`. */
  allRegionsList?: Maybe<Array<Region>>;
  /** Reads a set of `ReleaseLog`. */
  allReleaseLogsList?: Maybe<Array<ReleaseLog>>;
  /** Reads a set of `Release`. */
  allReleasesList?: Maybe<Array<Release>>;
  /** Reads a set of `Sale`. */
  allSalesList?: Maybe<Array<Sale>>;
  /** Reads a set of `Single`. */
  allSinglesList?: Maybe<Array<Single>>;
  /** Reads a set of `TourDateType`. */
  allTourDateTypesList?: Maybe<Array<TourDateType>>;
  /** Reads and enables pagination through a set of `TourDate`. */
  allTourDates?: Maybe<TourDatesConnection>;
  /** Reads a set of `TourDate`. */
  allTourDatesList?: Maybe<Array<TourDate>>;
  /** Reads a set of `VisibleAccountArtistBinding`. */
  allVisibleAccountArtistBindingsList?: Maybe<
    Array<VisibleAccountArtistBinding>
  >;
  /** Reads a single `Application` using its globally unique `ID`. */
  application?: Maybe<Application>;
  /** Reads a single `ApplicationBoardNote` using its globally unique `ID`. */
  applicationBoardNote?: Maybe<ApplicationBoardNote>;
  applicationBoardNoteByApplicationId?: Maybe<ApplicationBoardNote>;
  applicationByArtistIdAndFundingRoundId?: Maybe<Application>;
  applicationById?: Maybe<Application>;
  /** Reads a single `Artist` using its globally unique `ID`. */
  artist?: Maybe<Artist>;
  artistById?: Maybe<Artist>;
  artistByImportId?: Maybe<Artist>;
  artistInvitationStatus?: Maybe<ArtistInvitationStatusResponse>;
  /** Reads a single `ArtistReleaseBinding` using its globally unique `ID`. */
  artistReleaseBinding?: Maybe<ArtistReleaseBinding>;
  artistReleaseBindingByArtistIdAndReleaseId?: Maybe<ArtistReleaseBinding>;
  /** Reads a single `Attachment` using its globally unique `ID`. */
  attachment?: Maybe<Attachment>;
  /** Reads a single `AttachmentApplicationBinding` using its globally unique `ID`. */
  attachmentApplicationBinding?: Maybe<AttachmentApplicationBinding>;
  attachmentApplicationBindingByAttachmentIdAndApplicationId?: Maybe<AttachmentApplicationBinding>;
  /** Reads a single `AttachmentBoardMeetingBlockBinding` using its globally unique `ID`. */
  attachmentBoardMeetingBlockBinding?: Maybe<AttachmentBoardMeetingBlockBinding>;
  attachmentBoardMeetingBlockBindingByAttachmentIdAndBlockId?: Maybe<AttachmentBoardMeetingBlockBinding>;
  attachmentById?: Maybe<Attachment>;
  /** Reads a single `BoardMeetingBlock` using its globally unique `ID`. */
  boardMeetingBlock?: Maybe<BoardMeetingBlock>;
  boardMeetingBlockById?: Maybe<BoardMeetingBlock>;
  /** Reads a single `BoardMemberApplicationBlock` using its globally unique `ID`. */
  boardMemberApplicationBlock?: Maybe<BoardMemberApplicationBlock>;
  boardMemberApplicationBlockByAccountIdAndApplicationId?: Maybe<BoardMemberApplicationBlock>;
  boardMemberInvitationStatus?: Maybe<BoardMemberInvitationStatusResponse>;
  /** Reads a single `Contact` using its globally unique `ID`. */
  contact?: Maybe<Contact>;
  contactByApplicationIdAndEmail?: Maybe<Contact>;
  /** Reads a single `Contract` using its globally unique `ID`. */
  contract?: Maybe<Contract>;
  contractByApplicationId?: Maybe<Contract>;
  /** Reads a single `Country` using its globally unique `ID`. */
  country?: Maybe<Country>;
  countryById?: Maybe<Country>;
  createAccountInvitationStatusResponse?: Maybe<AccountInvitationStatusResponse>;
  createAddArtistResponse?: Maybe<AddArtistResponse>;
  createAddReleaseResponse?: Maybe<AddReleaseResponse>;
  createApproveApplicationAndNotifyResponse?: Maybe<ApproveApplicationAndNotifyResponse>;
  createArtistInvitationStatusResponse?: Maybe<ArtistInvitationStatusResponse>;
  createBoardMemberInvitationStatusResponse?: Maybe<BoardMemberInvitationStatusResponse>;
  createChangePasswordResponse?: Maybe<ChangePasswordResponse>;
  createCreateAccountInvitationResponse?: Maybe<CreateAccountInvitationResponse>;
  createCreateApplicationResponse?: Maybe<CreateApplicationResponse>;
  createCreateArtistAndLinkResponse?: Maybe<CreateArtistAndLinkResponse>;
  createCreateArtistInvitationResponse?: Maybe<CreateArtistInvitationResponse>;
  createCreateBoardMemberInvitationResponse?: Maybe<CreateBoardMemberInvitationResponse>;
  createDeleteArtistInvitationResponse?: Maybe<DeleteArtistInvitationResponse>;
  createDeleteBoardMemberInvitationResponse?: Maybe<DeleteBoardMemberInvitationResponse>;
  createForgotPasswordResponse?: Maybe<ForgotPasswordResponse>;
  createInsertDirectDepositAccountResponse?: Maybe<InsertDirectDepositAccountResponse>;
  createMarkApplicationCompletedResponse?: Maybe<MarkApplicationCompletedResponse>;
  createRemoveBoardMemberResponse?: Maybe<RemoveBoardMemberResponse>;
  createReopenApplicationClaimsResponse?: Maybe<ReopenApplicationClaimsResponse>;
  createResetPasswordResponse?: Maybe<ResetPasswordResponse>;
  createResetPasswordStatusResponse?: Maybe<ResetPasswordStatusResponse>;
  createResubmitApplicationClaimsResponse?: Maybe<ResubmitApplicationClaimsResponse>;
  createSubmitApplicationClaimsResponse?: Maybe<SubmitApplicationClaimsResponse>;
  createSubmitApplicationResponse?: Maybe<SubmitApplicationResponse>;
  createUpdateAccountInvitationResponse?: Maybe<UpdateAccountInvitationResponse>;
  createUpdateArtistInvitationResponse?: Maybe<UpdateArtistInvitationResponse>;
  createUpdateArtistStatusResponse?: Maybe<UpdateArtistStatusResponse>;
  createUpdateReleaseSalesResponse?: Maybe<UpdateReleaseSalesResponse>;
  createUpsertApplicationBoardNotesResponse?: Maybe<UpsertApplicationBoardNotesResponse>;
  createUpsertEligibilityNoticeResponse?: Maybe<UpsertEligibilityNoticeResponse>;
  createUpsertManagementResponse?: Maybe<UpsertManagementResponse>;
  deleteManagementDetailsResponse?: Maybe<DeleteManagementDetailsResponse>;
  /** Reads a single `DirectDepositAccount` using its globally unique `ID`. */
  directDepositAccount?: Maybe<DirectDepositAccount>;
  directDepositAccountById?: Maybe<DirectDepositAccount>;
  directDepositAccountByImportId?: Maybe<DirectDepositAccount>;
  /** Reads a single `Distributor` using its globally unique `ID`. */
  distributor?: Maybe<Distributor>;
  distributorById?: Maybe<Distributor>;
  distributorByName?: Maybe<Distributor>;
  eligibilityCutoffDate?: Maybe<Scalars['Date']>;
  /** Reads a single `EligibilityNotice` using its globally unique `ID`. */
  eligibilityNotice?: Maybe<EligibilityNotice>;
  eligibilityNoticeByArtistId?: Maybe<EligibilityNotice>;
  /** Reads a single `Expense` using its globally unique `ID`. */
  expense?: Maybe<Expense>;
  expenseById?: Maybe<Expense>;
  fiscalYearEnd?: Maybe<Scalars['Date']>;
  /** Reads a single `FundingRequest` using its globally unique `ID`. */
  fundingRequest?: Maybe<FundingRequest>;
  fundingRequestById?: Maybe<FundingRequest>;
  /** Reads a single `FundingRound` using its globally unique `ID`. */
  fundingRound?: Maybe<FundingRound>;
  fundingRoundById?: Maybe<FundingRound>;
  fundingRoundByTitle?: Maybe<FundingRound>;
  /** Reads a single `Genre` using its globally unique `ID`. */
  genre?: Maybe<Genre>;
  genreById?: Maybe<Genre>;
  getAdminApplicationLogCount?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `FundingRound`. */
  getAllActiveRoundsList?: Maybe<Array<Maybe<FundingRound>>>;
  getAllBoardMembersList?: Maybe<Array<Maybe<GetAllBoardMembersRecord>>>;
  getApplication?: Maybe<Application>;
  /** Reads and enables pagination through a set of `Application`. */
  getApplicationsByIsActiveList?: Maybe<Array<Maybe<Application>>>;
  getCurrentBoardRound?: Maybe<FundingRound>;
  getCurrentFundingRound?: Maybe<FundingRound>;
  getCurrentFundingRoundId?: Maybe<Scalars['UUID']>;
  getCurrentOrNextFundingRound?: Maybe<FundingRound>;
  getFluxCapacitor?: Maybe<Scalars['Datetime']>;
  getFundingRound?: Maybe<FundingRound>;
  getNextBoardMeetingDate?: Maybe<Scalars['Date']>;
  getNextFundingRound?: Maybe<FundingRound>;
  getNextOrionRound?: Maybe<FundingRound>;
  /** Reads and enables pagination through a set of `PendingBoardMember`. */
  getPendingBoardMembersList?: Maybe<Array<Maybe<PendingBoardMember>>>;
  getPendingEmailsList?: Maybe<Array<Maybe<GetPendingEmailsRecord>>>;
  getPreviousFundingRound?: Maybe<FundingRound>;
  /** Reads and enables pagination through a set of `FundingRound`. */
  getRoundScheduleList?: Maybe<Array<Maybe<FundingRound>>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  jsonDiffToLog?: Maybe<Scalars['String']>;
  jsonDiffToParsableLog?: Maybe<Scalars['String']>;
  /** Reads a single `Label` using its globally unique `ID`. */
  label?: Maybe<Label>;
  labelById?: Maybe<Label>;
  labelByName?: Maybe<Label>;
  /** Reads a single `LegalStatus` using its globally unique `ID`. */
  legalStatus?: Maybe<LegalStatus>;
  legalStatusById?: Maybe<LegalStatus>;
  /** Reads a single `Management` using its globally unique `ID`. */
  management?: Maybe<Management>;
  managementById?: Maybe<Management>;
  managementByImportId?: Maybe<Management>;
  maskAccount?: Maybe<Scalars['String']>;
  maximumFundingAmount?: Maybe<Scalars['Int']>;
  minimumPasswordLength?: Maybe<Scalars['Int']>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  now?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Payment` using its globally unique `ID`. */
  payment?: Maybe<Payment>;
  paymentById?: Maybe<Payment>;
  presentCutoffDate?: Maybe<Scalars['Date']>;
  /** Reads a single `Province` using its globally unique `ID`. */
  province?: Maybe<Province>;
  provinceById?: Maybe<Province>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads a single `Region` using its globally unique `ID`. */
  region?: Maybe<Region>;
  regionById?: Maybe<Region>;
  /** Reads a single `Release` using its globally unique `ID`. */
  release?: Maybe<Release>;
  releaseById?: Maybe<Release>;
  releaseByImportId?: Maybe<Release>;
  releasesScannedDivisor?: Maybe<Scalars['Int']>;
  reportAnnualGenre?: Maybe<Scalars['JSON']>;
  reportAnnualNewArtists?: Maybe<Scalars['JSON']>;
  reportAnnualProgram?: Maybe<Scalars['JSON']>;
  reportAnnualProvince?: Maybe<Scalars['JSON']>;
  reportAnnualSubmitted?: Maybe<Scalars['JSON']>;
  resetPasswordStatus?: Maybe<ResetPasswordStatusResponse>;
  /** Reads a single `Sale` using its globally unique `ID`. */
  sale?: Maybe<Sale>;
  saleById?: Maybe<Sale>;
  saleByImportId?: Maybe<Sale>;
  searchAccountsList?: Maybe<Array<Maybe<SearchAccountsRecord>>>;
  searchAdminArtistsList?: Maybe<Array<Maybe<SearchAdminArtistsRecord>>>;
  searchApplicationsList?: Maybe<Array<Maybe<SearchApplicationsRecord>>>;
  /** Reads and enables pagination through a set of `Artist`. */
  searchArtistsList?: Maybe<Array<Maybe<Artist>>>;
  /** Reads and enables pagination through a set of `Label`. */
  searchLabelsList?: Maybe<Array<Maybe<Label>>>;
  searchSubscribersList?: Maybe<Array<Maybe<SearchSubscribersRecord>>>;
  /** Reads a single `Single` using its globally unique `ID`. */
  single?: Maybe<Single>;
  singleById?: Maybe<Single>;
  singleByImportId?: Maybe<Single>;
  statementTimestamp?: Maybe<Scalars['Datetime']>;
  timezone?: Maybe<Scalars['String']>;
  totalNumberOfAccounts?: Maybe<Scalars['Int']>;
  /** Reads a single `TourDate` using its globally unique `ID`. */
  tourDate?: Maybe<TourDate>;
  tourDateById?: Maybe<TourDate>;
  /** Reads a single `TourDateType` using its globally unique `ID`. */
  tourDateType?: Maybe<TourDateType>;
  tourDateTypeById?: Maybe<TourDateType>;
  tracksDownloadedDivisor?: Maybe<Scalars['Int']>;
  tracksStreamedDivisor?: Maybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountArtistBindingArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountArtistBindingByAccountIdAndArtistIdArgs = {
  accountId: Scalars['UUID'];
  artistId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountByEmailArgs = {
  email: Scalars['Email'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountInvitationStatusArgs = {
  tag: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountPasswordArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountPasswordByAccountIdArgs = {
  accountId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAccountArtistBindingsListArgs = {
  condition?: InputMaybe<AccountArtistBindingCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountArtistBindingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAccountLogsListArgs = {
  condition?: InputMaybe<AccountLogCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountLogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAccountPasswordsListArgs = {
  condition?: InputMaybe<AccountPasswordCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountPasswordsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAccountsListArgs = {
  condition?: InputMaybe<AccountCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllApplicationBoardNotesListArgs = {
  condition?: InputMaybe<ApplicationBoardNoteCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ApplicationBoardNotesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllApplicationsListArgs = {
  condition?: InputMaybe<ApplicationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ApplicationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllArtistLogsListArgs = {
  condition?: InputMaybe<ArtistLogCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistLogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllArtistReleaseBindingsListArgs = {
  condition?: InputMaybe<ArtistReleaseBindingCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistReleaseBindingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllArtistsListArgs = {
  condition?: InputMaybe<ArtistCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAttachmentApplicationBindingsListArgs = {
  condition?: InputMaybe<AttachmentApplicationBindingCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AttachmentApplicationBindingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAttachmentBoardMeetingBlockBindingsListArgs = {
  condition?: InputMaybe<AttachmentBoardMeetingBlockBindingCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AttachmentBoardMeetingBlockBindingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAttachmentsListArgs = {
  condition?: InputMaybe<AttachmentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AttachmentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBoardMeetingBlocksListArgs = {
  condition?: InputMaybe<BoardMeetingBlockCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BoardMeetingBlocksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBoardMemberApplicationBlocksListArgs = {
  condition?: InputMaybe<BoardMemberApplicationBlockCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BoardMemberApplicationBlocksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllContactsListArgs = {
  condition?: InputMaybe<ContactCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllContractsListArgs = {
  condition?: InputMaybe<ContractCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContractsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCountriesListArgs = {
  condition?: InputMaybe<CountryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDirectDepositAccountsListArgs = {
  condition?: InputMaybe<DirectDepositAccountCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DirectDepositAccountsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDistributorsListArgs = {
  condition?: InputMaybe<DistributorCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DistributorsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEligibilityNoticesListArgs = {
  condition?: InputMaybe<EligibilityNoticeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EligibilityNoticesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllExpensesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExpenseCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExpensesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllExpensesListArgs = {
  condition?: InputMaybe<ExpenseCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExpensesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFundingRequestsListArgs = {
  condition?: InputMaybe<FundingRequestCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FundingRequestsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFundingRoundsListArgs = {
  condition?: InputMaybe<FundingRoundCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FundingRoundsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllGenresListArgs = {
  condition?: InputMaybe<GenreCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLabelsListArgs = {
  condition?: InputMaybe<LabelCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LabelsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLegalStatusesListArgs = {
  condition?: InputMaybe<LegalStatusCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegalStatusesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllManagementsListArgs = {
  condition?: InputMaybe<ManagementCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ManagementsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllPaymentsListArgs = {
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllProvincesListArgs = {
  condition?: InputMaybe<ProvinceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProvincesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllRegionsListArgs = {
  condition?: InputMaybe<RegionCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RegionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllReleaseLogsListArgs = {
  condition?: InputMaybe<ReleaseLogCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReleaseLogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllReleasesListArgs = {
  condition?: InputMaybe<ReleaseCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReleasesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSalesListArgs = {
  condition?: InputMaybe<SaleCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SalesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSinglesListArgs = {
  condition?: InputMaybe<SingleCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SinglesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTourDateTypesListArgs = {
  condition?: InputMaybe<TourDateTypeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TourDateTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTourDatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TourDateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TourDatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTourDatesListArgs = {
  condition?: InputMaybe<TourDateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TourDatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllVisibleAccountArtistBindingsListArgs = {
  condition?: InputMaybe<VisibleAccountArtistBindingCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VisibleAccountArtistBindingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationBoardNoteArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationBoardNoteByApplicationIdArgs = {
  applicationId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationByArtistIdAndFundingRoundIdArgs = {
  artistId: Scalars['UUID'];
  fundingRoundId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistByImportIdArgs = {
  importId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistInvitationStatusArgs = {
  tag: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistReleaseBindingArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryArtistReleaseBindingByArtistIdAndReleaseIdArgs = {
  artistId: Scalars['UUID'];
  releaseId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAttachmentArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAttachmentApplicationBindingArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAttachmentApplicationBindingByAttachmentIdAndApplicationIdArgs =
  {
    applicationId: Scalars['UUID'];
    attachmentId: Scalars['UUID'];
  };

/** The root query type which gives access points into the data universe. */
export type QueryAttachmentBoardMeetingBlockBindingArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAttachmentBoardMeetingBlockBindingByAttachmentIdAndBlockIdArgs =
  {
    attachmentId: Scalars['UUID'];
    blockId: Scalars['UUID'];
  };

/** The root query type which gives access points into the data universe. */
export type QueryAttachmentByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBoardMeetingBlockArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBoardMeetingBlockByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBoardMemberApplicationBlockArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBoardMemberApplicationBlockByAccountIdAndApplicationIdArgs = {
  accountId: Scalars['UUID'];
  applicationId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBoardMemberInvitationStatusArgs = {
  tag: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryContactArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryContactByApplicationIdAndEmailArgs = {
  applicationId: Scalars['UUID'];
  email: Scalars['Email'];
};

/** The root query type which gives access points into the data universe. */
export type QueryContractArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryContractByApplicationIdArgs = {
  applicationId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCountryArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCountryByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateAccountInvitationStatusResponseArgs = {
  code: AccountInvitationStatusResponseCode;
  email: Scalars['String'];
  isOrion: Scalars['Boolean'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateAddArtistResponseArgs = {
  artistId: Scalars['String'];
  code: AddArtistResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateAddReleaseResponseArgs = {
  code: AddReleaseResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateApproveApplicationAndNotifyResponseArgs = {
  code: ApproveApplicationAndNotifyResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateArtistInvitationStatusResponseArgs = {
  code: ArtistInvitationStatusResponseCode;
  email: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateBoardMemberInvitationStatusResponseArgs = {
  code: BoardMemberInvitationStatusResponseCode;
  email: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateChangePasswordResponseArgs = {
  code: ChangePasswordResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateCreateAccountInvitationResponseArgs = {
  code: CreateAccountInvitationResponseCode;
  invitationId: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateCreateApplicationResponseArgs = {
  code: CreateApplicationResponseCode;
  id: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateCreateArtistAndLinkResponseArgs = {
  code: CreateArtistAndLinkResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateCreateArtistInvitationResponseArgs = {
  code: CreateArtistInvitationResponseCode;
  email: Scalars['String'];
  invitationId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateCreateBoardMemberInvitationResponseArgs = {
  accountId: Scalars['UUID'];
  code: CreateBoardMemberInvitationResponseCode;
  email: Scalars['String'];
  name: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateDeleteArtistInvitationResponseArgs = {
  code: DeleteArtistInvitationResponseCode;
  email: Scalars['String'];
  invitationId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateDeleteBoardMemberInvitationResponseArgs = {
  email: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateForgotPasswordResponseArgs = {
  code: ForgotPasswordResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateInsertDirectDepositAccountResponseArgs = {
  code: InsertDirectDepositAccountResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateMarkApplicationCompletedResponseArgs = {
  code: MarkApplicationCompletedResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateRemoveBoardMemberResponseArgs = {
  accountId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateReopenApplicationClaimsResponseArgs = {
  code: ReopenApplicationClaimsResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateResetPasswordResponseArgs = {
  code: ResetPasswordResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateResetPasswordStatusResponseArgs = {
  code: ResetPasswordStatusResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateResubmitApplicationClaimsResponseArgs = {
  code: ResubmitApplicationClaimsResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateSubmitApplicationClaimsResponseArgs = {
  code: SubmitApplicationClaimsResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateSubmitApplicationResponseArgs = {
  code: SubmitApplicationResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateUpdateAccountInvitationResponseArgs = {
  code: UpdateAccountInvitationResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateUpdateArtistInvitationResponseArgs = {
  code: UpdateArtistInvitationResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateUpdateArtistStatusResponseArgs = {
  code: UpdateArtistStatusResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateUpdateReleaseSalesResponseArgs = {
  code: UpdateReleaseSalesResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateUpsertApplicationBoardNotesResponseArgs = {
  code: UpsertApplicationBoardNotesResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateUpsertEligibilityNoticeResponseArgs = {
  code: UpsertEligibilityNoticeResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreateUpsertManagementResponseArgs = {
  code: UpsertManagementResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryDeleteManagementDetailsResponseArgs = {
  code: DeleteManagementDetailsResponseCode;
};

/** The root query type which gives access points into the data universe. */
export type QueryDirectDepositAccountArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDirectDepositAccountByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDirectDepositAccountByImportIdArgs = {
  importId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDistributorArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDistributorByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDistributorByNameArgs = {
  name: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEligibilityCutoffDateArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEligibilityNoticeArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEligibilityNoticeByArtistIdArgs = {
  artistId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryExpenseArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryExpenseByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFiscalYearEndArgs = {
  year?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFundingRequestArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFundingRequestByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFundingRoundArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFundingRoundByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFundingRoundByTitleArgs = {
  title: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGenreArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGenreByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGetAdminApplicationLogCountArgs = {
  applicationId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGetAllActiveRoundsListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  nowDate?: InputMaybe<Scalars['Datetime']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetAllBoardMembersListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetApplicationArgs = {
  applicationId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGetApplicationsByIsActiveListArgs = {
  artistId: Scalars['UUID'];
  first?: InputMaybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  offset?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetCurrentBoardRoundArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetCurrentFundingRoundArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetCurrentFundingRoundIdArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetCurrentOrNextFundingRoundArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetFundingRoundArgs = {
  fundingRoundId: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGetNextBoardMeetingDateArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetNextFundingRoundArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetNextOrionRoundArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetPendingBoardMembersListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetPendingEmailsListArgs = {
  artistId: Scalars['UUID'];
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetPreviousFundingRoundArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetRoundScheduleListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  nowDate?: InputMaybe<Scalars['Datetime']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryJsonDiffToLogArgs = {
  new: Scalars['JSON'];
  old: Scalars['JSON'];
  sensitiveFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryJsonDiffToParsableLogArgs = {
  ignoreFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  itemName: Scalars['String'];
  new: Scalars['JSON'];
  old: Scalars['JSON'];
  sensitiveFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLabelArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLabelByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLabelByNameArgs = {
  name: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLegalStatusArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLegalStatusByIdArgs = {
  id: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryManagementArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryManagementByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryManagementByImportIdArgs = {
  importId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMaskAccountArgs = {
  account: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPaymentArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPaymentByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPresentCutoffDateArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryProvinceArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProvinceByIdArgs = {
  id: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryRegionArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryRegionByIdArgs = {
  id: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryReleaseArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryReleaseByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryReleaseByImportIdArgs = {
  importId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryReportAnnualGenreArgs = {
  year?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryReportAnnualNewArtistsArgs = {
  year?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryReportAnnualProgramArgs = {
  year?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryReportAnnualProvinceArgs = {
  year?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryReportAnnualSubmittedArgs = {
  year?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryResetPasswordStatusArgs = {
  tag: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySaleArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySaleByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySaleByImportIdArgs = {
  importId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchAccountsListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pLimit: Scalars['Int'];
  pOffset: Scalars['Int'];
  search: Scalars['String'];
  sort: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchAdminArtistsListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  isActivitySlowing: Scalars['Boolean'];
  isEligible: Scalars['Boolean'];
  isIneligible: Scalars['Boolean'];
  isOrionPending: Scalars['Boolean'];
  nearing600KFunding: Scalars['Boolean'];
  offset?: InputMaybe<Scalars['Int']>;
  over400KFunding: Scalars['Boolean'];
  pLimit: Scalars['Int'];
  pOffset: Scalars['Int'];
  searchTerm: Scalars['String'];
  sortMode: SearchAdminArtistsSortOrder;
  under400KFunding: Scalars['Boolean'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchApplicationsListArgs = {
  alternateRequest?: InputMaybe<
    Array<InputMaybe<SearchApplicationsAlternateRequest>>
  >;
  applicationStatus?: InputMaybe<ApplicationStatus>;
  applicationType?: InputMaybe<ApplicationType>;
  first?: InputMaybe<Scalars['Int']>;
  initialPaymentPaid?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy: SearchApplicationsSortOrder;
  resultLimit: Scalars['Int'];
  resultOffset: Scalars['Int'];
  reviewStatus?: InputMaybe<Array<InputMaybe<ApplicationReviewStatus>>>;
  roundTitle?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Array<InputMaybe<ApplicationStage>>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchArtistsListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchLabelsListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchSubscribersListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pLimit: Scalars['Int'];
  pOffset: Scalars['Int'];
  search: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySingleArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySingleByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySingleByImportIdArgs = {
  importId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTourDateArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTourDateByIdArgs = {
  id: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTourDateTypeArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTourDateTypeByIdArgs = {
  id: Scalars['String'];
};

export type Region = Node & {
  __typename?: 'Region';
  /** Reads and enables pagination through a set of `Country`. */
  countriesByRegionIdList: Array<Country>;
  displayOrder: Scalars['Int'];
  id: Scalars['String'];
  maximumAllowedYearlyDates?: Maybe<Scalars['Int']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  title: Scalars['String'];
};

export type RegionCountriesByRegionIdListArgs = {
  condition?: InputMaybe<CountryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

/** A condition to be used against `Region` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RegionCondition = {
  /** Checks for equality with the object’s `displayOrder` field. */
  displayOrder?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `maximumAllowedYearlyDates` field. */
  maximumAllowedYearlyDates?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Region` */
export type RegionInput = {
  displayOrder?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  maximumAllowedYearlyDates?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
};

/** Represents an update to a `Region`. Fields that are set will be updated. */
export type RegionPatch = {
  displayOrder?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  maximumAllowedYearlyDates?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Region`. */
export enum RegionsOrderBy {
  DisplayOrderAsc = 'DISPLAY_ORDER_ASC',
  DisplayOrderDesc = 'DISPLAY_ORDER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MaximumAllowedYearlyDatesAsc = 'MAXIMUM_ALLOWED_YEARLY_DATES_ASC',
  MaximumAllowedYearlyDatesDesc = 'MAXIMUM_ALLOWED_YEARLY_DATES_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
}

export type Release = Node & {
  __typename?: 'Release';
  aboveSalesRequirement?: Maybe<Scalars['Boolean']>;
  address: Scalars['String'];
  /** Reads and enables pagination through a set of `ArtistReleaseBinding`. */
  artistReleaseBindingsByReleaseIdList: Array<ArtistReleaseBinding>;
  company: Scalars['String'];
  contact: Scalars['String'];
  createdAt: Scalars['Datetime'];
  date: Scalars['Date'];
  /** Reads a single `Distributor` that is related to this `Release`. */
  distributorByDistributorId?: Maybe<Distributor>;
  distributorId: Scalars['UUID'];
  email: Scalars['Email'];
  /** Reads a single `Genre` that is related to this `Release`. */
  genreByGenreId?: Maybe<Genre>;
  genreId: Scalars['UUID'];
  hasTooManySales?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  importId?: Maybe<Scalars['Int']>;
  inCanada: Scalars['Boolean'];
  isEligible?: Maybe<Scalars['Boolean']>;
  isMajorityEnglish: Scalars['Boolean'];
  isOwner?: Maybe<Scalars['Boolean']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isUnbound?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Label` that is related to this `Release`. */
  labelByLabelId?: Maybe<Label>;
  labelId: Scalars['UUID'];
  meetsDateRequirement?: Maybe<Scalars['Boolean']>;
  meetsLanguageRequirement?: Maybe<Scalars['Boolean']>;
  meetsMasterRequirement?: Maybe<Scalars['Boolean']>;
  meetsMinutesRequirement?: Maybe<Scalars['Boolean']>;
  meetsRecordingRequirement?: Maybe<Scalars['Boolean']>;
  meetsSalesRequirement?: Maybe<Scalars['Boolean']>;
  meetsTracksRequirement?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  notes: Scalars['String'];
  numberOfMinutes: Scalars['Int'];
  numberOfTracks: Scalars['Int'];
  otherDistributor?: Maybe<Scalars['String']>;
  otherLabel?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  /** Reads and enables pagination through a set of `Sale`. */
  salesByReleaseIdList: Array<Sale>;
  /** Reads and enables pagination through a set of `Single`. */
  singlesByReleaseIdList: Array<Single>;
  title: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  website: Scalars['String'];
};

export type ReleaseArtistReleaseBindingsByReleaseIdListArgs = {
  condition?: InputMaybe<ArtistReleaseBindingCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArtistReleaseBindingsOrderBy>>;
};

export type ReleaseIsEligibleArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ReleaseMeetsDateRequirementArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type ReleaseSalesByReleaseIdListArgs = {
  condition?: InputMaybe<SaleCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SalesOrderBy>>;
};

export type ReleaseSinglesByReleaseIdListArgs = {
  condition?: InputMaybe<SingleCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SinglesOrderBy>>;
};

/** A condition to be used against `Release` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ReleaseCondition = {
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `company` field. */
  company?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `contact` field. */
  contact?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `distributorId` field. */
  distributorId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['Email']>;
  /** Checks for equality with the object’s `genreId` field. */
  genreId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `importId` field. */
  importId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `inCanada` field. */
  inCanada?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isMajorityEnglish` field. */
  isMajorityEnglish?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `labelId` field. */
  labelId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `numberOfMinutes` field. */
  numberOfMinutes?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `numberOfTracks` field. */
  numberOfTracks?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `otherDistributor` field. */
  otherDistributor?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `otherLabel` field. */
  otherLabel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `website` field. */
  website?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Release` */
export type ReleaseInput = {
  address: Scalars['String'];
  company: Scalars['String'];
  contact: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  date: Scalars['Date'];
  distributorId: Scalars['UUID'];
  email: Scalars['Email'];
  genreId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  inCanada: Scalars['Boolean'];
  isMajorityEnglish: Scalars['Boolean'];
  labelId: Scalars['UUID'];
  notes: Scalars['String'];
  numberOfMinutes: Scalars['Int'];
  numberOfTracks: Scalars['Int'];
  otherDistributor?: InputMaybe<Scalars['String']>;
  otherLabel?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  website: Scalars['String'];
};

export type ReleaseLog = {
  __typename?: 'ReleaseLog';
  logAccountId?: Maybe<Scalars['UUID']>;
  logMessage?: Maybe<Scalars['String']>;
  logRole?: Maybe<Scalars['String']>;
  logStatementDate?: Maybe<Scalars['Datetime']>;
  logTransactionDate?: Maybe<Scalars['Datetime']>;
  relation?: Maybe<Scalars['String']>;
  releaseId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `ReleaseLog` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ReleaseLogCondition = {
  /** Checks for equality with the object’s `logAccountId` field. */
  logAccountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `logMessage` field. */
  logMessage?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `logRole` field. */
  logRole?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `logStatementDate` field. */
  logStatementDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `logTransactionDate` field. */
  logTransactionDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `relation` field. */
  relation?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `releaseId` field. */
  releaseId?: InputMaybe<Scalars['UUID']>;
};

/** Methods to use when ordering `ReleaseLog`. */
export enum ReleaseLogsOrderBy {
  LogAccountIdAsc = 'LOG_ACCOUNT_ID_ASC',
  LogAccountIdDesc = 'LOG_ACCOUNT_ID_DESC',
  LogMessageAsc = 'LOG_MESSAGE_ASC',
  LogMessageDesc = 'LOG_MESSAGE_DESC',
  LogRoleAsc = 'LOG_ROLE_ASC',
  LogRoleDesc = 'LOG_ROLE_DESC',
  LogStatementDateAsc = 'LOG_STATEMENT_DATE_ASC',
  LogStatementDateDesc = 'LOG_STATEMENT_DATE_DESC',
  LogTransactionDateAsc = 'LOG_TRANSACTION_DATE_ASC',
  LogTransactionDateDesc = 'LOG_TRANSACTION_DATE_DESC',
  Natural = 'NATURAL',
  RelationAsc = 'RELATION_ASC',
  RelationDesc = 'RELATION_DESC',
  ReleaseIdAsc = 'RELEASE_ID_ASC',
  ReleaseIdDesc = 'RELEASE_ID_DESC',
}

/** Represents an update to a `Release`. Fields that are set will be updated. */
export type ReleasePatch = {
  address?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  date?: InputMaybe<Scalars['Date']>;
  distributorId?: InputMaybe<Scalars['UUID']>;
  email?: InputMaybe<Scalars['Email']>;
  genreId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  inCanada?: InputMaybe<Scalars['Boolean']>;
  isMajorityEnglish?: InputMaybe<Scalars['Boolean']>;
  labelId?: InputMaybe<Scalars['UUID']>;
  notes?: InputMaybe<Scalars['String']>;
  numberOfMinutes?: InputMaybe<Scalars['Int']>;
  numberOfTracks?: InputMaybe<Scalars['Int']>;
  otherDistributor?: InputMaybe<Scalars['String']>;
  otherLabel?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Release`. */
export enum ReleasesOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  CompanyAsc = 'COMPANY_ASC',
  CompanyDesc = 'COMPANY_DESC',
  ContactAsc = 'CONTACT_ASC',
  ContactDesc = 'CONTACT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  DistributorIdAsc = 'DISTRIBUTOR_ID_ASC',
  DistributorIdDesc = 'DISTRIBUTOR_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  GenreIdAsc = 'GENRE_ID_ASC',
  GenreIdDesc = 'GENRE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportIdAsc = 'IMPORT_ID_ASC',
  ImportIdDesc = 'IMPORT_ID_DESC',
  InCanadaAsc = 'IN_CANADA_ASC',
  InCanadaDesc = 'IN_CANADA_DESC',
  IsMajorityEnglishAsc = 'IS_MAJORITY_ENGLISH_ASC',
  IsMajorityEnglishDesc = 'IS_MAJORITY_ENGLISH_DESC',
  LabelIdAsc = 'LABEL_ID_ASC',
  LabelIdDesc = 'LABEL_ID_DESC',
  Natural = 'NATURAL',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  NumberOfMinutesAsc = 'NUMBER_OF_MINUTES_ASC',
  NumberOfMinutesDesc = 'NUMBER_OF_MINUTES_DESC',
  NumberOfTracksAsc = 'NUMBER_OF_TRACKS_ASC',
  NumberOfTracksDesc = 'NUMBER_OF_TRACKS_DESC',
  OtherDistributorAsc = 'OTHER_DISTRIBUTOR_ASC',
  OtherDistributorDesc = 'OTHER_DISTRIBUTOR_DESC',
  OtherLabelAsc = 'OTHER_LABEL_ASC',
  OtherLabelDesc = 'OTHER_LABEL_DESC',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  WebsiteAsc = 'WEBSITE_ASC',
  WebsiteDesc = 'WEBSITE_DESC',
}

/** All input for the `removeBoardMember` mutation. */
export type RemoveBoardMemberInput = {
  accountId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `removeBoardMember` mutation. */
export type RemoveBoardMemberPayload = {
  __typename?: 'RemoveBoardMemberPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  removeBoardMemberResponse?: Maybe<RemoveBoardMemberResponse>;
};

export type RemoveBoardMemberResponse = {
  __typename?: 'RemoveBoardMemberResponse';
  accountId?: Maybe<Scalars['UUID']>;
};

/** All input for the `reopenApplicationClaims` mutation. */
export type ReopenApplicationClaimsInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  notes: Scalars['String'];
};

/** The output of our `reopenApplicationClaims` mutation. */
export type ReopenApplicationClaimsPayload = {
  __typename?: 'ReopenApplicationClaimsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  reopenApplicationClaimsResponse?: Maybe<ReopenApplicationClaimsResponse>;
};

export type ReopenApplicationClaimsResponse = {
  __typename?: 'ReopenApplicationClaimsResponse';
  code?: Maybe<ReopenApplicationClaimsResponseCode>;
};

export enum ReopenApplicationClaimsResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

/** All input for the `resetPassword` mutation. */
export type ResetPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  tag: Scalars['String'];
};

/** The output of our `resetPassword` mutation. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  resetPasswordResponse?: Maybe<ResetPasswordResponse>;
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  code?: Maybe<ResetPasswordResponseCode>;
};

export enum ResetPasswordResponseCode {
  InvalidPassword = 'INVALID_PASSWORD',
  InvalidTag = 'INVALID_TAG',
  Success = 'SUCCESS',
}

export type ResetPasswordStatusResponse = {
  __typename?: 'ResetPasswordStatusResponse';
  code?: Maybe<ResetPasswordStatusResponseCode>;
};

export enum ResetPasswordStatusResponseCode {
  InvalidTag = 'INVALID_TAG',
  Success = 'SUCCESS',
}

/** All input for the `resubmitApplicationClaims` mutation. */
export type ResubmitApplicationClaimsInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `resubmitApplicationClaims` mutation. */
export type ResubmitApplicationClaimsPayload = {
  __typename?: 'ResubmitApplicationClaimsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  resubmitApplicationClaimsResponse?: Maybe<ResubmitApplicationClaimsResponse>;
};

export type ResubmitApplicationClaimsResponse = {
  __typename?: 'ResubmitApplicationClaimsResponse';
  code?: Maybe<ResubmitApplicationClaimsResponseCode>;
};

export enum ResubmitApplicationClaimsResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

export type Sale = Node & {
  __typename?: 'Sale';
  aboveGenreRequirement?: Maybe<Scalars['Boolean']>;
  belowGenreRequirement?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  importId?: Maybe<Scalars['Int']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  meetsGenreOrionRequirement?: Maybe<Scalars['Boolean']>;
  meetsGenreRequirement?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Release` that is related to this `Sale`. */
  releaseByReleaseId?: Maybe<Release>;
  releaseId?: Maybe<Scalars['UUID']>;
  releasesScanned: Scalars['Int'];
  totalUnits?: Maybe<Scalars['Int']>;
  tracksDownloaded: Scalars['Int'];
  tracksStreamed: Scalars['Int'];
};

/** A condition to be used against `Sale` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SaleCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `importId` field. */
  importId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `releaseId` field. */
  releaseId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `releasesScanned` field. */
  releasesScanned?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `tracksDownloaded` field. */
  tracksDownloaded?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `tracksStreamed` field. */
  tracksStreamed?: InputMaybe<Scalars['Int']>;
};

/** An input for mutations affecting `Sale` */
export type SaleInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  releaseId?: InputMaybe<Scalars['UUID']>;
  releasesScanned: Scalars['Int'];
  tracksDownloaded: Scalars['Int'];
  tracksStreamed: Scalars['Int'];
};

/** Represents an update to a `Sale`. Fields that are set will be updated. */
export type SalePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  releaseId?: InputMaybe<Scalars['UUID']>;
  releasesScanned?: InputMaybe<Scalars['Int']>;
  tracksDownloaded?: InputMaybe<Scalars['Int']>;
  tracksStreamed?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `Sale`. */
export enum SalesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportIdAsc = 'IMPORT_ID_ASC',
  ImportIdDesc = 'IMPORT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReleasesScannedAsc = 'RELEASES_SCANNED_ASC',
  ReleasesScannedDesc = 'RELEASES_SCANNED_DESC',
  ReleaseIdAsc = 'RELEASE_ID_ASC',
  ReleaseIdDesc = 'RELEASE_ID_DESC',
  TracksDownloadedAsc = 'TRACKS_DOWNLOADED_ASC',
  TracksDownloadedDesc = 'TRACKS_DOWNLOADED_DESC',
  TracksStreamedAsc = 'TRACKS_STREAMED_ASC',
  TracksStreamedDesc = 'TRACKS_STREAMED_DESC',
}

/** The return type of our `searchAccounts` query. */
export type SearchAccountsRecord = {
  __typename?: 'SearchAccountsRecord';
  count?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Email']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

/** The return type of our `searchAdminArtists` query. */
export type SearchAdminArtistsRecord = {
  __typename?: 'SearchAdminArtistsRecord';
  createdAt?: Maybe<Scalars['Datetime']>;
  eligibility?: Maybe<ArtistEligibility>;
  id?: Maybe<Scalars['UUID']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  releases?: Maybe<Scalars['JSON']>;
  status?: Maybe<ArtistStatus>;
  total?: Maybe<Scalars['BigInt']>;
};

export enum SearchAdminArtistsSortOrder {
  ArtistNameAsc = 'ARTIST_NAME_ASC',
  ArtistNameDesc = 'ARTIST_NAME_DESC',
  LatestModified = 'LATEST_MODIFIED',
  RecentlyAdded = 'RECENTLY_ADDED',
}

export enum SearchApplicationsAlternateRequest {
  Pending = 'PENDING',
  Unsettled = 'UNSETTLED',
}

/** The return type of our `searchApplications` query. */
export type SearchApplicationsRecord = {
  __typename?: 'SearchApplicationsRecord';
  applications?: Maybe<Array<Maybe<Application>>>;
  total?: Maybe<Scalars['Int']>;
};

export enum SearchApplicationsSortOrder {
  ArtistNameAsc = 'ARTIST_NAME_ASC',
  ArtistNameDesc = 'ARTIST_NAME_DESC',
  ModifiedDesc = 'MODIFIED_DESC',
  SubmittedAtDesc = 'SUBMITTED_AT_DESC',
}

/** The return type of our `searchSubscribers` query. */
export type SearchSubscribersRecord = {
  __typename?: 'SearchSubscribersRecord';
  count?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Email']>;
  tag?: Maybe<Scalars['UUID']>;
};

/** All input for the `setFluxCapacitor` mutation. */
export type SetFluxCapacitorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  newNow: Scalars['Datetime'];
};

/** The output of our `setFluxCapacitor` mutation. */
export type SetFluxCapacitorPayload = {
  __typename?: 'SetFluxCapacitorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['Datetime']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Single = Node & {
  __typename?: 'Single';
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  importId?: Maybe<Scalars['Int']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Release` that is related to this `Single`. */
  releaseByReleaseId?: Maybe<Release>;
  releaseId: Scalars['UUID'];
  spins: Scalars['Int'];
  title: Scalars['String'];
};

/** A condition to be used against `Single` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SingleCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `importId` field. */
  importId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `releaseId` field. */
  releaseId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `spins` field. */
  spins?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `Single` */
export type SingleInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  releaseId: Scalars['UUID'];
  spins: Scalars['Int'];
  title: Scalars['String'];
};

/** Represents an update to a `Single`. Fields that are set will be updated. */
export type SinglePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  importId?: InputMaybe<Scalars['Int']>;
  releaseId?: InputMaybe<Scalars['UUID']>;
  spins?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Single`. */
export enum SinglesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportIdAsc = 'IMPORT_ID_ASC',
  ImportIdDesc = 'IMPORT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReleaseIdAsc = 'RELEASE_ID_ASC',
  ReleaseIdDesc = 'RELEASE_ID_DESC',
  SpinsAsc = 'SPINS_ASC',
  SpinsDesc = 'SPINS_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
}

/** All input for the `submitApplicationClaims` mutation. */
export type SubmitApplicationClaimsInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `submitApplicationClaims` mutation. */
export type SubmitApplicationClaimsPayload = {
  __typename?: 'SubmitApplicationClaimsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  submitApplicationClaimsResponse?: Maybe<SubmitApplicationClaimsResponse>;
};

export type SubmitApplicationClaimsResponse = {
  __typename?: 'SubmitApplicationClaimsResponse';
  code?: Maybe<SubmitApplicationClaimsResponseCode>;
};

export enum SubmitApplicationClaimsResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

/** All input for the `submitApplication` mutation. */
export type SubmitApplicationInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `submitApplication` mutation. */
export type SubmitApplicationPayload = {
  __typename?: 'SubmitApplicationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  submitApplicationResponse?: Maybe<SubmitApplicationResponse>;
};

export type SubmitApplicationResponse = {
  __typename?: 'SubmitApplicationResponse';
  code?: Maybe<SubmitApplicationResponseCode>;
};

export enum SubmitApplicationResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

/** All input for the `subscribeToNewsletter` mutation. */
export type SubscribeToNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['Email'];
};

/** The output of our `subscribeToNewsletter` mutation. */
export type SubscribeToNewsletterPayload = {
  __typename?: 'SubscribeToNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `swapBoardMeetingBlockOrder` mutation. */
export type SwapBoardMeetingBlockOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentBlock: BoardMeetingBlockInput;
  swappingBlock: BoardMeetingBlockInput;
};

/** The output of our `swapBoardMeetingBlockOrder` mutation. */
export type SwapBoardMeetingBlockOrderPayload = {
  __typename?: 'SwapBoardMeetingBlockOrderPayload';
  boardMeetingBlocks?: Maybe<Array<Maybe<BoardMeetingBlock>>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type TourDate = Node & {
  __typename?: 'TourDate';
  alternate?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Application` that is related to this `TourDate`. */
  applicationByApplicationId?: Maybe<Application>;
  applicationId: Scalars['UUID'];
  averageTicketPrice: Scalars['BigFloat'];
  bookingAgency: Scalars['String'];
  bookingAgent: Scalars['String'];
  cancelled: Scalars['Boolean'];
  confirmed: Scalars['Boolean'];
  /** Reads a single `Country` that is related to this `TourDate`. */
  countryByTourDateCountryId?: Maybe<Country>;
  countrySubdivision: Scalars['String'];
  createdAt: Scalars['Datetime'];
  date: Scalars['Date'];
  id: Scalars['UUID'];
  isCancellable?: Maybe<Scalars['Boolean']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  isTooMany?: Maybe<Scalars['Boolean']>;
  isTooNew?: Maybe<Scalars['Boolean']>;
  isTooOld?: Maybe<Scalars['Boolean']>;
  isWritable?: Maybe<Scalars['Boolean']>;
  meetsTicketedVenueCapacity?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  paymentGuarantee: Scalars['BigFloat'];
  promoter: Scalars['String'];
  reason: Scalars['String'];
  sameDateCount?: Maybe<Scalars['Int']>;
  sameDateLimit?: Maybe<Scalars['Int']>;
  status: TourDateStatus;
  tourDateCountryId: Scalars['UUID'];
  /** Reads a single `TourDateType` that is related to this `TourDate`. */
  tourDateTypeByTourDateTypeId?: Maybe<TourDateType>;
  tourDateTypeId: Scalars['String'];
  venueCapacity: Scalars['Int'];
  venueName: Scalars['String'];
};

export type TourDateIsEligibleArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type TourDateIsTooNewArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

export type TourDateIsTooOldArgs = {
  nowDate?: InputMaybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `TourDate` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TourDateCondition = {
  /** Checks for equality with the object’s `applicationId` field. */
  applicationId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `averageTicketPrice` field. */
  averageTicketPrice?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `bookingAgency` field. */
  bookingAgency?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `bookingAgent` field. */
  bookingAgent?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `cancelled` field. */
  cancelled?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `confirmed` field. */
  confirmed?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `countrySubdivision` field. */
  countrySubdivision?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `paymentGuarantee` field. */
  paymentGuarantee?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `promoter` field. */
  promoter?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `reason` field. */
  reason?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<TourDateStatus>;
  /** Checks for equality with the object’s `tourDateCountryId` field. */
  tourDateCountryId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `tourDateTypeId` field. */
  tourDateTypeId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `venueCapacity` field. */
  venueCapacity?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `venueName` field. */
  venueName?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `TourDate` */
export type TourDateInput = {
  applicationId: Scalars['UUID'];
  averageTicketPrice: Scalars['BigFloat'];
  bookingAgency: Scalars['String'];
  bookingAgent: Scalars['String'];
  cancelled?: InputMaybe<Scalars['Boolean']>;
  confirmed: Scalars['Boolean'];
  countrySubdivision: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  date: Scalars['Date'];
  id?: InputMaybe<Scalars['UUID']>;
  paymentGuarantee: Scalars['BigFloat'];
  promoter: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TourDateStatus>;
  tourDateCountryId: Scalars['UUID'];
  tourDateTypeId: Scalars['String'];
  venueCapacity: Scalars['Int'];
  venueName: Scalars['String'];
};

/** Represents an update to a `TourDate`. Fields that are set will be updated. */
export type TourDatePatch = {
  applicationId?: InputMaybe<Scalars['UUID']>;
  averageTicketPrice?: InputMaybe<Scalars['BigFloat']>;
  bookingAgency?: InputMaybe<Scalars['String']>;
  bookingAgent?: InputMaybe<Scalars['String']>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  countrySubdivision?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  date?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['UUID']>;
  paymentGuarantee?: InputMaybe<Scalars['BigFloat']>;
  promoter?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TourDateStatus>;
  tourDateCountryId?: InputMaybe<Scalars['UUID']>;
  tourDateTypeId?: InputMaybe<Scalars['String']>;
  venueCapacity?: InputMaybe<Scalars['Int']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type TourDateRegion = {
  __typename?: 'TourDateRegion';
  availableTourDates?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  maximumTourDates?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  totalTourDates?: Maybe<Scalars['Int']>;
};

export enum TourDateStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Pending = 'PENDING',
  Unsettled = 'UNSETTLED',
}

export type TourDateType = Node & {
  __typename?: 'TourDateType';
  displayorder: Scalars['Int'];
  id: Scalars['String'];
  maximumTicketedVenueCapacity?: Maybe<Scalars['Int']>;
  minimumTicketedVenueCapacity?: Maybe<Scalars['Int']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  title: Scalars['String'];
  /** Reads and enables pagination through a set of `TourDate`. */
  tourDatesByTourDateTypeId: TourDatesConnection;
  /** Reads and enables pagination through a set of `TourDate`. */
  tourDatesByTourDateTypeIdList: Array<TourDate>;
};

export type TourDateTypeTourDatesByTourDateTypeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TourDateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TourDatesOrderBy>>;
};

export type TourDateTypeTourDatesByTourDateTypeIdListArgs = {
  condition?: InputMaybe<TourDateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TourDatesOrderBy>>;
};

/**
 * A condition to be used against `TourDateType` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TourDateTypeCondition = {
  /** Checks for equality with the object’s `displayorder` field. */
  displayorder?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `maximumTicketedVenueCapacity` field. */
  maximumTicketedVenueCapacity?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minimumTicketedVenueCapacity` field. */
  minimumTicketedVenueCapacity?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
};

/** An input for mutations affecting `TourDateType` */
export type TourDateTypeInput = {
  displayorder?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  maximumTicketedVenueCapacity?: InputMaybe<Scalars['Int']>;
  minimumTicketedVenueCapacity?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
};

/** Represents an update to a `TourDateType`. Fields that are set will be updated. */
export type TourDateTypePatch = {
  displayorder?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  maximumTicketedVenueCapacity?: InputMaybe<Scalars['Int']>;
  minimumTicketedVenueCapacity?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `TourDateType`. */
export enum TourDateTypesOrderBy {
  DisplayorderAsc = 'DISPLAYORDER_ASC',
  DisplayorderDesc = 'DISPLAYORDER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MaximumTicketedVenueCapacityAsc = 'MAXIMUM_TICKETED_VENUE_CAPACITY_ASC',
  MaximumTicketedVenueCapacityDesc = 'MAXIMUM_TICKETED_VENUE_CAPACITY_DESC',
  MinimumTicketedVenueCapacityAsc = 'MINIMUM_TICKETED_VENUE_CAPACITY_ASC',
  MinimumTicketedVenueCapacityDesc = 'MINIMUM_TICKETED_VENUE_CAPACITY_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
}

/** A connection to a list of `TourDate` values. */
export type TourDatesConnection = {
  __typename?: 'TourDatesConnection';
  /** A list of edges which contains the `TourDate` and cursor to aid in pagination. */
  edges: Array<TourDatesEdge>;
  /** A list of `TourDate` objects. */
  nodes: Array<Maybe<TourDate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TourDate` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TourDate` edge in the connection. */
export type TourDatesEdge = {
  __typename?: 'TourDatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TourDate` at the end of the edge. */
  node?: Maybe<TourDate>;
};

/** Methods to use when ordering `TourDate`. */
export enum TourDatesOrderBy {
  ApplicationIdAsc = 'APPLICATION_ID_ASC',
  ApplicationIdDesc = 'APPLICATION_ID_DESC',
  AverageTicketPriceAsc = 'AVERAGE_TICKET_PRICE_ASC',
  AverageTicketPriceDesc = 'AVERAGE_TICKET_PRICE_DESC',
  BookingAgencyAsc = 'BOOKING_AGENCY_ASC',
  BookingAgencyDesc = 'BOOKING_AGENCY_DESC',
  BookingAgentAsc = 'BOOKING_AGENT_ASC',
  BookingAgentDesc = 'BOOKING_AGENT_DESC',
  CancelledAsc = 'CANCELLED_ASC',
  CancelledDesc = 'CANCELLED_DESC',
  ConfirmedAsc = 'CONFIRMED_ASC',
  ConfirmedDesc = 'CONFIRMED_DESC',
  CountrySubdivisionAsc = 'COUNTRY_SUBDIVISION_ASC',
  CountrySubdivisionDesc = 'COUNTRY_SUBDIVISION_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PaymentGuaranteeAsc = 'PAYMENT_GUARANTEE_ASC',
  PaymentGuaranteeDesc = 'PAYMENT_GUARANTEE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PromoterAsc = 'PROMOTER_ASC',
  PromoterDesc = 'PROMOTER_DESC',
  ReasonAsc = 'REASON_ASC',
  ReasonDesc = 'REASON_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TourDateCountryIdAsc = 'TOUR_DATE_COUNTRY_ID_ASC',
  TourDateCountryIdDesc = 'TOUR_DATE_COUNTRY_ID_DESC',
  TourDateTypeIdAsc = 'TOUR_DATE_TYPE_ID_ASC',
  TourDateTypeIdDesc = 'TOUR_DATE_TYPE_ID_DESC',
  VenueCapacityAsc = 'VENUE_CAPACITY_ASC',
  VenueCapacityDesc = 'VENUE_CAPACITY_DESC',
  VenueNameAsc = 'VENUE_NAME_ASC',
  VenueNameDesc = 'VENUE_NAME_DESC',
}

/** All input for the `unsubscribeFromNewsletter` mutation. */
export type UnsubscribeFromNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  tag: Scalars['UUID'];
};

/** The output of our `unsubscribeFromNewsletter` mutation. */
export type UnsubscribeFromNewsletterPayload = {
  __typename?: 'UnsubscribeFromNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAccountArtistBindingByAccountIdAndArtistId` mutation. */
export type UpdateAccountArtistBindingByAccountIdAndArtistIdInput = {
  /** An object where the defined keys will be set on the `AccountArtistBinding` being updated. */
  accountArtistBindingPatch: AccountArtistBindingPatch;
  accountId: Scalars['UUID'];
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `updateAccountArtistBinding` mutation. */
export type UpdateAccountArtistBindingInput = {
  /** An object where the defined keys will be set on the `AccountArtistBinding` being updated. */
  accountArtistBindingPatch: AccountArtistBindingPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AccountArtistBinding` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `AccountArtistBinding` mutation. */
export type UpdateAccountArtistBindingPayload = {
  __typename?: 'UpdateAccountArtistBindingPayload';
  /** The `AccountArtistBinding` that was updated by this mutation. */
  accountArtistBinding?: Maybe<AccountArtistBinding>;
  /** Reads a single `Account` that is related to this `AccountArtistBinding`. */
  accountByAccountId?: Maybe<Account>;
  /** Reads a single `Artist` that is related to this `AccountArtistBinding`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAccountByEmail` mutation. */
export type UpdateAccountByEmailInput = {
  /** An object where the defined keys will be set on the `Account` being updated. */
  accountPatch: AccountPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['Email'];
};

/** All input for the `updateAccountById` mutation. */
export type UpdateAccountByIdInput = {
  /** An object where the defined keys will be set on the `Account` being updated. */
  accountPatch: AccountPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `updateAccount` mutation. */
export type UpdateAccountInput = {
  /** An object where the defined keys will be set on the `Account` being updated. */
  accountPatch: AccountPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Account` to be updated. */
  nodeId: Scalars['ID'];
};

/** All input for the `updateAccountInvitation` mutation. */
export type UpdateAccountInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  invitationId: Scalars['String'];
};

/** The output of our `updateAccountInvitation` mutation. */
export type UpdateAccountInvitationPayload = {
  __typename?: 'UpdateAccountInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  updateAccountInvitationResponse?: Maybe<UpdateAccountInvitationResponse>;
};

export type UpdateAccountInvitationResponse = {
  __typename?: 'UpdateAccountInvitationResponse';
  code?: Maybe<UpdateAccountInvitationResponseCode>;
};

export enum UpdateAccountInvitationResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

/** All input for the `updateAccountPasswordByAccountId` mutation. */
export type UpdateAccountPasswordByAccountIdInput = {
  accountId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `AccountPassword` being updated. */
  accountPasswordPatch: AccountPasswordPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `updateAccountPassword` mutation. */
export type UpdateAccountPasswordInput = {
  /** An object where the defined keys will be set on the `AccountPassword` being updated. */
  accountPasswordPatch: AccountPasswordPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AccountPassword` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `AccountPassword` mutation. */
export type UpdateAccountPasswordPayload = {
  __typename?: 'UpdateAccountPasswordPayload';
  /** Reads a single `Account` that is related to this `AccountPassword`. */
  accountByAccountId?: Maybe<Account>;
  /** The `AccountPassword` that was updated by this mutation. */
  accountPassword?: Maybe<AccountPassword>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Account` mutation. */
export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  /** The `Account` that was updated by this mutation. */
  account?: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateApplicationBoardNoteByApplicationId` mutation. */
export type UpdateApplicationBoardNoteByApplicationIdInput = {
  /** An object where the defined keys will be set on the `ApplicationBoardNote` being updated. */
  applicationBoardNotePatch: ApplicationBoardNotePatch;
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `updateApplicationBoardNote` mutation. */
export type UpdateApplicationBoardNoteInput = {
  /** An object where the defined keys will be set on the `ApplicationBoardNote` being updated. */
  applicationBoardNotePatch: ApplicationBoardNotePatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ApplicationBoardNote` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `ApplicationBoardNote` mutation. */
export type UpdateApplicationBoardNotePayload = {
  __typename?: 'UpdateApplicationBoardNotePayload';
  /** The `ApplicationBoardNote` that was updated by this mutation. */
  applicationBoardNote?: Maybe<ApplicationBoardNote>;
  /** Reads a single `Application` that is related to this `ApplicationBoardNote`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateApplicationByArtistIdAndFundingRoundId` mutation. */
export type UpdateApplicationByArtistIdAndFundingRoundIdInput = {
  /** An object where the defined keys will be set on the `Application` being updated. */
  applicationPatch: ApplicationPatch;
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  fundingRoundId: Scalars['UUID'];
};

/** All input for the `updateApplicationById` mutation. */
export type UpdateApplicationByIdInput = {
  /** An object where the defined keys will be set on the `Application` being updated. */
  applicationPatch: ApplicationPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `updateApplication` mutation. */
export type UpdateApplicationInput = {
  /** An object where the defined keys will be set on the `Application` being updated. */
  applicationPatch: ApplicationPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Application` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Application` mutation. */
export type UpdateApplicationPayload = {
  __typename?: 'UpdateApplicationPayload';
  /** Reads a single `Account` that is related to this `Application`. */
  accountByPrimaryContactId?: Maybe<Account>;
  /** The `Application` that was updated by this mutation. */
  application?: Maybe<Application>;
  /** Reads a single `Artist` that is related to this `Application`. */
  artistByArtistId?: Maybe<Artist>;
  /** Reads a single `Attachment` that is related to this `Application`. */
  attachmentBySignedContractId?: Maybe<Attachment>;
  /** Reads a single `Attachment` that is related to this `Application`. */
  attachmentByTourSummaryId?: Maybe<Attachment>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `DirectDepositAccount` that is related to this `Application`. */
  directDepositAccountByFundingRequestDirectDepositId?: Maybe<DirectDepositAccount>;
  /** Reads a single `DirectDepositAccount` that is related to this `Application`. */
  directDepositAccountByTouringDirectDepositId?: Maybe<DirectDepositAccount>;
  /** Reads a single `FundingRound` that is related to this `Application`. */
  fundingRoundByFundingRoundId?: Maybe<FundingRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateArtistById` mutation. */
export type UpdateArtistByIdInput = {
  /** An object where the defined keys will be set on the `Artist` being updated. */
  artistPatch: ArtistPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `updateArtistByImportId` mutation. */
export type UpdateArtistByImportIdInput = {
  /** An object where the defined keys will be set on the `Artist` being updated. */
  artistPatch: ArtistPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
};

/** All input for the `updateArtist` mutation. */
export type UpdateArtistInput = {
  /** An object where the defined keys will be set on the `Artist` being updated. */
  artistPatch: ArtistPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Artist` to be updated. */
  nodeId: Scalars['ID'];
};

/** All input for the `updateArtistInvitation` mutation. */
export type UpdateArtistInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  tag: Scalars['String'];
};

/** The output of our `updateArtistInvitation` mutation. */
export type UpdateArtistInvitationPayload = {
  __typename?: 'UpdateArtistInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  updateArtistInvitationResponse?: Maybe<UpdateArtistInvitationResponse>;
};

export type UpdateArtistInvitationResponse = {
  __typename?: 'UpdateArtistInvitationResponse';
  code?: Maybe<UpdateArtistInvitationResponseCode>;
};

export enum UpdateArtistInvitationResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  Success = 'SUCCESS',
}

/** The output of our update `Artist` mutation. */
export type UpdateArtistPayload = {
  __typename?: 'UpdateArtistPayload';
  /** The `Artist` that was updated by this mutation. */
  artist?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LegalStatus` that is related to this `Artist`. */
  legalStatusByCompanyLegalStatus?: Maybe<LegalStatus>;
  /** Reads a single `Management` that is related to this `Artist`. */
  managementByManagementId?: Maybe<Management>;
  /** Reads a single `Province` that is related to this `Artist`. */
  provinceByCompanyLegalProvince?: Maybe<Province>;
  /** Reads a single `Province` that is related to this `Artist`. */
  provinceByProvinceId?: Maybe<Province>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateArtistReleaseBindingByArtistIdAndReleaseId` mutation. */
export type UpdateArtistReleaseBindingByArtistIdAndReleaseIdInput = {
  artistId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ArtistReleaseBinding` being updated. */
  artistReleaseBindingPatch: ArtistReleaseBindingPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  releaseId: Scalars['UUID'];
};

/** All input for the `updateArtistReleaseBinding` mutation. */
export type UpdateArtistReleaseBindingInput = {
  /** An object where the defined keys will be set on the `ArtistReleaseBinding` being updated. */
  artistReleaseBindingPatch: ArtistReleaseBindingPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ArtistReleaseBinding` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `ArtistReleaseBinding` mutation. */
export type UpdateArtistReleaseBindingPayload = {
  __typename?: 'UpdateArtistReleaseBindingPayload';
  /** Reads a single `Artist` that is related to this `ArtistReleaseBinding`. */
  artistByArtistId?: Maybe<Artist>;
  /** The `ArtistReleaseBinding` that was updated by this mutation. */
  artistReleaseBinding?: Maybe<ArtistReleaseBinding>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Release` that is related to this `ArtistReleaseBinding`. */
  releaseByReleaseId?: Maybe<Release>;
};

/** All input for the `updateArtistStatus` mutation. */
export type UpdateArtistStatusInput = {
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  newStatus: ArtistStatus;
};

/** The output of our `updateArtistStatus` mutation. */
export type UpdateArtistStatusPayload = {
  __typename?: 'UpdateArtistStatusPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  updateArtistStatusResponse?: Maybe<UpdateArtistStatusResponse>;
};

export type UpdateArtistStatusResponse = {
  __typename?: 'UpdateArtistStatusResponse';
  code?: Maybe<UpdateArtistStatusResponseCode>;
};

export enum UpdateArtistStatusResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

/** All input for the `updateAttachmentApplicationBindingByAttachmentIdAndApplicationId` mutation. */
export type UpdateAttachmentApplicationBindingByAttachmentIdAndApplicationIdInput =
  {
    applicationId: Scalars['UUID'];
    /** An object where the defined keys will be set on the `AttachmentApplicationBinding` being updated. */
    attachmentApplicationBindingPatch: AttachmentApplicationBindingPatch;
    attachmentId: Scalars['UUID'];
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>;
  };

/** All input for the `updateAttachmentApplicationBinding` mutation. */
export type UpdateAttachmentApplicationBindingInput = {
  /** An object where the defined keys will be set on the `AttachmentApplicationBinding` being updated. */
  attachmentApplicationBindingPatch: AttachmentApplicationBindingPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AttachmentApplicationBinding` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `AttachmentApplicationBinding` mutation. */
export type UpdateAttachmentApplicationBindingPayload = {
  __typename?: 'UpdateAttachmentApplicationBindingPayload';
  /** Reads a single `Application` that is related to this `AttachmentApplicationBinding`. */
  applicationByApplicationId?: Maybe<Application>;
  /** The `AttachmentApplicationBinding` that was updated by this mutation. */
  attachmentApplicationBinding?: Maybe<AttachmentApplicationBinding>;
  /** Reads a single `Attachment` that is related to this `AttachmentApplicationBinding`. */
  attachmentByAttachmentId?: Maybe<Attachment>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAttachmentBoardMeetingBlockBindingByAttachmentIdAndBlockId` mutation. */
export type UpdateAttachmentBoardMeetingBlockBindingByAttachmentIdAndBlockIdInput =
  {
    /** An object where the defined keys will be set on the `AttachmentBoardMeetingBlockBinding` being updated. */
    attachmentBoardMeetingBlockBindingPatch: AttachmentBoardMeetingBlockBindingPatch;
    attachmentId: Scalars['UUID'];
    blockId: Scalars['UUID'];
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>;
  };

/** All input for the `updateAttachmentBoardMeetingBlockBinding` mutation. */
export type UpdateAttachmentBoardMeetingBlockBindingInput = {
  /** An object where the defined keys will be set on the `AttachmentBoardMeetingBlockBinding` being updated. */
  attachmentBoardMeetingBlockBindingPatch: AttachmentBoardMeetingBlockBindingPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AttachmentBoardMeetingBlockBinding` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `AttachmentBoardMeetingBlockBinding` mutation. */
export type UpdateAttachmentBoardMeetingBlockBindingPayload = {
  __typename?: 'UpdateAttachmentBoardMeetingBlockBindingPayload';
  /** The `AttachmentBoardMeetingBlockBinding` that was updated by this mutation. */
  attachmentBoardMeetingBlockBinding?: Maybe<AttachmentBoardMeetingBlockBinding>;
  /** Reads a single `Attachment` that is related to this `AttachmentBoardMeetingBlockBinding`. */
  attachmentByAttachmentId?: Maybe<Attachment>;
  /** Reads a single `BoardMeetingBlock` that is related to this `AttachmentBoardMeetingBlockBinding`. */
  boardMeetingBlockByBlockId?: Maybe<BoardMeetingBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAttachmentById` mutation. */
export type UpdateAttachmentByIdInput = {
  /** An object where the defined keys will be set on the `Attachment` being updated. */
  attachmentPatch: AttachmentPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `updateAttachment` mutation. */
export type UpdateAttachmentInput = {
  /** An object where the defined keys will be set on the `Attachment` being updated. */
  attachmentPatch: AttachmentPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Attachment` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Attachment` mutation. */
export type UpdateAttachmentPayload = {
  __typename?: 'UpdateAttachmentPayload';
  /** Reads a single `Account` that is related to this `Attachment`. */
  accountByAccountId?: Maybe<Account>;
  /** The `Attachment` that was updated by this mutation. */
  attachment?: Maybe<Attachment>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateBoardMeetingBlockById` mutation. */
export type UpdateBoardMeetingBlockByIdInput = {
  /** An object where the defined keys will be set on the `BoardMeetingBlock` being updated. */
  boardMeetingBlockPatch: BoardMeetingBlockPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `updateBoardMeetingBlock` mutation. */
export type UpdateBoardMeetingBlockInput = {
  /** An object where the defined keys will be set on the `BoardMeetingBlock` being updated. */
  boardMeetingBlockPatch: BoardMeetingBlockPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BoardMeetingBlock` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `BoardMeetingBlock` mutation. */
export type UpdateBoardMeetingBlockPayload = {
  __typename?: 'UpdateBoardMeetingBlockPayload';
  /** The `BoardMeetingBlock` that was updated by this mutation. */
  boardMeetingBlock?: Maybe<BoardMeetingBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `FundingRound` that is related to this `BoardMeetingBlock`. */
  fundingRoundByFundingRoundId?: Maybe<FundingRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateBoardMemberApplicationBlockByAccountIdAndApplicationId` mutation. */
export type UpdateBoardMemberApplicationBlockByAccountIdAndApplicationIdInput =
  {
    accountId: Scalars['UUID'];
    applicationId: Scalars['UUID'];
    /** An object where the defined keys will be set on the `BoardMemberApplicationBlock` being updated. */
    boardMemberApplicationBlockPatch: BoardMemberApplicationBlockPatch;
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>;
  };

/** All input for the `updateBoardMemberApplicationBlock` mutation. */
export type UpdateBoardMemberApplicationBlockInput = {
  /** An object where the defined keys will be set on the `BoardMemberApplicationBlock` being updated. */
  boardMemberApplicationBlockPatch: BoardMemberApplicationBlockPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BoardMemberApplicationBlock` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `BoardMemberApplicationBlock` mutation. */
export type UpdateBoardMemberApplicationBlockPayload = {
  __typename?: 'UpdateBoardMemberApplicationBlockPayload';
  /** Reads a single `Account` that is related to this `BoardMemberApplicationBlock`. */
  accountByAccountId?: Maybe<Account>;
  /** Reads a single `Application` that is related to this `BoardMemberApplicationBlock`. */
  applicationByApplicationId?: Maybe<Application>;
  /** The `BoardMemberApplicationBlock` that was updated by this mutation. */
  boardMemberApplicationBlock?: Maybe<BoardMemberApplicationBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateContactByApplicationIdAndEmail` mutation. */
export type UpdateContactByApplicationIdAndEmailInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Contact` being updated. */
  contactPatch: ContactPatch;
  email: Scalars['Email'];
};

/** All input for the `updateContact` mutation. */
export type UpdateContactInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Contact` being updated. */
  contactPatch: ContactPatch;
  /** The globally unique `ID` which will identify a single `Contact` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Contact` mutation. */
export type UpdateContactPayload = {
  __typename?: 'UpdateContactPayload';
  /** Reads a single `Application` that is related to this `Contact`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Contact` that was updated by this mutation. */
  contact?: Maybe<Contact>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateContractByApplicationId` mutation. */
export type UpdateContractByApplicationIdInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Contract` being updated. */
  contractPatch: ContractPatch;
};

/** All input for the `updateContract` mutation. */
export type UpdateContractInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Contract` being updated. */
  contractPatch: ContractPatch;
  /** The globally unique `ID` which will identify a single `Contract` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Contract` mutation. */
export type UpdateContractPayload = {
  __typename?: 'UpdateContractPayload';
  /** Reads a single `Application` that is related to this `Contract`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Contract` that was updated by this mutation. */
  contract?: Maybe<Contract>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateCountryById` mutation. */
export type UpdateCountryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Country` being updated. */
  countryPatch: CountryPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateCountry` mutation. */
export type UpdateCountryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Country` being updated. */
  countryPatch: CountryPatch;
  /** The globally unique `ID` which will identify a single `Country` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Country` mutation. */
export type UpdateCountryPayload = {
  __typename?: 'UpdateCountryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Country` that was updated by this mutation. */
  country?: Maybe<Country>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Region` that is related to this `Country`. */
  regionByRegionId?: Maybe<Region>;
};

/** All input for the `updateDirectDepositAccountById` mutation. */
export type UpdateDirectDepositAccountByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `DirectDepositAccount` being updated. */
  directDepositAccountPatch: DirectDepositAccountPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateDirectDepositAccountByImportId` mutation. */
export type UpdateDirectDepositAccountByImportIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `DirectDepositAccount` being updated. */
  directDepositAccountPatch: DirectDepositAccountPatch;
  importId: Scalars['Int'];
};

/** All input for the `updateDirectDepositAccount` mutation. */
export type UpdateDirectDepositAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `DirectDepositAccount` being updated. */
  directDepositAccountPatch: DirectDepositAccountPatch;
  /** The globally unique `ID` which will identify a single `DirectDepositAccount` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `DirectDepositAccount` mutation. */
export type UpdateDirectDepositAccountPayload = {
  __typename?: 'UpdateDirectDepositAccountPayload';
  /** Reads a single `Artist` that is related to this `DirectDepositAccount`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DirectDepositAccount` that was updated by this mutation. */
  directDepositAccount?: Maybe<DirectDepositAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateDistributorById` mutation. */
export type UpdateDistributorByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Distributor` being updated. */
  distributorPatch: DistributorPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateDistributorByName` mutation. */
export type UpdateDistributorByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Distributor` being updated. */
  distributorPatch: DistributorPatch;
  name: Scalars['String'];
};

/** All input for the `updateDistributor` mutation. */
export type UpdateDistributorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Distributor` being updated. */
  distributorPatch: DistributorPatch;
  /** The globally unique `ID` which will identify a single `Distributor` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Distributor` mutation. */
export type UpdateDistributorPayload = {
  __typename?: 'UpdateDistributorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Distributor` that was updated by this mutation. */
  distributor?: Maybe<Distributor>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateEligibilityNoticeByArtistId` mutation. */
export type UpdateEligibilityNoticeByArtistIdInput = {
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EligibilityNotice` being updated. */
  eligibilityNoticePatch: EligibilityNoticePatch;
};

/** All input for the `updateEligibilityNotice` mutation. */
export type UpdateEligibilityNoticeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EligibilityNotice` being updated. */
  eligibilityNoticePatch: EligibilityNoticePatch;
  /** The globally unique `ID` which will identify a single `EligibilityNotice` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `EligibilityNotice` mutation. */
export type UpdateEligibilityNoticePayload = {
  __typename?: 'UpdateEligibilityNoticePayload';
  /** Reads a single `Artist` that is related to this `EligibilityNotice`. */
  artistByArtistId?: Maybe<Artist>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EligibilityNotice` that was updated by this mutation. */
  eligibilityNotice?: Maybe<EligibilityNotice>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateExpenseById` mutation. */
export type UpdateExpenseByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Expense` being updated. */
  expensePatch: ExpensePatch;
  id: Scalars['UUID'];
};

/** All input for the `updateExpense` mutation. */
export type UpdateExpenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Expense` being updated. */
  expensePatch: ExpensePatch;
  /** The globally unique `ID` which will identify a single `Expense` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Expense` mutation. */
export type UpdateExpensePayload = {
  __typename?: 'UpdateExpensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Expense` that was updated by this mutation. */
  expense?: Maybe<Expense>;
  /** An edge for our `Expense`. May be used by Relay 1. */
  expenseEdge?: Maybe<ExpensesEdge>;
  /** Reads a single `FundingRequest` that is related to this `Expense`. */
  fundingRequestByFundingRequestId?: Maybe<FundingRequest>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Expense` mutation. */
export type UpdateExpensePayloadExpenseEdgeArgs = {
  orderBy?: InputMaybe<Array<ExpensesOrderBy>>;
};

/** All input for the `updateFundingRequestById` mutation. */
export type UpdateFundingRequestByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FundingRequest` being updated. */
  fundingRequestPatch: FundingRequestPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateFundingRequest` mutation. */
export type UpdateFundingRequestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FundingRequest` being updated. */
  fundingRequestPatch: FundingRequestPatch;
  /** The globally unique `ID` which will identify a single `FundingRequest` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `FundingRequest` mutation. */
export type UpdateFundingRequestPayload = {
  __typename?: 'UpdateFundingRequestPayload';
  /** Reads a single `Application` that is related to this `FundingRequest`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FundingRequest` that was updated by this mutation. */
  fundingRequest?: Maybe<FundingRequest>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateFundingRoundById` mutation. */
export type UpdateFundingRoundByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FundingRound` being updated. */
  fundingRoundPatch: FundingRoundPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateFundingRoundByTitle` mutation. */
export type UpdateFundingRoundByTitleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FundingRound` being updated. */
  fundingRoundPatch: FundingRoundPatch;
  title: Scalars['String'];
};

/** All input for the `updateFundingRound` mutation. */
export type UpdateFundingRoundInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FundingRound` being updated. */
  fundingRoundPatch: FundingRoundPatch;
  /** The globally unique `ID` which will identify a single `FundingRound` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `FundingRound` mutation. */
export type UpdateFundingRoundPayload = {
  __typename?: 'UpdateFundingRoundPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FundingRound` that was updated by this mutation. */
  fundingRound?: Maybe<FundingRound>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateGenreById` mutation. */
export type UpdateGenreByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Genre` being updated. */
  genrePatch: GenrePatch;
  id: Scalars['UUID'];
};

/** All input for the `updateGenre` mutation. */
export type UpdateGenreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Genre` being updated. */
  genrePatch: GenrePatch;
  /** The globally unique `ID` which will identify a single `Genre` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Genre` mutation. */
export type UpdateGenrePayload = {
  __typename?: 'UpdateGenrePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Genre` that was updated by this mutation. */
  genre?: Maybe<Genre>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateLabelById` mutation. */
export type UpdateLabelByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Label` being updated. */
  labelPatch: LabelPatch;
};

/** All input for the `updateLabelByName` mutation. */
export type UpdateLabelByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Label` being updated. */
  labelPatch: LabelPatch;
  name: Scalars['String'];
};

/** All input for the `updateLabel` mutation. */
export type UpdateLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Label` being updated. */
  labelPatch: LabelPatch;
  /** The globally unique `ID` which will identify a single `Label` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Label` mutation. */
export type UpdateLabelPayload = {
  __typename?: 'UpdateLabelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Label`. */
  countryByCountryId?: Maybe<Country>;
  /** The `Label` that was updated by this mutation. */
  label?: Maybe<Label>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateLegalStatusById` mutation. */
export type UpdateLegalStatusByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `LegalStatus` being updated. */
  legalStatusPatch: LegalStatusPatch;
};

/** All input for the `updateLegalStatus` mutation. */
export type UpdateLegalStatusInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LegalStatus` being updated. */
  legalStatusPatch: LegalStatusPatch;
  /** The globally unique `ID` which will identify a single `LegalStatus` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `LegalStatus` mutation. */
export type UpdateLegalStatusPayload = {
  __typename?: 'UpdateLegalStatusPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LegalStatus` that was updated by this mutation. */
  legalStatus?: Maybe<LegalStatus>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateManagementById` mutation. */
export type UpdateManagementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Management` being updated. */
  managementPatch: ManagementPatch;
};

/** All input for the `updateManagementByImportId` mutation. */
export type UpdateManagementByImportIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
  /** An object where the defined keys will be set on the `Management` being updated. */
  managementPatch: ManagementPatch;
};

/** All input for the `updateManagement` mutation. */
export type UpdateManagementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Management` being updated. */
  managementPatch: ManagementPatch;
  /** The globally unique `ID` which will identify a single `Management` to be updated. */
  nodeId: Scalars['ID'];
};

/** The output of our update `Management` mutation. */
export type UpdateManagementPayload = {
  __typename?: 'UpdateManagementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Management`. */
  countryByCountryId?: Maybe<Country>;
  /** Reads a single `LegalStatus` that is related to this `Management`. */
  legalStatusByLegalStatus?: Maybe<LegalStatus>;
  /** The `Management` that was updated by this mutation. */
  management?: Maybe<Management>;
  /** Reads a single `Province` that is related to this `Management`. */
  provinceByLegalProvince?: Maybe<Province>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updatePaymentById` mutation. */
export type UpdatePaymentByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Payment` being updated. */
  paymentPatch: PaymentPatch;
};

/** All input for the `updatePayment` mutation. */
export type UpdatePaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Payment` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Payment` being updated. */
  paymentPatch: PaymentPatch;
};

/** The output of our update `Payment` mutation. */
export type UpdatePaymentPayload = {
  __typename?: 'UpdatePaymentPayload';
  /** Reads a single `Application` that is related to this `Payment`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `DirectDepositAccount` that is related to this `Payment`. */
  directDepositAccountByDirectDepositAccountId?: Maybe<DirectDepositAccount>;
  /** The `Payment` that was updated by this mutation. */
  payment?: Maybe<Payment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateProvinceById` mutation. */
export type UpdateProvinceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `Province` being updated. */
  provincePatch: ProvincePatch;
};

/** All input for the `updateProvince` mutation. */
export type UpdateProvinceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Province` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Province` being updated. */
  provincePatch: ProvincePatch;
};

/** The output of our update `Province` mutation. */
export type UpdateProvincePayload = {
  __typename?: 'UpdateProvincePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Province` that was updated by this mutation. */
  province?: Maybe<Province>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateRegionById` mutation. */
export type UpdateRegionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `Region` being updated. */
  regionPatch: RegionPatch;
};

/** All input for the `updateRegion` mutation. */
export type UpdateRegionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Region` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Region` being updated. */
  regionPatch: RegionPatch;
};

/** The output of our update `Region` mutation. */
export type UpdateRegionPayload = {
  __typename?: 'UpdateRegionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Region` that was updated by this mutation. */
  region?: Maybe<Region>;
};

/** All input for the `updateReleaseById` mutation. */
export type UpdateReleaseByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Release` being updated. */
  releasePatch: ReleasePatch;
};

/** All input for the `updateReleaseByImportId` mutation. */
export type UpdateReleaseByImportIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
  /** An object where the defined keys will be set on the `Release` being updated. */
  releasePatch: ReleasePatch;
};

/** All input for the `updateRelease` mutation. */
export type UpdateReleaseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Release` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Release` being updated. */
  releasePatch: ReleasePatch;
};

/** The output of our update `Release` mutation. */
export type UpdateReleasePayload = {
  __typename?: 'UpdateReleasePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Distributor` that is related to this `Release`. */
  distributorByDistributorId?: Maybe<Distributor>;
  /** Reads a single `Genre` that is related to this `Release`. */
  genreByGenreId?: Maybe<Genre>;
  /** Reads a single `Label` that is related to this `Release`. */
  labelByLabelId?: Maybe<Label>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Release` that was updated by this mutation. */
  release?: Maybe<Release>;
};

/** All input for the `updateReleaseSales` mutation. */
export type UpdateReleaseSalesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  genreId: Scalars['UUID'];
  releaseId: Scalars['UUID'];
  releasesScanned: Scalars['Int'];
  tracksDownloaded: Scalars['Int'];
  tracksStreamed: Scalars['Int'];
};

/** The output of our `updateReleaseSales` mutation. */
export type UpdateReleaseSalesPayload = {
  __typename?: 'UpdateReleaseSalesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  updateReleaseSalesResponse?: Maybe<UpdateReleaseSalesResponse>;
};

export type UpdateReleaseSalesResponse = {
  __typename?: 'UpdateReleaseSalesResponse';
  code?: Maybe<UpdateReleaseSalesResponseCode>;
};

export enum UpdateReleaseSalesResponseCode {
  InvalidRequest = 'INVALID_REQUEST',
  PermissionDenied = 'PERMISSION_DENIED',
  Success = 'SUCCESS',
}

/** All input for the `updateSaleById` mutation. */
export type UpdateSaleByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Sale` being updated. */
  salePatch: SalePatch;
};

/** All input for the `updateSaleByImportId` mutation. */
export type UpdateSaleByImportIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
  /** An object where the defined keys will be set on the `Sale` being updated. */
  salePatch: SalePatch;
};

/** All input for the `updateSale` mutation. */
export type UpdateSaleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Sale` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Sale` being updated. */
  salePatch: SalePatch;
};

/** The output of our update `Sale` mutation. */
export type UpdateSalePayload = {
  __typename?: 'UpdateSalePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Release` that is related to this `Sale`. */
  releaseByReleaseId?: Maybe<Release>;
  /** The `Sale` that was updated by this mutation. */
  sale?: Maybe<Sale>;
};

/** All input for the `updateSingleById` mutation. */
export type UpdateSingleByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Single` being updated. */
  singlePatch: SinglePatch;
};

/** All input for the `updateSingleByImportId` mutation. */
export type UpdateSingleByImportIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
  /** An object where the defined keys will be set on the `Single` being updated. */
  singlePatch: SinglePatch;
};

/** All input for the `updateSingle` mutation. */
export type UpdateSingleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Single` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Single` being updated. */
  singlePatch: SinglePatch;
};

/** The output of our update `Single` mutation. */
export type UpdateSinglePayload = {
  __typename?: 'UpdateSinglePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Release` that is related to this `Single`. */
  releaseByReleaseId?: Maybe<Release>;
  /** The `Single` that was updated by this mutation. */
  single?: Maybe<Single>;
};

/** All input for the `updateTourDateById` mutation. */
export type UpdateTourDateByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `TourDate` being updated. */
  tourDatePatch: TourDatePatch;
};

/** All input for the `updateTourDate` mutation. */
export type UpdateTourDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TourDate` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TourDate` being updated. */
  tourDatePatch: TourDatePatch;
};

/** The output of our update `TourDate` mutation. */
export type UpdateTourDatePayload = {
  __typename?: 'UpdateTourDatePayload';
  /** Reads a single `Application` that is related to this `TourDate`. */
  applicationByApplicationId?: Maybe<Application>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `TourDate`. */
  countryByTourDateCountryId?: Maybe<Country>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TourDate` that was updated by this mutation. */
  tourDate?: Maybe<TourDate>;
  /** An edge for our `TourDate`. May be used by Relay 1. */
  tourDateEdge?: Maybe<TourDatesEdge>;
  /** Reads a single `TourDateType` that is related to this `TourDate`. */
  tourDateTypeByTourDateTypeId?: Maybe<TourDateType>;
};

/** The output of our update `TourDate` mutation. */
export type UpdateTourDatePayloadTourDateEdgeArgs = {
  orderBy?: InputMaybe<Array<TourDatesOrderBy>>;
};

/** All input for the `updateTourDateTypeById` mutation. */
export type UpdateTourDateTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `TourDateType` being updated. */
  tourDateTypePatch: TourDateTypePatch;
};

/** All input for the `updateTourDateType` mutation. */
export type UpdateTourDateTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TourDateType` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TourDateType` being updated. */
  tourDateTypePatch: TourDateTypePatch;
};

/** The output of our update `TourDateType` mutation. */
export type UpdateTourDateTypePayload = {
  __typename?: 'UpdateTourDateTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TourDateType` that was updated by this mutation. */
  tourDateType?: Maybe<TourDateType>;
};

/** All input for the `upsertApplicationBoardNotes` mutation. */
export type UpsertApplicationBoardNotesInput = {
  applicationId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  notes: Scalars['String'];
};

/** The output of our `upsertApplicationBoardNotes` mutation. */
export type UpsertApplicationBoardNotesPayload = {
  __typename?: 'UpsertApplicationBoardNotesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  upsertApplicationBoardNotesResponse?: Maybe<UpsertApplicationBoardNotesResponse>;
};

export type UpsertApplicationBoardNotesResponse = {
  __typename?: 'UpsertApplicationBoardNotesResponse';
  code?: Maybe<UpsertApplicationBoardNotesResponseCode>;
};

export enum UpsertApplicationBoardNotesResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

/** All input for the `upsertEligibilityNotice` mutation. */
export type UpsertEligibilityNoticeInput = {
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  type: EligibilityNoticeTypes;
};

/** The output of our `upsertEligibilityNotice` mutation. */
export type UpsertEligibilityNoticePayload = {
  __typename?: 'UpsertEligibilityNoticePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  upsertEligibilityNoticeResponse?: Maybe<UpsertEligibilityNoticeResponse>;
};

export type UpsertEligibilityNoticeResponse = {
  __typename?: 'UpsertEligibilityNoticeResponse';
  code?: Maybe<UpsertEligibilityNoticeResponseCode>;
};

export enum UpsertEligibilityNoticeResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

/** All input for the `upsertManagement` mutation. */
export type UpsertManagementInput = {
  address: Scalars['String'];
  artistId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  countryId: Scalars['UUID'];
  email: Scalars['String'];
  legalProvince?: InputMaybe<Scalars['String']>;
  legalStatus: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
};

/** The output of our `upsertManagement` mutation. */
export type UpsertManagementPayload = {
  __typename?: 'UpsertManagementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  upsertManagementResponse?: Maybe<UpsertManagementResponse>;
};

export type UpsertManagementResponse = {
  __typename?: 'UpsertManagementResponse';
  code?: Maybe<UpsertManagementResponseCode>;
};

export enum UpsertManagementResponseCode {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

/** All input for the `verifyNewsletterSubscription` mutation. */
export type VerifyNewsletterSubscriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  tag: Scalars['UUID'];
};

/** The output of our `verifyNewsletterSubscription` mutation. */
export type VerifyNewsletterSubscriptionPayload = {
  __typename?: 'VerifyNewsletterSubscriptionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type VisibleAccountArtistBinding = {
  __typename?: 'VisibleAccountArtistBinding';
  accountId?: Maybe<Scalars['UUID']>;
  artistId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `VisibleAccountArtistBinding` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type VisibleAccountArtistBindingCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `artistId` field. */
  artistId?: InputMaybe<Scalars['UUID']>;
};

/** An input for mutations affecting `VisibleAccountArtistBinding` */
export type VisibleAccountArtistBindingInput = {
  accountId?: InputMaybe<Scalars['UUID']>;
  artistId?: InputMaybe<Scalars['UUID']>;
};

/** Methods to use when ordering `VisibleAccountArtistBinding`. */
export enum VisibleAccountArtistBindingsOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  ArtistIdAsc = 'ARTIST_ID_ASC',
  ArtistIdDesc = 'ARTIST_ID_DESC',
  Natural = 'NATURAL',
}

export type ApplicationAttributesFragment = {
  __typename?: 'Application';
  status: ApplicationStatus;
  reviewStatus: ApplicationReviewStatus;
  boardNotes?: {
    __typename?: 'ApplicationBoardNote';
    applicationId: string;
    notes: string;
  } | null;
  boardBlocks: Array<{
    __typename?: 'BoardMemberApplicationBlock';
    accountId: string;
    applicationId: string;
  }>;
};

export type DirectDepositAccountAttributesFragment = {
  __typename?: 'DirectDepositAccount';
  id: string;
  institution: string;
  maskedAccount: string;
  name: string;
  transit: string;
  paid?: string | null;
  payments: Array<{
    __typename?: 'Payment';
    id: string;
    directDepositAccountId: string;
    applicationId: string;
    createdAt: string;
    amount: string;
    transactionNumber: string;
    invoiceDate: string;
    notes?: string | null;
  }>;
};

export type ExpenseAttributesFragment = {
  __typename?: 'Expense';
  id: string;
  vendor: string;
  cost: string;
  invoiceNumber: string;
  date: string;
  description: string;
  fundingRequestId: string;
};

export type FundingRequestAttributesFragment = {
  __typename?: 'FundingRequest';
  id: string;
  applicationId: string;
  fundingType: string;
  cost: string;
  date: string;
  strategy: string;
  reason: string;
  amountApproved?: string | null;
  status: FundingRequestStatus;
  createdAt: string;
  isTooNew?: boolean | null;
  isTooOld?: boolean | null;
  isEligible?: boolean | null;
  isOwner?: boolean | null;
  isWritable?: boolean | null;
  isCancellable?: boolean | null;
  cancelled: boolean;
  alternate?: boolean | null;
};

export type FundingRoundAttributesFragment = {
  __typename?: 'FundingRound';
  id: string;
  title: string;
  timezone?: string | null;
  startDate?: string | null;
  endDate: string;
  futureCutoffDate?: string | null;
  contractDeadline?: string | null;
  claimDeadline?: string | null;
  boardStartDate?: string | null;
  boardEndDate?: string | null;
  boardMeetingDate: string;
  active?: boolean | null;
  allowOrion: boolean;
  status?: FundingRoundStatusType | null;
  numberOfApplications?: number | null;
};

export type ReleaseAttributesFragment = {
  __typename?: 'Release';
  id: string;
  updatedAt: string;
  isMajorityEnglish: boolean;
  title: string;
  otherLabel?: string | null;
  otherDistributor?: string | null;
  date: string;
  numberOfMinutes: number;
  numberOfTracks: number;
  company: string;
  website: string;
  inCanada: boolean;
  contact: string;
  phoneNumber: string;
  address: string;
  email: string;
  notes: string;
  isRestricted?: boolean | null;
  isEligible?: boolean | null;
  meetsDateRequirement?: boolean | null;
  meetsTracksRequirement?: boolean | null;
  meetsMinutesRequirement?: boolean | null;
  meetsSalesRequirement?: boolean | null;
  isOwner?: boolean | null;
  sales: Array<{
    __typename?: 'Sale';
    releasesScanned: number;
    tracksStreamed: number;
    tracksDownloaded: number;
    totalUnits?: number | null;
  }>;
  genre?: {
    __typename?: 'Genre';
    id: string;
    title: string;
    minimumSales: number;
    maximumSales: number;
  } | null;
  label?: { __typename?: 'Label'; id: string; name: string } | null;
  distributor?: { __typename?: 'Distributor'; id: string; name: string } | null;
  singles: Array<{
    __typename?: 'Single';
    id: string;
    title: string;
    spins: number;
  }>;
};

export type TourDateAttributesFragment = {
  __typename?: 'TourDate';
  id: string;
  applicationId: string;
  confirmed: boolean;
  countrySubdivision: string;
  venueName: string;
  venueCapacity: number;
  averageTicketPrice: string;
  paymentGuarantee: string;
  bookingAgency: string;
  bookingAgent: string;
  promoter: string;
  reason: string;
  date: string;
  status: TourDateStatus;
  isTooNew?: boolean | null;
  isTooOld?: boolean | null;
  isTooMany?: boolean | null;
  isEligible?: boolean | null;
  isOwner?: boolean | null;
  isWritable?: boolean | null;
  isCancellable?: boolean | null;
  meetsTicketedVenueCapacity?: boolean | null;
  sameDateLimit?: number | null;
  cancelled: boolean;
  alternate?: boolean | null;
  tourDateType?: {
    __typename?: 'TourDateType';
    id: string;
    title: string;
    minimumTicketedVenueCapacity?: number | null;
    maximumTicketedVenueCapacity?: number | null;
  } | null;
  tourDateCountry?: {
    __typename?: 'Country';
    id: string;
    name: string;
    regionId: string;
  } | null;
};

export type AddAlternateRequestMutationVariables = Exact<{
  input: AddAlternateRequestInput;
}>;

export type AddAlternateRequestMutation = {
  __typename?: 'Mutation';
  request?: {
    __typename?: 'AddAlternateRequestPayload';
    fundingRequest?: {
      __typename?: 'FundingRequest';
      id: string;
      applicationId: string;
      fundingType: string;
      cost: string;
      date: string;
      strategy: string;
      reason: string;
      amountApproved?: string | null;
      status: FundingRequestStatus;
      createdAt: string;
      isTooNew?: boolean | null;
      isTooOld?: boolean | null;
      isEligible?: boolean | null;
      isOwner?: boolean | null;
      isWritable?: boolean | null;
      isCancellable?: boolean | null;
      cancelled: boolean;
      alternate?: boolean | null;
      application?: {
        __typename?: 'Application';
        year?: number | null;
        budgetForFundingRequests?: string | null;
      } | null;
    } | null;
  } | null;
};

export type AddArtistMutationVariables = Exact<{
  artistName: Scalars['String'];
  artistProvince: Scalars['String'];
  companyName: Scalars['String'];
  companyLegalStatus: Scalars['String'];
  companyLegalProvince: Scalars['String'];
  managementName: Scalars['String'];
  managementCountryId: Scalars['String'];
  managementContactPerson: Scalars['String'];
  managementAddress: Scalars['String'];
  managementPhoneNumber: Scalars['String'];
  managementEmail: Scalars['String'];
  managementLegalStatus: Scalars['String'];
  managementLegalProvince: Scalars['String'];
}>;

export type AddArtistMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'AddArtistPayload';
    response?: {
      __typename?: 'AddArtistResponse';
      code?: AddArtistResponseCode | null;
      artistId?: string | null;
    } | null;
  } | null;
};

export type AddReleaseMutationVariables = Exact<{
  artistId: Scalars['String'];
  title: Scalars['String'];
  date: Scalars['String'];
  numberOfMinutes: Scalars['Int'];
  numberOfTracks: Scalars['Int'];
  isMajorityEnglish: Scalars['Boolean'];
  genreId: Scalars['String'];
  labelId: Scalars['String'];
  otherLabel?: InputMaybe<Scalars['String']>;
  distributorId: Scalars['String'];
  otherDistributor?: InputMaybe<Scalars['String']>;
  releasesScanned: Scalars['Int'];
  tracksDownloaded: Scalars['Int'];
  tracksStreamed: Scalars['Int'];
  company: Scalars['String'];
  website: Scalars['String'];
  contact: Scalars['String'];
  address: Scalars['String'];
  inCanada: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  notes: Scalars['String'];
}>;

export type AddReleaseMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'AddReleasePayload';
    response?: {
      __typename?: 'AddReleaseResponse';
      code?: AddReleaseResponseCode | null;
    } | null;
  } | null;
};

export type AddBoardBlockMutationVariables = Exact<{
  accountId: Scalars['UUID'];
  applicationId: Scalars['UUID'];
}>;

export type AddBoardBlockMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'CreateBoardMemberApplicationBlockPayload';
    query?: {
      __typename?: 'Query';
      application?: {
        __typename?: 'Application';
        status: ApplicationStatus;
        reviewStatus: ApplicationReviewStatus;
        boardNotes?: {
          __typename?: 'ApplicationBoardNote';
          applicationId: string;
          notes: string;
        } | null;
        boardBlocks: Array<{
          __typename?: 'BoardMemberApplicationBlock';
          accountId: string;
          applicationId: string;
        }>;
      } | null;
    } | null;
  } | null;
};

export type BoardMeetingBlockAttributesFragment = {
  __typename?: 'BoardMeetingBlock';
  title: string;
  content: string;
  id: string;
  displayOrder: number;
  fundingRoundId: string;
  attachments?: Array<{
    __typename?: 'Attachment';
    id: string;
    name: string;
    size: number;
  } | null> | null;
};

export type AddBoardMeetingBlockMutationVariables = Exact<{
  title: Scalars['String'];
  content: Scalars['String'];
  roundId: Scalars['UUID'];
}>;

export type AddBoardMeetingBlockMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'AddBoardMeetingBlockPayload';
    block?: {
      __typename?: 'BoardMeetingBlock';
      title: string;
      content: string;
      id: string;
      displayOrder: number;
      fundingRoundId: string;
      attachments?: Array<{
        __typename?: 'Attachment';
        id: string;
        name: string;
        size: number;
      } | null> | null;
    } | null;
  } | null;
};

export type AddPaymentMutationVariables = Exact<{
  input: CreatePaymentInput;
  directDepositAccountId: Scalars['UUID'];
  applicationId: Scalars['UUID'];
}>;

export type AddPaymentMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'CreatePaymentPayload';
    query?: {
      __typename?: 'Query';
      directDepositAccount?: {
        __typename?: 'DirectDepositAccount';
        id: string;
        institution: string;
        maskedAccount: string;
        name: string;
        transit: string;
        paid?: string | null;
        payments: Array<{
          __typename?: 'Payment';
          id: string;
          directDepositAccountId: string;
          applicationId: string;
          createdAt: string;
          amount: string;
          transactionNumber: string;
          invoiceDate: string;
          notes?: string | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type ApproveAndNotifyMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
  emails: Scalars['String'];
}>;

export type ApproveAndNotifyMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'ApproveApplicationAndNotifyPayload';
    query?: {
      __typename?: 'Query';
      application?: {
        __typename?: 'Application';
        status: ApplicationStatus;
        reviewStatus: ApplicationReviewStatus;
        boardNotes?: {
          __typename?: 'ApplicationBoardNote';
          applicationId: string;
          notes: string;
        } | null;
        boardBlocks: Array<{
          __typename?: 'BoardMemberApplicationBlock';
          accountId: string;
          applicationId: string;
        }>;
      } | null;
    } | null;
  } | null;
};

export type CreateAccountArtistBindingMutationVariables = Exact<{
  accountId: Scalars['UUID'];
  artistId: Scalars['UUID'];
}>;

export type CreateAccountArtistBindingMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'CreateAccountArtistBindingPayload';
    artistByArtistId?: {
      __typename?: 'Artist';
      name: string;
      id: string;
    } | null;
  } | null;
};

export type CreateBoardMemberInvitationMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type CreateBoardMemberInvitationMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'CreateBoardMemberInvitationPayload';
    response?: {
      __typename?: 'CreateBoardMemberInvitationResponse';
      code?: CreateBoardMemberInvitationResponseCode | null;
      email?: string | null;
      name?: string | null;
      accountId?: string | null;
    } | null;
  } | null;
};

export type CreateNewFundingRoundMutationVariables = Exact<{
  round: FundingRoundInput;
}>;

export type CreateNewFundingRoundMutation = {
  __typename?: 'Mutation';
  createFundingRound?: {
    __typename?: 'CreateFundingRoundPayload';
    round?: {
      __typename?: 'FundingRound';
      id: string;
      title: string;
      timezone?: string | null;
      startDate?: string | null;
      endDate: string;
      futureCutoffDate?: string | null;
      contractDeadline?: string | null;
      claimDeadline?: string | null;
      boardStartDate?: string | null;
      boardEndDate?: string | null;
      boardMeetingDate: string;
      active?: boolean | null;
      allowOrion: boolean;
      status?: FundingRoundStatusType | null;
      numberOfApplications?: number | null;
    } | null;
  } | null;
};

export type DeleteAccountArtistBindingMutationVariables = Exact<{
  accountId: Scalars['UUID'];
  artistId: Scalars['UUID'];
}>;

export type DeleteAccountArtistBindingMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DeleteAccountArtistBindingPayload';
    accountArtistBinding?: {
      __typename?: 'AccountArtistBinding';
      accountId: string;
      artistId: string;
    } | null;
  } | null;
};

export type DeleteBoardInvitationMutationVariables = Exact<{
  input: DeleteBoardMemberInvitationInput;
}>;

export type DeleteBoardInvitationMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DeleteBoardMemberInvitationPayload';
    response?: {
      __typename?: 'DeleteBoardMemberInvitationResponse';
      email?: string | null;
    } | null;
  } | null;
};

export type DeleteBoardMeetingBlockMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type DeleteBoardMeetingBlockMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DeleteBoardMeetingBlockPayload';
    block?: { __typename?: 'BoardMeetingBlock'; id: string } | null;
  } | null;
};

export type DeleteFundingRoundMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type DeleteFundingRoundMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DeleteFundingRoundPayload';
    round?: { __typename?: 'FundingRound'; id: string } | null;
  } | null;
};

export type RemoveBoardBlockMutationVariables = Exact<{
  accountId: Scalars['UUID'];
  applicationId: Scalars['UUID'];
}>;

export type RemoveBoardBlockMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DeleteBoardMemberApplicationBlockPayload';
    query?: {
      __typename?: 'Query';
      application?: {
        __typename?: 'Application';
        status: ApplicationStatus;
        reviewStatus: ApplicationReviewStatus;
        boardNotes?: {
          __typename?: 'ApplicationBoardNote';
          applicationId: string;
          notes: string;
        } | null;
        boardBlocks: Array<{
          __typename?: 'BoardMemberApplicationBlock';
          accountId: string;
          applicationId: string;
        }>;
      } | null;
    } | null;
  } | null;
};

export type RemoveBoardMemberMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type RemoveBoardMemberMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'RemoveBoardMemberPayload';
    response?: {
      __typename?: 'RemoveBoardMemberResponse';
      accountId?: string | null;
    } | null;
  } | null;
};

export type ReopenApplicationClaimsMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
  notes: Scalars['String'];
}>;

export type ReopenApplicationClaimsMutation = {
  __typename?: 'Mutation';
  reopenApplicationClaims?: {
    __typename?: 'ReopenApplicationClaimsPayload';
    reopenApplicationClaimsResponse?: {
      __typename?: 'ReopenApplicationClaimsResponse';
      code?: ReopenApplicationClaimsResponseCode | null;
    } | null;
  } | null;
};

export type SwapBoardMeetingBlocksMutationVariables = Exact<{
  input: SwapBoardMeetingBlockOrderInput;
}>;

export type SwapBoardMeetingBlocksMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'SwapBoardMeetingBlockOrderPayload';
    blocks?: Array<{
      __typename?: 'BoardMeetingBlock';
      title: string;
      content: string;
      id: string;
      displayOrder: number;
      fundingRoundId: string;
      attachments?: Array<{
        __typename?: 'Attachment';
        id: string;
        name: string;
        size: number;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateApplicationReviewMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
  patch: ApplicationPatch;
}>;

export type UpdateApplicationReviewMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpdateApplicationPayload';
    query?: {
      __typename?: 'Query';
      application?: {
        __typename?: 'Application';
        status: ApplicationStatus;
        reviewStatus: ApplicationReviewStatus;
        boardNotes?: {
          __typename?: 'ApplicationBoardNote';
          applicationId: string;
          notes: string;
        } | null;
        boardBlocks: Array<{
          __typename?: 'BoardMemberApplicationBlock';
          accountId: string;
          applicationId: string;
        }>;
      } | null;
    } | null;
  } | null;
};

export type UpdateArtistStatusMutationVariables = Exact<{
  artistId: Scalars['UUID'];
  status: ArtistStatus;
}>;

export type UpdateArtistStatusMutation = {
  __typename?: 'Mutation';
  updateArtistStatus?: {
    __typename?: 'UpdateArtistStatusPayload';
    response?: {
      __typename?: 'UpdateArtistStatusResponse';
      code?: UpdateArtistStatusResponseCode | null;
    } | null;
  } | null;
};

export type UpdateBoardMeetingBlockMutationVariables = Exact<{
  id: Scalars['UUID'];
  patch: BoardMeetingBlockPatch;
}>;

export type UpdateBoardMeetingBlockMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpdateBoardMeetingBlockPayload';
    block?: {
      __typename?: 'BoardMeetingBlock';
      title: string;
      content: string;
      id: string;
      displayOrder: number;
      fundingRoundId: string;
      attachments?: Array<{
        __typename?: 'Attachment';
        id: string;
        name: string;
        size: number;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateFundingRoundMutationVariables = Exact<{
  id: Scalars['UUID'];
  round: FundingRoundPatch;
}>;

export type UpdateFundingRoundMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpdateFundingRoundPayload';
    round?: {
      __typename?: 'FundingRound';
      id: string;
      title: string;
      timezone?: string | null;
      startDate?: string | null;
      endDate: string;
      futureCutoffDate?: string | null;
      contractDeadline?: string | null;
      claimDeadline?: string | null;
      boardStartDate?: string | null;
      boardEndDate?: string | null;
      boardMeetingDate: string;
      active?: boolean | null;
      allowOrion: boolean;
      status?: FundingRoundStatusType | null;
      numberOfApplications?: number | null;
    } | null;
  } | null;
};

export type UpdatePaymentMutationVariables = Exact<{
  input: UpdatePaymentByIdInput;
  directDepositAccountId: Scalars['UUID'];
  applicationId: Scalars['UUID'];
}>;

export type UpdatePaymentMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpdatePaymentPayload';
    query?: {
      __typename?: 'Query';
      directDepositAccount?: {
        __typename?: 'DirectDepositAccount';
        id: string;
        institution: string;
        maskedAccount: string;
        name: string;
        transit: string;
        paid?: string | null;
        payments: Array<{
          __typename?: 'Payment';
          id: string;
          directDepositAccountId: string;
          applicationId: string;
          createdAt: string;
          amount: string;
          transactionNumber: string;
          invoiceDate: string;
          notes?: string | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type UpdateReopenClaimsNotesMutationVariables = Exact<{
  id: Scalars['UUID'];
  reopenClaimsNotes: Scalars['String'];
}>;

export type UpdateReopenClaimsNotesMutation = {
  __typename?: 'Mutation';
  updateApplicationById?: {
    __typename?: 'UpdateApplicationPayload';
    application?: {
      __typename?: 'Application';
      reopenClaimsNotes?: string | null;
    } | null;
  } | null;
};

export type UpsertEligibilityNoticeMutationVariables = Exact<{
  input: UpsertEligibilityNoticeInput;
}>;

export type UpsertEligibilityNoticeMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpsertEligibilityNoticePayload';
    upsertEligibilityNoticeResponse?: {
      __typename?: 'UpsertEligibilityNoticeResponse';
      code?: UpsertEligibilityNoticeResponseCode | null;
    } | null;
  } | null;
};

export type CancelFundingRequestMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type CancelFundingRequestMutation = {
  __typename?: 'Mutation';
  cancelFundingRequest?: {
    __typename?: 'CancelFundingRequestPayload';
    cancelFundingRequestResponse?: {
      __typename?: 'CancelFundingRequestResponse';
      code?: CancelFundingRequestResponseCode | null;
      fundingRequest?: {
        __typename?: 'FundingRequest';
        id: string;
        applicationId: string;
        fundingType: string;
        cost: string;
        date: string;
        strategy: string;
        reason: string;
        amountApproved?: string | null;
        status: FundingRequestStatus;
        createdAt: string;
        isTooNew?: boolean | null;
        isTooOld?: boolean | null;
        isEligible?: boolean | null;
        isOwner?: boolean | null;
        isWritable?: boolean | null;
        isCancellable?: boolean | null;
        cancelled: boolean;
        alternate?: boolean | null;
        application?: {
          __typename?: 'Application';
          year?: number | null;
          budgetForFundingRequests?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CancelTourDateMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type CancelTourDateMutation = {
  __typename?: 'Mutation';
  cancelTourDate?: {
    __typename?: 'CancelTourDatePayload';
    cancelTourDateResponse?: {
      __typename?: 'CancelTourDateResponse';
      code?: CancelTourDateResponseCode | null;
      tourDate?: {
        __typename?: 'TourDate';
        id: string;
        applicationId: string;
        confirmed: boolean;
        countrySubdivision: string;
        venueName: string;
        venueCapacity: number;
        averageTicketPrice: string;
        paymentGuarantee: string;
        bookingAgency: string;
        bookingAgent: string;
        promoter: string;
        reason: string;
        date: string;
        status: TourDateStatus;
        isTooNew?: boolean | null;
        isTooOld?: boolean | null;
        isTooMany?: boolean | null;
        isEligible?: boolean | null;
        isOwner?: boolean | null;
        isWritable?: boolean | null;
        isCancellable?: boolean | null;
        meetsTicketedVenueCapacity?: boolean | null;
        sameDateLimit?: number | null;
        cancelled: boolean;
        alternate?: boolean | null;
        application?: {
          __typename?: 'Application';
          year?: number | null;
          alternateTourDatesAllowed?: boolean | null;
          tourDateRegions?: Array<{
            __typename?: 'TourDateRegion';
            id?: string | null;
            title?: string | null;
            totalTourDates?: number | null;
            maximumTourDates?: number | null;
            availableTourDates?: number | null;
          } | null> | null;
        } | null;
        tourDateType?: {
          __typename?: 'TourDateType';
          id: string;
          title: string;
          minimumTicketedVenueCapacity?: number | null;
          maximumTicketedVenueCapacity?: number | null;
        } | null;
        tourDateCountry?: {
          __typename?: 'Country';
          id: string;
          name: string;
          regionId: string;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword?: {
    __typename?: 'ChangePasswordPayload';
    response?: {
      __typename?: 'ChangePasswordResponse';
      code?: ChangePasswordResponseCode | null;
    } | null;
  } | null;
};

export type ClearFluxCapacitorMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ClearFluxCapacitorMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'ClearFluxCapacitorPayload';
    time?: string | null;
  } | null;
};

export type CompleteApplicationMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
}>;

export type CompleteApplicationMutation = {
  __typename?: 'Mutation';
  markApplicationCompleted?: {
    __typename?: 'MarkApplicationCompletedPayload';
    response?: {
      __typename?: 'MarkApplicationCompletedResponse';
      code?: MarkApplicationCompletedResponseCode | null;
    } | null;
  } | null;
};

export type CreateArtistAndLinkMutationVariables = Exact<{
  name: Scalars['String'];
}>;

export type CreateArtistAndLinkMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'CreateArtistAndLinkPayload';
    response?: {
      __typename?: 'CreateArtistAndLinkResponse';
      code?: CreateArtistAndLinkResponseCode | null;
    } | null;
  } | null;
};

export type CreateArtistInvitationMutationVariables = Exact<{
  email: Scalars['String'];
  artistId: Scalars['UUID'];
}>;

export type CreateArtistInvitationMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'CreateArtistInvitationPayload';
    response?: {
      __typename?: 'CreateArtistInvitationResponse';
      code?: CreateArtistInvitationResponseCode | null;
      email?: string | null;
      invitationId?: string | null;
    } | null;
  } | null;
};

export type CreateContactMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
  email: Scalars['Email'];
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact?: {
    __typename?: 'CreateContactPayload';
    contact?: { __typename?: 'Contact'; email: string } | null;
  } | null;
};

export type CreateCoreAccountInvitationMutationVariables = Exact<{
  invitationEmail: Scalars['String'];
  title: Scalars['String'];
  date: Scalars['String'];
  numberOfMinutes: Scalars['Int'];
  numberOfTracks: Scalars['Int'];
  isMajorityEnglish: Scalars['Boolean'];
  genreId: Scalars['String'];
  labelId: Scalars['String'];
  otherLabel?: InputMaybe<Scalars['String']>;
  distributorId: Scalars['String'];
  otherDistributor?: InputMaybe<Scalars['String']>;
  releasesScanned: Scalars['Int'];
  tracksDownloaded: Scalars['Int'];
  tracksStreamed: Scalars['Int'];
  company: Scalars['String'];
  website: Scalars['String'];
  contact: Scalars['String'];
  address: Scalars['String'];
  inCanada: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  notes: Scalars['String'];
}>;

export type CreateCoreAccountInvitationMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'CreateCoreAccountInvitationPayload';
    response?: {
      __typename?: 'CreateAccountInvitationResponse';
      code?: CreateAccountInvitationResponseCode | null;
      invitationId?: string | null;
    } | null;
  } | null;
};

export type CreateExpenseMutationVariables = Exact<{
  expense: ExpenseInput;
}>;

export type CreateExpenseMutation = {
  __typename?: 'Mutation';
  request?: {
    __typename?: 'CreateExpensePayload';
    fundingRequest?: {
      __typename?: 'FundingRequest';
      id: string;
      applicationId: string;
      fundingType: string;
      cost: string;
      date: string;
      strategy: string;
      reason: string;
      amountApproved?: string | null;
      status: FundingRequestStatus;
      createdAt: string;
      isTooNew?: boolean | null;
      isTooOld?: boolean | null;
      isEligible?: boolean | null;
      isOwner?: boolean | null;
      isWritable?: boolean | null;
      isCancellable?: boolean | null;
      cancelled: boolean;
      alternate?: boolean | null;
      expenses: Array<{
        __typename?: 'Expense';
        id: string;
        vendor: string;
        cost: string;
        invoiceNumber: string;
        date: string;
        description: string;
        fundingRequestId: string;
      }>;
    } | null;
  } | null;
};

export type CreateFundingRequestMutationVariables = Exact<{
  request: FundingRequestInput;
}>;

export type CreateFundingRequestMutation = {
  __typename?: 'Mutation';
  request?: {
    __typename?: 'CreateFundingRequestPayload';
    fundingRequest?: {
      __typename?: 'FundingRequest';
      id: string;
      applicationId: string;
      fundingType: string;
      cost: string;
      date: string;
      strategy: string;
      reason: string;
      amountApproved?: string | null;
      status: FundingRequestStatus;
      createdAt: string;
      isTooNew?: boolean | null;
      isTooOld?: boolean | null;
      isEligible?: boolean | null;
      isOwner?: boolean | null;
      isWritable?: boolean | null;
      isCancellable?: boolean | null;
      cancelled: boolean;
      alternate?: boolean | null;
      application?: {
        __typename?: 'Application';
        year?: number | null;
        budgetForFundingRequests?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateNewApplicationMutationVariables = Exact<{
  artistId: Scalars['UUID'];
}>;

export type CreateNewApplicationMutation = {
  __typename?: 'Mutation';
  createNewApplication?: {
    __typename?: 'CreateNewApplicationPayload';
    createApplicationResponse?: {
      __typename?: 'CreateApplicationResponse';
      id?: string | null;
    } | null;
  } | null;
};

export type CreateOrionAccountInvitationMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type CreateOrionAccountInvitationMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'CreateOrionAccountInvitationPayload';
    response?: {
      __typename?: 'CreateAccountInvitationResponse';
      code?: CreateAccountInvitationResponseCode | null;
      invitationId?: string | null;
    } | null;
  } | null;
};

export type CreateSingleMutationVariables = Exact<{
  input: CreateSingleInput;
}>;

export type CreateSingleMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'CreateSinglePayload';
    single?: {
      __typename?: 'Single';
      id: string;
      title: string;
      spins: number;
    } | null;
  } | null;
};

export type CreateTourDateMutationVariables = Exact<{
  tourDate: TourDateInput;
}>;

export type CreateTourDateMutation = {
  __typename?: 'Mutation';
  request?: {
    __typename?: 'CreateTourDatePayload';
    tourDate?: {
      __typename?: 'TourDate';
      id: string;
      applicationId: string;
      confirmed: boolean;
      countrySubdivision: string;
      venueName: string;
      venueCapacity: number;
      averageTicketPrice: string;
      paymentGuarantee: string;
      bookingAgency: string;
      bookingAgent: string;
      promoter: string;
      reason: string;
      date: string;
      status: TourDateStatus;
      isTooNew?: boolean | null;
      isTooOld?: boolean | null;
      isTooMany?: boolean | null;
      isEligible?: boolean | null;
      isOwner?: boolean | null;
      isWritable?: boolean | null;
      isCancellable?: boolean | null;
      meetsTicketedVenueCapacity?: boolean | null;
      sameDateLimit?: number | null;
      cancelled: boolean;
      alternate?: boolean | null;
      application?: {
        __typename?: 'Application';
        year?: number | null;
        alternateTourDatesAllowed?: boolean | null;
        tourDateRegions?: Array<{
          __typename?: 'TourDateRegion';
          id?: string | null;
          title?: string | null;
          totalTourDates?: number | null;
          maximumTourDates?: number | null;
          availableTourDates?: number | null;
        } | null> | null;
      } | null;
      tourDateType?: {
        __typename?: 'TourDateType';
        id: string;
        title: string;
        minimumTicketedVenueCapacity?: number | null;
        maximumTicketedVenueCapacity?: number | null;
      } | null;
      tourDateCountry?: {
        __typename?: 'Country';
        id: string;
        name: string;
        regionId: string;
      } | null;
    } | null;
  } | null;
};

export type DeleteArtistInvitationMutationVariables = Exact<{
  email: Scalars['String'];
  invitationId: Scalars['UUID'];
}>;

export type DeleteArtistInvitationMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DeleteArtistInvitationPayload';
    response?: {
      __typename?: 'DeleteArtistInvitationResponse';
      code?: DeleteArtistInvitationResponseCode | null;
      email?: string | null;
      invitationId?: string | null;
    } | null;
  } | null;
};

export type DeleteContactMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
  email: Scalars['Email'];
}>;

export type DeleteContactMutation = {
  __typename?: 'Mutation';
  deleteContactByApplicationIdAndEmail?: {
    __typename?: 'DeleteContactPayload';
    contact?: { __typename?: 'Contact'; email: string } | null;
  } | null;
};

export type DeleteArtistEligibilityNoticeMutationVariables = Exact<{
  input: DeleteEligibilityNoticeByArtistIdInput;
}>;

export type DeleteArtistEligibilityNoticeMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DeleteEligibilityNoticePayload';
    deletedEligibilityNoticeId?: string | null;
  } | null;
};

export type DeleteExpenseMutationVariables = Exact<{
  deleteId: DeleteExpenseByIdInput;
}>;

export type DeleteExpenseMutation = {
  __typename?: 'Mutation';
  request?: {
    __typename?: 'DeleteExpensePayload';
    fundingRequest?: {
      __typename?: 'FundingRequest';
      id: string;
      applicationId: string;
      fundingType: string;
      cost: string;
      date: string;
      strategy: string;
      reason: string;
      amountApproved?: string | null;
      status: FundingRequestStatus;
      createdAt: string;
      isTooNew?: boolean | null;
      isTooOld?: boolean | null;
      isEligible?: boolean | null;
      isOwner?: boolean | null;
      isWritable?: boolean | null;
      isCancellable?: boolean | null;
      cancelled: boolean;
      alternate?: boolean | null;
      expenses: Array<{
        __typename?: 'Expense';
        id: string;
        vendor: string;
        cost: string;
        invoiceNumber: string;
        date: string;
        description: string;
        fundingRequestId: string;
      }>;
    } | null;
  } | null;
};

export type DeleteFundingRequestMutationVariables = Exact<{
  deleteId: DeleteFundingRequestByIdInput;
}>;

export type DeleteFundingRequestMutation = {
  __typename?: 'Mutation';
  deleteFundingRequestById?: {
    __typename?: 'DeleteFundingRequestPayload';
    fundingRequest?: {
      __typename?: 'FundingRequest';
      id: string;
      application?: {
        __typename?: 'Application';
        year?: number | null;
        budgetForFundingRequests?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteManagementDetailsMutationVariables = Exact<{
  input: DeleteManagementDetailsInput;
}>;

export type DeleteManagementDetailsMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DeleteManagementDetailsPayload';
    clientMutationId?: string | null;
  } | null;
};

export type DeletePaymentMutationVariables = Exact<{
  input: DeletePaymentByIdInput;
  directDepositAccountId: Scalars['UUID'];
  applicationId: Scalars['UUID'];
}>;

export type DeletePaymentMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DeletePaymentPayload';
    payment?: { __typename?: 'Payment'; id: string } | null;
    query?: {
      __typename?: 'Query';
      directDepositAccount?: {
        __typename?: 'DirectDepositAccount';
        id: string;
        institution: string;
        maskedAccount: string;
        name: string;
        transit: string;
        paid?: string | null;
        payments: Array<{
          __typename?: 'Payment';
          id: string;
          directDepositAccountId: string;
          applicationId: string;
          createdAt: string;
          amount: string;
          transactionNumber: string;
          invoiceDate: string;
          notes?: string | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type DeleteReleaseMutationVariables = Exact<{
  input: DeleteReleaseByIdInput;
}>;

export type DeleteReleaseMutation = {
  __typename?: 'Mutation';
  deleteReleaseById?: {
    __typename?: 'DeleteReleasePayload';
    deletedReleaseId?: string | null;
  } | null;
};

export type DeleteSingleMutationVariables = Exact<{
  input: DeleteSingleByIdInput;
}>;

export type DeleteSingleMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DeleteSinglePayload';
    single?: { __typename?: 'Single'; id: string } | null;
  } | null;
};

export type DeleteTourDateMutationVariables = Exact<{
  deleteId: DeleteTourDateByIdInput;
}>;

export type DeleteTourDateMutation = {
  __typename?: 'Mutation';
  deleteTourDateById?: {
    __typename?: 'DeleteTourDatePayload';
    tourDate?: {
      __typename?: 'TourDate';
      id: string;
      application?: {
        __typename?: 'Application';
        year?: number | null;
        alternateTourDatesAllowed?: boolean | null;
        tourDateRegions?: Array<{
          __typename?: 'TourDateRegion';
          id?: string | null;
          title?: string | null;
          totalTourDates?: number | null;
          maximumTourDates?: number | null;
          availableTourDates?: number | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type InsertDirectDepositAndLinkToApplicationMutationVariables = Exact<{
  input: InsertDirectDepositAndLinkToApplicationInput;
  applicationId: Scalars['UUID'];
}>;

export type InsertDirectDepositAndLinkToApplicationMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'InsertDirectDepositAndLinkToApplicationPayload';
    response?: {
      __typename?: 'InsertDirectDepositAndLinkToApplicationResponse';
      code?: InsertDirectDepositAndLinkToApplicationResponseCode | null;
      directDepositAccount?: {
        __typename?: 'DirectDepositAccount';
        id: string;
        institution: string;
        maskedAccount: string;
        name: string;
        transit: string;
        paid?: string | null;
        payments: Array<{
          __typename?: 'Payment';
          id: string;
          directDepositAccountId: string;
          applicationId: string;
          createdAt: string;
          amount: string;
          transactionNumber: string;
          invoiceDate: string;
          notes?: string | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type LinkDirectDepositAccountToApplicationMutationVariables = Exact<{
  input: LinkDirectDepositAccountToApplicationInput;
  applicationId: Scalars['UUID'];
}>;

export type LinkDirectDepositAccountToApplicationMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'LinkDirectDepositAccountToApplicationPayload';
    response?: {
      __typename?: 'LinkDirectDepositAccountToApplicationResponse';
      code?: LinkDirectDepositAccountToApplicationResponseCode | null;
      directDepositAccount?: {
        __typename?: 'DirectDepositAccount';
        id: string;
        institution: string;
        maskedAccount: string;
        name: string;
        transit: string;
        paid?: string | null;
        payments: Array<{
          __typename?: 'Payment';
          id: string;
          directDepositAccountId: string;
          applicationId: string;
          createdAt: string;
          amount: string;
          transactionNumber: string;
          invoiceDate: string;
          notes?: string | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'ForgotPasswordPayload';
    response?: {
      __typename?: 'ForgotPasswordResponse';
      code?: ForgotPasswordResponseCode | null;
    } | null;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  tag: Scalars['String'];
  password: Scalars['String'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'ResetPasswordPayload';
    response?: {
      __typename?: 'ResetPasswordResponse';
      code?: ResetPasswordResponseCode | null;
    } | null;
  } | null;
};

export type ResubmitApplicationClaimsMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
}>;

export type ResubmitApplicationClaimsMutation = {
  __typename?: 'Mutation';
  resubmitApplicationClaims?: {
    __typename?: 'ResubmitApplicationClaimsPayload';
    response?: {
      __typename?: 'ResubmitApplicationClaimsResponse';
      code?: ResubmitApplicationClaimsResponseCode | null;
    } | null;
  } | null;
};

export type SetFluxCapacitorMutationVariables = Exact<{
  time: Scalars['Datetime'];
}>;

export type SetFluxCapacitorMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'SetFluxCapacitorPayload';
    time?: string | null;
  } | null;
};

export type SubmitApplicationMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
}>;

export type SubmitApplicationMutation = {
  __typename?: 'Mutation';
  submitApplication?: {
    __typename?: 'SubmitApplicationPayload';
    response?: {
      __typename?: 'SubmitApplicationResponse';
      code?: SubmitApplicationResponseCode | null;
    } | null;
  } | null;
};

export type SubmitApplicationClaimsMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
}>;

export type SubmitApplicationClaimsMutation = {
  __typename?: 'Mutation';
  submitApplicationClaims?: {
    __typename?: 'SubmitApplicationClaimsPayload';
    response?: {
      __typename?: 'SubmitApplicationClaimsResponse';
      code?: SubmitApplicationClaimsResponseCode | null;
    } | null;
  } | null;
};

export type SubscribeToNewsletterMutationVariables = Exact<{
  email: Scalars['Email'];
}>;

export type SubscribeToNewsletterMutation = {
  __typename?: 'Mutation';
  subscribeToNewsletter?: {
    __typename?: 'SubscribeToNewsletterPayload';
    clientMutationId?: string | null;
  } | null;
};

export type UnsubscribeFromNewsletterMutationVariables = Exact<{
  tag: Scalars['UUID'];
}>;

export type UnsubscribeFromNewsletterMutation = {
  __typename?: 'Mutation';
  unsubscribeFromNewsletter?: {
    __typename?: 'UnsubscribeFromNewsletterPayload';
    clientMutationId?: string | null;
  } | null;
};

export type UpdateAccountDetailsMutationVariables = Exact<{
  accountId: Scalars['UUID'];
  editedDetails: AccountPatch;
}>;

export type UpdateAccountDetailsMutation = {
  __typename?: 'Mutation';
  updateAccountById?: {
    __typename?: 'UpdateAccountPayload';
    account?: {
      __typename?: 'Account';
      name: string;
      phoneNumber: string;
      email: string;
      address: string;
      createdAt: string;
      artists: Array<{
        __typename?: 'AccountArtistBinding';
        artist?: { __typename?: 'Artist'; name: string; id: string } | null;
      }>;
    } | null;
    query?: {
      __typename?: 'Query';
      minimumPasswordLength?: number | null;
    } | null;
  } | null;
};

export type UpdateAccountInvitationMutationVariables = Exact<{
  invitationId: Scalars['String'];
  email: Scalars['String'];
}>;

export type UpdateAccountInvitationMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpdateAccountInvitationPayload';
    response?: {
      __typename?: 'UpdateAccountInvitationResponse';
      code?: UpdateAccountInvitationResponseCode | null;
    } | null;
  } | null;
};

export type UpdateApplicationMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
  statement?: InputMaybe<Scalars['String']>;
  primaryContactId?: InputMaybe<Scalars['UUID']>;
}>;

export type UpdateApplicationMutation = {
  __typename?: 'Mutation';
  request?: {
    __typename?: 'UpdateApplicationPayload';
    application?: {
      __typename?: 'Application';
      id: string;
      statement: string;
      primaryContactId: string;
      contacts: Array<{ __typename?: 'Contact'; email: string }>;
    } | null;
  } | null;
};

export type UpdateArtistDetailsMutationVariables = Exact<{
  input: UpdateArtistByIdInput;
}>;

export type UpdateArtistDetailsMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpdateArtistPayload';
    artist?: { __typename?: 'Artist'; name: string } | null;
  } | null;
};

export type UpdateArtistInvitationMutationVariables = Exact<{
  tag: Scalars['String'];
  email: Scalars['String'];
}>;

export type UpdateArtistInvitationMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpdateArtistInvitationPayload';
    response?: {
      __typename?: 'UpdateArtistInvitationResponse';
      code?: UpdateArtistInvitationResponseCode | null;
    } | null;
  } | null;
};

export type UpdateExpenseMutationVariables = Exact<{
  id: Scalars['UUID'];
  expense: ExpensePatch;
}>;

export type UpdateExpenseMutation = {
  __typename?: 'Mutation';
  request?: {
    __typename?: 'UpdateExpensePayload';
    fundingRequest?: {
      __typename?: 'FundingRequest';
      id: string;
      applicationId: string;
      fundingType: string;
      cost: string;
      date: string;
      strategy: string;
      reason: string;
      amountApproved?: string | null;
      status: FundingRequestStatus;
      createdAt: string;
      isTooNew?: boolean | null;
      isTooOld?: boolean | null;
      isEligible?: boolean | null;
      isOwner?: boolean | null;
      isWritable?: boolean | null;
      isCancellable?: boolean | null;
      cancelled: boolean;
      alternate?: boolean | null;
      expenses: Array<{
        __typename?: 'Expense';
        id: string;
        vendor: string;
        cost: string;
        invoiceNumber: string;
        date: string;
        description: string;
        fundingRequestId: string;
      }>;
    } | null;
  } | null;
};

export type UpdateFundingRequestMutationVariables = Exact<{
  id: Scalars['UUID'];
  request: FundingRequestPatch;
}>;

export type UpdateFundingRequestMutation = {
  __typename?: 'Mutation';
  request?: {
    __typename?: 'UpdateFundingRequestPayload';
    fundingRequest?: {
      __typename?: 'FundingRequest';
      id: string;
      applicationId: string;
      fundingType: string;
      cost: string;
      date: string;
      strategy: string;
      reason: string;
      amountApproved?: string | null;
      status: FundingRequestStatus;
      createdAt: string;
      isTooNew?: boolean | null;
      isTooOld?: boolean | null;
      isEligible?: boolean | null;
      isOwner?: boolean | null;
      isWritable?: boolean | null;
      isCancellable?: boolean | null;
      cancelled: boolean;
      alternate?: boolean | null;
      application?: {
        __typename?: 'Application';
        year?: number | null;
        budgetForFundingRequests?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateReleaseMutationVariables = Exact<{
  input: UpdateReleaseByIdInput;
}>;

export type UpdateReleaseMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpdateReleasePayload';
    release?: {
      __typename?: 'Release';
      id: string;
      updatedAt: string;
      isMajorityEnglish: boolean;
      title: string;
      otherLabel?: string | null;
      otherDistributor?: string | null;
      date: string;
      numberOfMinutes: number;
      numberOfTracks: number;
      company: string;
      website: string;
      inCanada: boolean;
      contact: string;
      phoneNumber: string;
      address: string;
      email: string;
      notes: string;
      isRestricted?: boolean | null;
      isEligible?: boolean | null;
      meetsDateRequirement?: boolean | null;
      meetsTracksRequirement?: boolean | null;
      meetsMinutesRequirement?: boolean | null;
      meetsSalesRequirement?: boolean | null;
      isOwner?: boolean | null;
      sales: Array<{
        __typename?: 'Sale';
        releasesScanned: number;
        tracksStreamed: number;
        tracksDownloaded: number;
        totalUnits?: number | null;
      }>;
      genre?: {
        __typename?: 'Genre';
        id: string;
        title: string;
        minimumSales: number;
        maximumSales: number;
      } | null;
      label?: { __typename?: 'Label'; id: string; name: string } | null;
      distributor?: {
        __typename?: 'Distributor';
        id: string;
        name: string;
      } | null;
      singles: Array<{
        __typename?: 'Single';
        id: string;
        title: string;
        spins: number;
      }>;
    } | null;
  } | null;
};

export type UpdateReleaseSalesMutationVariables = Exact<{
  releaseId: Scalars['UUID'];
  input: UpdateReleaseSalesInput;
}>;

export type UpdateReleaseSalesMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpdateReleaseSalesPayload';
    updateReleaseSalesResponse?: {
      __typename?: 'UpdateReleaseSalesResponse';
      code?: UpdateReleaseSalesResponseCode | null;
    } | null;
    query?: {
      __typename?: 'Query';
      release?: {
        __typename?: 'Release';
        id: string;
        updatedAt: string;
        isMajorityEnglish: boolean;
        title: string;
        otherLabel?: string | null;
        otherDistributor?: string | null;
        date: string;
        numberOfMinutes: number;
        numberOfTracks: number;
        company: string;
        website: string;
        inCanada: boolean;
        contact: string;
        phoneNumber: string;
        address: string;
        email: string;
        notes: string;
        isRestricted?: boolean | null;
        isEligible?: boolean | null;
        meetsDateRequirement?: boolean | null;
        meetsTracksRequirement?: boolean | null;
        meetsMinutesRequirement?: boolean | null;
        meetsSalesRequirement?: boolean | null;
        isOwner?: boolean | null;
        sales: Array<{
          __typename?: 'Sale';
          releasesScanned: number;
          tracksStreamed: number;
          tracksDownloaded: number;
          totalUnits?: number | null;
        }>;
        genre?: {
          __typename?: 'Genre';
          id: string;
          title: string;
          minimumSales: number;
          maximumSales: number;
        } | null;
        label?: { __typename?: 'Label'; id: string; name: string } | null;
        distributor?: {
          __typename?: 'Distributor';
          id: string;
          name: string;
        } | null;
        singles: Array<{
          __typename?: 'Single';
          id: string;
          title: string;
          spins: number;
        }>;
      } | null;
    } | null;
  } | null;
};

export type UpdateSingleMutationVariables = Exact<{
  input: UpdateSingleByIdInput;
}>;

export type UpdateSingleMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpdateSinglePayload';
    single?: {
      __typename?: 'Single';
      spins: number;
      title: string;
      id: string;
    } | null;
  } | null;
};

export type UpdateTourDateMutationVariables = Exact<{
  id: Scalars['UUID'];
  tourDate: TourDatePatch;
}>;

export type UpdateTourDateMutation = {
  __typename?: 'Mutation';
  request?: {
    __typename?: 'UpdateTourDatePayload';
    tourDate?: {
      __typename?: 'TourDate';
      id: string;
      applicationId: string;
      confirmed: boolean;
      countrySubdivision: string;
      venueName: string;
      venueCapacity: number;
      averageTicketPrice: string;
      paymentGuarantee: string;
      bookingAgency: string;
      bookingAgent: string;
      promoter: string;
      reason: string;
      date: string;
      status: TourDateStatus;
      isTooNew?: boolean | null;
      isTooOld?: boolean | null;
      isTooMany?: boolean | null;
      isEligible?: boolean | null;
      isOwner?: boolean | null;
      isWritable?: boolean | null;
      isCancellable?: boolean | null;
      meetsTicketedVenueCapacity?: boolean | null;
      sameDateLimit?: number | null;
      cancelled: boolean;
      alternate?: boolean | null;
      application?: {
        __typename?: 'Application';
        year?: number | null;
        alternateTourDatesAllowed?: boolean | null;
        tourDateRegions?: Array<{
          __typename?: 'TourDateRegion';
          id?: string | null;
          title?: string | null;
          totalTourDates?: number | null;
          maximumTourDates?: number | null;
          availableTourDates?: number | null;
        } | null> | null;
      } | null;
      tourDateType?: {
        __typename?: 'TourDateType';
        id: string;
        title: string;
        minimumTicketedVenueCapacity?: number | null;
        maximumTicketedVenueCapacity?: number | null;
      } | null;
      tourDateCountry?: {
        __typename?: 'Country';
        id: string;
        name: string;
        regionId: string;
      } | null;
    } | null;
  } | null;
};

export type UpsertApplicationBoardNotesMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
  notes: Scalars['String'];
}>;

export type UpsertApplicationBoardNotesMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpsertApplicationBoardNotesPayload';
    query?: {
      __typename?: 'Query';
      application?: {
        __typename?: 'Application';
        status: ApplicationStatus;
        reviewStatus: ApplicationReviewStatus;
        boardNotes?: {
          __typename?: 'ApplicationBoardNote';
          applicationId: string;
          notes: string;
        } | null;
        boardBlocks: Array<{
          __typename?: 'BoardMemberApplicationBlock';
          accountId: string;
          applicationId: string;
        }>;
      } | null;
    } | null;
  } | null;
};

export type UpsertManagementDetailsMutationVariables = Exact<{
  input: UpsertManagementInput;
}>;

export type UpsertManagementDetailsMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'UpsertManagementPayload';
    upsertManagementResponse?: {
      __typename?: 'UpsertManagementResponse';
      code?: UpsertManagementResponseCode | null;
    } | null;
  } | null;
};

export type VerifyNewsletterSubscriptionMutationVariables = Exact<{
  tag: Scalars['UUID'];
}>;

export type VerifyNewsletterSubscriptionMutation = {
  __typename?: 'Mutation';
  verifyNewsletterSubscription?: {
    __typename?: 'VerifyNewsletterSubscriptionPayload';
    clientMutationId?: string | null;
  } | null;
};

export type GetAdminBoardMeetingContextQueryVariables = Exact<{
  roundTitle: Scalars['String'];
}>;

export type GetAdminBoardMeetingContextQuery = {
  __typename?: 'Query';
  round?: {
    __typename?: 'FundingRound';
    id: string;
    title: string;
    numberOfCoreApplications?: number | null;
    numberOfOrionApplications?: number | null;
    pageBlocks: Array<{
      __typename?: 'BoardMeetingBlock';
      title: string;
      content: string;
      id: string;
      displayOrder: number;
      fundingRoundId: string;
      attachments?: Array<{
        __typename?: 'Attachment';
        id: string;
        name: string;
        size: number;
      } | null> | null;
    }>;
  } | null;
};

export type GetApplicationLogCountQueryVariables = Exact<{
  applicationId: Scalars['UUID'];
}>;

export type GetApplicationLogCountQuery = {
  __typename?: 'Query';
  count?: number | null;
};

export type GetApplicationLogsQueryVariables = Exact<{
  applicationId: Scalars['UUID'];
  offset: Scalars['Int'];
}>;

export type GetApplicationLogsQuery = {
  __typename?: 'Query';
  timezone?: string | null;
  application?: {
    __typename?: 'Application';
    logs?: Array<{
      __typename?: 'ApplicationLog';
      logMessage?: string | null;
      logAccountId?: string | null;
      logTransactionDate?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type GetBoardMembersQueryVariables = Exact<{ [key: string]: never }>;

export type GetBoardMembersQuery = {
  __typename?: 'Query';
  boardMembers?: Array<{
    __typename?: 'GetAllBoardMembersRecord';
    accountId?: string | null;
    name?: string | null;
    email?: string | null;
  } | null> | null;
  pendingMembers?: Array<{
    __typename?: 'PendingBoardMember';
    email?: string | null;
  } | null> | null;
};

export type GetDirectDepositAccountsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type GetDirectDepositAccountsQuery = {
  __typename?: 'Query';
  fundingRound?: {
    __typename?: 'FundingRound';
    id: string;
    title: string;
    applications: Array<{
      __typename?: 'Application';
      type: ApplicationType;
      touringDirectDepositAccount?: {
        __typename?: 'DirectDepositAccount';
        account: string;
        transit: string;
        name: string;
        maskedAccount: string;
        institution: string;
        id: string;
      } | null;
      fundingRequestDirectDepositAccount?: {
        __typename?: 'DirectDepositAccount';
        institution: string;
        maskedAccount: string;
        name: string;
        transit: string;
        id: string;
        account: string;
      } | null;
      artist?: { __typename?: 'Artist'; name: string } | null;
    }>;
  } | null;
};

export type GetFundingRoundsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFundingRoundsQuery = {
  __typename?: 'Query';
  timezone?: string | null;
  rounds?: Array<{
    __typename?: 'FundingRound';
    id: string;
    title: string;
    timezone?: string | null;
    startDate?: string | null;
    endDate: string;
    futureCutoffDate?: string | null;
    contractDeadline?: string | null;
    claimDeadline?: string | null;
    boardStartDate?: string | null;
    boardEndDate?: string | null;
    boardMeetingDate: string;
    active?: boolean | null;
    allowOrion: boolean;
    status?: FundingRoundStatusType | null;
    numberOfApplications?: number | null;
  }> | null;
};

export type SearchAccountsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  searchTerm: Scalars['String'];
  sortMode: Scalars['String'];
}>;

export type SearchAccountsQuery = {
  __typename?: 'Query';
  accounts?: Array<{
    __typename?: 'SearchAccountsRecord';
    id?: string | null;
    name?: string | null;
    email?: string | null;
    count?: number | null;
  } | null> | null;
};

export type SearchAdminArtistsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  sortMode: SearchAdminArtistsSortOrder;
  isEligible: Scalars['Boolean'];
  isIneligible: Scalars['Boolean'];
  isActivitySlowing: Scalars['Boolean'];
  isOrionPending: Scalars['Boolean'];
  under400kFunding: Scalars['Boolean'];
  over400kFunding: Scalars['Boolean'];
  nearing600kFunding: Scalars['Boolean'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;

export type SearchAdminArtistsQuery = {
  __typename?: 'Query';
  artists?: Array<{
    __typename?: 'SearchAdminArtistsRecord';
    name?: string | null;
    id?: string | null;
    eligibility?: ArtistEligibility | null;
    status?: ArtistStatus | null;
    releases?: string | null;
    total?: string | null;
  } | null> | null;
};

export type SearchSubscribersQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  searchTerm: Scalars['String'];
}>;

export type SearchSubscribersQuery = {
  __typename?: 'Query';
  subscribers?: Array<{
    __typename?: 'SearchSubscribersRecord';
    tag?: string | null;
    email?: string | null;
    count?: number | null;
  } | null> | null;
};

export type ArtistInvitationStatusQueryVariables = Exact<{
  tag: Scalars['String'];
}>;

export type ArtistInvitationStatusQuery = {
  __typename?: 'Query';
  minimumPasswordLength?: number | null;
  status?: {
    __typename?: 'ArtistInvitationStatusResponse';
    code?: ArtistInvitationStatusResponseCode | null;
    email?: string | null;
  } | null;
};

export type GetAccountDetailsQueryVariables = Exact<{
  accountId: Scalars['UUID'];
}>;

export type GetAccountDetailsQuery = {
  __typename?: 'Query';
  minimumPasswordLength?: number | null;
  account?: {
    __typename?: 'Account';
    name: string;
    phoneNumber: string;
    email: string;
    address: string;
    createdAt: string;
    artists: Array<{
      __typename?: 'AccountArtistBinding';
      artist?: { __typename?: 'Artist'; name: string; id: string } | null;
    }>;
  } | null;
};

export type GetAddArtistContextQueryVariables = Exact<{ [key: string]: never }>;

export type GetAddArtistContextQuery = {
  __typename?: 'Query';
  countries?: Array<{
    __typename?: 'Country';
    id: string;
    name: string;
    regionId: string;
  }> | null;
  provinces?: Array<{
    __typename?: 'Province';
    id: string;
    province: string;
  }> | null;
  legalStatuses?: Array<{
    __typename?: 'LegalStatus';
    id: string;
    name: string;
    description?: string | null;
    requiresProvince: boolean;
    provinceTitle?: string | null;
  }> | null;
};

export type GetAddReleaseContextQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAddReleaseContextQuery = {
  __typename?: 'Query';
  eligibilityCutoffDate?: string | null;
  releasesScannedDivisor?: number | null;
  tracksDownloadedDivisor?: number | null;
  tracksStreamedDivisor?: number | null;
  genres?: Array<{
    __typename?: 'Genre';
    id: string;
    title: string;
    minimumSales: number;
    maximumSales: number;
  }> | null;
  labels?: Array<{ __typename?: 'Label'; id: string; name: string }> | null;
  distributors?: Array<{
    __typename?: 'Distributor';
    id: string;
    name: string;
  }> | null;
};

export type GetAdminDashboardContextQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAdminDashboardContextQuery = {
  __typename?: 'Query';
  recentActiveRounds?: Array<{
    __typename?: 'FundingRound';
    id: string;
    title: string;
    timezone?: string | null;
    startDate?: string | null;
    endDate: string;
    futureCutoffDate?: string | null;
    contractDeadline?: string | null;
    claimDeadline?: string | null;
    boardStartDate?: string | null;
    boardEndDate?: string | null;
    boardMeetingDate: string;
    active?: boolean | null;
    allowOrion: boolean;
    status?: FundingRoundStatusType | null;
    numberOfApplications?: number | null;
  } | null> | null;
  allRounds?: Array<{
    __typename?: 'FundingRound';
    id: string;
    title: string;
  }> | null;
};

export type GetAllApplicationsPageQueryVariables = Exact<{
  artistId: Scalars['UUID'];
}>;

export type GetAllApplicationsPageQuery = {
  __typename?: 'Query';
  artist?: {
    __typename?: 'Artist';
    id: string;
    name: string;
    canStartApplication?: boolean | null;
    applications: Array<{
      __typename?: 'Application';
      id: string;
      timezone?: string | null;
      createdAt: string;
      submittedAt?: string | null;
      type: ApplicationType;
      status: ApplicationStatus;
      stage?: ApplicationStage | null;
      artistModifiedAt?: string | null;
      fundingRound?: { __typename?: 'FundingRound'; title: string } | null;
    }>;
  } | null;
};

export type GetAllArtistsPageQueryVariables = Exact<{
  accountId: Scalars['UUID'];
}>;

export type GetAllArtistsPageQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    name: string;
    artists?: Array<{
      __typename?: 'Artist';
      id: string;
      name: string;
    } | null> | null;
  } | null;
  currentRound?: {
    __typename?: 'FundingRound';
    title: string;
    timezone?: string | null;
    endDate: string;
    closingSoon?: boolean | null;
  } | null;
  nextRound?: { __typename?: 'FundingRound'; title: string } | null;
  previousRound?: {
    __typename?: 'FundingRound';
    title: string;
    timezone?: string | null;
    endDate: string;
  } | null;
};

export type GetAllReleasesPageQueryVariables = Exact<{
  artistId: Scalars['UUID'];
}>;

export type GetAllReleasesPageQuery = {
  __typename?: 'Query';
  artist?: {
    __typename?: 'Artist';
    id: string;
    name: string;
    isOwner?: boolean | null;
    releases?: Array<{
      __typename?: 'Release';
      id: string;
      title: string;
      isEligible?: boolean | null;
      date: string;
      otherLabel?: string | null;
      label?: { __typename?: 'Label'; name: string } | null;
    } | null> | null;
  } | null;
};

export type GetApplicationQueryVariables = Exact<{
  applicationId: Scalars['UUID'];
}>;

export type GetApplicationQuery = {
  __typename?: 'Query';
  now?: string | null;
  presentCutoffDate?: string | null;
  tourDateTypes?: Array<{
    __typename?: 'TourDateType';
    id: string;
    title: string;
    minimumTicketedVenueCapacity?: number | null;
    maximumTicketedVenueCapacity?: number | null;
  }> | null;
  countries?: Array<{
    __typename?: 'Country';
    id: string;
    name: string;
    regionId: string;
  }> | null;
  currentOrNextRound?: {
    __typename?: 'FundingRound';
    id: string;
    title: string;
    timezone?: string | null;
    startDate?: string | null;
    endDate: string;
    futureCutoffDate?: string | null;
    contractDeadline?: string | null;
    claimDeadline?: string | null;
    boardStartDate?: string | null;
    boardEndDate?: string | null;
    boardMeetingDate: string;
    active?: boolean | null;
    allowOrion: boolean;
    status?: FundingRoundStatusType | null;
    numberOfApplications?: number | null;
  } | null;
  previousRound?: { __typename?: 'FundingRound'; title: string } | null;
  application?: {
    __typename?: 'Application';
    requestedTotal?: string | null;
    requestedTotalForTourDates?: string | null;
    requestedTotalForFundingRequests?: string | null;
    tentativeTotal?: string | null;
    tentativeTotalForTourDates?: string | null;
    tentativeTotalForFundingRequests?: string | null;
    approvedTotal?: string | null;
    approvedTotalForTourDates?: string | null;
    approvedTotalForFundingRequests?: string | null;
    totalNumberOfTourDates?: number | null;
    totalNumberOfIneligibleTourDates?: number | null;
    totalNumberOfFundingRequests?: number | null;
    totalNumberOfIneligibleFundingRequests?: number | null;
    totalNumberOfApprovedTourDates?: number | null;
    totalNumberOfApprovedFundingRequests?: number | null;
    totalNumberOfClaimedTourDates?: number | null;
    totalNumberOfClaimedFundingRequests?: number | null;
    claimedTotalForFundingRequests?: string | null;
    claimedTotalForTourDates?: string | null;
    claimedTotal?: string | null;
    approvedFundingRequestsWithoutExpense?: number | null;
    touringPaid?: string | null;
    fundingRequestPaid?: string | null;
    isSubmittable?: boolean | null;
    meetsRoundRequirements?: boolean | null;
    isClaimsSubmittable?: boolean | null;
    isClaimsReopenable?: boolean | null;
    isCompletable?: boolean | null;
    claimsResubmissionDeadline?: string | null;
    year?: number | null;
    maximumYearlyTotalForFundingRequests?: string | null;
    remainingYearlyTotalForFundingRequests?: string | null;
    budgetForFundingRequests?: string | null;
    timezone?: string | null;
    submittedAt?: string | null;
    claimsSubmittedAt?: string | null;
    claimsReopenedAt?: string | null;
    claimsResubmittedAt?: string | null;
    reopenClaimsNotes?: string | null;
    eligibilityDeadline?: string | null;
    type: ApplicationType;
    meetsFundingRequestRequirement?: boolean | null;
    meetsStatementRequirement?: boolean | null;
    meetsTourDateRequirement?: boolean | null;
    meetsOrionRoundRequirement?: boolean | null;
    meetsClaimsTourDateRequirement?: boolean | null;
    meetsClaimsFundingRequestRequirement?: boolean | null;
    meetsClaimsTourSummaryRequirement?: boolean | null;
    meetsClaimsExpenseRequirement?: boolean | null;
    exceedsYearlyTotalForFundingRequests?: boolean | null;
    isOwner?: boolean | null;
    alternateTourDatesAllowed?: boolean | null;
    statement: string;
    primaryContactId: string;
    status: ApplicationStatus;
    stage?: ApplicationStage | null;
    reviewStatus: ApplicationReviewStatus;
    exceedsYearlyNumberOfTourDatesByRegion?: Array<string | null> | null;
    round?: {
      __typename?: 'FundingRound';
      id: string;
      title: string;
      timezone?: string | null;
      startDate?: string | null;
      endDate: string;
      futureCutoffDate?: string | null;
      contractDeadline?: string | null;
      claimDeadline?: string | null;
      boardStartDate?: string | null;
      boardEndDate?: string | null;
      boardMeetingDate: string;
      active?: boolean | null;
      allowOrion: boolean;
      status?: FundingRoundStatusType | null;
      numberOfApplications?: number | null;
    } | null;
    artist?: {
      __typename?: 'Artist';
      name: string;
      id: string;
      isEligible?: boolean | null;
      orionApprovalCount?: number | null;
      orionApprovalLimit?: number | null;
      accounts: Array<{
        __typename?: 'AccountArtistBinding';
        email?: string | null;
        name?: string | null;
        accountId: string;
      }>;
      directDepositAccounts: Array<{
        __typename?: 'DirectDepositAccount';
        id: string;
        institution: string;
        maskedAccount: string;
        name: string;
        transit: string;
        paid?: string | null;
        payments: Array<{
          __typename?: 'Payment';
          id: string;
          directDepositAccountId: string;
          applicationId: string;
          createdAt: string;
          amount: string;
          transactionNumber: string;
          invoiceDate: string;
          notes?: string | null;
        }>;
      }>;
    } | null;
    fundingRequestDirectDepositAccount?: {
      __typename?: 'DirectDepositAccount';
      id: string;
      institution: string;
      maskedAccount: string;
      name: string;
      transit: string;
      paid?: string | null;
      payments: Array<{
        __typename?: 'Payment';
        id: string;
        directDepositAccountId: string;
        applicationId: string;
        createdAt: string;
        amount: string;
        transactionNumber: string;
        invoiceDate: string;
        notes?: string | null;
      }>;
    } | null;
    touringDirectDepositAccount?: {
      __typename?: 'DirectDepositAccount';
      id: string;
      institution: string;
      maskedAccount: string;
      name: string;
      transit: string;
      paid?: string | null;
      payments: Array<{
        __typename?: 'Payment';
        id: string;
        directDepositAccountId: string;
        applicationId: string;
        createdAt: string;
        amount: string;
        transactionNumber: string;
        invoiceDate: string;
        notes?: string | null;
      }>;
    } | null;
    tourDateRegions?: Array<{
      __typename?: 'TourDateRegion';
      id?: string | null;
      title?: string | null;
      totalTourDates?: number | null;
      maximumTourDates?: number | null;
      availableTourDates?: number | null;
    } | null> | null;
    fundingRequests: Array<{
      __typename?: 'FundingRequest';
      id: string;
      applicationId: string;
      fundingType: string;
      cost: string;
      date: string;
      strategy: string;
      reason: string;
      amountApproved?: string | null;
      status: FundingRequestStatus;
      createdAt: string;
      isTooNew?: boolean | null;
      isTooOld?: boolean | null;
      isEligible?: boolean | null;
      isOwner?: boolean | null;
      isWritable?: boolean | null;
      isCancellable?: boolean | null;
      cancelled: boolean;
      alternate?: boolean | null;
      expenses: Array<{
        __typename?: 'Expense';
        id: string;
        vendor: string;
        cost: string;
        invoiceNumber: string;
        date: string;
        description: string;
        fundingRequestId: string;
      }>;
    }>;
    tourDates: Array<{
      __typename?: 'TourDate';
      id: string;
      applicationId: string;
      confirmed: boolean;
      countrySubdivision: string;
      venueName: string;
      venueCapacity: number;
      averageTicketPrice: string;
      paymentGuarantee: string;
      bookingAgency: string;
      bookingAgent: string;
      promoter: string;
      reason: string;
      date: string;
      status: TourDateStatus;
      isTooNew?: boolean | null;
      isTooOld?: boolean | null;
      isTooMany?: boolean | null;
      isEligible?: boolean | null;
      isOwner?: boolean | null;
      isWritable?: boolean | null;
      isCancellable?: boolean | null;
      meetsTicketedVenueCapacity?: boolean | null;
      sameDateLimit?: number | null;
      cancelled: boolean;
      alternate?: boolean | null;
      tourDateType?: {
        __typename?: 'TourDateType';
        id: string;
        title: string;
        minimumTicketedVenueCapacity?: number | null;
        maximumTicketedVenueCapacity?: number | null;
      } | null;
      tourDateCountry?: {
        __typename?: 'Country';
        id: string;
        name: string;
        regionId: string;
      } | null;
    }>;
    signedContract?: {
      __typename?: 'Attachment';
      id: string;
      name: string;
      size: number;
      isOwner?: boolean | null;
    } | null;
    tourSummary?: {
      __typename?: 'Attachment';
      id: string;
      name: string;
      size: number;
    } | null;
    attachments?: Array<{
      __typename?: 'Attachment';
      id: string;
      name: string;
      size: number;
      isOwner?: boolean | null;
    } | null> | null;
    contacts: Array<{ __typename?: 'Contact'; email: string }>;
    boardNotes?: {
      __typename?: 'ApplicationBoardNote';
      applicationId: string;
      notes: string;
    } | null;
    boardBlocks: Array<{
      __typename?: 'BoardMemberApplicationBlock';
      accountId: string;
      applicationId: string;
    }>;
    latestLog?: Array<{
      __typename?: 'ApplicationLog';
      logTransactionDate?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
  boardMembers?: Array<{
    __typename?: 'GetAllBoardMembersRecord';
    accountId?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetApplicationSearchPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetApplicationSearchPageQuery = {
  __typename?: 'Query';
  fundingRounds?: Array<{
    __typename?: 'FundingRound';
    id: string;
    title: string;
  }> | null;
  currentBoardRound?: { __typename?: 'FundingRound'; title: string } | null;
};

export type GetArtistByIdQueryVariables = Exact<{
  artistId: Scalars['UUID'];
}>;

export type GetArtistByIdQuery = {
  __typename?: 'Query';
  artist?: { __typename?: 'Artist'; id: string } | null;
};

export type GetArtistsQueryVariables = Exact<{ [key: string]: never }>;

export type GetArtistsQuery = {
  __typename?: 'Query';
  artists?: Array<{
    __typename?: 'Artist';
    id: string;
    name: string;
    applications: Array<{ __typename?: 'Application'; id: string }>;
  }> | null;
};

export type GetContactsByApplicationIdQueryVariables = Exact<{
  applicationId: Scalars['UUID'];
}>;

export type GetContactsByApplicationIdQuery = {
  __typename?: 'Query';
  accounts?: Array<{
    __typename?: 'AccountArtistBinding';
    email?: string | null;
  }> | null;
  application?: {
    __typename?: 'Application';
    contacts: Array<{ __typename?: 'Contact'; email: string }>;
  } | null;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  countries?: Array<{
    __typename?: 'Country';
    id: string;
    name: string;
  }> | null;
};

export type GetCountriesAndProvincesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCountriesAndProvincesQuery = {
  __typename?: 'Query';
  countries?: Array<{ __typename?: 'Country'; name: string }> | null;
  provinces?: Array<{ __typename?: 'Province'; province: string }> | null;
};

export type GetDashboardContextQueryVariables = Exact<{
  artistId: Scalars['UUID'];
}>;

export type GetDashboardContextQuery = {
  __typename?: 'Query';
  timezone?: string | null;
  maximumFundingAmount?: number | null;
  currentRound?: {
    __typename?: 'FundingRound';
    id: string;
    title: string;
    timezone?: string | null;
    endDate: string;
    boardMeetingDate: string;
    allowOrion: boolean;
    closingSoon?: boolean | null;
  } | null;
  nextRound?: { __typename?: 'FundingRound'; title: string } | null;
  previousRound?: {
    __typename?: 'FundingRound';
    title: string;
    timezone?: string | null;
    endDate: string;
  } | null;
  nextOrionRound?: { __typename?: 'FundingRound'; title: string } | null;
  countries?: Array<{
    __typename?: 'Country';
    id: string;
    name: string;
  }> | null;
  provinces?: Array<{
    __typename?: 'Province';
    id: string;
    province: string;
  }> | null;
  legalStatuses?: Array<{
    __typename?: 'LegalStatus';
    id: string;
    name: string;
    description?: string | null;
    requiresProvince: boolean;
    provinceTitle?: string | null;
  }> | null;
  pendingAccountBindings?: Array<{
    __typename?: 'GetPendingEmailsRecord';
    email?: string | null;
    invitationId?: string | null;
  } | null> | null;
  artist?: {
    __typename?: 'Artist';
    id: string;
    name: string;
    canStartApplication?: boolean | null;
    companyName: string;
    companyLegalProvince?: string | null;
    createdAt: string;
    isCoreEligible?: boolean | null;
    hasEligibleRelease?: boolean | null;
    aboveSalesRequirement?: boolean | null;
    aboveLifetimeFundingLimit?: boolean | null;
    status: ArtistStatus;
    orionApprovalCount?: number | null;
    orionApprovalLimit?: number | null;
    hasReachedOrionApprovalLimit?: boolean | null;
    showLegacyClaimsMessage: boolean;
    isOwner?: boolean | null;
    totalFunded?: string | null;
    legalStatus?: { __typename?: 'LegalStatus'; id: string } | null;
    management?: {
      __typename?: 'Management';
      name: string;
      contactPerson: string;
      address: string;
      phoneNumber: string;
      email: string;
      legalProvince?: string | null;
      countryId?: { __typename?: 'Country'; id: string } | null;
      legalStatus?: { __typename?: 'LegalStatus'; id: string } | null;
    } | null;
    provinceId?: { __typename?: 'Province'; id: string } | null;
    releases?: Array<{
      __typename?: 'Release';
      id: string;
      title: string;
      otherLabel?: string | null;
      eligible?: boolean | null;
      releaseDate: string;
      label?: { __typename?: 'Label'; name: string } | null;
    } | null> | null;
    accountBindings: Array<{
      __typename?: 'AccountArtistBinding';
      accountId: string;
      email?: string | null;
      isYou?: boolean | null;
      name?: string | null;
    }>;
    eligibilityNotice?: {
      __typename?: 'EligibilityNotice';
      message: string;
      type: EligibilityNoticeTypes;
    } | null;
  } | null;
  activeApplications?: Array<{
    __typename?: 'Application';
    id: string;
    timezone?: string | null;
    createdAt: string;
    totalNumberOfTourDates?: number | null;
    totalNumberOfFundingRequests?: number | null;
    approvedTotal?: string | null;
    requestedTotal?: string | null;
    type: ApplicationType;
    submittedAt?: string | null;
    status: ApplicationStatus;
    stage?: ApplicationStage | null;
    totalPaid?: string | null;
    claimsResubmissionDeadline?: string | null;
    reopenClaimsNotes?: string | null;
    fundingRound?: {
      __typename?: 'FundingRound';
      id: string;
      title: string;
      timezone?: string | null;
      boardMeetingDate: string;
      claimDeadline?: string | null;
    } | null;
  } | null> | null;
  closedApplications?: Array<{
    __typename?: 'Application';
    id: string;
    timezone?: string | null;
    submittedAt?: string | null;
    status: ApplicationStatus;
    stage?: ApplicationStage | null;
    type: ApplicationType;
    fundingRound?: { __typename?: 'FundingRound'; title: string } | null;
  } | null> | null;
};

export type GetEligibilityContextQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEligibilityContextQuery = {
  __typename?: 'Query';
  eligibilityCutoffDate?: string | null;
  releasesScannedDivisor?: number | null;
  tracksDownloadedDivisor?: number | null;
  tracksStreamedDivisor?: number | null;
  genres?: Array<{
    __typename?: 'Genre';
    id: string;
    title: string;
    minimumSales: number;
    maximumSales: number;
  }> | null;
  labels?: Array<{ __typename?: 'Label'; id: string; name: string }> | null;
  distributors?: Array<{
    __typename?: 'Distributor';
    id: string;
    name: string;
  }> | null;
};

export type GetFluxCapacitorQueryVariables = Exact<{ [key: string]: never }>;

export type GetFluxCapacitorQuery = {
  __typename?: 'Query';
  timezone?: string | null;
  time?: string | null;
  rounds?: Array<{
    __typename?: 'FundingRound';
    title: string;
    timezone?: string | null;
    startDate?: string | null;
  }> | null;
};

export type GetHomeContextQueryVariables = Exact<{ [key: string]: never }>;

export type GetHomeContextQuery = {
  __typename?: 'Query';
  now?: string | null;
  rounds?: Array<{
    __typename?: 'FundingRound';
    title: string;
    endDate: string;
    boardMeetingDate: string;
    status?: FundingRoundStatusType | null;
    claimDeadline?: string | null;
  } | null> | null;
};

export type GetOpenBoardMeetingContextQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOpenBoardMeetingContextQuery = {
  __typename?: 'Query';
  nextMeetingDate?: string | null;
  round?: {
    __typename?: 'FundingRound';
    id: string;
    title: string;
    numberOfCoreApplications?: number | null;
    numberOfOrionApplications?: number | null;
    pageBlocks: Array<{
      __typename?: 'BoardMeetingBlock';
      title: string;
      content: string;
      id: string;
      displayOrder: number;
      fundingRoundId: string;
      attachments?: Array<{
        __typename?: 'Attachment';
        id: string;
        name: string;
        size: number;
      } | null> | null;
    }>;
  } | null;
};

export type GetProvincesQueryVariables = Exact<{ [key: string]: never }>;

export type GetProvincesQuery = {
  __typename?: 'Query';
  provinces?: Array<{ __typename?: 'Province'; province: string }> | null;
};

export type GetRedeemAccountInvitationContextQueryVariables = Exact<{
  tag: Scalars['String'];
}>;

export type GetRedeemAccountInvitationContextQuery = {
  __typename?: 'Query';
  minimumPasswordLength?: number | null;
  countries?: Array<{
    __typename?: 'Country';
    id: string;
    name: string;
    regionId: string;
  }> | null;
  provinces?: Array<{
    __typename?: 'Province';
    id: string;
    province: string;
  }> | null;
  legalStatuses?: Array<{
    __typename?: 'LegalStatus';
    id: string;
    name: string;
    description?: string | null;
    requiresProvince: boolean;
    provinceTitle?: string | null;
  }> | null;
  status?: {
    __typename?: 'AccountInvitationStatusResponse';
    code?: AccountInvitationStatusResponseCode | null;
    email?: string | null;
    isOrion?: boolean | null;
  } | null;
};

export type GetRedeemArtistInvitationContextQueryVariables = Exact<{
  tag: Scalars['String'];
}>;

export type GetRedeemArtistInvitationContextQuery = {
  __typename?: 'Query';
  minimumPasswordLength?: number | null;
  status?: {
    __typename?: 'ArtistInvitationStatusResponse';
    code?: ArtistInvitationStatusResponseCode | null;
    email?: string | null;
  } | null;
};

export type GetRedeemBoardInvitationContextQueryVariables = Exact<{
  tag: Scalars['String'];
}>;

export type GetRedeemBoardInvitationContextQuery = {
  __typename?: 'Query';
  minimumPasswordLength?: number | null;
  status?: {
    __typename?: 'BoardMemberInvitationStatusResponse';
    code?: BoardMemberInvitationStatusResponseCode | null;
    email?: string | null;
  } | null;
};

export type GetReleasePageQueryVariables = Exact<{
  releaseId: Scalars['UUID'];
  artistId: Scalars['UUID'];
}>;

export type GetReleasePageQuery = {
  __typename?: 'Query';
  eligibilityCutoffDate?: string | null;
  tracksDownloadedDivisor?: number | null;
  tracksStreamedDivisor?: number | null;
  releasesScannedDivisor?: number | null;
  release?: {
    __typename?: 'Release';
    id: string;
    updatedAt: string;
    isMajorityEnglish: boolean;
    title: string;
    otherLabel?: string | null;
    otherDistributor?: string | null;
    date: string;
    numberOfMinutes: number;
    numberOfTracks: number;
    company: string;
    website: string;
    inCanada: boolean;
    contact: string;
    phoneNumber: string;
    address: string;
    email: string;
    notes: string;
    isRestricted?: boolean | null;
    isEligible?: boolean | null;
    meetsDateRequirement?: boolean | null;
    meetsTracksRequirement?: boolean | null;
    meetsMinutesRequirement?: boolean | null;
    meetsSalesRequirement?: boolean | null;
    isOwner?: boolean | null;
    sales: Array<{
      __typename?: 'Sale';
      releasesScanned: number;
      tracksStreamed: number;
      tracksDownloaded: number;
      totalUnits?: number | null;
    }>;
    genre?: {
      __typename?: 'Genre';
      id: string;
      title: string;
      minimumSales: number;
      maximumSales: number;
    } | null;
    label?: { __typename?: 'Label'; id: string; name: string } | null;
    distributor?: {
      __typename?: 'Distributor';
      id: string;
      name: string;
    } | null;
    singles: Array<{
      __typename?: 'Single';
      id: string;
      title: string;
      spins: number;
    }>;
  } | null;
  artist?: {
    __typename?: 'Artist';
    id: string;
    name: string;
    applications: Array<{
      __typename?: 'Application';
      submittedAt?: string | null;
      id: string;
    }>;
  } | null;
  genres?: Array<{
    __typename?: 'Genre';
    id: string;
    title: string;
    minimumSales: number;
    maximumSales: number;
  }> | null;
  labels?: Array<{ __typename?: 'Label'; id: string; name: string }> | null;
  distributors?: Array<{
    __typename?: 'Distributor';
    id: string;
    name: string;
  }> | null;
};

export type GetReportsQueryVariables = Exact<{
  year?: InputMaybe<Scalars['Int']>;
}>;

export type GetReportsQuery = {
  __typename?: 'Query';
  fiscalYearEnd?: string | null;
  newArtists?: string | null;
  submitted?: string | null;
  genre?: string | null;
  program?: string | null;
  province?: string | null;
};

export type ResetPasswordStatusQueryVariables = Exact<{
  tag: Scalars['String'];
}>;

export type ResetPasswordStatusQuery = {
  __typename?: 'Query';
  minimumPasswordLength?: number | null;
  status?: {
    __typename?: 'ResetPasswordStatusResponse';
    code?: ResetPasswordStatusResponseCode | null;
  } | null;
};

export type SearchApplicationsQueryVariables = Exact<{
  roundTitle?: InputMaybe<Scalars['String']>;
  alternateRequest?: InputMaybe<
    | Array<InputMaybe<SearchApplicationsAlternateRequest>>
    | InputMaybe<SearchApplicationsAlternateRequest>
  >;
  applicationStatus?: InputMaybe<ApplicationStatus>;
  applicationType?: InputMaybe<ApplicationType>;
  stage?: InputMaybe<
    Array<InputMaybe<ApplicationStage>> | InputMaybe<ApplicationStage>
  >;
  orderBy?: InputMaybe<SearchApplicationsSortOrder>;
  resultLimit?: InputMaybe<Scalars['Int']>;
  resultOffset?: InputMaybe<Scalars['Int']>;
  reviewStatus?: InputMaybe<
    | Array<InputMaybe<ApplicationReviewStatus>>
    | InputMaybe<ApplicationReviewStatus>
  >;
  searchQuery?: InputMaybe<Scalars['String']>;
  initialPaymentPaid?: InputMaybe<Scalars['Boolean']>;
}>;

export type SearchApplicationsQuery = {
  __typename?: 'Query';
  result?: Array<{
    __typename?: 'SearchApplicationsRecord';
    total?: number | null;
    applications?: Array<{
      __typename?: 'Application';
      id: string;
      timezone?: string | null;
      createdAt: string;
      status: ApplicationStatus;
      stage?: ApplicationStage | null;
      type: ApplicationType;
      submittedAt?: string | null;
      artistModifiedAt?: string | null;
      numberOfPendingRequests?: number | null;
      numberOfUnsettledRequests?: number | null;
      reviewStatus: ApplicationReviewStatus;
      totalRequested?: string | null;
      approvedTotal?: string | null;
      tentativeTotal?: string | null;
      claimedTotal?: string | null;
      totalPaid?: string | null;
      claimsResubmissionDeadline?: string | null;
      artist?: { __typename?: 'Artist'; id: string; name: string } | null;
      fundingRound?: { __typename?: 'FundingRound'; title: string } | null;
    } | null> | null;
  } | null> | null;
};

export const ApplicationAttributesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'applicationAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Application' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'boardNotes' },
            name: {
              kind: 'Name',
              value: 'applicationBoardNoteByApplicationId',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'boardBlocks' },
            name: {
              kind: 'Name',
              value: 'boardMemberApplicationBlocksByApplicationIdList',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicationAttributesFragment, unknown>;
export const DirectDepositAccountAttributesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directDepositAccountAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DirectDepositAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'institution' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maskedAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limitToApplicationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applicationId' },
                },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'payments' },
            name: {
              kind: 'Name',
              value: 'paymentsByDirectDepositAccountIdList',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'condition' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'CREATED_AT_DESC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'directDepositAccountId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DirectDepositAccountAttributesFragment, unknown>;
export const ExpenseAttributesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'expenseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Expense' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vendor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fundingRequestId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExpenseAttributesFragment, unknown>;
export const FundingRequestAttributesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fundingRequestAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundingRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fundingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amountApproved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTooNew' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTooOld' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isWritable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCancellable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alternate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FundingRequestAttributesFragment, unknown>;
export const FundingRoundAttributesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fundingRoundAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundingRound' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'futureCutoffDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'claimDeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boardStartDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boardEndDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'boardMeetingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowOrion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfApplications' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FundingRoundAttributesFragment, unknown>;
export const ReleaseAttributesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'releaseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Release' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sales' },
            name: { kind: 'Name', value: 'salesByReleaseIdList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'CREATED_AT_DESC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'releasesScanned' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tracksStreamed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tracksDownloaded' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalUnits' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'genre' },
            name: { kind: 'Name', value: 'genreByGenreId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumSales' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maximumSales' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isMajorityEnglish' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'label' },
            name: { kind: 'Name', value: 'labelByLabelId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherLabel' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'distributor' },
            name: { kind: 'Name', value: 'distributorByDistributorId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherDistributor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMinutes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfTracks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inCanada' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRestricted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEligible' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetsDateRequirement' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetsTracksRequirement' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetsMinutesRequirement' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetsSalesRequirement' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'singles' },
            name: { kind: 'Name', value: 'singlesByReleaseIdList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'spins' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReleaseAttributesFragment, unknown>;
export const TourDateAttributesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tourDateAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TourDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tourDateType' },
            name: { kind: 'Name', value: 'tourDateTypeByTourDateTypeId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumTicketedVenueCapacity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maximumTicketedVenueCapacity' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tourDateCountry' },
            name: { kind: 'Name', value: 'countryByTourDateCountryId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regionId' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'confirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countrySubdivision' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'venueName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'venueCapacity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageTicketPrice' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentGuarantee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bookingAgency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bookingAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promoter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTooNew' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTooOld' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTooMany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEligible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isWritable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCancellable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetsTicketedVenueCapacity' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sameDateLimit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alternate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TourDateAttributesFragment, unknown>;
export const BoardMeetingBlockAttributesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'boardMeetingBlockAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BoardMeetingBlock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fundingRoundId' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'attachments' },
            name: { kind: 'Name', value: 'attachmentsList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BoardMeetingBlockAttributesFragment, unknown>;
export const AddAlternateRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddAlternateRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddAlternateRequestInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'request' },
            name: { kind: 'Name', value: 'addAlternateRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fundingRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fundingRequestAttributes',
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: {
                          kind: 'Name',
                          value: 'applicationByApplicationId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'year' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'budgetForFundingRequests',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FundingRequestAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  AddAlternateRequestMutation,
  AddAlternateRequestMutationVariables
>;
export const AddArtistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddArtist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistProvince' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyLegalStatus' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyLegalProvince' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managementName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managementCountryId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managementContactPerson' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managementAddress' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managementPhoneNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managementEmail' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managementLegalStatus' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managementLegalProvince' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'addArtist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistProvince' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistProvince' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companyName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companyLegalStatus' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyLegalStatus' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companyLegalProvince' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyLegalProvince' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managementName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'managementName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managementCountryId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'managementCountryId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managementContactPerson' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'managementContactPerson',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managementAddress' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'managementAddress' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managementPhoneNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'managementPhoneNumber' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managementEmail' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'managementEmail' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managementLegalStatus' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'managementLegalStatus' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managementLegalProvince' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'managementLegalProvince',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: { kind: 'Name', value: 'addArtistResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'artistId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddArtistMutation, AddArtistMutationVariables>;
export const AddReleaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddRelease' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfMinutes' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfTracks' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isMajorityEnglish' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'genreId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'labelId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'otherLabel' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'distributorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'otherDistributor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'releasesScanned' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tracksDownloaded' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tracksStreamed' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'company' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'website' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contact' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'address' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inCanada' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'addRelease' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'numberOfMinutes' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'numberOfMinutes' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'numberOfTracks' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'numberOfTracks' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isMajorityEnglish' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isMajorityEnglish' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'genreId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'genreId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'labelId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'labelId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'otherLabel' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'otherLabel' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'distributorId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'distributorId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'otherDistributor' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'otherDistributor' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'releasesScanned' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'releasesScanned' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tracksDownloaded' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tracksDownloaded' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tracksStreamed' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tracksStreamed' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'company' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'company' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'website' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'website' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contact' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contact' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'address' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'inCanada' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inCanada' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'notes' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'notes' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: { kind: 'Name', value: 'addReleaseResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddReleaseMutation, AddReleaseMutationVariables>;
export const AddBoardBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddBoardBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'createBoardMemberApplicationBlock' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'boardMemberApplicationBlock',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'accountId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'accountId' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'applicationId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'applicationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'query' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: { kind: 'Name', value: 'applicationById' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'applicationId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'applicationAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  AddBoardBlockMutation,
  AddBoardBlockMutationVariables
>;
export const AddBoardMeetingBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddBoardMeetingBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'content' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roundId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'addBoardMeetingBlock' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'content' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'roundId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'roundId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'block' },
                  name: { kind: 'Name', value: 'boardMeetingBlock' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'boardMeetingBlockAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BoardMeetingBlockAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  AddBoardMeetingBlockMutation,
  AddBoardMeetingBlockMutationVariables
>;
export const AddPaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddPayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePaymentInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'directDepositAccountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'createPayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'query' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'directDepositAccount' },
                        name: {
                          kind: 'Name',
                          value: 'directDepositAccountById',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'directDepositAccountId',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'directDepositAccountAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DirectDepositAccountAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddPaymentMutation, AddPaymentMutationVariables>;
export const ApproveAndNotifyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveAndNotify' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emails' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'approveApplicationAndNotify' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emails' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'emails' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'query' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: { kind: 'Name', value: 'applicationById' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'applicationId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'applicationAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ApproveAndNotifyMutation,
  ApproveAndNotifyMutationVariables
>;
export const CreateAccountArtistBindingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAccountArtistBinding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'createAccountArtistBinding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountArtistBinding' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'accountId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'accountId' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'artistId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'artistId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'artistByArtistId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAccountArtistBindingMutation,
  CreateAccountArtistBindingMutationVariables
>;
export const CreateBoardMemberInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBoardMemberInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'createBoardMemberInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'createBoardMemberInvitationResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBoardMemberInvitationMutation,
  CreateBoardMemberInvitationMutationVariables
>;
export const CreateNewFundingRoundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNewFundingRound' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'round' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundingRoundInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFundingRound' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fundingRound' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'round' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'round' },
                  name: { kind: 'Name', value: 'fundingRound' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fundingRoundAttributes' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FundingRoundAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateNewFundingRoundMutation,
  CreateNewFundingRoundMutationVariables
>;
export const DeleteAccountArtistBindingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAccountArtistBinding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: {
              kind: 'Name',
              value: 'deleteAccountArtistBindingByAccountIdAndArtistId',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountArtistBinding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'artistId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAccountArtistBindingMutation,
  DeleteAccountArtistBindingMutationVariables
>;
export const DeleteBoardInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBoardInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteBoardMemberInvitationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteBoardMemberInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'deleteBoardMemberInvitationResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteBoardInvitationMutation,
  DeleteBoardInvitationMutationVariables
>;
export const DeleteBoardMeetingBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBoardMeetingBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteBoardMeetingBlockById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'block' },
                  name: { kind: 'Name', value: 'boardMeetingBlock' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteBoardMeetingBlockMutation,
  DeleteBoardMeetingBlockMutationVariables
>;
export const DeleteFundingRoundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFundingRound' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteFundingRoundById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'round' },
                  name: { kind: 'Name', value: 'fundingRound' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFundingRoundMutation,
  DeleteFundingRoundMutationVariables
>;
export const RemoveBoardBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveBoardBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: {
              kind: 'Name',
              value:
                'deleteBoardMemberApplicationBlockByAccountIdAndApplicationId',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'query' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: { kind: 'Name', value: 'applicationById' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'applicationId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'applicationAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  RemoveBoardBlockMutation,
  RemoveBoardBlockMutationVariables
>;
export const RemoveBoardMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveBoardMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'removeBoardMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: { kind: 'Name', value: 'removeBoardMemberResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveBoardMemberMutation,
  RemoveBoardMemberMutationVariables
>;
export const ReopenApplicationClaimsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReopenApplicationClaims' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reopenApplicationClaims' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'notes' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'notes' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'reopenApplicationClaimsResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReopenApplicationClaimsMutation,
  ReopenApplicationClaimsMutationVariables
>;
export const SwapBoardMeetingBlocksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwapBoardMeetingBlocks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SwapBoardMeetingBlockOrderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'swapBoardMeetingBlockOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'blocks' },
                  name: { kind: 'Name', value: 'boardMeetingBlocks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'boardMeetingBlockAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BoardMeetingBlockAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  SwapBoardMeetingBlocksMutation,
  SwapBoardMeetingBlocksMutationVariables
>;
export const UpdateApplicationReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateApplicationReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'patch' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApplicationPatch' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateApplicationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationPatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'patch' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'query' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: { kind: 'Name', value: 'applicationById' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'applicationId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'applicationAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateApplicationReviewMutation,
  UpdateApplicationReviewMutationVariables
>;
export const UpdateArtistStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateArtistStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArtistStatus' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateArtistStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'newStatus' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: { kind: 'Name', value: 'updateArtistStatusResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateArtistStatusMutation,
  UpdateArtistStatusMutationVariables
>;
export const UpdateBoardMeetingBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBoardMeetingBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'patch' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BoardMeetingBlockPatch' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateBoardMeetingBlockById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'boardMeetingBlockPatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'patch' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'block' },
                  name: { kind: 'Name', value: 'boardMeetingBlock' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'boardMeetingBlockAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BoardMeetingBlockAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateBoardMeetingBlockMutation,
  UpdateBoardMeetingBlockMutationVariables
>;
export const UpdateFundingRoundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFundingRound' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'round' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundingRoundPatch' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateFundingRoundById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fundingRoundPatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'round' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'round' },
                  name: { kind: 'Name', value: 'fundingRound' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fundingRoundAttributes' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FundingRoundAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateFundingRoundMutation,
  UpdateFundingRoundMutationVariables
>;
export const UpdatePaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePaymentByIdInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'directDepositAccountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updatePaymentById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'query' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'directDepositAccount' },
                        name: {
                          kind: 'Name',
                          value: 'directDepositAccountById',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'directDepositAccountId',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'directDepositAccountAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DirectDepositAccountAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdatePaymentMutation,
  UpdatePaymentMutationVariables
>;
export const UpdateReopenClaimsNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateReopenClaimsNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reopenClaimsNotes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateApplicationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationPatch' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'reopenClaimsNotes' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'reopenClaimsNotes',
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reopenClaimsNotes' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateReopenClaimsNotesMutation,
  UpdateReopenClaimsNotesMutationVariables
>;
export const UpsertEligibilityNoticeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertEligibilityNotice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertEligibilityNoticeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'upsertEligibilityNotice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'upsertEligibilityNoticeResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertEligibilityNoticeMutation,
  UpsertEligibilityNoticeMutationVariables
>;
export const CancelFundingRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelFundingRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelFundingRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fundingRequestId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cancelFundingRequestResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fundingRequest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fundingRequestAttributes',
                              },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'application' },
                              name: {
                                kind: 'Name',
                                value: 'applicationByApplicationId',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'year' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'budgetForFundingRequests',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FundingRequestAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CancelFundingRequestMutation,
  CancelFundingRequestMutationVariables
>;
export const CancelTourDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelTourDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelTourDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tourDateId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cancelTourDateResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tourDate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'tourDateAttributes',
                              },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'application' },
                              name: {
                                kind: 'Name',
                                value: 'applicationByApplicationId',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'year' },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'tourDateRegions',
                                    },
                                    name: {
                                      kind: 'Name',
                                      value: 'tourDateRegionsList',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'totalTourDates',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'maximumTourDates',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'availableTourDates',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'alternateTourDatesAllowed',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TourDateAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CancelTourDateMutation,
  CancelTourDateMutationVariables
>;
export const ChangePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'currentPassword' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currentPassword' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'newPassword' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newPassword' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: { kind: 'Name', value: 'changePasswordResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ClearFluxCapacitorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClearFluxCapacitor' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'clearFluxCapacitor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'ObjectValue', fields: [] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'time' },
                  name: { kind: 'Name', value: 'datetime' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClearFluxCapacitorMutation,
  ClearFluxCapacitorMutationVariables
>;
export const CompleteApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markApplicationCompleted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'markApplicationCompletedResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteApplicationMutation,
  CompleteApplicationMutationVariables
>;
export const CreateArtistAndLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateArtistAndLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'createArtistAndLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: { kind: 'Name', value: 'createArtistAndLinkResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateArtistAndLinkMutation,
  CreateArtistAndLinkMutationVariables
>;
export const CreateArtistInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateArtistInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'createArtistInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'createArtistInvitationResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invitationId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateArtistInvitationMutation,
  CreateArtistInvitationMutationVariables
>;
export const CreateContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contact' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'applicationId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'applicationId' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'email' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'email' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const CreateCoreAccountInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCoreAccountInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invitationEmail' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfMinutes' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfTracks' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isMajorityEnglish' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'genreId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'labelId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'otherLabel' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'distributorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'otherDistributor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'releasesScanned' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tracksDownloaded' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tracksStreamed' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'company' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'website' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contact' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'address' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inCanada' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'createCoreAccountInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'invitationEmail' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invitationEmail' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'numberOfMinutes' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'numberOfMinutes' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'numberOfTracks' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'numberOfTracks' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isMajorityEnglish' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isMajorityEnglish' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'genreId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'genreId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'labelId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'labelId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'otherLabel' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'otherLabel' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'distributorId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'distributorId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'otherDistributor' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'otherDistributor' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'releasesScanned' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'releasesScanned' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tracksDownloaded' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tracksDownloaded' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tracksStreamed' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tracksStreamed' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'company' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'company' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'website' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'website' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contact' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contact' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'address' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'inCanada' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inCanada' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'notes' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'notes' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'createAccountInvitationResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invitationId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCoreAccountInvitationMutation,
  CreateCoreAccountInvitationMutationVariables
>;
export const CreateExpenseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateExpense' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'expense' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExpenseInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'request' },
            name: { kind: 'Name', value: 'createExpense' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'expense' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'expense' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'fundingRequest' },
                  name: {
                    kind: 'Name',
                    value: 'fundingRequestByFundingRequestId',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fundingRequestAttributes',
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'expenses' },
                        name: {
                          kind: 'Name',
                          value: 'expensesByFundingRequestIdList',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'expenseAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FundingRequestAttributesFragmentDoc.definitions,
    ...ExpenseAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateExpenseMutation,
  CreateExpenseMutationVariables
>;
export const CreateFundingRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFundingRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'request' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundingRequestInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'request' },
            name: { kind: 'Name', value: 'createFundingRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fundingRequest' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'request' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fundingRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fundingRequestAttributes',
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: {
                          kind: 'Name',
                          value: 'applicationByApplicationId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'year' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'budgetForFundingRequests',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FundingRequestAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateFundingRequestMutation,
  CreateFundingRequestMutationVariables
>;
export const CreateNewApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNewApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNewApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createApplicationResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewApplicationMutation,
  CreateNewApplicationMutationVariables
>;
export const CreateOrionAccountInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOrionAccountInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'createOrionAccountInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'invitationEmail' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'createAccountInvitationResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invitationId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrionAccountInvitationMutation,
  CreateOrionAccountInvitationMutationVariables
>;
export const CreateSingleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSingle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSingleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'createSingle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'single' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spins' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSingleMutation,
  CreateSingleMutationVariables
>;
export const CreateTourDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTourDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tourDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TourDateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'request' },
            name: { kind: 'Name', value: 'createTourDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tourDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tourDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tourDate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'tourDateAttributes' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: {
                          kind: 'Name',
                          value: 'applicationByApplicationId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'year' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'tourDateRegions' },
                              name: {
                                kind: 'Name',
                                value: 'tourDateRegionsList',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'totalTourDates',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'maximumTourDates',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'availableTourDates',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'alternateTourDatesAllowed',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TourDateAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateTourDateMutation,
  CreateTourDateMutationVariables
>;
export const DeleteArtistInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteArtistInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invitationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteArtistInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'invitationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invitationId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'deleteArtistInvitationResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invitationId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteArtistInvitationMutation,
  DeleteArtistInvitationMutationVariables
>;
export const DeleteContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'deleteContactByApplicationIdAndEmail',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;
export const DeleteArtistEligibilityNoticeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteArtistEligibilityNotice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DeleteEligibilityNoticeByArtistIdInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteEligibilityNoticeByArtistId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deletedEligibilityNoticeId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteArtistEligibilityNoticeMutation,
  DeleteArtistEligibilityNoticeMutationVariables
>;
export const DeleteExpenseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteExpense' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteExpenseByIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'request' },
            name: { kind: 'Name', value: 'deleteExpenseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deleteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'fundingRequest' },
                  name: {
                    kind: 'Name',
                    value: 'fundingRequestByFundingRequestId',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fundingRequestAttributes',
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'expenses' },
                        name: {
                          kind: 'Name',
                          value: 'expensesByFundingRequestIdList',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'expenseAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FundingRequestAttributesFragmentDoc.definitions,
    ...ExpenseAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  DeleteExpenseMutation,
  DeleteExpenseMutationVariables
>;
export const DeleteFundingRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFundingRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteFundingRequestByIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFundingRequestById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deleteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fundingRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: {
                          kind: 'Name',
                          value: 'applicationByApplicationId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'year' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'budgetForFundingRequests',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFundingRequestMutation,
  DeleteFundingRequestMutationVariables
>;
export const DeleteManagementDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteManagementDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteManagementDetailsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteManagementDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientMutationId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteManagementDetailsMutation,
  DeleteManagementDetailsMutationVariables
>;
export const DeletePaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePaymentByIdInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'directDepositAccountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deletePaymentById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'query' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'directDepositAccount' },
                        name: {
                          kind: 'Name',
                          value: 'directDepositAccountById',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'directDepositAccountId',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'directDepositAccountAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DirectDepositAccountAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  DeletePaymentMutation,
  DeletePaymentMutationVariables
>;
export const DeleteReleaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRelease' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteReleaseByIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReleaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deletedReleaseId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReleaseMutation,
  DeleteReleaseMutationVariables
>;
export const DeleteSingleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSingle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteSingleByIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteSingleById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'single' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSingleMutation,
  DeleteSingleMutationVariables
>;
export const DeleteTourDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTourDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteTourDateByIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTourDateById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deleteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tourDate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: {
                          kind: 'Name',
                          value: 'applicationByApplicationId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'year' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'tourDateRegions' },
                              name: {
                                kind: 'Name',
                                value: 'tourDateRegionsList',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'totalTourDates',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'maximumTourDates',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'availableTourDates',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'alternateTourDatesAllowed',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTourDateMutation,
  DeleteTourDateMutationVariables
>;
export const InsertDirectDepositAndLinkToApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InsertDirectDepositAndLinkToApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'InsertDirectDepositAndLinkToApplicationInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: {
              kind: 'Name',
              value: 'insertDirectDepositAndLinkToApplication',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'insertDirectDepositAndLinkToApplicationResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'directDepositAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'directDepositAccountAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DirectDepositAccountAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  InsertDirectDepositAndLinkToApplicationMutation,
  InsertDirectDepositAndLinkToApplicationMutationVariables
>;
export const LinkDirectDepositAccountToApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LinkDirectDepositAccountToApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'LinkDirectDepositAccountToApplicationInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: {
              kind: 'Name',
              value: 'linkDirectDepositAccountToApplication',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'linkDirectDepositAccountToApplicationResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'directDepositAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'directDepositAccountAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DirectDepositAccountAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  LinkDirectDepositAccountToApplicationMutation,
  LinkDirectDepositAccountToApplicationMutationVariables
>;
export const ForgotPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ForgotPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'forgotPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: { kind: 'Name', value: 'forgotPasswordResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tag' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tag' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'password' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'password' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: { kind: 'Name', value: 'resetPasswordResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ResubmitApplicationClaimsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResubmitApplicationClaims' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resubmitApplicationClaims' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'resubmitApplicationClaimsResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResubmitApplicationClaimsMutation,
  ResubmitApplicationClaimsMutationVariables
>;
export const SetFluxCapacitorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetFluxCapacitor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'time' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Datetime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'setFluxCapacitor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'newNow' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'time' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'time' },
                  name: { kind: 'Name', value: 'datetime' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetFluxCapacitorMutation,
  SetFluxCapacitorMutationVariables
>;
export const SubmitApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: { kind: 'Name', value: 'submitApplicationResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitApplicationMutation,
  SubmitApplicationMutationVariables
>;
export const SubmitApplicationClaimsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitApplicationClaims' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitApplicationClaims' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'submitApplicationClaimsResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitApplicationClaimsMutation,
  SubmitApplicationClaimsMutationVariables
>;
export const SubscribeToNewsletterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubscribeToNewsletter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToNewsletter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientMutationId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToNewsletterMutation,
  SubscribeToNewsletterMutationVariables
>;
export const UnsubscribeFromNewsletterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnsubscribeFromNewsletter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unsubscribeFromNewsletter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tag' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tag' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientMutationId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnsubscribeFromNewsletterMutation,
  UnsubscribeFromNewsletterMutationVariables
>;
export const UpdateAccountDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccountDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'editedDetails' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AccountPatch' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccountById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountPatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'editedDetails' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'artists' },
                        name: {
                          kind: 'Name',
                          value: 'accountArtistBindingsByAccountIdList',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'artist' },
                              name: { kind: 'Name', value: 'artistByArtistId' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'id' },
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'query' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minimumPasswordLength' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccountDetailsMutation,
  UpdateAccountDetailsMutationVariables
>;
export const UpdateAccountInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccountInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invitationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateAccountInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'invitationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invitationId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'updateAccountInvitationResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccountInvitationMutation,
  UpdateAccountInvitationMutationVariables
>;
export const UpdateApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'statement' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'primaryContactId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'request' },
            name: { kind: 'Name', value: 'updateApplicationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationPatch' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'statement' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'statement' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'primaryContactId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'primaryContactId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statement' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'primaryContactId' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'contacts' },
                        name: {
                          kind: 'Name',
                          value: 'contactsByApplicationIdList',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>;
export const UpdateArtistDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateArtistDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateArtistByIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateArtistById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'artist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateArtistDetailsMutation,
  UpdateArtistDetailsMutationVariables
>;
export const UpdateArtistInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateArtistInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateArtistInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tag' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tag' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'response' },
                  name: {
                    kind: 'Name',
                    value: 'updateArtistInvitationResponse',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateArtistInvitationMutation,
  UpdateArtistInvitationMutationVariables
>;
export const UpdateExpenseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateExpense' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'expense' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExpensePatch' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'request' },
            name: { kind: 'Name', value: 'updateExpenseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'expensePatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'expense' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'fundingRequest' },
                  name: {
                    kind: 'Name',
                    value: 'fundingRequestByFundingRequestId',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fundingRequestAttributes',
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'expenses' },
                        name: {
                          kind: 'Name',
                          value: 'expensesByFundingRequestIdList',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'expenseAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FundingRequestAttributesFragmentDoc.definitions,
    ...ExpenseAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateExpenseMutation,
  UpdateExpenseMutationVariables
>;
export const UpdateFundingRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFundingRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'request' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundingRequestPatch' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'request' },
            name: { kind: 'Name', value: 'updateFundingRequestById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fundingRequestPatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'request' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fundingRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fundingRequestAttributes',
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: {
                          kind: 'Name',
                          value: 'applicationByApplicationId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'year' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'budgetForFundingRequests',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FundingRequestAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateFundingRequestMutation,
  UpdateFundingRequestMutationVariables
>;
export const UpdateReleaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRelease' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateReleaseByIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateReleaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'release' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'releaseAttributes' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ReleaseAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateReleaseMutation,
  UpdateReleaseMutationVariables
>;
export const UpdateReleaseSalesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateReleaseSales' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'releaseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateReleaseSalesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateReleaseSales' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateReleaseSalesResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'query' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'release' },
                        name: { kind: 'Name', value: 'releaseById' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'releaseId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'releaseAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ReleaseAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateReleaseSalesMutation,
  UpdateReleaseSalesMutationVariables
>;
export const UpdateSingleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSingle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSingleByIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateSingleById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'single' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'spins' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSingleMutation,
  UpdateSingleMutationVariables
>;
export const UpdateTourDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTourDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tourDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TourDatePatch' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'request' },
            name: { kind: 'Name', value: 'updateTourDateById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tourDatePatch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tourDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tourDate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'tourDateAttributes' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: {
                          kind: 'Name',
                          value: 'applicationByApplicationId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'year' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'tourDateRegions' },
                              name: {
                                kind: 'Name',
                                value: 'tourDateRegionsList',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'totalTourDates',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'maximumTourDates',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'availableTourDates',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'alternateTourDatesAllowed',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TourDateAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateTourDateMutation,
  UpdateTourDateMutationVariables
>;
export const UpsertApplicationBoardNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertApplicationBoardNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'upsertApplicationBoardNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'notes' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'notes' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'query' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'application' },
                        name: { kind: 'Name', value: 'applicationById' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'applicationId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'applicationAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpsertApplicationBoardNotesMutation,
  UpsertApplicationBoardNotesMutationVariables
>;
export const UpsertManagementDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertManagementDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertManagementInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'upsertManagement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upsertManagementResponse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertManagementDetailsMutation,
  UpsertManagementDetailsMutationVariables
>;
export const VerifyNewsletterSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyNewsletterSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyNewsletterSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tag' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tag' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientMutationId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyNewsletterSubscriptionMutation,
  VerifyNewsletterSubscriptionMutationVariables
>;
export const GetAdminBoardMeetingContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdminBoardMeetingContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roundTitle' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'round' },
            name: { kind: 'Name', value: 'fundingRoundByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'roundTitle' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfCoreApplications' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfOrionApplications' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'pageBlocks' },
                  name: {
                    kind: 'Name',
                    value: 'boardMeetingBlocksByFundingRoundIdList',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'EnumValue', value: 'DISPLAY_ORDER_ASC' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'boardMeetingBlockAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BoardMeetingBlockAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetAdminBoardMeetingContextQuery,
  GetAdminBoardMeetingContextQueryVariables
>;
export const GetApplicationLogCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApplicationLogCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'count' },
            name: { kind: 'Name', value: 'getAdminApplicationLogCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applicationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applicationId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetApplicationLogCountQuery,
  GetApplicationLogCountQueryVariables
>;
export const GetApplicationLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApplicationLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'application' },
            name: { kind: 'Name', value: 'applicationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applicationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'logs' },
                  name: { kind: 'Name', value: 'logsList' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'offset' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logMessage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logAccountId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logTransactionDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetApplicationLogsQuery,
  GetApplicationLogsQueryVariables
>;
export const GetBoardMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBoardMembers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'boardMembers' },
            name: { kind: 'Name', value: 'getAllBoardMembersList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'pendingMembers' },
            name: { kind: 'Name', value: 'getPendingBoardMembersList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBoardMembersQuery,
  GetBoardMembersQueryVariables
>;
export const GetDirectDepositAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDirectDepositAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'fundingRound' },
            name: { kind: 'Name', value: 'fundingRoundById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'applications' },
                  name: {
                    kind: 'Name',
                    value: 'applicationsByFundingRoundIdList',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: {
                          kind: 'Name',
                          value: 'touringDirectDepositAccount',
                        },
                        name: {
                          kind: 'Name',
                          value: 'directDepositAccountByTouringDirectDepositId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'transit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maskedAccount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'institution' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: {
                          kind: 'Name',
                          value: 'fundingRequestDirectDepositAccount',
                        },
                        name: {
                          kind: 'Name',
                          value:
                            'directDepositAccountByFundingRequestDirectDepositId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'institution' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maskedAccount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'transit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'artist' },
                        name: { kind: 'Name', value: 'artistByArtistId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDirectDepositAccountsQuery,
  GetDirectDepositAccountsQueryVariables
>;
export const GetFundingRoundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFundingRounds' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'rounds' },
            name: { kind: 'Name', value: 'allFundingRoundsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'START_DATE_DESC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fundingRoundAttributes' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
        ],
      },
    },
    ...FundingRoundAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetFundingRoundsQuery,
  GetFundingRoundsQueryVariables
>;
export const SearchAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortMode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'accounts' },
            name: { kind: 'Name', value: 'searchAccountsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pOffset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pLimit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchTerm' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortMode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAccountsQuery, SearchAccountsQueryVariables>;
export const SearchAdminArtistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchAdminArtists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortMode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SearchAdminArtistsSortOrder' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isEligible' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isIneligible' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isActivitySlowing' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isOrionPending' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'under400kFunding' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'over400kFunding' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nearing600kFunding' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'artists' },
            name: { kind: 'Name', value: 'searchAdminArtistsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchTerm' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortMode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortMode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isEligible' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isEligible' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isIneligible' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isIneligible' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isActivitySlowing' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isActivitySlowing' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isOrionPending' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isOrionPending' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'under400KFunding' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'under400kFunding' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'over400KFunding' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'over400kFunding' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nearing600KFunding' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nearing600kFunding' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pOffset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pLimit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eligibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'releases' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchAdminArtistsQuery,
  SearchAdminArtistsQueryVariables
>;
export const SearchSubscribersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchSubscribers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subscribers' },
            name: { kind: 'Name', value: 'searchSubscribersList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pOffset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pLimit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchTerm' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchSubscribersQuery,
  SearchSubscribersQueryVariables
>;
export const ArtistInvitationStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArtistInvitationStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'status' },
            name: { kind: 'Name', value: 'artistInvitationStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tag' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tag' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumPasswordLength' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ArtistInvitationStatusQuery,
  ArtistInvitationStatusQueryVariables
>;
export const GetAccountDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'account' },
            name: { kind: 'Name', value: 'accountById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'artists' },
                  name: {
                    kind: 'Name',
                    value: 'accountArtistBindingsByAccountIdList',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'artist' },
                        name: { kind: 'Name', value: 'artistByArtistId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumPasswordLength' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccountDetailsQuery,
  GetAccountDetailsQueryVariables
>;
export const GetAddArtistContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAddArtistContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'countries' },
            name: { kind: 'Name', value: 'allCountriesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regionId' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'provinces' },
            name: { kind: 'Name', value: 'allProvincesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'province' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'legalStatuses' },
            name: { kind: 'Name', value: 'allLegalStatusesList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'DISPLAYORDER_ASC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiresProvince' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provinceTitle' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAddArtistContextQuery,
  GetAddArtistContextQueryVariables
>;
export const GetAddReleaseContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAddReleaseContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'genres' },
            name: { kind: 'Name', value: 'allGenresList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'condition' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'enabled' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'DISPLAYORDER_ASC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumSales' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maximumSales' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'labels' },
            name: { kind: 'Name', value: 'allLabelsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'DISPLAY_ORDER_ASC' },
                    { kind: 'EnumValue', value: 'NAME_ASC' },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'distributors' },
            name: { kind: 'Name', value: 'allDistributorsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'DISPLAY_ORDER_ASC' },
                    { kind: 'EnumValue', value: 'NAME_ASC' },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eligibilityCutoffDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releasesScannedDivisor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tracksDownloadedDivisor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tracksStreamedDivisor' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAddReleaseContextQuery,
  GetAddReleaseContextQueryVariables
>;
export const GetAdminDashboardContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdminDashboardContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'recentActiveRounds' },
            name: { kind: 'Name', value: 'getAllActiveRoundsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '3' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fundingRoundAttributes' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allRounds' },
            name: { kind: 'Name', value: 'allFundingRoundsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'TITLE_DESC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
    ...FundingRoundAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetAdminDashboardContextQuery,
  GetAdminDashboardContextQueryVariables
>;
export const GetAllApplicationsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllApplicationsPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'artist' },
            name: { kind: 'Name', value: 'artistById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'artistId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'canStartApplication' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'applications' },
                  name: { kind: 'Name', value: 'applicationsByArtistIdList' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'EnumValue', value: 'SUBMITTED_AT_DESC' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submittedAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'artistModifiedAt' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'fundingRound' },
                        name: {
                          kind: 'Name',
                          value: 'fundingRoundByFundingRoundId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllApplicationsPageQuery,
  GetAllApplicationsPageQueryVariables
>;
export const GetAllArtistsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllArtistsPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'account' },
            name: { kind: 'Name', value: 'accountById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'artists' },
                  name: { kind: 'Name', value: 'artistsByAccountIdList' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'EnumValue', value: 'NAME_ASC' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'currentRound' },
            name: { kind: 'Name', value: 'getCurrentFundingRound' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closingSoon' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'nextRound' },
            name: { kind: 'Name', value: 'getNextFundingRound' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'previousRound' },
            name: { kind: 'Name', value: 'getPreviousFundingRound' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllArtistsPageQuery,
  GetAllArtistsPageQueryVariables
>;
export const GetAllReleasesPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllReleasesPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'artist' },
            name: { kind: 'Name', value: 'artistById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'artistId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'releases' },
                  name: { kind: 'Name', value: 'releasesByArtistIdList' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'EnumValue', value: 'DATE_DESC' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isEligible' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'labelByLabelId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'otherLabel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllReleasesPageQuery,
  GetAllReleasesPageQueryVariables
>;
export const GetApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'now' } },
          { kind: 'Field', name: { kind: 'Name', value: 'presentCutoffDate' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tourDateTypes' },
            name: { kind: 'Name', value: 'allTourDateTypesList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'DISPLAYORDER_ASC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumTicketedVenueCapacity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maximumTicketedVenueCapacity' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'countries' },
            name: { kind: 'Name', value: 'allCountriesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regionId' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'currentOrNextRound' },
            name: { kind: 'Name', value: 'getCurrentOrNextFundingRound' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fundingRoundAttributes' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'previousRound' },
            name: { kind: 'Name', value: 'getPreviousFundingRound' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'application' },
            name: { kind: 'Name', value: 'applicationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applicationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestedTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestedTotalForTourDates' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'requestedTotalForFundingRequests',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tentativeTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tentativeTotalForTourDates' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'tentativeTotalForFundingRequests',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvedTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvedTotalForTourDates' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'approvedTotalForFundingRequests',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalNumberOfTourDates' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'totalNumberOfIneligibleTourDates',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalNumberOfFundingRequests' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'totalNumberOfIneligibleFundingRequests',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'totalNumberOfApprovedTourDates',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'totalNumberOfApprovedFundingRequests',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'totalNumberOfClaimedTourDates',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'totalNumberOfClaimedFundingRequests',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'claimedTotalForFundingRequests',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'claimedTotalForTourDates' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'claimedTotal' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'approvedFundingRequestsWithoutExpense',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'touringPaid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fundingRequestPaid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSubmittable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meetsRoundRequirements' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isClaimsSubmittable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isClaimsReopenable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCompletable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'claimsResubmissionDeadline' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'maximumYearlyTotalForFundingRequests',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'remainingYearlyTotalForFundingRequests',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budgetForFundingRequests' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'claimsSubmittedAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'claimsReopenedAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'claimsResubmittedAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reopenClaimsNotes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eligibilityDeadline' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'meetsFundingRequestRequirement',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meetsStatementRequirement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meetsTourDateRequirement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meetsOrionRoundRequirement' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'meetsClaimsTourDateRequirement',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'meetsClaimsFundingRequestRequirement',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'meetsClaimsTourSummaryRequirement',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'meetsClaimsExpenseRequirement',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'exceedsYearlyTotalForFundingRequests',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alternateTourDatesAllowed' },
                },
                {
                  kind: 'Field',
                  alias: {
                    kind: 'Name',
                    value: 'exceedsYearlyNumberOfTourDatesByRegion',
                  },
                  name: {
                    kind: 'Name',
                    value: 'exceedsYearlyNumberOfTourDatesByRegionList',
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'round' },
                  name: { kind: 'Name', value: 'fundingRoundByFundingRoundId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fundingRoundAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'artist' },
                  name: { kind: 'Name', value: 'artistByArtistId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isEligible' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orionApprovalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orionApprovalLimit' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'accounts' },
                        name: {
                          kind: 'Name',
                          value: 'accountArtistBindingsByArtistIdList',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'directDepositAccounts' },
                        name: {
                          kind: 'Name',
                          value: 'directDepositAccountsByArtistIdList',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'directDepositAccountAttributes',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orionApprovalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orionApprovalLimit' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: {
                    kind: 'Name',
                    value: 'fundingRequestDirectDepositAccount',
                  },
                  name: {
                    kind: 'Name',
                    value:
                      'directDepositAccountByFundingRequestDirectDepositId',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'directDepositAccountAttributes',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'touringDirectDepositAccount' },
                  name: {
                    kind: 'Name',
                    value: 'directDepositAccountByTouringDirectDepositId',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'directDepositAccountAttributes',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tourDateRegions' },
                  name: { kind: 'Name', value: 'tourDateRegionsList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalTourDates' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maximumTourDates' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableTourDates' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'fundingRequests' },
                  name: {
                    kind: 'Name',
                    value: 'fundingRequestsByApplicationIdList',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fundingRequestAttributes',
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'expenses' },
                        name: {
                          kind: 'Name',
                          value: 'expensesByFundingRequestIdList',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'expenseAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tourDates' },
                  name: { kind: 'Name', value: 'tourDatesByApplicationIdList' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'EnumValue', value: 'DATE_ASC' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'tourDateAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'signedContract' },
                  name: { kind: 'Name', value: 'attachmentBySignedContractId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isOwner' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tourSummary' },
                  name: { kind: 'Name', value: 'attachmentByTourSummaryId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'attachments' },
                  name: {
                    kind: 'Name',
                    value: 'attachmentsByApplicationIdList',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isOwner' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'statement' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'contacts' },
                  name: { kind: 'Name', value: 'contactsByApplicationIdList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primaryContactId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewStatus' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'boardNotes' },
                  name: {
                    kind: 'Name',
                    value: 'applicationBoardNoteByApplicationId',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'boardBlocks' },
                  name: {
                    kind: 'Name',
                    value: 'boardMemberApplicationBlocksByApplicationIdList',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicationId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'latestLog' },
                  name: { kind: 'Name', value: 'logsList' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logTransactionDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'boardMembers' },
            name: { kind: 'Name', value: 'getAllBoardMembersList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    ...FundingRoundAttributesFragmentDoc.definitions,
    ...DirectDepositAccountAttributesFragmentDoc.definitions,
    ...FundingRequestAttributesFragmentDoc.definitions,
    ...ExpenseAttributesFragmentDoc.definitions,
    ...TourDateAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetApplicationQuery, GetApplicationQueryVariables>;
export const GetApplicationSearchPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApplicationSearchPage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'fundingRounds' },
            name: { kind: 'Name', value: 'allFundingRoundsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'TITLE_DESC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'currentBoardRound' },
            name: { kind: 'Name', value: 'getCurrentBoardRound' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetApplicationSearchPageQuery,
  GetApplicationSearchPageQueryVariables
>;
export const GetArtistByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetArtistById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'artist' },
            name: { kind: 'Name', value: 'artistById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'artistId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetArtistByIdQuery, GetArtistByIdQueryVariables>;
export const GetArtistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetArtists' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'artists' },
            name: { kind: 'Name', value: 'allArtistsList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'applications' },
                  name: { kind: 'Name', value: 'applicationsByArtistIdList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetArtistsQuery, GetArtistsQueryVariables>;
export const GetContactsByApplicationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContactsByApplicationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'accounts' },
            name: { kind: 'Name', value: 'allAccountArtistBindingsList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'application' },
            name: { kind: 'Name', value: 'applicationById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applicationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'contacts' },
                  name: { kind: 'Name', value: 'contactsByApplicationIdList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContactsByApplicationIdQuery,
  GetContactsByApplicationIdQueryVariables
>;
export const GetCountriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCountries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'countries' },
            name: { kind: 'Name', value: 'allCountriesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetCountriesAndProvincesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCountriesAndProvinces' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'countries' },
            name: { kind: 'Name', value: 'allCountriesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'provinces' },
            name: { kind: 'Name', value: 'allProvincesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'province' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCountriesAndProvincesQuery,
  GetCountriesAndProvincesQueryVariables
>;
export const GetDashboardContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDashboardContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumFundingAmount' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'currentRound' },
            name: { kind: 'Name', value: 'getCurrentFundingRound' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boardMeetingDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'allowOrion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closingSoon' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'nextRound' },
            name: { kind: 'Name', value: 'getNextFundingRound' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'previousRound' },
            name: { kind: 'Name', value: 'getPreviousFundingRound' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'nextOrionRound' },
            name: { kind: 'Name', value: 'getNextOrionRound' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'countries' },
            name: { kind: 'Name', value: 'allCountriesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'provinces' },
            name: { kind: 'Name', value: 'allProvincesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'province' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'legalStatuses' },
            name: { kind: 'Name', value: 'allLegalStatusesList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'DISPLAYORDER_ASC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiresProvince' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provinceTitle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'pendingAccountBindings' },
            name: { kind: 'Name', value: 'getPendingEmailsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'artistId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'artistId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitationId' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'artist' },
            name: { kind: 'Name', value: 'artistById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'artistId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'canStartApplication' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'legalStatus' },
                  name: {
                    kind: 'Name',
                    value: 'legalStatusByCompanyLegalStatus',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'management' },
                  name: { kind: 'Name', value: 'managementByManagementId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'countryId' },
                        name: { kind: 'Name', value: 'countryByCountryId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactPerson' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'legalStatus' },
                        name: {
                          kind: 'Name',
                          value: 'legalStatusByLegalStatus',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'legalProvince' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyLegalProvince' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'provinceId' },
                  name: { kind: 'Name', value: 'provinceByProvinceId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'releases' },
                  name: { kind: 'Name', value: 'releasesByArtistIdList' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'EnumValue', value: 'DATE_DESC' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'eligible' },
                        name: { kind: 'Name', value: 'isEligible' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'releaseDate' },
                        name: { kind: 'Name', value: 'date' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'labelByLabelId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'otherLabel' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'accountBindings' },
                  name: {
                    kind: 'Name',
                    value: 'accountArtistBindingsByArtistIdList',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'totalFunded' },
                  name: { kind: 'Name', value: 'getTotalFunded' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCoreEligible' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasEligibleRelease' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aboveSalesRequirement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aboveLifetimeFundingLimit' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'eligibilityNotice' },
                  name: { kind: 'Name', value: 'eligibilityNoticeByArtistId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orionApprovalCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orionApprovalLimit' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasReachedOrionApprovalLimit' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'showLegacyClaimsMessage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'activeApplications' },
            name: { kind: 'Name', value: 'getApplicationsByIsActiveList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'artistId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'artistId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isActive' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'fundingRound' },
                  name: { kind: 'Name', value: 'fundingRoundByFundingRoundId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardMeetingDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'claimDeadline' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalNumberOfTourDates' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalNumberOfFundingRequests' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvedTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestedTotal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPaid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'claimsResubmissionDeadline' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reopenClaimsNotes' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'closedApplications' },
            name: { kind: 'Name', value: 'getApplicationsByIsActiveList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'artistId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'artistId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isActive' },
                value: { kind: 'BooleanValue', value: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '3' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'fundingRound' },
                  name: { kind: 'Name', value: 'fundingRoundByFundingRoundId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDashboardContextQuery,
  GetDashboardContextQueryVariables
>;
export const GetEligibilityContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEligibilityContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'genres' },
            name: { kind: 'Name', value: 'allGenresList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'condition' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'enabled' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'DISPLAYORDER_ASC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumSales' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maximumSales' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'labels' },
            name: { kind: 'Name', value: 'allLabelsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'condition' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'enabled' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'DISPLAY_ORDER_ASC' },
                    { kind: 'EnumValue', value: 'NAME_ASC' },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'distributors' },
            name: { kind: 'Name', value: 'allDistributorsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'DISPLAY_ORDER_ASC' },
                    { kind: 'EnumValue', value: 'NAME_ASC' },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eligibilityCutoffDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releasesScannedDivisor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tracksDownloadedDivisor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tracksStreamedDivisor' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEligibilityContextQuery,
  GetEligibilityContextQueryVariables
>;
export const GetFluxCapacitorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFluxCapacitor' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'time' },
            name: { kind: 'Name', value: 'getFluxCapacitor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'rounds' },
            name: { kind: 'Name', value: 'allFundingRoundsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'TITLE_ASC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFluxCapacitorQuery,
  GetFluxCapacitorQueryVariables
>;
export const GetHomeContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'now' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'rounds' },
            name: { kind: 'Name', value: 'getRoundScheduleList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boardMeetingDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'claimDeadline' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHomeContextQuery, GetHomeContextQueryVariables>;
export const GetOpenBoardMeetingContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOpenBoardMeetingContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'round' },
            name: { kind: 'Name', value: 'getCurrentBoardRound' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfCoreApplications' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfOrionApplications' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'pageBlocks' },
                  name: {
                    kind: 'Name',
                    value: 'boardMeetingBlocksByFundingRoundIdList',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'EnumValue', value: 'DISPLAY_ORDER_ASC' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'boardMeetingBlockAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'nextMeetingDate' },
            name: { kind: 'Name', value: 'getNextBoardMeetingDate' },
          },
        ],
      },
    },
    ...BoardMeetingBlockAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetOpenBoardMeetingContextQuery,
  GetOpenBoardMeetingContextQueryVariables
>;
export const GetProvincesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProvinces' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'provinces' },
            name: { kind: 'Name', value: 'allProvincesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'province' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProvincesQuery, GetProvincesQueryVariables>;
export const GetRedeemAccountInvitationContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRedeemAccountInvitationContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'countries' },
            name: { kind: 'Name', value: 'allCountriesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regionId' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'provinces' },
            name: { kind: 'Name', value: 'allProvincesList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'province' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'legalStatuses' },
            name: { kind: 'Name', value: 'allLegalStatusesList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'DISPLAYORDER_ASC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiresProvince' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provinceTitle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'status' },
            name: { kind: 'Name', value: 'accountInvitationStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tag' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tag' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOrion' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumPasswordLength' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRedeemAccountInvitationContextQuery,
  GetRedeemAccountInvitationContextQueryVariables
>;
export const GetRedeemArtistInvitationContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRedeemArtistInvitationContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'status' },
            name: { kind: 'Name', value: 'artistInvitationStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tag' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tag' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumPasswordLength' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRedeemArtistInvitationContextQuery,
  GetRedeemArtistInvitationContextQueryVariables
>;
export const GetRedeemBoardInvitationContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRedeemBoardInvitationContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'status' },
            name: { kind: 'Name', value: 'boardMemberInvitationStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tag' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tag' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumPasswordLength' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRedeemBoardInvitationContextQuery,
  GetRedeemBoardInvitationContextQueryVariables
>;
export const GetReleasePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReleasePage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'releaseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'artistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'release' },
            name: { kind: 'Name', value: 'releaseById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'releaseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'releaseAttributes' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'artist' },
            name: { kind: 'Name', value: 'artistById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'artistId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'applications' },
                  name: { kind: 'Name', value: 'applicationsByArtistIdList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submittedAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'genres' },
            name: { kind: 'Name', value: 'allGenresList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'condition' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'enabled' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'DISPLAYORDER_ASC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumSales' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maximumSales' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'labels' },
            name: { kind: 'Name', value: 'allLabelsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'condition' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'enabled' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'DISPLAY_ORDER_ASC' },
                    { kind: 'EnumValue', value: 'NAME_ASC' },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'distributors' },
            name: { kind: 'Name', value: 'allDistributorsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'DISPLAY_ORDER_ASC' },
                    { kind: 'EnumValue', value: 'NAME_ASC' },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eligibilityCutoffDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tracksDownloadedDivisor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tracksStreamedDivisor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releasesScannedDivisor' },
          },
        ],
      },
    },
    ...ReleaseAttributesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetReleasePageQuery, GetReleasePageQueryVariables>;
export const GetReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fiscalYearEnd' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'newArtists' },
            name: { kind: 'Name', value: 'reportAnnualNewArtists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' },
                },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'submitted' },
            name: { kind: 'Name', value: 'reportAnnualSubmitted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' },
                },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'genre' },
            name: { kind: 'Name', value: 'reportAnnualGenre' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' },
                },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'program' },
            name: { kind: 'Name', value: 'reportAnnualProgram' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' },
                },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'province' },
            name: { kind: 'Name', value: 'reportAnnualProvince' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReportsQuery, GetReportsQueryVariables>;
export const ResetPasswordStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResetPasswordStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'status' },
            name: { kind: 'Name', value: 'resetPasswordStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tag' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tag' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumPasswordLength' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordStatusQuery,
  ResetPasswordStatusQueryVariables
>;
export const SearchApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchApplications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roundTitle' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'alternateRequest' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'SearchApplicationsAlternateRequest',
              },
            },
          },
          defaultValue: { kind: 'NullValue' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationStatus' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ApplicationStatus' },
          },
          defaultValue: { kind: 'NullValue' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ApplicationType' },
          },
          defaultValue: { kind: 'NullValue' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stage' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApplicationStage' },
            },
          },
          defaultValue: { kind: 'NullValue' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchApplicationsSortOrder' },
          },
          defaultValue: { kind: 'EnumValue', value: 'MODIFIED_DESC' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resultLimit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '20' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resultOffset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reviewStatus' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApplicationReviewStatus' },
            },
          },
          defaultValue: { kind: 'NullValue' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchQuery' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'NullValue' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'initialPaymentPaid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'NullValue' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'searchApplicationsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'alternateRequest' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'alternateRequest' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applicationStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applicationStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applicationType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applicationType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'stage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resultLimit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resultLimit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resultOffset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resultOffset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reviewStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reviewStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roundTitle' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'roundTitle' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchQuery' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchQuery' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'initialPaymentPaid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'initialPaymentPaid' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'artist' },
                        name: { kind: 'Name', value: 'artistByArtistId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'fundingRound' },
                        name: {
                          kind: 'Name',
                          value: 'fundingRoundByFundingRoundId',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submittedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'artistModifiedAt' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'numberOfPendingRequests',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'numberOfUnsettledRequests',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalRequested' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvedTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tentativeTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'claimedTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPaid' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'claimsResubmissionDeadline',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchApplicationsQuery,
  SearchApplicationsQueryVariables
>;
