import { GraphQLClient } from 'graphql-request';
import {
  CreateFundingRequestDocument,
  AddAlternateRequestDocument,
} from '../../graphql/operations';
import { AddFundingRequest } from '../../events/AddFundingRequest';
import { prepareFundingRequest } from './prepareFundingRequest';
import { upsertFundingRequestResultSchema } from '../../schemas/fundingRequests/upsertFundingRequestResultSchema';
import { prepareExpense } from './Expenses/prepareExpense';

export const createAddFundingRequestPromise =
  (client: GraphQLClient) =>
  async (_: unknown, { request, expense, isAlternate }: AddFundingRequest) => {
    const preparedRequest = prepareFundingRequest(request);

    const { request: requestResponse } = isAlternate
      ? await client.request(AddAlternateRequestDocument, {
          input: {
            ...preparedRequest,
            expense: expense ? prepareExpense(expense) : undefined,
          },
        })
      : await client.request(CreateFundingRequestDocument, {
          request: preparedRequest,
        });

    return upsertFundingRequestResultSchema.parse(
      requestResponse?.fundingRequest
    );
  };

export type CreateAddFundingRequestPromise = ReturnType<
  typeof createAddFundingRequestPromise
>;

export type CreateAddFundingRequestPromiseResult = Awaited<
  ReturnType<CreateAddFundingRequestPromise>
>;
