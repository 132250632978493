import cn from 'classnames';
import { Button } from '../../Button';
import { Icon, IconName } from '../../Icon';
import { Feedback, FeedbackType } from '../../Feedback';
import { match } from 'ts-pattern';
import { formatCalendarDate } from '../../../utils/formatCalendarDate';
import {
  ApplicationStage,
  ApplicationStatus,
  FundingRequestStatus,
} from '../../../graphql/operations';
import {
  FundingRequestCardMachineSender,
  FundingRequestCardMachineState,
} from '../../../machines/components/FundingRequestCardMachine';
import { InlineFormContainer } from '../../InlineFormContainer';
import { Select } from '../../Select';
import { TextInput } from '../../TextInput';
import { FormField } from '../../FormField';
import { ExpenseBlockController } from '../Expenses/ExpenseBlockController';
import { parsePositiveFloat } from '../../../utils/parsePositiveFloat';
import { formatMoney } from '../../../utils/formatMoney';
import { ExpenseBlockMachineActor } from '../../../machines/components/ExpenseBlockMachine';

import styles from './FundingRequestCard.module.scss';

interface FundingRequestCardProps {
  state: FundingRequestCardMachineState;
  send: FundingRequestCardMachineSender;
  showExpenseEdit: boolean;
  showExpenseDelete: boolean;
}

const fundingRequestStatuses = [
  { label: 'Approved', status: FundingRequestStatus.Approved },
  { label: 'Denied', status: FundingRequestStatus.Denied },
  { label: 'Pending', status: FundingRequestStatus.Pending },
  { label: 'Unsettled', status: FundingRequestStatus.Unsettled },
];

export function FundingRequestCardView({
  state,
  send,
  showExpenseEdit,
  showExpenseDelete,
}: FundingRequestCardProps) {
  const {
    request,
    applicationStatus,
    isAdmin,
    isBoard,
    isClaimsSubmitted,
    stage,
  } = state.context;
  const hasAdminHandledApplicaton =
    applicationStatus === ApplicationStatus.Approved ||
    applicationStatus === ApplicationStatus.Denied;

  const feedbackProps = match<
    { status: FundingRequestStatus; cancelled: boolean },
    { icon: IconName; type: FeedbackType; status: string }
  >({ status: request.status, cancelled: request.cancelled })
    .with({ cancelled: true }, () => ({
      icon: 'alertTriangle',
      type: 'warning',
      status: 'Cancelled',
    }))
    .with({ status: FundingRequestStatus.Pending }, () => ({
      icon: 'clock',
      type: 'neutral',
      status: 'Pending',
    }))
    .with({ status: FundingRequestStatus.Unsettled }, () => ({
      icon: 'clock',
      type: 'neutral',
      status: 'Unsettled',
    }))
    .with({ status: FundingRequestStatus.Approved }, () => ({
      icon: 'checkCircle',
      type: 'positive',
      status: 'Approved',
    }))
    .with({ status: FundingRequestStatus.Denied }, () => ({
      icon: 'xCircle',
      type: 'warning',
      status: 'Denied',
    }))
    .run();

  return (
    <div className={styles.container} id={request.id}>
      <div className={styles.row}>
        <div className={styles.col}>
          <h3 className={styles.heading}>{request.fundingType}</h3>
          <div className={styles.darkerBold}>
            {formatCalendarDate(request.date, 'short')}
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <div className={cn(styles.buttonsContainer, styles.buttonsSection)}>
            {match(request.isWritable)
              .with(true, () => (
                <>
                  <Button
                    variant="neutral"
                    size="small"
                    label="Remove"
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure you want to remove this funding request?'
                        )
                      ) {
                        send({ type: 'DELETE_REQUEST', id: request.id });
                      }
                    }}
                  />
                  <Button
                    variant="secondary"
                    size="small"
                    label="Edit"
                    startIcon="edit"
                    onClick={() =>
                      send({ type: 'OPEN_REQUEST', id: request.id })
                    }
                  />
                </>
              ))
              .with(false, () => (
                <>
                  <Button
                    variant="secondary"
                    size="small"
                    label="View"
                    onClick={() =>
                      send({ type: 'OPEN_REQUEST', id: request.id })
                    }
                  />
                  {request.isOwner && request.isCancellable && (
                    <Button
                      label="Void Request"
                      variant="neutral"
                      size="small"
                      onClick={() => {
                        if (
                          window.confirm(
                            'Are you sure you want to void this funding request?'
                          )
                        ) {
                          send({ type: 'CANCEL_REQUEST', id: request.id });
                        }
                      }}
                    />
                  )}
                </>
              ))
              .otherwise(() => null)}{' '}
          </div>
          {request.status === FundingRequestStatus.Unsettled && (
            <Button
              className={styles.expenseButton}
              variant="secondary"
              size="small"
              label="View Expenses"
              onClick={() =>
                send({
                  type: 'OPEN_REQUEST',
                  id: request.id,
                  isUnsettledCardView: true,
                })
              }
            />
          )}
        </div>
      </div>
      <div className={styles.statuses}>
        <div>
          <span className="body-text-bold">
            ${parseFloat(request.cost).toLocaleString()}
          </span>{' '}
          requested
        </div>
        {((!state.matches('form.editing') &&
          !state.matches('form.complete') &&
          (isAdmin || isBoard)) ||
          hasAdminHandledApplicaton) &&
          request.status === FundingRequestStatus.Approved && (
            <div className={styles.approved}>
              <Icon name="checkCircle" />
              <div>
                <span className="body-text-bold">
                  ${parseFloat(request.amountApproved).toLocaleString()}
                </span>{' '}
                approved
              </div>
            </div>
          )}
      </div>
      {hasAdminHandledApplicaton &&
        (request.status === FundingRequestStatus.Approved ||
          request.status === FundingRequestStatus.Unsettled) && (
          <div className={styles.expenses}>
            <Icon name="file" />
            <span className="body-text-bold">
              {formatMoney(
                state.context.request.expenses.reduce(
                  (acc, cur) => acc + parsePositiveFloat(cur.cost),
                  0
                )
              )}
            </span>
            spent ({state.context.request.expenses.length} expense(s))
          </div>
        )}

      {request.isWritable && request.isTooOld && (
        <Feedback size="compact" type="warning">
          <div>
            <span className={styles.label}>
              This funding request is not eligible.
            </span>{' '}
            <span>
              Date must be on or after{' '}
              {formatCalendarDate(state.context.presentCutoffDate)}.
            </span>
          </div>
        </Feedback>
      )}
      {request.isWritable && request.isTooNew && (
        <Feedback size="compact" type="warning">
          <div>
            <span className={styles.label}>
              This funding request is not eligible.
            </span>{' '}
            <span>
              {state.context.futureCutoffDate === undefined
                ? // The first string should never be displayed since you can't be
                  // too far ahead of a date that is undefined but if we somehow get
                  // into that state at least some error will be displayed to the user.
                  'Date is too far in the future.'
                : `Date must be on or before ${formatCalendarDate(
                    state.context.futureCutoffDate
                  )}.`}
            </span>
          </div>
        </Feedback>
      )}
      {((!state.matches('form.editing') &&
        !state.matches('form.complete') &&
        (isAdmin || isBoard)) ||
        hasAdminHandledApplicaton) && (
        <Feedback
          size="compact"
          type={feedbackProps.type}
          icon={feedbackProps.icon}
          title={feedbackProps.status}
          {...(isAdmin && {
            feedbackButtons: (
              <Button
                size="small"
                variant="secondary"
                startIcon="settings"
                label="Change Approval"
                onClick={() => send({ type: 'TOGGLE_FORM_VISIBILITY' })}
              />
            ),
          })}
        />
      )}
      {(state.matches('form.editing') || state.matches('form.complete')) &&
        isAdmin && (
          <InlineFormContainer
            primaryButtonText="Submit Review"
            primaryButtonOnClick={() => send({ type: 'UPDATE_ADMIN_REQUEST' })}
            secondaryButtonText="Cancel"
            secondaryButtonOnClick={() =>
              send({ type: 'TOGGLE_FORM_VISIBILITY' })
            }
          >
            <div className={styles.row}>
              <div className={styles.col}>
                <FormField label="Status">
                  <Select
                    id="status"
                    items={fundingRequestStatuses}
                    itemToKey={(item) => item?.status}
                    getItemText={(item) => item?.label}
                    selectedItem={
                      fundingRequestStatuses.find(
                        ({ status }) =>
                          status === state.context.adminRequest.status
                      ) ?? null
                    }
                    onChange={({ status }) =>
                      send({ type: 'SET_STATUS', status })
                    }
                  />
                </FormField>
              </div>
              <div className={styles.col}>
                <FormField
                  label="Amount Approved"
                  feedbackType="warning"
                  formFieldStatus={
                    state.matches('form.editing.amount.invalid')
                      ? 'error'
                      : 'default'
                  }
                  infoLabel={match(state)
                    .when(
                      () => state.matches('form.editing.amount.invalid.empty'),
                      () => 'This field is required.'
                    )
                    .when(
                      () => state.matches('form.editing.amount.invalid.value'),
                      () => 'This field must be numeric.'
                    )
                    .when(
                      () => state.matches('form.editing.amount.invalid.high'),
                      () => "Amount can't be greater than requested amount."
                    )
                    .otherwise(() => '')}
                >
                  <TextInput
                    id="amountApproved"
                    value={state.context.adminRequest.approvedCostOverride}
                    onChange={(amountApproved) =>
                      send({ type: 'SET_AMOUNT_APPROVED', amountApproved })
                    }
                    onBlur={() => send('BLUR_AMOUNT_APPROVED')}
                    onFocus={() => send('FOCUS_AMOUNT_APPROVED')}
                  />
                </FormField>
              </div>
            </div>
            {(state.matches('form.editing.warning.on') ||
              state.matches('form.editing.warning.pulse')) && (
              <Feedback
                type="warning"
                pulse={state.matches('form.editing.warning.pulse')}
              >
                Please address all issues in the fields above.
              </Feedback>
            )}
          </InlineFormContainer>
        )}
      {request.isOwner &&
        ((request.isCancellable &&
          request.status === FundingRequestStatus.Approved) ||
          request.status === FundingRequestStatus.Unsettled) &&
        !state.context.hasOpenExpense &&
        (!isClaimsSubmitted || stage === ApplicationStage.ClaimsReopened) && (
          <div className={styles.addExpenseButton}>
            <Button
              label="Add Expense"
              size="small"
              onClick={() => send('OPEN_EXPENSE')}
            />
          </div>
        )}
      {state.context.hasOpenExpense && (
        <ExpenseBlockController
          actor={state.children.expenseBlockMachine as ExpenseBlockMachineActor}
          showEdit={showExpenseEdit}
          showDelete={showExpenseDelete}
        />
      )}
    </div>
  );
}
