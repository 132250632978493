import {
  ExpenseMachineSender,
  ExpenseMachineState,
} from '../../../machines/components/ExpenseMachine';
import { InlineFormContainer } from '../../InlineFormContainer';
import { InlineFormHeading } from '../../InlineFormHeading';
import { ExpenseFormFields } from './ExpenseFormFields';

interface ExpenseViewProps {
  state: ExpenseMachineState;
  send: ExpenseMachineSender;
}

export function ExpenseView({ state, send }: ExpenseViewProps) {
  return state.context.isUnsettledRequestExpense ? (
    <>
      <InlineFormHeading>Add Expense</InlineFormHeading>
      <ExpenseFormFields state={state} send={send} />
    </>
  ) : (
    <InlineFormContainer
      primaryButtonText={
        state.context.editContext ? 'Update Expense' : 'Add Expense'
      }
      primaryButtonOnClick={() => send('ADD_EXPENSE')}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={() => send('CLOSE_EXPENSE')}
    >
      <InlineFormHeading>
        {state.context.editContext ? 'Update Expense' : 'Add Expense'}
      </InlineFormHeading>
      <ExpenseFormFields state={state} send={send} />
    </InlineFormContainer>
  );
}
