import { Feedback } from '../Feedback';
import { match } from 'ts-pattern';
import { InlineFormContainer } from '../InlineFormContainer';
import { InlineFormHeading } from '../InlineFormHeading';
import { FormField } from '../FormField';
import { TextInput } from '../TextInput';
import { TextArea } from '../TextArea';
import {
  FundingRequestFormMachineSender,
  FundingRequestFormMachineState,
} from '../../machines/components/FundingRequestFormMachine';
import { InvalidRequestFeedback } from './InvalidRequestFeedback';
import { ApplicationType } from '../../graphql/operations';
import { formatCalendarDate } from '../../utils/formatCalendarDate';
import { ErrorFeedback } from '../ErrorFeedback';
import { DatePicker } from '../DatePicker/DatePicker';
import { closeFundingRequest } from '../../events/CloseFundingRequest';
import { ExpenseController } from './Expenses/ExpenseController';

import styles from './FundingRequestFormView.module.scss';

interface FundingRequestFormViewProps {
  state: FundingRequestFormMachineState;
  send: FundingRequestFormMachineSender;
}

export function FundingRequestFormView({
  state,
  send,
}: FundingRequestFormViewProps) {
  const {
    request,
    year,
    budgetForFundingRequests,
    editContext,
    alternate,
    expenseRef,
  } = state.context;

  const blockTitle = match(state.context)
    .with(
      { alternate: true, applicationType: ApplicationType.Core },
      () => 'Alternate Digital Content Request'
    )
    .with(
      { alternate: true, applicationType: ApplicationType.Orion },
      () => 'Alternate Funding Request'
    )
    .with(
      { alternate: false, applicationType: ApplicationType.Core },
      () => 'Digital Content Request'
    )
    .with(
      { alternate: false, applicationType: ApplicationType.Orion },
      () => 'Funding Request'
    )
    .exhaustive();
  return (
    <InlineFormContainer
      rawFeedback={
        state.matches('form.cost.invalid.cap') && (
          <InvalidRequestFeedback
            title={`This ${blockTitle} is not eligible`}
            size="default"
            year={year}
            budget={budgetForFundingRequests}
          >
            <p className={styles.feedback}>
              Please adjust your request to stay within this limit or remove one
              of your previously added requests.
            </p>
          </InvalidRequestFeedback>
        )
      }
      primaryButtonDisabled={state.matches('form.cost.invalid.cap')}
      primaryButtonText={
        editContext === undefined ? `Add ${blockTitle}` : `Save ${blockTitle}`
      }
      primaryButtonOnClick={() => send('SAVE_REQUEST')}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={() => send(closeFundingRequest(editContext?.id))}
    >
      <InlineFormHeading>
        {editContext === undefined ? `Add ${blockTitle}` : `Edit ${blockTitle}`}
      </InlineFormHeading>
      <FormField
        id="funding"
        label="Activity"
        description={
          state.context.applicationType === ApplicationType.Core
            ? 'Videographer, media for online marketing and/or the cost of a music video'
            : 'Recording, promotion, publicity, advertising, digital marketing, touring, videos, etc.'
        }
        feedbackType="warning"
        formFieldStatus={
          state.matches('form.funding-type.invalid') ? 'error' : 'default'
        }
        infoLabel={
          state.matches('form.funding-type.invalid')
            ? 'This field is required.'
            : ''
        }
      >
        <TextInput
          id="funding"
          label="funding"
          value={request.fundingType}
          onChange={(fundingType) =>
            send({ type: 'SET_FUNDING_TYPE', fundingType })
          }
          onBlur={() => send({ type: 'BLUR_FUNDING_TYPE' })}
          onFocus={() => send({ type: 'FOCUS_FUNDING_TYPE' })}
        />
      </FormField>
      <div className={styles.feedbackCol}>
        <div className={styles.row}>
          <FormField
            id="cost"
            label="Cost"
            description="In Canadian dollars including taxes"
            feedbackType="warning"
            formFieldStatus={
              state.matches('form.cost.invalid') ? 'error' : 'default'
            }
            infoLabel={match(state)
              .when(
                ({ matches }) => matches('form.cost.invalid.empty'),
                () => 'This field is required.'
              )
              .when(
                ({ matches }) => matches('form.cost.invalid.type'),
                () => 'This field must be numeric.'
              )
              .otherwise(() => '')}
          >
            <TextInput
              id="cost"
              label="cost"
              value={request.cost}
              placeholder="$"
              onChange={(cost) => send({ type: 'SET_COST', cost })}
              onBlur={() => send({ type: 'BLUR_COST' })}
              onFocus={() => send({ type: 'FOCUS_COST' })}
            />
          </FormField>
          <FormField
            id="date"
            label="Start Date"
            description="Starting date for this expense"
            feedbackType="warning"
            formFieldStatus={
              state.matches('form.date.invalid') ? 'error' : 'default'
            }
            infoLabel={match(state)
              .when(
                ({ matches }) => matches('form.date.invalid.empty'),
                () => 'This field is required.'
              )
              .when(
                ({ matches }) => matches('form.date.invalid.past'),
                () =>
                  alternate
                    ? `Start date must be on or after application submission date.`
                    : `Start date must be on or after ${formatCalendarDate(
                        state.context.presentCutoffDate
                      )}.`
              )
              .when(
                ({ matches }) => matches('form.date.invalid.future'),
                () =>
                  state.context.futureCutoffDate === undefined
                    ? // The first string should never be displayed since you can't be
                      // too far ahead of a date that is undefined but if we somehow get
                      // into that state at least some error will be displayed to the user.
                      'Start date is too far in the future.'
                    : `Start date must be on or before ${formatCalendarDate(
                        state.context.futureCutoffDate
                      )}.`
              )
              .otherwise(() => '')}
          >
            <DatePicker
              isDisabled={Boolean(editContext && alternate)}
              aria-label="Round start date"
              value={request.date}
              onChange={(date) => send({ type: 'SET_DATE', date })}
              onFocus={() => send('FOCUS_DATE')}
            />
          </FormField>
        </div>
        {state.matches('form.cost.invalid.cap') && (
          <InvalidRequestFeedback
            size="compact"
            year={year}
            budget={budgetForFundingRequests}
          />
        )}
      </div>
      <FormField
        id="strategy"
        label="Notes"
        note="Optional"
        description="Describe any specifics or additional context"
      >
        <TextArea
          id="strategy"
          size="large"
          label="strategy"
          value={request.strategy}
          onChange={(strategy) => send({ type: 'SET_STRATEGY', strategy })}
        />
      </FormField>
      {alternate && (
        <FormField
          id="reason"
          label={`Reason for ${blockTitle}`}
          feedbackType="warning"
          formFieldStatus={
            state.matches('form.reason.invalid') ? 'error' : 'default'
          }
          infoLabel={match(state)
            .when(
              ({ matches }) => matches('form.reason.invalid'),
              () => 'This field is required.'
            )
            .otherwise(() => '')}
        >
          <TextArea
            id="reason"
            size="large"
            label="reason"
            value={state.context.request.reason}
            onChange={(reason) => send({ type: 'SET_REASON', reason })}
            onBlur={() => send({ type: 'BLUR_REASON' })}
            onFocus={() => send({ type: 'FOCUS_REASON' })}
          />
        </FormField>
      )}
      {expenseRef && <ExpenseController actor={expenseRef} />}
      {(state.matches('form.warning.on') ||
        state.matches('form.warning.pulse') ||
        expenseRef?.getSnapshot()?.matches('editing.warning.on') ||
        expenseRef?.getSnapshot()?.matches('editing.warning.pulse')) &&
        !state.matches('form.cost.invalid.cap') && (
          <Feedback
            type="warning"
            pulse={
              state.matches('form.warning.pulse') ||
              expenseRef?.getSnapshot()?.matches('editing.warning.pulse')
            }
            className={styles.feedback}
          >
            Please fix the above issues.
          </Feedback>
        )}
      {state.matches('form.warning.error') && <ErrorFeedback />}
    </InlineFormContainer>
  );
}
