import { FundingRequest } from '../graphql/operations';

export type OpenFundingRequest = {
  type: 'OPEN_REQUEST';
  id?: FundingRequest['id'];
  isUnsettledCardView?: boolean;
};

export const openFundingRequest = (
  id?: OpenFundingRequest['id'],
  isUnsettledCardView?: OpenFundingRequest['isUnsettledCardView']
): OpenFundingRequest => ({
  type: 'OPEN_REQUEST',
  id,
  isUnsettledCardView,
});
